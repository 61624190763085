<template>
    <div class="filters-panel text-black" :style="panelStyle">
      <div v-if="currentLevel === 0">
        <header class="header">
          <span></span>
            <img @click="close" class="icon x-icon" src="../assets/x-icon.svg" />
        </header>
        <ul class="selections">

          <li>
            <span>View Details</span>
          </li>
          <li>
            <span>Download</span>
          </li>
        </ul>


        </div>

    </div>
</template>

<script>

    //import ScreenLink from "@/components/ScreenLink";
    export default {
        name: "document-list-options-panel",
        components: {},
        props: {
            left: {
                default: "0em"
            },

            top: {
                default: "0em"
            },

            width: {
                default: "6em"
            },
          selection: {
            default: ""
          }
        },
        data: function () {
            return {
                isInactive: true,
                hasChanges: false,
                title: "Filters",
                currentLevel: 0,
                currentCategory: 0,




            }
        },
        methods: {
            close: function(){
                this.setLevel(0);
                this.$emit('close');
            },

          onLinkClick: function(e){
            this.$emit('link',e)
          },

          showPullout: function(e){
            this.$emit('pullout',e)
          },



            apply: function(){
                this.hasChanges = false;
                this.$emit('apply', this.selections);
            },


            setLevel: function(level){
              console.log("Set Level", level)
                this.currentLevel = level;
            },



        },
        computed: {
            panelStyle: function(){
                return {
                    width: this.width,
                    top: this.top,
                    left: this.left
                }
            },


        }
    }
</script>

<style scoped>


    .filters-panel {
        font-size: 0.9em;
        position: absolute;

        background-color: white;
        box-shadow: 0 2px 5px rgba(0,0,0,0.3);
    }

    .header {
        display: flex;
        justify-content: space-between;
        padding: 1em 1em;
      user-select: none;
    }

    .v-center {
      display: flex;
      align-items: center;
    }

    .footer {
        display: flex;
        justify-content: space-between;
        align-items: center;

        border-top: 1px solid #D8D8D8;
        padding: 0.5em 1em;


    }

    span {
      user-select: none;
    }


    .selections {
        margin: 0;
        margin-bottom: 0.75em;
        padding: 0;
        list-style: none;
    }

    .selections li {
        margin: 0;
        padding: 0.75em 1em;
        display: flex;
        justify-content: space-between;
        align-items: center;


    }

    .selections.checkboxes li {
        margin: 0;
        padding: 0.75em 1em;
        display: flex;
        justify-content: flex-start;
        align-items: center;


    }

    li.selected {
      color: #0369b1;
      background: #e5f0f7;
    }

    .x-icon {
      cursor: pointer;
    }

    .icon {
        height: 1em;
    }

    .back-icon {
      height: 1.5em;
      width: 1em;
      margin-right: 0.2em;
    }

    .icon.chevron-icon {
        height: 0.9em;
        margin-left: 0.5em;
    }

    .icon.chevron-icon-left {
        transform: rotate(180deg);
    }

    .check-box {
        margin-right: 0.75em;
        margin-left: 0.5em;
    }

</style>
