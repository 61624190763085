


<template>
<div class="basic-pullout pullout-panel">
  <div class="bkg"></div>
  <div class="primary-panel">

    <div class="basic-scroll" ref="basicScroll">
      <div class="detail-panel" v-if="detailPanel">

        <div class="detail-content" :data-active="detailPanel=='corestream'" >
          <img class="w-full" src="/img/screens/employee/perks/pullout-corestream.png?v=06102024" />
          <div class="links">
            <screen-link left="50%" top="3%" width="15%" height="5%" data-tgt="" dot-class="dot-left-center" data-is-overlay="true" @click.native="$emit('close')"></screen-link>
            <screen-link left="2%" top="69%" width="6%" height="5%" data-tgt="corestream-agree" dot-class="dot-bottom-center" data-is-overlay="true" @click.native="showDetails"></screen-link>
          </div>
        </div>

        <div class="detail-content" :data-active="detailPanel=='corestream-agree'" >
          <img class="w-full" src="/img/screens/employee/perks/pullout-corestream-agree.png?v=06102024" />
          <div class="links">
            <screen-link left="50%" top="3%" width="15%" height="5%" data-tgt="" dot-class="dot-left-center" data-is-overlay="true" @click.native="$emit('close')"></screen-link>
            <screen-link left="66%" top="3%" width="31%" height="5%" dot-class="dot-bottom-center" data-tgt="/#/corestream/home" data-tgt-query="true" @click.native="exitAndOpenTab"></screen-link>
          </div>
        </div>




    </div>

  </div>
  </div>


</div>
</template>

<script>
import ScreenLink from "@/components/ScreenLink";


export default {
    name: "my-perks-pullout",
  components: {ScreenLink},
  props: {
      employeeState: {}
  },
    data: function(){
        return {
          detailPanel: "loan-terms",
          showOptions: false,
        }
        },
    mounted() {
      if(this.$route.query.level !== undefined){
        this.detailPanel = this.$route.query.level;
      }else{
        if(this.initInfo){
          this.detailPanel = this.initInfo; //start screen
        }
      }
    },

    watch: {
      $route: function(next){
        if(next.query.level !== undefined){
          this.detailPanel = next.query.level;
        }
      }
    },

    methods: {

      exitAndOpenTab: function(e){
        this.$emit('close')
        this.$screens.showExternalPage(e)
      },


      setBene: function(confirmed){
        this.employeeState.retirement.hasBeneficiary = confirmed;
        this.showDetails({
          target:{
            dataset: {
              tgt: "add-bene-confirmation"
            }
          }
        })
      },


      showDetails: function(e){

        this.showOptions = false;

        this.detailPanel = e.target.dataset.tgt;

        if(!this.detailPanel){
          this.$emit('close');
          return;
        }

        let query = {...this.$route.query};
        query.level=e.target.dataset.tgt;
        this.$router.replace({ name: this.$route.name, params: this.$route.params, query: query });
        this.$refs['basicScroll'].scrollTo(0,0);


      },

    },
    computed: {

    }
}
</script>

<style scoped>


/* SEE GLOBAL CSS FOR BASIC PULLOUT CLASSES */

.close-icon {
  padding: 0.5em;
  position: absolute;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
}

.detail-content {
  display: none;
}

.detail-content[data-active="true"] {
  display: block;
}

</style>
