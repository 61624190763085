


<template>
<div class="check-stubs-pullout pullout-panel">
  <div class="bkg"></div>
  <div class="check-stubs-list">


    <div class="check-stubs-scroll" v-if="!detailPanel">
      <img class="w-full" src="/img/screens/employee/dashboard/pullouts/pullout-conversations-view-all.png?v=10122022" />
      <div class="links">
        <screen-link left="71.5%" top="1%" width="19%" height="5%" data-tgt="add-note" dot-class="dot-bottom-center" data-is-overlay="true" @click.native="showDetails"></screen-link>
        <screen-link left="93%" top="1%" width="5%" height="5%" data-tgt="profile" dot-class="dot-bottom-center" data-is-overlay="true" @click.native="$emit('close')"></screen-link>

      </div>
    </div>
    <div class="check-stubs-scroll" v-if="detailPanel === 'add-note'">
      <img class="w-full" src="/img/screens/people/pullout-people-conversations-add-note.png?v=10122022" />
      <div class="links">

        <screen-link left="68%" top="1%" width="14%" height="5%" data-tgt="profile" dot-class="dot-bottom-center" data-is-overlay="true" @click.native="$emit('close')"></screen-link>


      </div>
    </div>



  </div>



</div>
</template>

<script>
import ScreenLink from "@/components/ScreenLink";

export default {
    name: "conversations-pullout",
  components: {ScreenLink},
  props: {},
    data: function(){
        return {
          detailPanel: null
        }
        },
    mounted() {
      if(this.$route.query.level !== undefined){
        this.detailPanel = this.$route.query.level;
      }
    },

    watch: {
      $route: function(next){
        if(next.query.level !== undefined){
          this.detailPanel = next.query.level;
        }
      }
    },

    methods: {

      clockIn: function(){
        this.$store.state.clockin = 'working';
        this.$emit('close');
      },

      showDetails: function(e){
          this.detailPanel = e.target.dataset.tgt;
let query = {...this.$route.query};
          query.level=e.target.dataset.tgt;
          this.$router.replace({ name: this.$route.name, params: this.$route.params, query: query });
      },

      onLinkClick: function(e){
        console.log(e);
        this.$emit('link', e);
      }
    },
    computed: {}
}
</script>

<style scoped>


.check-stubs-pullout {
  width: 100%;
  position: absolute;
  top: calc((60/920) * 100%);
  height: calc(((920 - 60)/920) * 100%);

}

.check-stubs-pullout .bkg {
  position: absolute;
  width: 100%;
  height: 100%;
  right: 0;
  background-color: black;
  opacity: 0.4;
}

.check-stubs-pullout img {
  display: block;
}


.check-stubs-list {

  position: absolute;
  width: calc((600/1580) * 100% + 15px);
  height: 100%;

  right: 0;
  display: flex;
  flex-direction: column;

}



.check-stubs-scroll {
  position: relative;

  overflow-y: scroll;
}

.w-full {
  width: 100%;
}

.links {

}

</style>
