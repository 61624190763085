


<template>
<div class="check-stubs-pullout pullout-panel">
  <div class="bkg"></div>
  <div class="check-stubs-list">


    <div class="check-stubs-scroll" v-if="!detailPanel">
      <img class="w-full" src="/img/screens/employee/dashboard/pullouts/pullout-check-stubs.png?v=10122022" />
      <div class="links">
        <screen-link left="92.5%" top="1%" width="6%" height="5%" data-tgt="profile" dot-class="dot-bottom-center" data-is-overlay="true" @click.native="$emit('close')"></screen-link>
        <screen-link left="21.5%" top="21%" width="24%" height="3.5%" data-tgt="upcoming" dot-class="dot-left-center" data-is-overlay="true" @click.native="showDetails"></screen-link>
        <screen-link left="2%" top="40%" width="24%" height="3.5%" data-tgt="check-details" dot-class="dot-right-center" data-is-overlay="true" @click.native="showDetails"></screen-link>
      </div>
    </div>
    <div class="check-stubs-scroll" v-if="detailPanel === 'check-details'">
      <img class="w-full" src="/img/screens/employee/dashboard/pullouts/pullout-check-details.png?v=10122022" />
      <div class="links">
        <screen-link left="1%" top="1%" width="30%" height="4%" data-tgt="" dot-class="dot-left-center" data-is-overlay="true" @click.native="showDetails"></screen-link>
        <screen-link left="92.5%" top="1%" width="6%" height="4%" data-tgt="profile" dot-class="dot-bottom-center" data-is-overlay="true" @click.native="$emit('close')"></screen-link>
        <screen-link v-if="$store.state.company === 'pro'" left="80%" top="5.75%" width="18%" height="5%" data-tgt="/documents/CheckStub_2022-04-29-pro.pdf" dot-class="dot-left-center" data-is-overlay="true" @click.native="$screens.showExternalPage"></screen-link>
        <screen-link v-if="$store.state.company !== 'pro'" left="80%" top="5.75%" width="18%" height="5%" data-tgt="/documents/CheckStub_2022-04-29.pdf" dot-class="dot-left-center" data-is-overlay="true" @click.native="$screens.showExternalPage"></screen-link>

      </div>
    </div>
    <div class="check-stubs-scroll" v-if="detailPanel === 'upcoming'">
      <img class="w-full" src="/img/screens/employee/dashboard/pullouts/pullout-check-stubs-review.png?v=10122022" />
      <coming-soon></coming-soon>
      <div class="links">
        <screen-link left="1%" top="1%" width="30%" height="4%" data-tgt="" dot-class="dot-left-center" data-is-overlay="true" @click.native="showDetails"></screen-link>
        <screen-link left="92.5%" top="1%" width="6%" height="5%" data-tgt="profile" dot-class="dot-bottom-center" data-is-overlay="true" @click.native="$emit('close')"></screen-link>
        <screen-link left="5%" top="28%" width="30%" height="4%" data-tgt="upcoming-details" dot-class="dot-left-center" data-is-overlay="true" @click.native="showDetails"></screen-link>

      </div>
    </div>
    <div class="check-stubs-scroll" v-if="detailPanel === 'upcoming-details'">
      <img class="w-full" src="/img/screens/employee/dashboard/pullouts/pullout-check-stubs-review-check.png?v=10122022" />
      <coming-soon></coming-soon>
      <div class="links">
        <screen-link left="1%" top="1%" width="30%" height="4%" data-tgt="upcoming" dot-class="dot-left-center" data-is-overlay="true" @click.native="showDetails"></screen-link>
        <screen-link left="92.5%" top="1%" width="6%" height="5%" data-tgt="profile" dot-class="dot-bottom-center" data-is-overlay="true" @click.native="$emit('close')"></screen-link>
        <screen-link left="58%" top="5.5%" width="25%" height="5.5%" data-tgt="report-issue" dot-class="dot-left-center" data-is-overlay="true" @click.native="showDetails"></screen-link>

      </div>
    </div>
    <div class="check-stubs-scroll" v-if="detailPanel === 'report-issue'">
      <img class="w-full" src="/img/screens/employee/dashboard/pullouts/pullout-employee-check-stubs-report-issue.png?v=10122022" />
      <coming-soon></coming-soon>
      <div class="links">
        <screen-link left="68%" top="1%" width="14%" height="5%" data-tgt="upcoming-details" dot-class="dot-bottom-center" data-is-overlay="true" @click.native="showDetails"></screen-link>

        <screen-link left="6%" top="48%" width="90%" height="23%" data-tgt="report-issue-details" dot-class="dot-left-center" data-is-overlay="true" @click.native="showDetails"></screen-link>

      </div>
    </div>

    <div class="check-stubs-scroll" v-if="detailPanel === 'report-issue-details'">
      <img class="w-full" src="/img/screens/employee/dashboard/pullouts/pullout-employee-check-stubs-report-issue-details.png?v=10122022" />
      <coming-soon></coming-soon>
      <div class="links">
        <screen-link left="68%" top="1%" width="14%" height="5%" data-tgt="upcoming-details" dot-class="dot-bottom-center" data-is-overlay="true" @click.native="showDetails"></screen-link>
        <screen-link left="83%" top="1%" width="14%" height="5%" data-tgt="upcoming-details" dot-class="dot-bottom-center" data-is-overlay="true" @click.native="showDetails"></screen-link>

        <screen-link left="6%" top="48%" width="90%" height="23%" data-tgt="report-issue-details" dot-class="dot-left-center" data-is-overlay="true" @click.native="showDetails"></screen-link>

      </div>
    </div>

    <div class="check-stubs-scroll" v-if="detailPanel === 'fed-tax-details'">
      <img class="w-full" src="/img/screens/employee/mypay/pullouts/pullout-federal-tax-details.png?v=10122022" />
      <div class="links">

        <screen-link left="92.5%" top="1%" width="6%" height="4%" data-tgt="profile" dot-class="dot-bottom-center" data-is-overlay="true" @click.native="$emit('close')"></screen-link>

      </div>
    </div>


  </div>



</div>
</template>

<script>
import ScreenLink from "@/components/ScreenLink";
import ComingSoon from "@/components/ComingSoon";
export default {
    name: "check-stubs-pullout",
  components: {ComingSoon, ScreenLink},
  props: {},
    data: function(){
        return {
          detailPanel: null
        }
        },
    mounted() {
      if(this.$route.query.level !== undefined){
        this.detailPanel = this.$route.query.level;
      }
    },

    watch: {
      $route: function(next){
        if(next.query.level !== undefined){
          this.detailPanel = next.query.level;
        }
      }
    },

    methods: {
      showDetails: function(e){
          this.detailPanel = e.target.dataset.tgt;
let query = {...this.$route.query};
          query.level=e.target.dataset.tgt;
          this.$router.replace({ name: this.$route.name, params: this.$route.params, query: query });
      },

      onLinkClick: function(e){
        console.log(e);
        this.$emit('link', e);
      }
    },
    computed: {}
}
</script>

<style scoped>


.check-stubs-pullout {
  width: 100%;
  position: absolute;
  top: calc((60/920) * 100%);
  height: calc(((920 - 60)/920) * 100%);

}

.check-stubs-pullout .bkg {
  position: absolute;
  width: 100%;
  height: 100%;
  right: 0;
  background-color: black;
  opacity: 0.4;
}

.check-stubs-pullout img {
  display: block;
}


.check-stubs-list {

  position: absolute;
  width: calc((600/1580) * 100% + 15px);
  height: 100%;

  right: 0;
  display: flex;
  flex-direction: column;

}



.check-stubs-scroll {
  position: relative;

  overflow-y: scroll;
}

.w-full {
  width: 100%;
}

.links {

}

</style>
