<template>
  <div class="document-sign">
    <div v-if="!isSigned">
      <img src="/img/screens/onboarding/document-sign-popover.png?v=10122022" />
      <screen-link left="90%" top="2%" width="10%" height="15%" dot-class="dot-right-center" @click.native="close"></screen-link>
      <screen-link left="3%" top="39%" width="60%" height="35%" dot-class="dot-left-center" @click.native="setSigned(true)"></screen-link>
    </div>
    <div v-if="isSigned">
      <img src="/img/screens/peo/document-signed-popover.png?v=10122022" />
      <p class="signature">{{ person }}</p>
      <screen-link left="90%" top="2%" width="10%" height="15%" dot-class="dot-right-center" @click.native="close"></screen-link>
      <screen-link left="3%" top="80%" width="23%" height="15%" dot-class="dot-right-center" @click.native="confirm"></screen-link>

    </div>



  </div>
</template>

<script>
import ScreenLink from "@/components/ScreenLink";

export default {
  name: "document-sign",
  components: { ScreenLink },
  props: {
    confirmTgt: {},
    confirmMode: {},
    person: {
      type: String,
      default: "Andrea Apple"
    }
  },
  data: function(){
    return {
      isSigned: false
    }
  },
  methods: {
    close: function(){
      this.$screens.setOverlayPanel()
    },

    setSigned: function(signed){
      this.isSigned = signed;
    },
    confirm: function(){
      this.$screens.onLinkClick({
        target: {
          dataset: {
            tgt: this.confirmTgt,
            tgtMode: this.confirmMode,
            tgtScrolltop: true
          }
        }
      })
    }
  }
}
</script>

<style scoped>

.document-sign {

  position: absolute;
  top: 100%;
  left: 30%;
  width: calc((452 / 1580) * 100 * 1em);
  box-shadow: rgba(0,0,0,0.2) 0 0 10px;

}

.document-sign img {

  margin: 0;
  display: block;
  width: 100%;

}

.signature {
  position: absolute;
  top: 59%;
  left: 5%;
}

</style>