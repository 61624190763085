<template>
  <div>
    <div class="screen" data-screen="retirement-summary" :data-active="(currentScreen=='retirement-summary') && !employeeState.retirement.hasBeneficiary">
      <img class="w-full max-w-100" src="/img/screens/retirement/employee-retirement-summary.png?v=02072024" />
      <div v-if="currentRor" class="rate-of-return" style="left:67.65%;top: 27.12%;">
        <img src="/img/screens/retirement/employee-retirement-rate-of-return-feb.png" />
      </div>

      <div class="links">

        <div class="overlay-text-summary" style="display:none;top:71.4%; right:34.2%;"><span>{{ employeeState.retirement.contribution + employeeState.retirement.contribution + 2 }}%</span></div>
        <div class="overlay-text-summary" style="display:none;top:75.4%; right:34.2%; "><span>4%</span></div>
        <div class="overlay-text-summary" style="display:none;top:80.6%; right:63%;"><span>{{ employeeState.retirement.contribution + employeeState.retirement.contribution+6}}%</span></div>

        <screen-link left="14.5%" top="19.5%" width="5.5%" height="3%" dot-class="dot-top-center" data-tgt="retirement-summary" data-tgt-scrolltop="true" @click.native="onLinkClick"></screen-link>
        <screen-link left="20%" top="19.5%" width="6.5%" height="3%" dot-class="dot-top-center" data-tgt="retirement-contributions" data-tgt-scrolltop="true" @click.native="onLinkClick"></screen-link>
        <screen-link left="26.75%" top="19.5%" width="5.5%" height="3%" dot-class="dot-top-center" data-tgt="retirement-investments" data-tgt-scrolltop="true" @click.native="onLinkClick"></screen-link>
        <screen-link left="32.5%" top="19.5%" width="3.5%" height="3%" dot-class="dot-top-center" data-tgt="retirement-loans" data-tgt-scrolltop="true" @click.native="onLinkClick"></screen-link>
        <screen-link left="36.25%" top="19.5%" width="9.5%" height="3%" dot-class="dot-top-center" data-tgt="retirement-distributions" data-tgt-scrolltop="true" @click.native="onLinkClick"></screen-link>
        <screen-link left="46%" top="19.5%" width="8%" height="3%" dot-class="dot-top-center" data-tgt="retirement-statements" data-tgt-scrolltop="true" @click.native="onLinkClick"></screen-link>

        <screen-link left="41.25%" top="48%" width="6.5%" height="5%" dot-class="dot-bottom-center" data-tgt="retirement-goals" data-tgt-scrolltop="true" @click.native="onLinkClick"></screen-link>
        <screen-link left="39.5%" top="92.5%" width="15%" height="4%" dot-class="dot-bottom-center" data-tgt="retirement-contributions" data-tgt-scrolltop="true" @click.native="onLinkClick"></screen-link>


        <screen-link left="68.5%" top="33.5%" width="8.5%" height="4%" dot-class="dot-top-center" data-tgt="retirement-contributions" data-tgt-scrolltop="true" @click.native="()=>{currentRor = false;}"></screen-link>
        <screen-link left="77.75%" top="33.5%" width="8.5%" height="4%" dot-class="dot-top-center" data-tgt="retirement-contributions" data-tgt-scrolltop="true" @click.native="()=>{currentRor = true;}"></screen-link>

        <screen-link left="39.5%" top="129.5%" width="15%" height="5%" dot-class="dot-bottom-center" data-tgt-scrolltop="true" data-tgt="pullout-retirement-beneficiaries" @click.native="showPullout"></screen-link>


        <company-tab :company="$store.state.company" select-tgt="retirement-summary"/>
        <info-box left="97.5%" top="10.5%" width="40em" height="4.5%" >
          <p>Employees receive access to a fully integrated module through My Retirement, where they can access their retirement dashboard to view balances, access goal setting tools, and manage their own investments. </p>
        </info-box>
      </div>
    </div>

    <div class="screen" data-screen="retirement-summary" :data-active="(currentScreen=='retirement-summary') && employeeState.retirement.hasBeneficiary">
      <img class="w-full max-w-100" src="/img/screens/retirement/employee-retirement-summary-w-bene.png?v=02072024" />
      <div v-if="currentRor" class="rate-of-return" style="left:67.65%;top: 27.12%;">
        <img src="/img/screens/retirement/employee-retirement-rate-of-return-feb.png" />
      </div>
      <div class="links">

        <div class="overlay-text-summary" style="display:none;top:71.4%; right:34.2%;"><span>{{ employeeState.retirement.contribution + employeeState.retirement.contribution + 2 }}%</span></div>
        <div class="overlay-text-summary" style="display:none;top:75.4%; right:34.2%; "><span>4%</span></div>
        <div class="overlay-text-summary" style="display:none;top:80.6%; right:63%;"><span>{{ employeeState.retirement.contribution + employeeState.retirement.contribution+6}}%</span></div>


        <screen-link left="14.5%" top="19.5%" width="5.5%" height="3%" dot-class="dot-top-center" data-tgt="retirement-summary" data-tgt-scrolltop="true" @click.native="onLinkClick"></screen-link>
        <screen-link left="20%" top="19.5%" width="6.5%" height="3%" dot-class="dot-top-center" data-tgt="retirement-contributions" data-tgt-scrolltop="true" @click.native="onLinkClick"></screen-link>
        <screen-link left="26.75%" top="19.5%" width="5.5%" height="3%" dot-class="dot-top-center" data-tgt="retirement-investments" data-tgt-scrolltop="true" @click.native="onLinkClick"></screen-link>
        <screen-link left="32.5%" top="19.5%" width="3.5%" height="3%" dot-class="dot-top-center" data-tgt="retirement-loans" data-tgt-scrolltop="true" @click.native="onLinkClick"></screen-link>
        <screen-link left="36.25%" top="19.5%" width="9.5%" height="3%" dot-class="dot-top-center" data-tgt="retirement-distributions" data-tgt-scrolltop="true" @click.native="onLinkClick"></screen-link>
        <screen-link left="46%" top="19.5%" width="8%" height="3%" dot-class="dot-top-center" data-tgt="retirement-statements" data-tgt-scrolltop="true" @click.native="onLinkClick"></screen-link>

        <screen-link left="41.25%" top="48%" width="6.5%" height="5%" dot-class="dot-bottom-center" data-tgt="retirement-goals" data-tgt-scrolltop="true" @click.native="onLinkClick"></screen-link>
        <screen-link left="40%" top="92.5%" width="15%" height="4%" dot-class="dot-bottom-center" data-tgt="retirement-contributions" data-tgt-scrolltop="true" @click.native="onLinkClick"></screen-link>


        <screen-link left="68.5%" top="33.5%" width="8.5%" height="4%" dot-class="dot-top-center" data-tgt="retirement-contributions" data-tgt-scrolltop="true" @click.native="()=>{currentRor = false;}"></screen-link>
        <screen-link left="77.75%" top="33.5%" width="8.5%" height="4%" dot-class="dot-top-center" data-tgt="retirement-contributions" data-tgt-scrolltop="true" @click.native="()=>{currentRor = true;}"></screen-link>





        <screen-link left="59.75%" top="100%" width="6%" height="5%" dot-class="dot-bottom-center" data-tgt-scrolltop="true" data-tgt="pullout-retirement-beneficiaries" data-init-info="manage-bene" @click.native="showPullout"></screen-link>
        <screen-link left="54.75%" top="100%" width="5%" height="5%" dot-class="dot-bottom-center" data-tgt-scrolltop="true" data-tgt="/img/screens/retirement/Beneficiaries-Form.pdf"  @click.native="$screens.showExternalPage"></screen-link>

        <company-tab :company="$store.state.company" select-tgt="retirement-summary"/>
        <info-box left="97.5%" top="10.5%" width="40em" height="4.5%" >
          <p>Employees receive access to a fully integrated module through My Retirement, where they can access their retirement dashboard to view balances, access goal setting tools, and manage their own investments. </p>
        </info-box>
      </div>
    </div>

    <div class="screen" data-screen="retirement-goals" :data-active="currentScreen=='retirement-goals'">
      <img class="w-full max-w-100" src="/img/screens/retirement/retirement-goals.png?v=02192024" />
      <div class="links">
        <screen-link left="53%" top="47%" width="9%" height="5%" dot-class="dot-left-center" data-tgt="retirement-goals-55"  @click.native="onLinkClick"></screen-link>
        <company-tab :company="$store.state.company" select-tgt="retirement-summary"/>
      </div>
    </div>

    <div class="screen" data-screen="retirement-goals-55" :data-active="currentScreen=='retirement-goals-55'">
      <img class="w-full max-w-100" src="/img/screens/retirement/retirement-goals-55.png?v=10182023" />
      <div class="links">
        <screen-link left="74%" top="66%" width="8.5%" height="5%" dot-class="dot-top-center" data-tgt="retirement-calculator"  @click.native="onLinkClick"></screen-link>
        <company-tab :company="$store.state.company" select-tgt="retirement-summary"/>
      </div>
    </div>

    <div class="screen" data-screen="retirement-calculator" :data-active="currentScreen=='retirement-calculator'">
      <img class="w-full max-w-100" src="/img/screens/retirement/employee-retirement-calculator.png?v=02072024" />
      <div class="links">
        <screen-link left="16%" top="13.5%" width="6%" height="5%" dot-class="dot-right-center" data-tgt="retirement-summary" data-tgt-scrollTop="true"  @click.native="onLinkClick"></screen-link>

        <screen-link left="30.5%" top="39%" width="26%" height="4%" dot-class="dot-left-center" data-tgt="retirement-calculator-5"  @click.native="onLinkClick"></screen-link>
        <screen-link left="30%" top="67%" width="8%" height="4%" dot-class="dot-right-center" data-tgt="retirement-goals-55"  @click.native="onLinkClick"></screen-link>
        <company-tab :company="$store.state.company" select-tgt="retirement-summary"/>
      </div>
    </div>

    <div class="screen" data-screen="retirement-calculator-5" :data-active="currentScreen=='retirement-calculator-5'">
      <img class="w-full max-w-100" src="/img/screens/retirement/employee-retirement-calculator-12.png?v=02172024" />
      <div class="links">
        <screen-link left="16%" top="13.5%" width="6%" height="5%" dot-class="dot-right-center" data-tgt="retirement-summary" data-tgt-scrollTop="true"  @click.native="onLinkClick"></screen-link>
        <screen-link left="30%" top="39%" width="26%" height="4%" dot-class="dot-left-center" data-tgt="retirement-calculator"  @click.native="onLinkClick"></screen-link>
        <screen-link left="30%" top="67%" width="8%" height="4%" dot-class="dot-right-center" data-tgt="retirement-goals-55"  @click.native="onLinkClick"></screen-link>
        <screen-link left="31%" top="73%" width="10%" height="4%" dot-class="dot-right-center" data-tgt="retirement-calculator-updated"  @click.native="onLinkClick"></screen-link>
        <company-tab :company="$store.state.company" select-tgt="retirement-summary"/>
      </div>
    </div>


    <div class="screen" data-screen="retirement-calculator-updated" :data-active="currentScreen=='retirement-calculator-updated'">
      <img class="w-full max-w-100" src="/img/screens/retirement/employee-retirement-calculator-updated.png?v=02072024" />
      <div class="links">
        <screen-link left="16%" top="13%" width="6%" height="5%" dot-class="dot-right-center" data-tgt="retirement-summary" data-tgt-scrollTop="true"  @click.native="onLinkClick"></screen-link>
        <screen-link left="65%" top="80.25%" width="8.5%" height="4%" dot-class="dot-right-center" data-tgt="pullout-retirement-contributions" init-info="contributions"  @click.native="showPullout"></screen-link>

        <screen-link left="30%" top="67%" width="8%" height="4%" dot-class="dot-right-center" data-tgt="retirement-goals-55"  @click.native="onLinkClick"></screen-link>

        <company-tab :company="$store.state.company" select-tgt="retirement-summary"/>
      </div>
    </div>

    <div class="screen" data-screen="retirement-recommendations" :data-active="currentScreen=='retirement-recommendations'">
      <img class="w-full max-w-100" src="/img/screens/retirement/employee-retirement-recommendations.png?v=10182023" />
      <div class="links">
        <screen-link left="16%" top="13%" width="13%" height="5%" dot-class="dot-right-center" data-tgt="retirement-calculator" data-tgt-scrollTop="true"  @click.native="onLinkClick"></screen-link>
        <screen-link left="77.5%" top="173%" width="5%" height="5%" dot-class="dot-top-center" data-tgt="retirement-calculator" data-tgt-scrollTop="true"  @click.native="onLinkClick"></screen-link>
        <company-tab :company="$store.state.company" select-tgt="retirement-summary"/>
      </div>
    </div>





    <div class="screen" data-screen="retirement-contributions" :data-active="(currentScreen=='retirement-contributions') && !employeeState.retirement.autoIncrease">
      <img class="w-full max-w-100" src="/img/screens/retirement/employee-retirement-contributions.png?v=10182023" />
      <div class="links">

        <div class="overlay-text" style="top:71.2%; right:34.4%"><span>{{ employeeState.retirement.contribution + employeeState.retirement.contribution + 2 }}%</span></div>
        <div class="overlay-text" style="top:75.2%; right:34.4%"><span>{{ employeeState.retirement.contribution }}%</span></div>
        <div class="overlay-text" style="top:79.2%; right:34.4%"><span>{{ employeeState.retirement.contribution+2}}%</span></div>

        <screen-link left="14.5%" top="19.5%" width="5.5%" height="3%" dot-class="dot-top-center" data-tgt="retirement-summary" data-tgt-scrolltop="true" @click.native="onLinkClick"></screen-link>
        <screen-link left="20%" top="19.5%" width="6.5%" height="3%" dot-class="dot-top-center" data-tgt="retirement-contributions" data-tgt-scrolltop="true" @click.native="onLinkClick"></screen-link>
        <screen-link left="26.75%" top="19.5%" width="5.5%" height="3%" dot-class="dot-top-center" data-tgt="retirement-investments" data-tgt-scrolltop="true" @click.native="onLinkClick"></screen-link>
        <screen-link left="32.5%" top="19.5%" width="3.5%" height="3%" dot-class="dot-top-center" data-tgt="retirement-loans" data-tgt-scrolltop="true" @click.native="onLinkClick"></screen-link>
        <screen-link left="36.25%" top="19.5%" width="9.5%" height="3%" dot-class="dot-top-center" data-tgt="retirement-distributions" data-tgt-scrolltop="true" @click.native="onLinkClick"></screen-link>
        <screen-link left="46%" top="19.5%" width="8%" height="3%" dot-class="dot-top-center" data-tgt="retirement-statements" data-tgt-scrolltop="true" @click.native="onLinkClick"></screen-link>

        <screen-link left="60%" top="64%" width="6%" height="5.5%" dot-class="dot-top-center" data-tgt="pullout-retirement-contributions" :data-init-info="(employeeState.retirement.contribution !== 5)?'contributions':'contributions-10'" @click.native="showPullout" />

        <screen-link left="67.5%" top="25%" width="20%" height="11%" dot-class="dot-right-center" data-tgt="pullout-retirement-contributions" data-init-info="contributions-auto-increase" @click.native="showPullout" />
        <company-tab :company="$store.state.company" select-tgt="retirement-summary"/>
      </div>
    </div>

    <div class="screen" data-screen="retirement-contributions" :data-active="(currentScreen=='retirement-contributions') && employeeState.retirement.autoIncrease">
      <img class="w-full max-w-100" src="/img/screens/retirement/employee-retirement-contributions-auto.png?v=10182023" />
      <div class="links">

        <div class="overlay-text" style="top:81.2%; right:33.8%"><span>{{ employeeState.retirement.contribution }}%</span></div>

        <screen-link left="14.5%" top="19.5%" width="5.5%" height="3%" dot-class="dot-top-center" data-tgt="retirement-summary" data-tgt-scrolltop="true" @click.native="onLinkClick"></screen-link>
        <screen-link left="20%" top="19.5%" width="6.5%" height="3%" dot-class="dot-top-center" data-tgt="retirement-contributions" data-tgt-scrolltop="true" @click.native="onLinkClick"></screen-link>
        <screen-link left="26.75%" top="19.5%" width="5.5%" height="3%" dot-class="dot-top-center" data-tgt="retirement-investments" data-tgt-scrolltop="true" @click.native="onLinkClick"></screen-link>
        <screen-link left="32.5%" top="19.5%" width="3.5%" height="3%" dot-class="dot-top-center" data-tgt="retirement-loans" data-tgt-scrolltop="true" @click.native="onLinkClick"></screen-link>
        <screen-link left="36.25%" top="19.5%" width="9.5%" height="3%" dot-class="dot-top-center" data-tgt="retirement-distributions" data-tgt-scrolltop="true" @click.native="onLinkClick"></screen-link>
        <screen-link left="46%" top="19.5%" width="8%" height="3%" dot-class="dot-top-center" data-tgt="retirement-statements" data-tgt-scrolltop="true" @click.native="onLinkClick"></screen-link>

        <screen-link left="60.5%" top="74%" width="6%" height="5.5%" dot-class="dot-top-center" data-tgt="pullout-retirement-contributions" :data-init-info="(employeeState.retirement.contribution !== 10)?'contributions':'contributions-10'" @click.native="showPullout" />

        <screen-link left="67.5%" top="35%" width="20%" height="26%" dot-class="dot-bottom-center" data-tgt="pullout-retirement-contributions" data-init-info="contributions-auto-increase-on"  @click.native="showPullout" />
        <company-tab :company="$store.state.company" select-tgt="retirement-summary"/>
      </div>
    </div>



    <div class="screen" data-screen="retirement-investments" :data-active="currentScreen=='retirement-investments'">
      <img class="w-full max-w-100" src="/img/screens/retirement/employee-retirement-investments.png?v=10182023" />
      <div class="links">
        <screen-link left="14.5%" top="19.5%" width="5.5%" height="3%" dot-class="dot-top-center" data-tgt="retirement-summary" data-tgt-scrolltop="true" @click.native="onLinkClick"></screen-link>
        <screen-link left="20%" top="19.5%" width="6.5%" height="3%" dot-class="dot-top-center" data-tgt="retirement-contributions" data-tgt-scrolltop="true" @click.native="onLinkClick"></screen-link>
        <screen-link left="26.75%" top="19.5%" width="5.5%" height="3%" dot-class="dot-top-center" data-tgt="retirement-investments" data-tgt-scrolltop="true" @click.native="onLinkClick"></screen-link>
        <screen-link left="32.5%" top="19.5%" width="3.5%" height="3%" dot-class="dot-top-center" data-tgt="retirement-loans" data-tgt-scrolltop="true" @click.native="onLinkClick"></screen-link>
        <screen-link left="36.25%" top="19.5%" width="9.5%" height="3%" dot-class="dot-top-center" data-tgt="retirement-distributions" data-tgt-scrolltop="true" @click.native="onLinkClick"></screen-link>
        <screen-link left="46%" top="19.5%" width="8%" height="3%" dot-class="dot-top-center" data-tgt="retirement-statements" data-tgt-scrolltop="true" @click.native="onLinkClick"></screen-link>

        <screen-link left="56.8%" top="27%" width="9%" height="5%" dot-class="dot-left-center" data-tgt="retirement-investments-performance"  @click.native="onLinkClick"></screen-link>
        <screen-link left="27.8%" top="74%" width="20%" height="5%" dot-class="dot-left-center" data-tgt="pullout-manage-investments" data-init-info="fund-info"  @click.native="showPullout"></screen-link>
        <screen-link left="59.8%" top="64.5%" width="6%" height="5%" dot-class="dot-top-center" data-tgt="pullout-manage-investments" data-tgt-scrolltop="true" @click.native="showPullout"></screen-link>

        <screen-link left="72.5%" top="44%" width="10%" height="4%" dot-class="dot-bottom-center" data-tgt="/#/guided-choice/gc-dashboard" data-tgt-query="true" @click.native="$screens.showExternalPage"></screen-link>
        <screen-link left="74.4%" top="83%" width="6%" height="5%" dot-class="dot-top-center" data-tgt="pullout-manage-investments" data-init-info="auto-rebalance" data-tgt-scrolltop="true" @click.native="showPullout"></screen-link>



        <company-tab :company="$store.state.company" select-tgt="retirement-summary"/>
      </div>
    </div>

    <div class="screen" data-screen="retirement-investments-changed" :data-active="currentScreen=='retirement-investments-changed'">
      <img class="w-full max-w-100" src="/img/screens/retirement/employee-retirement-investments-changed.png?v=10182023" />
      <div class="links">
        <screen-link left="14.5%" top="19.5%" width="5.5%" height="3%" dot-class="dot-top-center" data-tgt="retirement-summary" data-tgt-scrolltop="true" @click.native="onLinkClick"></screen-link>
        <screen-link left="20%" top="19.5%" width="6.5%" height="3%" dot-class="dot-top-center" data-tgt="retirement-contributions" data-tgt-scrolltop="true" @click.native="onLinkClick"></screen-link>
        <screen-link left="26.75%" top="19.5%" width="5.5%" height="3%" dot-class="dot-top-center" data-tgt="retirement-investments" data-tgt-scrolltop="true" @click.native="onLinkClick"></screen-link>
        <screen-link left="32.5%" top="19.5%" width="3.5%" height="3%" dot-class="dot-top-center" data-tgt="retirement-loans" data-tgt-scrolltop="true" @click.native="onLinkClick"></screen-link>
        <screen-link left="36.25%" top="19.5%" width="9.5%" height="3%" dot-class="dot-top-center" data-tgt="retirement-distributions" data-tgt-scrolltop="true" @click.native="onLinkClick"></screen-link>
        <screen-link left="46%" top="19.5%" width="8%" height="3%" dot-class="dot-top-center" data-tgt="retirement-statements" data-tgt-scrolltop="true" @click.native="onLinkClick"></screen-link>


        <screen-link left="56.8%" top="34.5%" width="9%" height="5%" dot-class="dot-left-center" data-tgt="retirement-investments-performance"  @click.native="onLinkClick"></screen-link>
        <screen-link left="72.5%" top="52%" width="10%" height="4%" dot-class="dot-bottom-center" data-tgt="/#/guided-choice/gc-dashboard" data-tgt-query="true" @click.native="$screens.showExternalPage"></screen-link>

        <screen-link left="74.4%" top="90.5%" width="6%" height="5%" dot-class="dot-top-center" data-tgt="pullout-manage-investments" data-init-info="auto-rebalance" data-tgt-scrolltop="true" @click.native="showPullout"></screen-link>
        <company-tab :company="$store.state.company" select-tgt="retirement-summary"/>
      </div>
    </div>

    <div class="screen" data-screen="retirement-investments-changed-auto" :data-active="currentScreen=='retirement-investments-changed-auto'">
      <img class="w-full max-w-100" src="/img/screens/retirement/employee-retirement-investments-changed-auto.png?v=10182023" />
      <div class="links">
        <screen-link left="14.5%" top="19.5%" width="5.5%" height="3%" dot-class="dot-top-center" data-tgt="retirement-summary" data-tgt-scrolltop="true" @click.native="onLinkClick"></screen-link>
        <screen-link left="20%" top="19.5%" width="6.5%" height="3%" dot-class="dot-top-center" data-tgt="retirement-contributions" data-tgt-scrolltop="true" @click.native="onLinkClick"></screen-link>
        <screen-link left="26.75%" top="19.5%" width="5.5%" height="3%" dot-class="dot-top-center" data-tgt="retirement-investments" data-tgt-scrolltop="true" @click.native="onLinkClick"></screen-link>
        <screen-link left="32.5%" top="19.5%" width="3.5%" height="3%" dot-class="dot-top-center" data-tgt="retirement-loans" data-tgt-scrolltop="true" @click.native="onLinkClick"></screen-link>
        <screen-link left="36.25%" top="19.5%" width="9.5%" height="3%" dot-class="dot-top-center" data-tgt="retirement-distributions" data-tgt-scrolltop="true" @click.native="onLinkClick"></screen-link>
        <screen-link left="46%" top="19.5%" width="8%" height="3%" dot-class="dot-top-center" data-tgt="retirement-statements" data-tgt-scrolltop="true" @click.native="onLinkClick"></screen-link>

        <screen-link left="56.8%" top="42.5%" width="9%" height="5%" dot-class="dot-left-center" data-tgt="retirement-investments-performance"  @click.native="onLinkClick"></screen-link>
        <screen-link left="27.8%" top="90%" width="20%" height="5%" dot-class="dot-left-center" data-tgt="pullout-manage-investments" data-init-info="fund-info"  @click.native="showPullout"></screen-link>
        <screen-link left="59.8%" top="80%" width="6%" height="5%" dot-class="dot-top-center" data-tgt="pullout-manage-investments" data-tgt-scrolltop="true" @click.native="showPullout"></screen-link>

        <screen-link left="72.5%" top="60%" width="10%" height="4%" dot-class="dot-bottom-center" data-tgt="/#/guided-choice/gc-dashboard" data-tgt-query="true" @click.native="$screens.showExternalPage"></screen-link>

        <screen-link left="83.5%" top="71%" width="3%" height="4%" dot-class="dot-top-center" data-tgt="pullout-manage-investments" data-init-info="auto-rebalance-active-yearly" data-tgt-scrolltop="true" @click.native="showPullout"></screen-link>

        <company-tab :company="$store.state.company" select-tgt="retirement-summary"/>
      </div>
    </div>

    <div class="screen" data-screen="retirement-investments-performance" :data-active="currentScreen=='retirement-investments-performance'">
      <img class="w-full max-w-100" src="/img/screens/retirement/employee-retirement-investments-performance.png?v=10182023" />
      <div class="links">
        <screen-link left="14.5%" top="23%" width="6.5%" height="3%" dot-class="dot-top-center" data-tgt="retirement-investments-performance" data-tgt-scrolltop="true" @click.native="onLinkClick"></screen-link>
        <screen-link left="21%" top="23%" width="6.5%" height="3%" dot-class="dot-top-center" data-tgt="retirement-investments-performance-yield" data-tgt-scrolltop="true" @click.native="onLinkClick"></screen-link>
        <screen-link left="27.5%" top="23%" width="10.5%" height="3%" dot-class="dot-top-center" data-tgt="retirement-investments-performance-ror" data-tgt-scrolltop="true" @click.native="onLinkClick"></screen-link>
        <screen-link left="15%" top="14.5%" width="8%" height="3%" dot-class="dot-right-center" data-tgt="retirement-investments" data-tgt-scrolltop="true" @click.native="onLinkClick"></screen-link>

        <company-tab :company="$store.state.company" select-tgt="retirement-summary"/>
      </div>
    </div>

    <div class="screen" data-screen="retirement-investments-performance-yield" :data-active="currentScreen=='retirement-investments-performance-yield'">
      <img class="w-full max-w-100" src="/img/screens/retirement/employee-retirement-investments-performance-yield.png?v=10182023" />
      <div class="links">
        <screen-link left="14.5%" top="23%" width="6.5%" height="3%" dot-class="dot-top-center" data-tgt="retirement-investments-performance" data-tgt-scrolltop="true" @click.native="onLinkClick"></screen-link>
        <screen-link left="21%" top="23%" width="6.5%" height="3%" dot-class="dot-top-center" data-tgt="retirement-investments-performance-yield" data-tgt-scrolltop="true" @click.native="onLinkClick"></screen-link>
        <screen-link left="27.5%" top="23%" width="10.5%" height="3%" dot-class="dot-top-center" data-tgt="retirement-investments-performance-ror" data-tgt-scrolltop="true" @click.native="onLinkClick"></screen-link>
        <screen-link left="15%" top="14.5%" width="8%" height="3%" dot-class="dot-right-center" data-tgt="retirement-investments" data-tgt-scrolltop="true" @click.native="onLinkClick"></screen-link>

        <company-tab :company="$store.state.company" select-tgt="retirement-summary"/>
      </div>
    </div>

    <div class="screen" data-screen="retirement-investments-performance-ror" :data-active="currentScreen=='retirement-investments-performance-ror'">
      <img class="w-full max-w-100" src="/img/screens/retirement/employee-retirement-investments-performance-ror.png?v=10182023" />
      <div class="links">
        <screen-link left="14.5%" top="23%" width="6.5%" height="3%" dot-class="dot-top-center" data-tgt="retirement-investments-performance" data-tgt-scrolltop="true" @click.native="onLinkClick"></screen-link>
        <screen-link left="21%" top="23%" width="6.5%" height="3%" dot-class="dot-top-center" data-tgt="retirement-investments-performance-yield" data-tgt-scrolltop="true" @click.native="onLinkClick"></screen-link>
        <screen-link left="27.5%" top="23%" width="10.5%" height="3%" dot-class="dot-top-center" data-tgt="retirement-investments-performance-ror" data-tgt-scrolltop="true" @click.native="onLinkClick"></screen-link>
        <screen-link left="15%" top="14.5%" width="8%" height="3%" dot-class="dot-right-center" data-tgt="retirement-investments" data-tgt-scrolltop="true" @click.native="onLinkClick"></screen-link>

        <company-tab :company="$store.state.company" select-tgt="retirement-summary"/>
      </div>
    </div>

    <div class="screen" data-screen="retirement-loans" :data-active="currentScreen=='retirement-loans'">
      <img class="w-full max-w-100" src="/img/screens/retirement/employee-retirement-loans.png?v=10182023" />
      <div class="links">
        <screen-link left="14.5%" top="19.5%" width="5.5%" height="3%" dot-class="dot-top-center" data-tgt="retirement-summary" data-tgt-scrolltop="true" @click.native="onLinkClick"></screen-link>
        <screen-link left="20%" top="19.5%" width="6.5%" height="3%" dot-class="dot-top-center" data-tgt="retirement-contributions" data-tgt-scrolltop="true" @click.native="onLinkClick"></screen-link>
        <screen-link left="26.75%" top="19.5%" width="5.5%" height="3%" dot-class="dot-top-center" data-tgt="retirement-investments" data-tgt-scrolltop="true" @click.native="onLinkClick"></screen-link>
        <screen-link left="32.5%" top="19.5%" width="3.5%" height="3%" dot-class="dot-top-center" data-tgt="retirement-loans" data-tgt-scrolltop="true" @click.native="onLinkClick"></screen-link>
        <screen-link left="36.25%" top="19.5%" width="9.5%" height="3%" dot-class="dot-top-center" data-tgt="retirement-distributions" data-tgt-scrolltop="true" @click.native="onLinkClick"></screen-link>
        <screen-link left="46%" top="19.5%" width="8%" height="3%" dot-class="dot-top-center" data-tgt="retirement-statements" data-tgt-scrolltop="true" @click.native="onLinkClick"></screen-link>

        <screen-link left="67%" top="26%" width="9%" height="5%" dot-class="dot-top-center" data-tgt="retirement-loans-request" data-tgt-scrolltop="true" @click.native="onLinkClick"></screen-link>
        <screen-link left="53%" top="104%" width="8.5%" height="5%" dot-class="dot-right-center" data-tgt="pullout-estimate-payment" data-tgt-scrolltop="true" @click.native="showPullout"></screen-link>
        <company-tab :company="$store.state.company" select-tgt="retirement-summary"/>
      </div>
    </div>

    <div class="screen" data-screen="retirement-loans-request" :data-active="currentScreen=='retirement-loans-request'">
      <img class="w-full max-w-100" src="/img/screens/retirement/employee-retirement-loans-request.png?v=10182023" />
      <div class="links">
        <screen-link left="61.5%" top="73.5%" width="6.7%" height="5%" dot-class="dot-top-center" data-tgt="retirement-loans" data-tgt-scrolltop="true" @click.native="onLinkClick"></screen-link>
        <screen-link left="68.5%" top="73.5%" width="7.2%" height="5%" dot-class="dot-top-center" data-tgt="retirement-loans-request-choose" data-tgt-scrolltop="true" @click.native="onLinkClick"></screen-link>
        <company-tab :company="$store.state.company" select-tgt="retirement-summary"/>
      </div>
    </div>

    <div class="screen" data-screen="retirement-loans-request-choose" :data-active="currentScreen=='retirement-loans-request-choose'">
      <img class="w-full max-w-100" src="/img/screens/retirement/employee-retirement-loans-request-choose.png?v=10182023" />
      <div class="links">
        <screen-link left="43.2%" top="78%" width="6.7%" height="5%" dot-class="dot-top-center" data-tgt="retirement-loans-request-choose-general" data-tgt-scrolltop="true" @click.native="onLinkClick"></screen-link>
        <company-tab :company="$store.state.company" select-tgt="retirement-summary"/>
      </div>
    </div>

    <div class="screen" data-screen="retirement-loans-request-choose-general" :data-active="currentScreen=='retirement-loans-request-choose-general'">
      <img class="w-full max-w-100" src="/img/screens/retirement/employee-retirement-loans-request-choose-general.png?v=10182023" />
      <div class="links">
        <screen-link left="72%" top="86%" width="7.2%" height="5%" dot-class="dot-right-center" data-tgt="retirement-loans-request-choose-amount" data-tgt-scrolltop="true" @click.native="onLinkClick"></screen-link>
        <company-tab :company="$store.state.company" select-tgt="retirement-summary"/>
      </div>
    </div>

    <div class="screen" data-screen="retirement-loans-request-choose-amount" :data-active="currentScreen=='retirement-loans-request-choose-amount'">
      <img class="w-full max-w-100" src="/img/screens/retirement/employee-retirement-loans-request-choose-amount.png?v=10182023" />
      <div class="links">

        <screen-link left="35%" top="95.2%" width="7.2%" height="5%" dot-class="dot-left-center" data-tgt="retirement-loans-request-choose-general" data-tgt-scrolltop="true" @click.native="onLinkClick"></screen-link>


        <screen-link left="37%" top="57.5%" width="41%" height="5%" dot-class="dot-right-center" data-tgt="retirement-loans-request-choose-amount-5000"  @click.native="onLinkClick"></screen-link>
        <screen-link left="37%" top="70%" width="41%" height="5%" dot-class="dot-right-center" data-tgt="retirement-loans-request-choose-amount-5000"  @click.native="onLinkClick"></screen-link>
        <company-tab :company="$store.state.company" select-tgt="retirement-summary"/>
      </div>
    </div>

    <div class="screen" data-screen="retirement-loans-request-choose-amount-5000" :data-active="currentScreen=='retirement-loans-request-choose-amount-5000'">
      <img class="w-full max-w-100" src="/img/screens/retirement/employee-retirement-loans-request-choose-amount-5000.png?v=10182023" />
      <div class="links">
        <screen-link left="35%" top="95.2%" width="7.2%" height="5%" dot-class="dot-left-center" data-tgt="retirement-loans-request-choose-general" data-tgt-scrolltop="true" @click.native="onLinkClick"></screen-link>

        <screen-link left="72%" top="95%" width="7.2%" height="5%" dot-class="dot-right-center" data-tgt="retirement-loans-request-direct-deposit" data-tgt-scrolltop="true" @click.native="onLinkClick"></screen-link>
        <company-tab :company="$store.state.company" select-tgt="retirement-summary"/>
      </div>
    </div>

    <div class="screen" data-screen="retirement-loans-request-direct-deposit" :data-active="currentScreen=='retirement-loans-request-direct-deposit'">
      <img class="w-full max-w-100" src="/img/screens/retirement/employee-retirement-loans-request-direct-deposit.png?v=10182023" />
      <div class="links">

        <screen-link left="35%" top="78.5%" width="7.2%" height="5%" dot-class="dot-left-center" data-tgt="retirement-loans-request-choose-amount-5000" data-tgt-scrolltop="true" @click.native="onLinkClick"></screen-link>

        <screen-link left="36.5%" top="71%" width="21%" height="5%" dot-class="dot-left-center" data-tgt="pullout-direct-deposit"  @click.native="showPullout"></screen-link>
        <company-tab :company="$store.state.company" select-tgt="retirement-summary"/>
      </div>
    </div>

    <div class="screen" data-screen="retirement-loans-request-direct-deposit-selected" :data-active="currentScreen=='retirement-loans-request-direct-deposit-selected'">
      <img class="w-full max-w-100" src="/img/screens/retirement/employee-retirement-loans-request-direct-deposit-selected.png?v=10182023" />
      <div class="links">
        <screen-link left="35%" top="78.5%" width="7.2%" height="5%" dot-class="dot-left-center" data-tgt="retirement-loans-request-choose-amount-5000" data-tgt-scrolltop="true" @click.native="onLinkClick"></screen-link>

        <screen-link left="72%" top="78.5%" width="7.2%" height="5%" dot-class="dot-right-center" data-tgt="retirement-loans-request-review" data-tgt-scrolltop="true" @click.native="onLinkClick"></screen-link>
        <company-tab :company="$store.state.company" select-tgt="retirement-summary"/>
      </div>
    </div>

    <div class="screen" data-screen="retirement-loans-request-review" :data-active="currentScreen=='retirement-loans-request-review'">
      <img class="w-full max-w-100" src="/img/screens/retirement/employee-retirement-loans-request-review.png?v=10182023" />
      <div class="links">
        <screen-link left="35%" top="114%" width="7.2%" height="5%" dot-class="dot-left-center" data-tgt="retirement-loans-request-direct-deposit-selected" data-tgt-scrolltop="true" @click.native="onLinkClick"></screen-link>

        <screen-link left="55%" top="114%" width="3%" height="5%" dot-class="dot-left-center" data-tgt="retirement-loans-request-review-agree"  @click.native="onLinkClick"></screen-link>
        <screen-link left="62%" top="114%" width="7%" height="5%" dot-class="dot-bottom-center" data-tgt="pullout-legal-info"  @click.native="showPullout"></screen-link>
        <company-tab :company="$store.state.company" select-tgt="retirement-summary"/>
      </div>
    </div>

    <div class="screen" data-screen="retirement-loans-request-review-agree" :data-active="currentScreen=='retirement-loans-request-review-agree'">
      <img class="w-full max-w-100" src="/img/screens/retirement/employee-retirement-loans-request-review-agree.png?v=10182023" />
      <div class="links">
        <screen-link left="35%" top="114%" width="7.2%" height="5%" dot-class="dot-left-center" data-tgt="retirement-loans-request-direct-deposit-selected" data-tgt-scrolltop="true" @click.native="onLinkClick"></screen-link>

        <screen-link left="55%" top="114%" width="3%" height="5%" dot-class="dot-left-center" data-tgt="retirement-loans-request-review"  @click.native="onLinkClick"></screen-link>
        <screen-link left="62%" top="114%" width="7%" height="5%" dot-class="dot-bottom-center" data-tgt="pullout-legal-info"  @click.native="showPullout"></screen-link>


        <screen-link left="72%" top="114%" width="7%" height="5%" dot-class="dot-right-center" data-tgt="retirement-loans-request-confirmation" data-tgt-scrolltop="true" @click.native="onLinkClick"></screen-link>
        <company-tab :company="$store.state.company" select-tgt="retirement-summary"/>

      </div>
    </div>

    <div class="screen" data-screen="retirement-loans-request-confirmation" :data-active="currentScreen=='retirement-loans-request-confirmation'">
      <img class="w-full max-w-100" src="/img/screens/retirement/employee-retirement-loans-request-confirmation.png?v=10182023" />
      <div class="links">
        <screen-link left="53%" top="52.5%" width="8.5%" height="5%" dot-class="dot-right-center" data-tgt="retirement-loans-after-request" data-tgt-scrolltop="true" @click.native="onLinkClick"></screen-link>

        <screen-link left="51%" top="47%" width="12%" height="5%" dot-class="dot-right-center" data-tgt="/documents/loan_report_1876537.pdf" @click.native="$screens.showExternalPage"></screen-link>
        <company-tab :company="$store.state.company" select-tgt="retirement-summary"/>
      </div>
    </div>

    <div class="screen" data-screen="retirement-loans-after-request" :data-active="currentScreen=='retirement-loans-after-request'">
      <img class="w-full max-w-100" src="/img/screens/retirement/employee-retirement-loans-after-request.png?v=10182023" />
      <div class="links">
        <screen-link left="14.5%" top="19.5%" width="5.5%" height="3%" dot-class="dot-top-center" data-tgt="retirement-summary" data-tgt-scrolltop="true" @click.native="onLinkClick"></screen-link>
        <screen-link left="20%" top="19.5%" width="6.5%" height="3%" dot-class="dot-top-center" data-tgt="retirement-contributions" data-tgt-scrolltop="true" @click.native="onLinkClick"></screen-link>
        <screen-link left="26.75%" top="19.5%" width="5.5%" height="3%" dot-class="dot-top-center" data-tgt="retirement-investments" data-tgt-scrolltop="true" @click.native="onLinkClick"></screen-link>
        <screen-link left="32.5%" top="19.5%" width="3.5%" height="3%" dot-class="dot-top-center" data-tgt="retirement-loans" data-tgt-scrolltop="true" @click.native="onLinkClick"></screen-link>
        <screen-link left="36.25%" top="19.5%" width="9.5%" height="3%" dot-class="dot-top-center" data-tgt="retirement-distributions" data-tgt-scrolltop="true" @click.native="onLinkClick"></screen-link>
        <screen-link left="46%" top="19.5%" width="8%" height="3%" dot-class="dot-top-center" data-tgt="retirement-statements" data-tgt-scrolltop="true" @click.native="onLinkClick"></screen-link>

        <screen-link left="53%" top="103%" width="8.5%" height="5%" dot-class="dot-right-center" data-tgt="pullout-estimate-payment" data-tgt-scrolltop="true" @click.native="showPullout"></screen-link>

        <company-tab :company="$store.state.company" select-tgt="retirement-summary"/>
      </div>
    </div>




    <div class="screen" data-screen="retirement-distributions" :data-active="currentScreen=='retirement-distributions'">
      <img class="w-full max-w-100" src="/img/screens/retirement/employee-retirement-withdrawal.png?v=10182023" />
      <div class="links">
        <screen-link left="14.5%" top="19.5%" width="5.5%" height="3%" dot-class="dot-top-center" data-tgt="retirement-summary" data-tgt-scrolltop="true" @click.native="onLinkClick"></screen-link>
        <screen-link left="20%" top="19.5%" width="6.5%" height="3%" dot-class="dot-top-center" data-tgt="retirement-contributions" data-tgt-scrolltop="true" @click.native="onLinkClick"></screen-link>
        <screen-link left="26.75%" top="19.5%" width="5.5%" height="3%" dot-class="dot-top-center" data-tgt="retirement-investments" data-tgt-scrolltop="true" @click.native="onLinkClick"></screen-link>
        <screen-link left="32.5%" top="19.5%" width="3.5%" height="3%" dot-class="dot-top-center" data-tgt="retirement-loans" data-tgt-scrolltop="true" @click.native="onLinkClick"></screen-link>
        <screen-link left="36.25%" top="19.5%" width="9.5%" height="3%" dot-class="dot-top-center" data-tgt="retirement-distributions" data-tgt-scrolltop="true" @click.native="onLinkClick"></screen-link>
        <screen-link left="46%" top="19.5%" width="8%" height="3%" dot-class="dot-top-center" data-tgt="retirement-statements" data-tgt-scrolltop="true" @click.native="onLinkClick"></screen-link>

        <screen-link left="28%" top="58.75%" width="38.25%" height="7.5%" dot-class="dot-right-center" data-tgt="retirement-withdrawal-options" data-init-info="" @click.native="onLinkClick"></screen-link>


        <screen-link left="68.5%" top="33.5%" width="17.5%" height="5%" dot-class="dot-right-center" data-tgt="pullout-legal-info" data-init-info="about-bene-request" @click.native="showPullout"></screen-link>
        <screen-link left="68.5%" top="40.5%" width="17.5%" height="5%" dot-class="dot-right-center" data-tgt="pullout-legal-info" data-init-info="about-disability-req" @click.native="showPullout"></screen-link>
        <company-tab :company="$store.state.company" select-tgt="retirement-summary"/>
      </div>
    </div>

    <div class="screen" data-screen="retirement-withdrawal-options" :data-active="currentScreen=='retirement-withdrawal-options'">
      <img class="w-full max-w-100" src="/img/screens/retirement/employee-retirement-withdrawal-options.png?v=10182023" />
      <div class="links">

        <screen-link left="15%" top="14.5%" width="12%" height="3%" dot-class="dot-right-center" data-tgt="retirement-distributions" data-tgt-scrolltop="true" @click.native="onLinkClick"></screen-link>
        <screen-link left="38%" top="48%" width="39.5%" height="9%" dot-class="dot-right-center" data-tgt="retirement-withdrawal-full" data-tgt-scrolltop="true" @click.native="onLinkClick"></screen-link>

        <company-tab :company="$store.state.company" select-tgt="retirement-summary"/>
      </div>
    </div>

    <div class="screen" data-screen="retirement-withdrawal-full" :data-active="currentScreen=='retirement-withdrawal-full'">
      <img class="w-full max-w-100" src="/img/screens/retirement/employee-retirement-withdrawal-full.png?v=10182023" />
      <div class="links">

        <screen-link left="62.5%" top="65%" width="7%" height="5%" dot-class="dot-left-center" data-tgt="retirement-distributions" data-tgt-scrolltop="true" @click.native="onLinkClick"></screen-link>
        <screen-link left="70%" top="65%" width="7%" height="5%" dot-class="dot-right-center" data-tgt="retirement-withdrawal-full-address" data-tgt-scrolltop="true" @click.native="onLinkClick"></screen-link>

        <company-tab :company="$store.state.company" select-tgt="retirement-summary"/>
      </div>
    </div>

    <div class="screen" data-screen="retirement-withdrawal-full-address" :data-active="currentScreen=='retirement-withdrawal-full-address'">
      <img class="w-full max-w-100" src="/img/screens/retirement/employee-retirement-withdrawal-full-address.png?v=10182023" />
      <div class="links">

        <screen-link left="36.75%" top="53%" width="6%" height="4%" dot-class="dot-left-center" data-tgt="retirement-withdrawal-full-address-yes" data-tgt-scrolltop="true" @click.native="onLinkClick"></screen-link>

        <company-tab :company="$store.state.company" select-tgt="retirement-summary"/>
      </div>
    </div>

    <div class="screen" data-screen="retirement-withdrawal-full-address-yes" :data-active="currentScreen=='retirement-withdrawal-full-address-yes'">
      <img class="w-full max-w-100" src="/img/screens/retirement/employee-retirement-withdrawal-full-address-yes.png?v=10182023" />
      <div class="links">

        <screen-link left="72%" top="63%" width="7%" height="5%" dot-class="dot-right-center" data-tgt="retirement-withdrawal-full-details" data-tgt-scrolltop="true" @click.native="onLinkClick"></screen-link>

        <company-tab :company="$store.state.company" select-tgt="retirement-summary"/>
      </div>
    </div>

    <div class="screen" data-screen="retirement-withdrawal-full-details" :data-active="currentScreen=='retirement-withdrawal-full-details'">
      <img class="w-full max-w-100" src="/img/screens/retirement/employee-retirement-withdrawal-full-details.png?v=10182023" />
      <div class="links">

        <screen-link left="35.5%" top="75%" width="7%" height="5%" dot-class="dot-left-center" data-tgt="retirement-withdrawal-full-address-yes" data-tgt-scrolltop="true" @click.native="onLinkClick"></screen-link>
        <screen-link left="72%" top="75%" width="7%" height="5%" dot-class="dot-right-center" data-tgt="retirement-withdrawal-full-payment" data-tgt-scrolltop="true" @click.native="onLinkClick"></screen-link>

        <company-tab :company="$store.state.company" select-tgt="retirement-summary"/>
      </div>
    </div>

    <div class="screen" data-screen="retirement-withdrawal-full-payment" :data-active="currentScreen=='retirement-withdrawal-full-payment'">
      <img class="w-full max-w-100" src="/img/screens/retirement/employee-retirement-withdrawal-full-payment.png?v=10182023" />
      <div class="links">

        <screen-link left="35.5%" top="74%" width="7%" height="5%" dot-class="dot-left-center" data-tgt="retirement-withdrawal-full-details" data-tgt-scrolltop="true" @click.native="onLinkClick"></screen-link>
        <screen-link left="57.5%" top="56%" width="20%" height="15%" dot-class="dot-bottom-center" data-tgt="retirement-withdrawal-full-payment-check"  @click.native="onLinkClick"></screen-link>


        <company-tab :company="$store.state.company" select-tgt="retirement-summary"/>
      </div>
    </div>

    <div class="screen" data-screen="retirement-withdrawal-full-payment-check" :data-active="currentScreen=='retirement-withdrawal-full-payment-check'">
      <img class="w-full max-w-100" src="/img/screens/retirement/employee-retirement-withdrawal-full-payment-check.png?v=10182023" />
      <div class="links">

        <screen-link left="35.5%" top="85%" width="7%" height="5%" dot-class="dot-left-center" data-tgt="retirement-withdrawal-full-details" data-tgt-scrolltop="true" @click.native="onLinkClick"></screen-link>

        <screen-link left="72%" top="85%" width="7%" height="5%" dot-class="dot-right-center" data-tgt="retirement-withdrawal-full-review" data-tgt-scrolltop="true" @click.native="onLinkClick"></screen-link>

        <company-tab :company="$store.state.company" select-tgt="retirement-summary"/>
      </div>
    </div>

    <div class="screen" data-screen="retirement-withdrawal-full-review" :data-active="currentScreen=='retirement-withdrawal-full-review'">
      <img class="w-full max-w-100" src="/img/screens/retirement/employee-retirement-withdrawal-full-review.png?v=10182023" />
      <div class="links">

        <screen-link left="35.5%" top="101.5%" width="7%" height="5%" dot-class="dot-left-center" data-tgt="retirement-withdrawal-full-payment-check" data-tgt-scrolltop="true" @click.native="onLinkClick"></screen-link>

        <screen-link left="35%" top="94.7%" width="3%" height="5%" dot-class="dot-left-center" data-tgt="retirement-withdrawal-full-review-authorize" :data-tgt-scrolltop="false" @click.native="onLinkClick"></screen-link>

        <company-tab :company="$store.state.company" select-tgt="retirement-summary"/>
      </div>
    </div>

    <div class="screen" data-screen="retirement-withdrawal-full-review-authorize" :data-active="currentScreen=='retirement-withdrawal-full-review-authorize'">
      <img class="w-full max-w-100" src="/img/screens/retirement/employee-retirement-withdrawal-full-review-authorize.png?v=10182023" />
      <div class="links">

        <screen-link left="35.5%" top="101.5%" width="7%" height="5%" dot-class="dot-left-center" data-tgt="retirement-withdrawal-full-payment-check" data-tgt-scrolltop="true" @click.native="onLinkClick"></screen-link>

        <screen-link left="72%" top="101.5%" width="7%" height="5%" dot-class="dot-right-center" data-tgt="retirement-withdrawal-full-submitted" data-tgt-scrolltop="true" @click.native="onLinkClick"></screen-link>
        <screen-link left="72%" top="101.5%" width="7%" height="5%" dot-class="dot-right-center" data-tgt="retirement-withdrawal-full-submitted" data-tgt-scrolltop="true" @click.native="onLinkClick"></screen-link>
        <screen-link left="67%" top="95%" width="9%" height="4%" dot-class="dot-right-center" data-tgt="/img/screens/retirement/special_tax_notice.pdf" data-tgt-scrolltop="true" @click.native="$screens.showExternalPage"></screen-link>


        <company-tab :company="$store.state.company" select-tgt="retirement-summary"/>
      </div>
    </div>

    <div class="screen" data-screen="retirement-withdrawal-full-submitted" :data-active="currentScreen=='retirement-withdrawal-full-submitted'">
      <img class="w-full max-w-100" src="/img/screens/retirement/employee-retirement-withdrawal-full-submitted.png?v=10182023" />
      <div class="links">

        <screen-link left="53%" top="59%" width="8%" height="5%" dot-class="dot-left-center" data-tgt="retirement-distributions" data-tgt-scrolltop="true" @click.native="onLinkClick"></screen-link>


        <company-tab :company="$store.state.company" select-tgt="retirement-summary"/>
      </div>
    </div>

    <div class="screen" data-screen="retirement-statements" :data-active="currentScreen=='retirement-statements'">
      <img class="w-full max-w-100" src="/img/screens/retirement/employee-retirement-statement-history.png?v=10182023" />
      <div class="links">
        <screen-link left="14.5%" top="19.5%" width="5.5%" height="3%" dot-class="dot-top-center" data-tgt="retirement-summary" data-tgt-scrolltop="true" @click.native="onLinkClick"></screen-link>
        <screen-link left="20%" top="19.5%" width="6.5%" height="3%" dot-class="dot-top-center" data-tgt="retirement-contributions" data-tgt-scrolltop="true" @click.native="onLinkClick"></screen-link>
        <screen-link left="26.75%" top="19.5%" width="5.5%" height="3%" dot-class="dot-top-center" data-tgt="retirement-investments" data-tgt-scrolltop="true" @click.native="onLinkClick"></screen-link>
        <screen-link left="32.5%" top="19.5%" width="3.5%" height="3%" dot-class="dot-top-center" data-tgt="retirement-loans" data-tgt-scrolltop="true" @click.native="onLinkClick"></screen-link>
        <screen-link left="36.25%" top="19.5%" width="9.5%" height="3%" dot-class="dot-top-center" data-tgt="retirement-distributions" data-tgt-scrolltop="true" @click.native="onLinkClick"></screen-link>
        <screen-link left="46%" top="19.5%" width="8%" height="3%" dot-class="dot-top-center" data-tgt="retirement-statements" data-tgt-scrolltop="true" @click.native="onLinkClick"></screen-link>

        <screen-link left="15.75%" top="48%" width="20%" height="5%" dot-class="dot-left-center" data-tgt="retirement-statements-totals" data-tgt-scrolltop="true" @click.native="onLinkClick"></screen-link>


        <company-tab :company="$store.state.company" select-tgt="retirement-summary"/>
      </div>
    </div>

    <div class="screen" data-screen="retirement-statements-totals" :data-active="currentScreen=='retirement-statements-totals'">
      <img class="w-full max-w-100" src="/img/screens/retirement/employee-retirement-statement-history-totals.png?v=10182023" />
      <div class="links">
        <screen-link left="14.5%" top="19.5%" width="5.5%" height="3%" dot-class="dot-top-center" data-tgt="retirement-summary" data-tgt-scrolltop="true" @click.native="onLinkClick"></screen-link>
        <screen-link left="20%" top="19.5%" width="6.5%" height="3%" dot-class="dot-top-center" data-tgt="retirement-contributions" data-tgt-scrolltop="true" @click.native="onLinkClick"></screen-link>
        <screen-link left="26.75%" top="19.5%" width="5.5%" height="3%" dot-class="dot-top-center" data-tgt="retirement-investments" data-tgt-scrolltop="true" @click.native="onLinkClick"></screen-link>
        <screen-link left="32.5%" top="19.5%" width="3.5%" height="3%" dot-class="dot-top-center" data-tgt="retirement-loans" data-tgt-scrolltop="true" @click.native="onLinkClick"></screen-link>
        <screen-link left="36.25%" top="19.5%" width="9.5%" height="3%" dot-class="dot-top-center" data-tgt="retirement-distributions" data-tgt-scrolltop="true" @click.native="onLinkClick"></screen-link>
        <screen-link left="46%" top="19.5%" width="8%" height="3%" dot-class="dot-top-center" data-tgt="retirement-statements" data-tgt-scrolltop="true" @click.native="onLinkClick"></screen-link>

        <screen-link left="15.75%" top="48%" width="20%" height="5%" dot-class="dot-left-center" data-tgt="retirement-statements" data-tgt-scrolltop="true" @click.native="onLinkClick"></screen-link>
        <screen-link left="15.75%" top="63%" width="20%" height="5%" dot-class="dot-left-center" data-tgt="retirement-statements-other" data-tgt-scrolltop="true" @click.native="onLinkClick"></screen-link>


        <company-tab :company="$store.state.company" select-tgt="retirement-summary"/>
      </div>
    </div>

    <div class="screen" data-screen="retirement-statements-other" :data-active="currentScreen=='retirement-statements-other'">
      <img class="w-full max-w-100" src="/img/screens/retirement/employee-retirement-statement-history-other.png?v=10182023" />
      <div class="links">
        <screen-link left="14.5%" top="19.5%" width="5.5%" height="3%" dot-class="dot-top-center" data-tgt="retirement-summary" data-tgt-scrolltop="true" @click.native="onLinkClick"></screen-link>
        <screen-link left="20%" top="19.5%" width="6.5%" height="3%" dot-class="dot-top-center" data-tgt="retirement-contributions" data-tgt-scrolltop="true" @click.native="onLinkClick"></screen-link>
        <screen-link left="26.75%" top="19.5%" width="5.5%" height="3%" dot-class="dot-top-center" data-tgt="retirement-investments" data-tgt-scrolltop="true" @click.native="onLinkClick"></screen-link>
        <screen-link left="32.5%" top="19.5%" width="3.5%" height="3%" dot-class="dot-top-center" data-tgt="retirement-loans" data-tgt-scrolltop="true" @click.native="onLinkClick"></screen-link>
        <screen-link left="36.25%" top="19.5%" width="9.5%" height="3%" dot-class="dot-top-center" data-tgt="retirement-distributions" data-tgt-scrolltop="true" @click.native="onLinkClick"></screen-link>
        <screen-link left="46%" top="19.5%" width="8%" height="3%" dot-class="dot-top-center" data-tgt="retirement-statements" data-tgt-scrolltop="true" @click.native="onLinkClick"></screen-link>

        <screen-link left="15.75%" top="48%" width="20%" height="5%" dot-class="dot-left-center" data-tgt="retirement-statements" data-tgt-scrolltop="true" @click.native="onLinkClick"></screen-link>
        <screen-link left="15.75%" top="63%" width="20%" height="5%" dot-class="dot-left-center" data-tgt="retirement-statements" data-tgt-scrolltop="true" @click.native="onLinkClick"></screen-link>




        <company-tab :company="$store.state.company" select-tgt="retirement-summary"/>
      </div>
    </div>




    <div class="screen" data-screen="retirement-auto-increase" :data-active="currentScreen=='retirement-auto-increase'">
      <img class="w-full max-w-100" src="/img/screens/employee-retirement-auto-increase.png?v=10182023" />
      <div class="links">

        <screen-link dot-class="dot-bottom-center" left="87.6%" top="7%" width="6%" height="5%" data-tgt="retirement-contributions" @click.native="onLinkClick"></screen-link>


        <screen-link left="94%" top="31.5%" width="5%" height="4%" dot-class="dot-left-center" data-tgt="retirement-auto-increase-on" @click.native="onLinkClick"></screen-link>


        <company-tab :company="$store.state.company" select-tgt="retirement-summary"/>
      </div>


    </div>



    <!--- ENROLLMENT -->]



    <div class="screen" data-screen="retirement-enrollment-begin" :data-active="currentScreen=='retirement-enrollment-begin'">
      <img class="w-full max-w-100" src="/img/screens/retirement/employee-retirement-enrollment-begin.png?v=10182023" />
      <div class="links">
        <screen-link left="61.5%" top="74%" width="6.4%" height="4.5%" data-tgt="dashboard" data-tgt-scrolltop="true" @click.native="onLinkClick"></screen-link>
        <screen-link left="68.5%" top="74%" width="7%" height="4.5%" data-tgt="retirement-enrollment-calc" data-tgt-scrolltop="true" @click.native="onLinkClick"></screen-link>
        <company-tab :company="$store.state.company" select-tgt="retirement-summary"/>
      </div>
    </div>

    <div class="screen" data-screen="retirement-enrollment-calc" :data-active="currentScreen=='retirement-enrollment-calc'">
      <img class="w-full max-w-100" src="/img/screens/retirement/employee-retirement-enrollment-calc.png?v=10182023" />
      <div class="links">
        <screen-link left="39%" top="119%" width="6.25%" height="4.5%" data-tgt="retirement-enrollment-begin" data-tgt-scrolltop="true" @click.native="onLinkClick"></screen-link>
        <screen-link left="70%" top="119%" width="7%" height="4.5%" data-tgt="retirement-enrollment-select-investments" data-tgt-scrolltop="true" @click.native="onLinkClick"></screen-link>

        <screen-link left="59%" top="110.75%" width="11.5%" height="4.5%" dot-class="dot-right-center" data-tgt="pullout-legal-info" data-init-info="retirement-calc" @click.native="showPullout"></screen-link>
        <company-tab :company="$store.state.company" select-tgt="retirement-summary"/>
      </div>
    </div>


    <div class="screen" data-screen="retirement-enrollment-select-investments" :data-active="currentScreen=='retirement-enrollment-select-investments'">
      <img class="w-full max-w-100" src="/img/screens/retirement/employee-retirement-enrollment-select-investments.png?v=10182023" />
      <div class="links">
        <screen-link left="39%" top="107.5%" width="6.25%" height="4.5%" data-tgt="retirement-enrollment-calc" @click.native="onLinkClick"></screen-link>
        <screen-link left="69.5%" top="47%" width="6%" height="4.5%" data-tgt="retirement-enrollment-select-investments-auto" @click.native="onLinkClick"></screen-link>

        <screen-link left="69.5%" top="74.1%" width="6%" height="4.5%" data-tgt="retirement-enrollment-select-investments-manual" @click.native="onLinkClick"></screen-link>
        <company-tab :company="$store.state.company" select-tgt="retirement-summary"/>
      </div>
    </div>

    <div class="screen" data-screen="retirement-enrollment-select-investments-auto" :data-active="currentScreen=='retirement-enrollment-select-investments-auto'">
      <img class="w-full max-w-100" src="/img/screens/retirement/employee-retirement-enrollment-select-investments-auto.png?v=10182023" />
      <div class="links">
        <screen-link left="39%" top="107.75%" width="6.25%" height="4.5%" data-tgt="retirement-enrollment-calc" @click.native="onLinkClick"></screen-link>
        <screen-link left="70%" top="107.75%" width="7%" height="4.5%" data-tgt="retirement-enrollment-review"  @click.native="onLinkClick"></screen-link>
        <screen-link left="69.5%" top="74.1%" width="6%" height="4.5%" data-tgt="retirement-enrollment-select-investments-manual" @click.native="onLinkClick"></screen-link>

        <company-tab :company="$store.state.company" select-tgt="retirement-summary"/>
      </div>
    </div>

    <div class="screen" data-screen="retirement-enrollment-select-investments-manual" :data-active="currentScreen=='retirement-enrollment-select-investments-manual'">
      <img class="w-full max-w-100" src="/img/screens/retirement/employee-retirement-enrollment-select-investments-manual.png?v=10182023" />
      <div class="links">
        <screen-link left="39%" top="107.75%" width="6.25%" height="4.5%" data-tgt="retirement-enrollment-calc" data-tgt-scrolltop="true" @click.native="onLinkClick"></screen-link>
        <screen-link left="70%" top="107.75%" width="7%" height="4.5%" data-tgt="retirement-enrollment-select-allocations" data-tgt-scrolltop="true" @click.native="onLinkClick"></screen-link>

        <screen-link left="69.5%" top="47%" width="6%" height="4.5%" data-tgt="retirement-enrollment-select-investments-auto" @click.native="onLinkClick"></screen-link>
        <company-tab :company="$store.state.company" select-tgt="retirement-summary"/>
      </div>
    </div>

    <div class="screen" data-screen="retirement-enrollment-select-allocations" :data-active="currentScreen=='retirement-enrollment-select-allocations'">
      <img class="w-full max-w-100" src="/img/screens/retirement/employee-retirement-enrollment-select-allocations.png?v=10182023" />
      <div class="links">
        <screen-link left="39%" top="267.5%" width="6.25%" height="4.5%" data-tgt="retirement-enrollment-select-investments-manual" data-tgt-scrolltop="true" @click.native="onLinkClick"></screen-link>
        <screen-link left="70%" top="267.5%" width="7%" height="4.5%" data-tgt="retirement-enrollment-review-manual" data-tgt-scrolltop="true" @click.native="onLinkClick"></screen-link>


        <company-tab :company="$store.state.company" select-tgt="retirement-summary"/>
      </div>
    </div>

    <div class="screen" data-screen="retirement-enrollment-review-manual" :data-active="currentScreen=='retirement-enrollment-review-manual'">
      <img class="w-full max-w-100" src="/img/screens/retirement/employee-retirement-enrollment-review-manual.png?v=10182023" />
      <div class="links">
        <screen-link left="39%" top="107.5%" width="6.25%" height="4.5%" data-tgt="retirement-enrollment-select-allocations" data-tgt-scrolltop="true" @click.native="onLinkClick"></screen-link>
        <screen-link left="70%" top="107.5%" width="7%" height="4.5%" data-tgt="retirement-enrollment-confirmation"  data-tgt-scrolltop="true" @click.native="onLinkClick"></screen-link>
        <company-tab :company="$store.state.company" select-tgt="retirement-summary"/>
      </div>
    </div>


    <div class="screen" data-screen="retirement-enrollment-review" :data-active="currentScreen=='retirement-enrollment-review'">
      <img class="w-full max-w-100" src="/img/screens/retirement/employee-retirement-enrollment-review.png?v=10182023" />
      <div class="links">
        <screen-link left="39%" top="74%" width="6.25%" height="4.5%" data-tgt="retirement-enrollment-select-investments-auto" data-tgt-scrolltop="true" @click.native="onLinkClick"></screen-link>
        <screen-link left="70%" top="74%" width="7%" height="4.5%" data-tgt="retirement-enrollment-confirmation" data-tgt-scrolltop="true" @click.native="onLinkClick"></screen-link>
        <company-tab :company="$store.state.company" select-tgt="retirement-summary"/>
      </div>
    </div>

    <div class="screen" data-screen="retirement-enrollment-confirmation" :data-active="currentScreen=='retirement-enrollment-confirmation'">
      <img class="w-full max-w-100" src="/img/screens/retirement/employee-retirement-enrollment-confirmation.png?v=10182023" />
      <div class="links">

        <screen-link left="52%" top="59%" width="11.5%" height="4.5%" dot-class="dot-right-center" data-tgt="retirement-summary" @click.native="onLinkClick"></screen-link>
        <company-tab :company="$store.state.company" select-tgt="retirement-summary"/>
      </div>
    </div>


  </div>
</template>

<script>

export default {
  name: "EmployeeRetirementScreens",
  props: {
    employeeState: {},
    onLinkClick: {},
    showPullout: {},
    currentScreen: {
      default: "retirement-summary"
    }
  },
  data: function(){
    return {
      currentRor: false
    }
  }
}
</script>

<style scoped>

 .contributions-10-cutout {
   position: absolute;
   display: block;
   height: calc(356/920 * 100%);
   width: calc(310/1580 * 100%);
 }

 .overlay-text {
   position: absolute;
   font-weight: bold;
   background-color: white;
   color: #606060;
   font-family: "Helvetica", "Arial", sans-serif;
   font-size:1em;
   padding: 0.2em;
   text-align: right;
 }

 .overlay-text-summary {
   position: absolute;
   font-weight: bold;
   background-color: white;
   color: #606060;
   font-family: "Helvetica", "Arial", sans-serif;
   font-size:1em;
   padding: 0.2em 1em;
   text-align: right;
 }

 .rate-of-return {
   position: absolute;
   width: calc((310/1580) * 100%);

 }

 .rate-of-return img {
   width: 100%;
 }

</style>