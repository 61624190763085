<template>
  <div>
    <div class="screen" data-screen="myperks" :data-active="currentScreen=='myperks'">
      <img class="w-full max-w-100" src="/img/screens/employee/perks/my-perks.png?v=10122022" />
      <div class="links">
        <screen-link dot-class="dot-top-center" left="21%" top="48.75%" width="12%" height="5%" data-tgt="/#/corestream/home" data-tgt-query="true" @click.native="$screens.showExternalPage"></screen-link>
        <screen-link dot-class="dot-top-center" left="41%" top="48.75%" width="12.5%" height="5%" data-tgt="/#/mypay/dashboard" data-tgt-query="true"   @click.native="$screens.showExternalPage"></screen-link>
        <screen-link dot-class="dot-top-center" left="61.5%" top="48.75%" width="12%" height="5%" data-tgt="/#/payactiv/home" data-tgt-query="true"  @click.native="$screens.showExternalPage"></screen-link>
        <screen-link dot-class="dot-top-center" left="82%" top="48.75%" width="12%" height="5%" data-tgt="/#/finfit/start" data-tgt-query="true"  @click.native="$screens.showExternalPage"></screen-link>


        <info-box left="97.5%" top="10.5%" width="40em" height="4.5%" >
          <p>Using My Perks, employees can access extra services and benefits offered by their employer.</p>
        </info-box>
      </div>
    </div>




  </div>
</template>

<script>

export default {
  name: "EmployeeMyPerksScreens",
  props: {
    onLinkClick: {},
    showPullout: {},
    currentScreen: {
      default: ""
    }
  }
}
</script>

<style scoped>

</style>