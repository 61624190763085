


<template>
<div class="basic-pullout pullout-panel">
  <div class="bkg"></div>
  <div class="primary-panel">

    <div class="basic-scroll" v-if="lang === 'en' && langSelection === 'en'">
      <img class="w-full" src="/img/screens/preferences/employee-user-preferences-languages-english-on.png?v=10122022" />

      <div class="links">
        <screen-link left="93%" top="1%" width="6%" height="5%" data-tgt="profile" dot-class="dot-bottom-center" data-is-overlay="true" @click.native="$emit('close')"></screen-link>
        <screen-link left="5%" top="18%" width="25%" height="4%" data-tgt="profile" dot-class="dot-left-center" data-is-overlay="true" @click.native="()=>(showOptions = true)"></screen-link>
      </div>
      <basic-options-panel v-if="showOptions" width="50%" left="3%" top="15%" :items="langPrefs" selection="en" :on-select="onLanguageChange"></basic-options-panel>
    </div>

    <div class="basic-scroll" v-if="lang === 'en' && langSelection !== 'en'">
      <img class="w-full" src="/img/screens/preferences/employee-user-preferences-languages-en-es.png?v=10122022" />

      <div class="links">
        <screen-link left="93%" top="1%" width="6%" height="5%" data-tgt="profile" dot-class="dot-bottom-center" data-is-overlay="true" @click.native="$emit('close')"></screen-link>
        <screen-link left="5%" top="18%" width="25%" height="4%" data-tgt="profile" dot-class="dot-left-center" data-is-overlay="true" @click.native="()=>(showOptions = true)"></screen-link>
        <screen-link left="70%" top="15.5%" width="24%" height="5%" data-tgt="profile" dot-class="dot-left-center" data-is-overlay="true" @click.native="onSetLanguage"></screen-link>
      </div>
      <basic-options-panel v-if="showOptions" width="50%" left="3%" top="15%" :items="langPrefs" selection="es" :on-select="onLanguageChange"></basic-options-panel>
    </div>

    <div class="basic-scroll" v-if="lang === 'es' && langSelection === 'es'">
      <img class="w-full" src="/img/screens/preferences/employee-user-preferences-languages-spanish-on.png?v=10122022" />

      <div class="links">
        <screen-link left="93%" top="1%" width="6%" height="5%" data-tgt="profile" dot-class="dot-bottom-center" data-is-overlay="true" @click.native="$emit('close')"></screen-link>
        <screen-link left="5%" top="18%" width="25%" height="4%" data-tgt="profile" dot-class="dot-left-center" data-is-overlay="true" @click.native="()=>(showOptions = true)"></screen-link>
      </div>
      <basic-options-panel v-if="showOptions" width="50%" left="3%" top="15%" :items="langPrefs" selection="es" :on-select="onLanguageChange"></basic-options-panel>
    </div>

    <div class="basic-scroll" v-if="lang === 'es' && langSelection !== 'es'">
      <img class="w-full" src="/img/screens/preferences/employee-user-preferences-languages-es-en.png?v=10122022" />

      <div class="links">
        <screen-link left="93%" top="1%" width="6%" height="5%" data-tgt="profile" dot-class="dot-bottom-center" data-is-overlay="true" @click.native="$emit('close')"></screen-link>
        <screen-link left="5%" top="18%" width="25%" height="4%" data-tgt="profile" dot-class="dot-left-center" data-is-overlay="true" @click.native="()=>(showOptions = true)"></screen-link>
        <screen-link left="70%" top="15.5%" width="24%" height="5%" data-tgt="profile" dot-class="dot-left-center" data-is-overlay="true" @click.native="onSetLanguage"></screen-link>
      </div>
      <basic-options-panel v-if="showOptions" width="50%" left="3%" top="15%" :items="langPrefs" selection="en" :on-select="onLanguageChange"></basic-options-panel>
    </div>

    <transition name="fade-slide">
      <user-alert>This feature is only available for non-admin users.</user-alert>
    </transition>



  </div>


</div>
</template>

<script>
import ScreenLink from "@/components/ScreenLink";
import BasicOptionsPanel from "@/components/BasicOptionsPanel";
import UserAlert from "@/components/UserAlert";

export default {
    name: "preferences-pullout",
  components: {UserAlert, BasicOptionsPanel, ScreenLink},
  props: {
    lang: {
      default: "en"
    }
  },
    data: function(){
        return {
          detailPanel: null,
          showOptions: false,
          langSelection: this.lang,
          langPrefs: [
            { label: "English", value:"en"},
            { label: "Espanol", value:"es"},
          ],
        }
        },
    mounted() {
      if(this.$route.query.level !== undefined){
        this.detailPanel = this.$route.query.level;
      }
    },

    watch: {
      $route: function(next){
        if(next.query.level !== undefined){
          this.detailPanel = next.query.level;
        }
      }
    },

    methods: {

      onLanguageChange: function(e){
        //this.$emit("langChange", e.value);
        this.langSelection = e.value;
        this.showOptions = false;
      },

      onSetLanguage: function(){
        this.$emit("langChange", this.langSelection);
        this.showOptions = false;
        this.$emit('close');
      },

      showDetails: function(e){
        this.showOptions = false;
        this.showAdd = false;

        this.detailPanel = e.target.dataset.tgt;
        let query = {...this.$route.query};
        query.level=e.target.dataset.tgt;
        this.$router.replace({ name: this.$route.name, params: this.$route.params, query: query });


      },

    },
    computed: {

    }
}
</script>

<style scoped>


/* SEE GLOBAL CSS FOR BASIC PULLOUT CLASSES */

.close-icon {
  padding: 0.5em;
  position: absolute;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
}

</style>
