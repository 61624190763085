<template>
  <div class="screen-container">
    <div class="demo" :data-noscroll="$store.state.overlayPanel?true:false" :data-guided="guidedMode" :data-show-links="showLinks">

      <div class="screens" ref="screens">

        <div class="screen z-max" data-screen="start" :data-active="currentScreen=='start'">
          <img class="w-full max-w-100" src="/img/screens/employee/perks/fin-fit-start.png?v=06102024" />
          <div class="links">

            <screen-link left="59%" top="27%" width="14%" height="5.5%" dot-class="dot-left-center" data-tgt="credit" @click.native="onLinkClick"></screen-link>
            <screen-link left="59%" top="40.75%" width="14%" height="5.5%" dot-class="dot-left-center" data-tgt="loan" @click.native="onLinkClick"></screen-link>
            <screen-link left="80%" top="47.5%" width="14%" height="6%" dot-class="dot-left-center" data-tgt="toolkit" @click.native="onLinkClick"></screen-link>

            <div class="close-button" @click="close">
              <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 21 21">
                <path d="M28.5,9.615,26.385,7.5,18,15.885,9.615,7.5,7.5,9.615,15.885,18,7.5,26.385,9.615,28.5,18,20.115,26.385,28.5,28.5,26.385,20.115,18Z" transform="translate(-7.5 -7.5)"/>
              </svg>
            </div>

          </div>
        </div>

        <div class="screen z-max" data-screen="toolkit" :data-active="currentScreen=='toolkit'">
          <img class="w-full max-w-100" src="/img/screens/employee/perks/fin-fit-get-started.png?v=06102024" />
          <div class="links">

            <screen-link left="0%" top="1%" width="11%" height="5.5%" dot-class="dot-bottom-center" data-tgt="start" @click.native="onLinkClick"></screen-link>


            <div class="close-button" @click="close">
              <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 21 21">
                <path d="M28.5,9.615,26.385,7.5,18,15.885,9.615,7.5,7.5,9.615,15.885,18,7.5,26.385,9.615,28.5,18,20.115,26.385,28.5,28.5,26.385,20.115,18Z" transform="translate(-7.5 -7.5)"/>
              </svg>
            </div>

          </div>
        </div>

        <div class="screen z-max" data-screen="credit" :data-active="currentScreen=='credit'">
          <img class="w-full max-w-100" src="/img/screens/employee/perks/fin-fit-credit-line.png?v=06102024" />
          <div class="links">

            <screen-link left="59%" top="80%" width="14%" height="5.5%" dot-class="dot-left-center" data-tgt="loan" @click.native="onLinkClick"></screen-link>
            <screen-link left="80%" top="47.5%" width="14%" height="6%" dot-class="dot-left-center" data-tgt="toolkit" @click.native="onLinkClick"></screen-link>

            <div class="close-button" @click="close">
              <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 21 21">
                <path d="M28.5,9.615,26.385,7.5,18,15.885,9.615,7.5,7.5,9.615,15.885,18,7.5,26.385,9.615,28.5,18,20.115,26.385,28.5,28.5,26.385,20.115,18Z" transform="translate(-7.5 -7.5)"/>
              </svg>
            </div>

          </div>
        </div>

        <div class="screen z-max" data-screen="loan" :data-active="currentScreen=='loan'">
          <img class="w-full max-w-100" src="/img/screens/employee/perks/fin-fit-loan.png?v=06102024" />
          <div class="links">

            <screen-link left="59%" top="27%" width="14%" height="5.5%" dot-class="dot-left-center" data-tgt="credit" @click.native="onLinkClick"></screen-link>
            <screen-link left="59%" top="87.75%" width="14%" height="5.5%" dot-class="dot-left-center" data-tgt-scrollTop="true" data-tgt="loan-1" @click.native="onLinkClick"></screen-link>
            <screen-link left="80%" top="47.5%" width="14%" height="6%" dot-class="dot-left-center" data-tgt="toolkit" @click.native="onLinkClick"></screen-link>

            <div class="close-button" @click="close">
              <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 21 21">
                <path d="M28.5,9.615,26.385,7.5,18,15.885,9.615,7.5,7.5,9.615,15.885,18,7.5,26.385,9.615,28.5,18,20.115,26.385,28.5,28.5,26.385,20.115,18Z" transform="translate(-7.5 -7.5)"/>
              </svg>
            </div>

          </div>
        </div>

        <div class="screen z-max" data-screen="loan-1" :data-active="currentScreen=='loan-1'">
          <img class="w-full max-w-100" src="/img/screens/employee/perks/fin-fit-loan-1.png?v=06102024" />
          <div class="links">

            <screen-link left="19%" top="63.5%" width="16%" height="5.5%" dot-class="dot-left-center" data-tgt="loan-2" @click.native="onLinkClick"></screen-link>

            <div class="close-button" @click="close">
              <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 21 21">
                <path d="M28.5,9.615,26.385,7.5,18,15.885,9.615,7.5,7.5,9.615,15.885,18,7.5,26.385,9.615,28.5,18,20.115,26.385,28.5,28.5,26.385,20.115,18Z" transform="translate(-7.5 -7.5)"/>
              </svg>
            </div>

          </div>
        </div>
        <div class="screen z-max" data-screen="loan-2" :data-active="currentScreen=='loan-2'">
          <img class="w-full max-w-100" src="/img/screens/employee/perks/fin-fit-loan-2.png?v=06102024" />
          <div class="links">

            <screen-link left="19%" top="44.5%" width="16%" height="5.5%" dot-class="dot-left-center" data-tgt="loan-3" @click.native="onLinkClick"></screen-link>

            <div class="close-button" @click="close">
              <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 21 21">
                <path d="M28.5,9.615,26.385,7.5,18,15.885,9.615,7.5,7.5,9.615,15.885,18,7.5,26.385,9.615,28.5,18,20.115,26.385,28.5,28.5,26.385,20.115,18Z" transform="translate(-7.5 -7.5)"/>
              </svg>
            </div>

          </div>
        </div>
        <div class="screen z-max" data-screen="loan-3" :data-active="currentScreen=='loan-3'">
          <img class="w-full max-w-100" src="/img/screens/employee/perks/fin-fit-loan-3.png?v=06102024" />
          <div class="links">

            <screen-link left="19%" top="129%" width="16%" height="5.5%" dot-class="dot-left-center" data-tgt-scrollTop="true" data-tgt="loan-4" @click.native="onLinkClick"></screen-link>

            <div class="close-button" @click="close">
              <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 21 21">
                <path d="M28.5,9.615,26.385,7.5,18,15.885,9.615,7.5,7.5,9.615,15.885,18,7.5,26.385,9.615,28.5,18,20.115,26.385,28.5,28.5,26.385,20.115,18Z" transform="translate(-7.5 -7.5)"/>
              </svg>
            </div>

          </div>
        </div>
        <div class="screen z-max" data-screen="loan-4" :data-active="currentScreen=='loan-4'">
          <img class="w-full max-w-100" src="/img/screens/employee/perks/fin-fit-loan-4.png?v=06102024" />
          <div class="links">

            <screen-link left="19%" top="124%" width="16%" height="5.5%" dot-class="dot-left-center" data-tgt-scrollTop="true" data-tgt="loan-5" @click.native="onLinkClick"></screen-link>

            <div class="close-button" @click="close">
              <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 21 21">
                <path d="M28.5,9.615,26.385,7.5,18,15.885,9.615,7.5,7.5,9.615,15.885,18,7.5,26.385,9.615,28.5,18,20.115,26.385,28.5,28.5,26.385,20.115,18Z" transform="translate(-7.5 -7.5)"/>
              </svg>
            </div>

          </div>
        </div>
        <div class="screen z-max" data-screen="loan-5" :data-active="currentScreen=='loan-5'">
          <img class="w-full max-w-100" src="/img/screens/employee/perks/fin-fit-loan-5.png?v=06102024" />
          <div class="links">

            <screen-link left="19%" top="89.5%" width="16%" height="5.5%" dot-class="dot-left-center" data-tgt-scrollTop="true" data-tgt="loan-6" @click.native="onLinkClick"></screen-link>

            <div class="close-button" @click="close">
              <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 21 21">
                <path d="M28.5,9.615,26.385,7.5,18,15.885,9.615,7.5,7.5,9.615,15.885,18,7.5,26.385,9.615,28.5,18,20.115,26.385,28.5,28.5,26.385,20.115,18Z" transform="translate(-7.5 -7.5)"/>
              </svg>
            </div>

          </div>
        </div>
        <div class="screen z-max" data-screen="loan-6" :data-active="currentScreen=='loan-6'">
          <img class="w-full max-w-100" src="/img/screens/employee/perks/fin-fit-loan-6.png?v=06102024" />
          <div class="links">

            <screen-link left="19%" top="75%" width="16%" height="5.5%" dot-class="dot-left-center"  data-tgt-scrollTop="true" data-tgt="loan-7" @click.native="onLinkClick"></screen-link>

            <div class="close-button" @click="close">
              <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 21 21">
                <path d="M28.5,9.615,26.385,7.5,18,15.885,9.615,7.5,7.5,9.615,15.885,18,7.5,26.385,9.615,28.5,18,20.115,26.385,28.5,28.5,26.385,20.115,18Z" transform="translate(-7.5 -7.5)"/>
              </svg>
            </div>

          </div>
        </div>
        <div class="screen z-max" data-screen="loan-7" :data-active="currentScreen=='loan-7'">
          <img class="w-full max-w-100" src="/img/screens/employee/perks/fin-fit-loan-7.png?v=06102024" />
          <div class="links">

            <screen-link left="19%" top="116%" width="16%" height="5.5%" dot-class="dot-left-center" data-tgt-scrollTop="true"  data-tgt="loan-8" @click.native="onLinkClick"></screen-link>

            <div class="close-button" @click="close">
              <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 21 21">
                <path d="M28.5,9.615,26.385,7.5,18,15.885,9.615,7.5,7.5,9.615,15.885,18,7.5,26.385,9.615,28.5,18,20.115,26.385,28.5,28.5,26.385,20.115,18Z" transform="translate(-7.5 -7.5)"/>
              </svg>
            </div>

          </div>
        </div>

        <div class="screen z-max" data-screen="loan-8" :data-active="currentScreen=='loan-8'">
          <img class="w-full max-w-100" src="/img/screens/employee/perks/fin-fit-loan-8.png?v=06102024" />
          <div class="links">

            <screen-link left="19%" top="115.75%" width="16%" height="5.5%" dot-class="dot-left-center" data-tgt-scrollTop="true"  data-tgt="loan-9" @click.native="onLinkClick"></screen-link>

            <div class="close-button" @click="close">
              <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 21 21">
                <path d="M28.5,9.615,26.385,7.5,18,15.885,9.615,7.5,7.5,9.615,15.885,18,7.5,26.385,9.615,28.5,18,20.115,26.385,28.5,28.5,26.385,20.115,18Z" transform="translate(-7.5 -7.5)"/>
              </svg>
            </div>

          </div>
        </div>
        <div class="screen z-max" data-screen="loan-9" :data-active="currentScreen=='loan-9'">
          <img class="w-full max-w-100" src="/img/screens/employee/perks/fin-fit-loan-9.png?v=06102024" />
          <div class="links">

            <screen-link left="31%" top="77.5%" width="12%" height="5.5%" dot-class="dot-left-center" data-tgt-scrollTop="true" data-tgt="loan-10" @click.native="onLinkClick"></screen-link>

            <div class="close-button" @click="close">
              <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 21 21">
                <path d="M28.5,9.615,26.385,7.5,18,15.885,9.615,7.5,7.5,9.615,15.885,18,7.5,26.385,9.615,28.5,18,20.115,26.385,28.5,28.5,26.385,20.115,18Z" transform="translate(-7.5 -7.5)"/>
              </svg>
            </div>

          </div>
        </div>
        <div class="screen z-max" data-screen="loan-10" :data-active="currentScreen=='loan-10'">
          <img class="w-full max-w-100" src="/img/screens/employee/perks/fin-fit-loan-10.png?v=06102024" />
          <div class="links">

            <screen-link left="19%" top="109%" width="16%" height="5.5%" dot-class="dot-left-center" data-tgt-scrollTop="true"  data-tgt="loan-11" @click.native="onLinkClick"></screen-link>

            <div class="close-button" @click="close">
              <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 21 21">
                <path d="M28.5,9.615,26.385,7.5,18,15.885,9.615,7.5,7.5,9.615,15.885,18,7.5,26.385,9.615,28.5,18,20.115,26.385,28.5,28.5,26.385,20.115,18Z" transform="translate(-7.5 -7.5)"/>
              </svg>
            </div>

          </div>
        </div>
        <div class="screen z-max" data-screen="loan-11" :data-active="currentScreen=='loan-11'">
          <img class="w-full max-w-100" src="/img/screens/employee/perks/fin-fit-loan-11.png?v=06102024" />
          <div class="links">

            <screen-link left="19%" top="35%" width="16%" height="5.5%" dot-class="dot-left-center" data-tgt-scrollTop="true"  data-tgt="loan-12" @click.native="onLinkClick"></screen-link>

            <div class="close-button" @click="close">
              <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 21 21">
                <path d="M28.5,9.615,26.385,7.5,18,15.885,9.615,7.5,7.5,9.615,15.885,18,7.5,26.385,9.615,28.5,18,20.115,26.385,28.5,28.5,26.385,20.115,18Z" transform="translate(-7.5 -7.5)"/>
              </svg>
            </div>

          </div>
        </div>
        <div class="screen z-max" data-screen="loan-12" :data-active="currentScreen=='loan-12'">
          <img class="w-full max-w-100" src="/img/screens/employee/perks/fin-fit-loan-12.png?v=06102024" />
          <div class="links">

            <screen-link left="19%" top="39.5%" width="16%" height="5.5%" dot-class="dot-left-center" data-tgt-scrollTop="true" data-tgt="loan-added" @click.native="onLinkClick"></screen-link>

            <div class="close-button" @click="close">
              <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 21 21">
                <path d="M28.5,9.615,26.385,7.5,18,15.885,9.615,7.5,7.5,9.615,15.885,18,7.5,26.385,9.615,28.5,18,20.115,26.385,28.5,28.5,26.385,20.115,18Z" transform="translate(-7.5 -7.5)"/>
              </svg>
            </div>

          </div>
        </div>
        <div class="screen z-max" data-screen="loan-1" :data-active="currentScreen=='loan-added'">
          <img class="w-full max-w-100" src="/img/screens/employee/perks/fin-fit-loan-added.png?v=06102024" />

          <div class="links">


            <screen-link left="80%" top="47.5%" width="14%" height="6%" dot-class="dot-left-center" data-tgt="toolkit" @click.native="onLinkClick"></screen-link>
            <div class="close-button" @click="close">
              <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 21 21">
                <path d="M28.5,9.615,26.385,7.5,18,15.885,9.615,7.5,7.5,9.615,15.885,18,7.5,26.385,9.615,28.5,18,20.115,26.385,28.5,28.5,26.385,20.115,18Z" transform="translate(-7.5 -7.5)"/>
              </svg>
            </div>

          </div>
        </div>




        <div class="screen z-max" data-screen="finfit-home" :data-active="currentScreen=='finfit-home'">
          <img class="w-full max-w-100" src="/img/screens/finfit/finfit-home.png?v=10122022" />
          <div class="links">

            <screen-link left="13.5%" top="48%" width="3%" height="30%" dot-class="dot-left-center" data-tgt="finfit-home-2" @click.native="onLinkClick"></screen-link>
            <screen-link left="83.5%" top="48%" width="3%" height="30%" dot-class="dot-right-center" data-tgt="finfit-home-2" @click.native="onLinkClick"></screen-link>

            <screen-link left="42%" top="77%" width="16%" height="8%" dot-class="dot-right-center" data-tgt="" @click.native="close"></screen-link>

          </div>
        </div>

        <div class="screen z-max" data-screen="finfit-home-2" :data-active="currentScreen=='finfit-home-2'">
          <img class="w-full max-w-100" src="/img/screens/finfit/finfit-home-2.png?v=10122022" />
          <div class="links">

            <screen-link left="13.5%" top="48%" width="3%" height="30%" dot-class="dot-left-center" data-tgt="finfit-home" @click.native="onLinkClick"></screen-link>
            <screen-link left="83.5%" top="48%" width="3%" height="30%" dot-class="dot-right-center" data-tgt="finfit-home" @click.native="onLinkClick"></screen-link>

            <screen-link left="42%" top="77%" width="16%" height="8%" dot-class="dot-right-center" data-tgt="" @click.native="close"></screen-link>

          </div>
        </div>




      </div>

    </div>

  </div>

</template>

<script>
import ScreenLink from "../components/ScreenLink";


export default {
  name: "finfit-screens",
  components: { ScreenLink, /*FiltersPanel, FlexInfo, SelectBox,*/ },
  mounted: function(){

    this.$store.state.screenScrollRef = window;

    this.$store.commit("showMenu", false);
    if(this.$route.query){
      this.$store.commit("guidedMode", this.$route.query.guided);
      if(this.$route.query.comments){
        this.$store.commit("showFeedback", true);
      }
    }

  },
  props: {},
  data: function () {
    return {
      defaultScreen: 'finfit-home',
      visibility: {
        topnav: true
      },
      pulloutPanel: null,
      disableScreenScoll: false,


    }
  },
  methods: {

    close: function(){
      window.close();
    },

    onSetGuided: function(guided){

      //this.guidedMode = guided;
      //this.showMenu = false;

      this.$store.commit("guidedMode", guided);
      this.$store.commit("showMenu", false);

    },


    showPrevious: function(){
      this.$router.back();
    },

    onLinkClick: function(e){
      // this.currentScreen = e.target.dataset.tgt;
      if(!e.target.dataset.tgtMode){
        e.target.dataset.tgtMode = 'finfit';
      }

      this.$screens.onLinkClick(e);

    },

    showExternalPage: function(e){
      // this.currentScreen = e.target.dataset.tgt;
      let tgtUrl = e.target.dataset.tgt;
      window.open(tgtUrl, "_blank");
    },

    showPullout: function(e){
      // this.currentScreen = e.target.dataset.tgt;
      this.pulloutPanel = e.target.dataset.tgt
    },

    showOverlay: function(id){
      this.$screens.setOverlayPanel(id)
      //this.overlayPanel = id;
    }
  },
  computed: {

    showMenu: function(){
      return this.$store.state.showMenu;
    },

    showLinks: function(){
      return this.$store.state.showLinks;
    },

    guidedMode: function(){
      return this.$store.state.guidedMode;
    },

    currentScreen: function(){

      let target = this.defaultScreen;
      if(this.$route.params.screen){
        target = this.$route.params.screen;
      }

      return target
    },


    sideNavMode: function(){
      if(this.$route.query.mode){
        return this.$route.query.mode;
      }else{
        return "admin";
      }
    }
  }
}
</script>

<style scoped>

img {
  margin: 0;
}

.screen-container {

  margin: 0px auto;
}

#app[data-feedback-mode="true"] .screen-container {

  margin: 0;

}


.demo {
  width: 100%;
  position: relative;



}

.demo[data-noscroll="true"]{


}



.screens {

  position: relative;
  width: 100%;
  overflow-x: hidden;
}

.overlay {
  position: absolute;
  width: calc((320/1580) * 100%);
  top: calc((60/920) * 100%);
  right: 0;
  display: none;
}

.overlay[data-active="true"] {
  display: block;
}

.pullout {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  right: 0;
  display: none;
  background-color: rgba(0,0,0,0.49);
}

.pullout[data-active="true"] {
  display: block;
}

.popover {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  right: 0;
  display: none;
}

.popover[data-active="true"] {
  display: block;
}

.overlay[data-active="true"] {
  display: block;
}

.screens .screen {
  position: relative;
  width: 100%;
  display: none;
}

.screen[data-active="true"] {
  display: block;
}

.screen .links {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 0;
  padding-top: calc((920/1580) * 100%);
}

.pullout .links {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 0;
  padding-top: calc((920/1580) * 100%);
}


/* UTILITIES */

.w-full {
  width: 100%;
}



.pullout-1200 {
  position: absolute;
  width: calc(100% * (1200 / 3160));
  top: 0;
  right: 0;
}

.popover-email {
  position: absolute;
  width: calc(100% * (1000 / 1580));
  top: 20%;
  right: 5%;
  box-shadow: 0 10px 20px rgba(0,0,0,0.3);

}

.popover-with-bkg {
  background-color: rgba(0,0,0,0.4);


}

.popover-time-attendance {
  position: absolute;
  width: calc(100% * (1000 / 1580));
  top: 20%;
  right: 15%;
  box-shadow: 0 10px 20px rgba(0,0,0,0.3);

}

.popover-schedule-details {
  position: absolute;
  width: calc(100% * (808 / 1580));
  top: 20%;
  right: 20%;
  box-shadow: 0 10px 20px rgba(0,0,0,0.3);

}

.x-ray {
  opacity: 0.5;
}

*[data-hidden] {
  opacity: 0;
}



.demo[data-show-links="true"] .select-box {

}

.demo[data-show-links="true"] .flex-info {
  border: 2px solid var(--color-green);
}

.demo[data-show-links="true"] .info-box {
  display: block;
}

.demo .info-box {
  display: none;
}


.demo[data-guided="true"] .info-box {
  display: block;
}

.z-max {
  z-index: 50;
}

.z-45 {
  z-index: 45;
}

.z-40 {
  z-index: 40;
}

.info-box {
  z-index: 30;
}

.demo-menu {
  top: 0;
}

.close-button {
  position: absolute;
  top: 0;
  right: 0;
  margin: 0.75em;
  z-index: 100;


}

.close-button svg {
  fill:white;
  padding: 0.5em;
  background-color: var(--color-green);
  box-shadow: 0 0 10px rgba(0,0,0,0.5);
  border-radius: 100%;
  border: 2px solid white;
  pointer-events: none;

}

</style>

<style>
html {
  background: #D8D8D8;
}
</style>
