


<template>
<div class="check-stubs-pullout pullout-panel">
  <div class="bkg"></div>
  <div class="check-stubs-list">


    <div class="check-stubs-scroll" v-if="!detailPanel">
      <img class="w-full" src="/img/screens/employee/time-attendance/pullouts/pullout-time-off.png?v=10122022" />
      <div class="links">
        <screen-link left="92.5%" top="1%" width="6%" height="5%" data-tgt="profile" dot-class="dot-bottom-center" data-is-overlay="true" @click.native="$emit('close')"></screen-link>

        <screen-link left="2%" top="5%" width="13%" height="5%" data-tgt="" dot-class="dot-bottom-center" data-is-overlay="true" @click.native="showDetails"></screen-link>
        <screen-link left="15.5%" top="5%" width="13%" height="5%" data-tgt="requests" dot-class="dot-bottom-center" data-is-overlay="true" @click.native="showDetails"></screen-link>
        <screen-link left="29%" top="5%" width="18%" height="5%" data-tgt="history" dot-class="dot-bottom-center" data-is-overlay="true" @click.native="showDetails"></screen-link>
        <screen-link left="47.5%" top="5%" width="24%" height="5%" data-tgt="non-taa" dot-class="dot-bottom-center" data-is-overlay="true" @click.native="showDetails"></screen-link>

        <screen-link left="26%" top="22%" width="25%" height="5%" data-tgt="request" dot-class="dot-left-center" data-is-overlay="true" @click.native="showDetails"></screen-link>

      </div>
    </div>

    <div class="check-stubs-scroll" v-if="detailPanel === 'requests'">
      <img class="w-full" src="/img/screens/employee/time-attendance/pullouts/pullout-time-off-requests.png?v=10122022" />
      <div class="links">
        <screen-link left="92.5%" top="1%" width="6%" height="5%" data-tgt="profile" dot-class="dot-bottom-center" data-is-overlay="true" @click.native="$emit('close')"></screen-link>

        <screen-link left="2%" top="5%" width="13%" height="5%" data-tgt="" dot-class="dot-bottom-center" data-is-overlay="true" @click.native="showDetails"></screen-link>
        <screen-link left="15.5%" top="5%" width="13%" height="5%" data-tgt="requests" dot-class="dot-bottom-center" data-is-overlay="true" @click.native="showDetails"></screen-link>
        <screen-link left="29%" top="5%" width="18%" height="5%" data-tgt="history" dot-class="dot-bottom-center" data-is-overlay="true" @click.native="showDetails"></screen-link>
        <screen-link left="47.5%" top="5%" width="24%" height="5%" data-tgt="non-taa" dot-class="dot-bottom-center" data-is-overlay="true" @click.native="showDetails"></screen-link>


      </div>
    </div>

    <div class="check-stubs-scroll" v-if="detailPanel === 'history'">
      <img class="w-full" src="/img/screens/employee/time-attendance/pullouts/pullout-time-off-accrual.png?v=10122022" />
      <div class="links">
        <screen-link left="92.5%" top="1%" width="6%" height="5%" data-tgt="profile" dot-class="dot-bottom-center" data-is-overlay="true" @click.native="$emit('close')"></screen-link>

        <screen-link left="2%" top="5%" width="13%" height="5%" data-tgt="" dot-class="dot-bottom-center" data-is-overlay="true" @click.native="showDetails"></screen-link>
        <screen-link left="15.5%" top="5%" width="13%" height="5%" data-tgt="requests" dot-class="dot-bottom-center" data-is-overlay="true" @click.native="showDetails"></screen-link>
        <screen-link left="29%" top="5%" width="18%" height="5%" data-tgt="history" dot-class="dot-bottom-center" data-is-overlay="true" @click.native="showDetails"></screen-link>
        <screen-link left="47.5%" top="5%" width="24%" height="5%" data-tgt="non-taa" dot-class="dot-bottom-center" data-is-overlay="true" @click.native="showDetails"></screen-link>


      </div>
    </div>

    <div class="check-stubs-scroll" v-if="detailPanel === 'non-taa'">
      <img class="w-full" src="/img/screens/employee/time-attendance/pullouts/pullout-time-off-non-taa.png?v=10122022" />
      <div class="links">
        <screen-link left="92.5%" top="1%" width="6%" height="5%" data-tgt="profile" dot-class="dot-bottom-center" data-is-overlay="true" @click.native="$emit('close')"></screen-link>

        <screen-link left="2%" top="5%" width="13%" height="5%" data-tgt="" dot-class="dot-bottom-center" data-is-overlay="true" @click.native="showDetails"></screen-link>
        <screen-link left="15.5%" top="5%" width="13%" height="5%" data-tgt="requests" dot-class="dot-bottom-center" data-is-overlay="true" @click.native="showDetails"></screen-link>
        <screen-link left="29%" top="5%" width="18%" height="5%" data-tgt="history" dot-class="dot-bottom-center" data-is-overlay="true" @click.native="showDetails"></screen-link>
        <screen-link left="47.5%" top="5%" width="24%" height="5%" data-tgt="non-taa" dot-class="dot-bottom-center" data-is-overlay="true" @click.native="showDetails"></screen-link>

        <coming-soon label="Enterprise bundle includes Time Off Accruals with optional upgrade for requests and approvals." />
      </div>
    </div>

    <div class="check-stubs-scroll" v-if="detailPanel === 'calendar-settings'">
      <img class="w-full" src="/img/screens/employee/time-attendance/pullouts/pullout-calendar-settings.png?v=10122022" />
      <div class="links">
        <screen-link left="92.5%" top="1%" width="6%" height="5%" data-tgt="profile" dot-class="dot-bottom-center" data-is-overlay="true" @click.native="$emit('close')"></screen-link>

      </div>
    </div>

    <div class="check-stubs-scroll" v-if="detailPanel === 'request'">
      <img class="w-full" src="/img/screens/employee/time-attendance/pullouts/pullout-time-off-request.png?v=10122022" />
      <div class="links">
        <screen-link left="67%" top="1%" width="15%" height="4%" data-tgt="" dot-class="dot-left-center" data-is-overlay="true" @click.native="$emit('close')"></screen-link>

        <screen-link left="8%" top="27%" width="40%" height="5%" data-tgt="request-details" dot-class="dot-left-center" data-is-overlay="true" @click.native="showDetails"></screen-link>
      </div>
    </div>
    <div class="check-stubs-scroll" v-if="detailPanel === 'request-details'">
      <img class="w-full" src="/img/screens/employee/time-attendance/pullouts/pullout-time-off-request-vacation.png?v=10122022" />

      <div class="links">
        <screen-link left="67%" top="1%" width="15%" height="4%" data-tgt="" dot-class="dot-left-center" data-is-overlay="true" @click.native="$emit('close')"></screen-link>

        <screen-link left="83%" top="1%" width="15%" height="4%" data-tgt="" dot-class="dot-right-center" data-is-overlay="true" @click.native="$emit('close')"></screen-link>

      </div>
    </div>
    <div class="check-stubs-scroll" v-if="detailPanel === 'upcoming-details'">
      <img class="w-full" src="/img/screens/employee/dashboard/pullouts/pullout-check-stubs-review-check.png?v=10122022" />

      <div class="links">
        <screen-link left="1%" top="1%" width="30%" height="4%" data-tgt="upcoming" dot-class="dot-left-center" data-is-overlay="true" @click.native="showDetails"></screen-link>
        <screen-link left="92.5%" top="1%" width="6%" height="5%" data-tgt="profile" dot-class="dot-bottom-center" data-is-overlay="true" @click.native="$emit('close')"></screen-link>
        <screen-link left="58%" top="5.5%" width="25%" height="5.5%" data-tgt="report-issue" dot-class="dot-left-center" data-is-overlay="true" @click.native="showDetails"></screen-link>

      </div>
    </div>
    <div class="check-stubs-scroll" v-if="detailPanel === 'report-issue'">
      <img class="w-full" src="/img/screens/employee/dashboard/pullouts/pullout-employee-check-stubs-report-issue.png?v=10122022" />
      <coming-soon></coming-soon>
      <div class="links">
        <screen-link left="68%" top="1%" width="14%" height="5%" data-tgt="upcoming-details" dot-class="dot-bottom-center" data-is-overlay="true" @click.native="showDetails"></screen-link>

        <screen-link left="6%" top="48%" width="90%" height="23%" data-tgt="report-issue-details" dot-class="dot-left-center" data-is-overlay="true" @click.native="showDetails"></screen-link>

      </div>
    </div>

    <div class="check-stubs-scroll" v-if="detailPanel === 'report-issue-details'">
      <img class="w-full" src="/img/screens/employee/dashboard/pullouts/pullout-employee-check-stubs-report-issue-details.png?v=10122022" />
      <coming-soon></coming-soon>
      <div class="links">
        <screen-link left="68%" top="1%" width="14%" height="5%" data-tgt="upcoming-details" dot-class="dot-bottom-center" data-is-overlay="true" @click.native="showDetails"></screen-link>
        <screen-link left="83%" top="1%" width="14%" height="5%" data-tgt="upcoming-details" dot-class="dot-bottom-center" data-is-overlay="true" @click.native="showDetails"></screen-link>

        <screen-link left="6%" top="48%" width="90%" height="23%" data-tgt="report-issue-details" dot-class="dot-left-center" data-is-overlay="true" @click.native="showDetails"></screen-link>

      </div>
    </div>


  </div>



</div>
</template>

<script>
import ScreenLink from "@/components/ScreenLink";
import ComingSoon from "@/components/ComingSoon";
export default {
    name: "time-off-pullout",
  components: {ComingSoon, ScreenLink},
  props: {},
    data: function(){
        return {
          detailPanel: null
        }
        },
    mounted() {
      if(this.$route.query.level !== undefined){
        this.detailPanel = this.$route.query.level;
      }
    },

    watch: {
      $route: function(next){
        if(next.query.level !== undefined){
          this.detailPanel = next.query.level;
        }
      }
    },

    methods: {
      showDetails: function(e){
          this.detailPanel = e.target.dataset.tgt;
let query = {...this.$route.query};
          query.level=e.target.dataset.tgt;
          this.$router.replace({ name: this.$route.name, params: this.$route.params, query: query });
      },

      onLinkClick: function(e){
        console.log(e);
        this.$emit('link', e);
      }
    },
    computed: {}
}
</script>

<style scoped>


.check-stubs-pullout {
  width: 100%;
  position: absolute;
  top: calc((60/920) * 100%);
  height: calc(((920 - 60)/920) * 100%);

}

.check-stubs-pullout .bkg {
  position: absolute;
  width: 100%;
  height: 100%;
  right: 0;
  background-color: black;
  opacity: 0.4;
}

.check-stubs-pullout img {
  display: block;
}


.check-stubs-list {

  position: absolute;
  width: calc((600/1580) * 100% + 15px);
  height: 100%;

  right: 0;
  display: flex;
  flex-direction: column;

}



.check-stubs-scroll {
  position: relative;

  overflow-y: scroll;
}

.w-full {
  width: 100%;
}

.links {

}

</style>
