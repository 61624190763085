<template>
  <div class="screen-container">
    <div class="demo" :data-noscroll="$store.state.overlayPanel?true:false" :data-guided="guidedMode" :data-show-links="showLinks">

      <div class="screens" ref="screens">
        <!--- DEFAULT ONBOARDING WITH FULL RECRUITING -->
        <div>



        <div class="screen z-max" data-screen="onboarding-email" :data-active="currentScreen=='onboarding-email'">
          <img class="w-full max-w-100" src="/img/screens/onboarding/onboarding-email.png?v=10122022" />
          <div class="links">
            <screen-link left="97%" top="0%" width="3%" height="4.5%" dot-class="dot-bottom-center" data-tgt="hiring-applicants-requisition-email" data-tgt-mode="admin" @click.native="onLinkClick"></screen-link>
            <screen-link left="0.5%" top="57%" width="11%" height="4%" dot-class="dot-bottom-center" data-tgt="onboarding-begin" @click.native="onLinkClick"></screen-link>

          </div>
        </div>

        <div class="screen z-max" data-screen="onboarding-email-norecruit" :data-active="currentScreen=='onboarding-email-norecruit'">
          <img class="w-full max-w-100" src="/img/screens/onboarding/onboarding-email.png?v=10122022" />
          <div class="links">
            <screen-link left="97%" top="0%" width="3%" height="4.5%" dot-class="dot-bottom-center" data-tgt="hiring-norecruit-workers-add-details" data-tgt-mode="admin" @click.native="onLinkClick"></screen-link>
            <screen-link left="0.5%" top="57%" width="11%" height="4%" dot-class="dot-bottom-center" data-tgt="onboarding-begin" @click.native="setNoRecruitMode"></screen-link>

          </div>
        </div>

        <div class="screen z-max" data-screen="onboarding-begin" :data-active="currentScreen=='onboarding-begin'">
          <img class="w-full max-w-100" src="/img/screens/onboarding/onboarding-begin.png?v=10122022" />
          <div class="links">
            <screen-link left="85%" top="2%" width="5%" height="4.5%" dot-class="dot-right-center" data-tgt="hiring-applicants-jobboard-manager-sign" data-tgt-mode="admin" @click.native="close"></screen-link>
            <screen-link left="82%" top="85.5%" width="6.5%" height="4.5%" dot-class="dot-right-center" data-tgt="onboarding-id" @click.native="onLinkClick"></screen-link>

          </div>
        </div>

        <div class="screen z-max" data-screen="onboarding-id" :data-active="currentScreen=='onboarding-id'">
          <img class="w-full max-w-100" src="/img/screens/onboarding/onboarding-id.png?v=10122022" />
          <div class="links">

            <screen-link left="85%" top="2%" width="5%" height="4.5%" dot-class="dot-right-center" data-tgt="hiring-applicants-jobboard-manager-sign" data-tgt-mode="admin" @click.native="close"></screen-link>

            <screen-link left="10%" top="12.25%" width="20%" height="4.5%" data-tgt-scrolltop="true" data-tgt="onboarding-id" @click.native="onLinkClick"></screen-link>
            <screen-link left="10%" top="19.5%" width="20%" height="4.5%" data-tgt-scrolltop="true" data-tgt="onboarding-directdeposit" @click.native="onLinkClick"></screen-link>
            <screen-link left="10%" top="26.25%" width="20%" height="4.5%" data-tgt-scrolltop="true" data-tgt="onboarding-w4" @click.native="onLinkClick"></screen-link>
            <screen-link left="10%" top="33.5%" width="20%" height="4.5%" data-tgt-scrolltop="true" data-tgt="onboarding-state-w4" @click.native="onLinkClick"></screen-link>
            <screen-link left="10%" top="40.5%" width="20%" height="4.5%" data-tgt-scrolltop="true" data-tgt="onboarding-download" @click.native="onLinkClick"></screen-link>
            <screen-link left="10%" top="47.5%" width="20%" height="4.5%" data-tgt-scrolltop="true" data-tgt="onboarding-upload" @click.native="onLinkClick"></screen-link>
            <screen-link left="10%" top="54.5%" width="20%" height="4.5%" data-tgt-scrolltop="true" data-tgt="onboarding-policy" @click.native="onLinkClick"></screen-link>
            <screen-link left="10%" top="61.75%" width="20%" height="4.5%" data-tgt-scrolltop="true" data-tgt="onboarding-handbook" @click.native="onLinkClick"></screen-link>
            <screen-link left="10%" top="68.75%" width="20%" height="4.5%" data-tgt-scrolltop="true" data-tgt="onboarding-contacts" @click.native="onLinkClick"></screen-link>
            <screen-link left="10%" top="75.75%" width="20%" height="4.5%" data-tgt-scrolltop="true" data-tgt="onboarding-i9" @click.native="onLinkClick"></screen-link>
            <screen-link left="10%" top="82.75%" width="20%" height="4.5%" data-tgt-scrolltop="true" data-tgt="onboarding-final" @click.native="onLinkClick"></screen-link>


            <screen-link left="35%" top="127.5%" width="6.5%" height="4.5%" dot-class="dot-right-center" data-tgt-scrolltop="true" data-tgt="onboarding-begin" @click.native="onLinkClick"></screen-link>
            <screen-link left="82%" top="127.5%" width="6.5%" height="4.5%" dot-class="dot-right-center" data-tgt-scrolltop="true" data-tgt="onboarding-directdeposit" @click.native="onLinkClick"></screen-link>

          </div>
        </div>

        <div class="screen z-max" data-screen="onboarding-directdeposit" :data-active="currentScreen=='onboarding-directdeposit'">
          <img class="w-full max-w-100" src="/img/screens/onboarding/onboarding-directdeposit.png?v=10122022" />
          <div class="links">
            <screen-link left="85%" top="2%" width="5%" height="4.5%" dot-class="dot-right-center" data-tgt="hiring-applicants-jobboard-manager-sign" data-tgt-mode="admin" @click.native="close"></screen-link>

            <screen-link left="10%" top="12.25%" width="20%" height="4.5%" data-tgt-scrolltop="true" data-tgt="onboarding-id" @click.native="onLinkClick"></screen-link>
            <screen-link left="10%" top="19.5%" width="20%" height="4.5%" data-tgt-scrolltop="true" data-tgt="onboarding-directdeposit" @click.native="onLinkClick"></screen-link>
            <screen-link left="10%" top="26.25%" width="20%" height="4.5%" data-tgt-scrolltop="true" data-tgt="onboarding-w4" @click.native="onLinkClick"></screen-link>
            <screen-link left="10%" top="33.5%" width="20%" height="4.5%" data-tgt-scrolltop="true" data-tgt="onboarding-state-w4" @click.native="onLinkClick"></screen-link>
            <screen-link left="10%" top="40.5%" width="20%" height="4.5%" data-tgt-scrolltop="true" data-tgt="onboarding-download" @click.native="onLinkClick"></screen-link>
            <screen-link left="10%" top="47.5%" width="20%" height="4.5%" data-tgt-scrolltop="true" data-tgt="onboarding-upload" @click.native="onLinkClick"></screen-link>
            <screen-link left="10%" top="54.5%" width="20%" height="4.5%" data-tgt-scrolltop="true" data-tgt="onboarding-policy" @click.native="onLinkClick"></screen-link>
            <screen-link left="10%" top="61.75%" width="20%" height="4.5%" data-tgt-scrolltop="true" data-tgt="onboarding-handbook" @click.native="onLinkClick"></screen-link>
            <screen-link left="10%" top="68.75%" width="20%" height="4.5%" data-tgt-scrolltop="true" data-tgt="onboarding-contacts" @click.native="onLinkClick"></screen-link>
            <screen-link left="10%" top="75.75%" width="20%" height="4.5%" data-tgt-scrolltop="true" data-tgt="onboarding-i9" @click.native="onLinkClick"></screen-link>
            <screen-link left="10%" top="82.75%" width="20%" height="4.5%" data-tgt-scrolltop="true" data-tgt="onboarding-final" @click.native="onLinkClick"></screen-link>



            <screen-link left="79.5%" top="162%" width="9%" height="4.5%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="onboarding-directdeposit-doc" @click.native="onLinkClick"></screen-link>

          </div>
        </div>

        <div class="screen z-max" data-screen="onboarding-directdeposit-doc" :data-active="currentScreen=='onboarding-directdeposit-doc'">
          <img class="w-full max-w-100" src="/img/screens/onboarding/onboarding-directdeposit-doc.png?v=10122022" />
          <div class="links">
            <screen-link left="85%" top="2%" width="5%" height="4.5%" dot-class="dot-right-center" data-tgt="hiring-applicants-jobboard-manager-sign" data-tgt-mode="admin" @click.native="close"></screen-link>
            <screen-link left="10%" top="12.25%" width="20%" height="4.5%" data-tgt-scrolltop="true" data-tgt="onboarding-id" @click.native="onLinkClick"></screen-link>
            <screen-link left="10%" top="19.5%" width="20%" height="4.5%" data-tgt-scrolltop="true" data-tgt="onboarding-directdeposit" @click.native="onLinkClick"></screen-link>
            <screen-link left="10%" top="26.25%" width="20%" height="4.5%" data-tgt-scrolltop="true" data-tgt="onboarding-w4" @click.native="onLinkClick"></screen-link>
            <screen-link left="10%" top="33.5%" width="20%" height="4.5%" data-tgt-scrolltop="true" data-tgt="onboarding-state-w4" @click.native="onLinkClick"></screen-link>
            <screen-link left="10%" top="40.5%" width="20%" height="4.5%" data-tgt-scrolltop="true" data-tgt="onboarding-download" @click.native="onLinkClick"></screen-link>
            <screen-link left="10%" top="47.5%" width="20%" height="4.5%" data-tgt-scrolltop="true" data-tgt="onboarding-upload" @click.native="onLinkClick"></screen-link>
            <screen-link left="10%" top="54.5%" width="20%" height="4.5%" data-tgt-scrolltop="true" data-tgt="onboarding-policy" @click.native="onLinkClick"></screen-link>
            <screen-link left="10%" top="61.75%" width="20%" height="4.5%" data-tgt-scrolltop="true" data-tgt="onboarding-handbook" @click.native="onLinkClick"></screen-link>
            <screen-link left="10%" top="68.75%" width="20%" height="4.5%" data-tgt-scrolltop="true" data-tgt="onboarding-contacts" @click.native="onLinkClick"></screen-link>
            <screen-link left="10%" top="75.75%" width="20%" height="4.5%" data-tgt-scrolltop="true" data-tgt="onboarding-i9" @click.native="onLinkClick"></screen-link>
            <screen-link left="10%" top="82.75%" width="20%" height="4.5%" data-tgt-scrolltop="true" data-tgt="onboarding-final" @click.native="onLinkClick"></screen-link>



            <screen-link left="79.5%" top="164%" width="9%" height="4.5%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="" @click.native="showOverlay('sign-directdeposit')"></screen-link>
            <document-sign v-if="$store.state.overlayPanel === 'sign-directdeposit'" confirm-tgt="onboarding-w4" confirm-mode="onboarding"></document-sign>


          </div>
        </div>

        <div class="screen z-max" data-screen="onboarding-w4" :data-active="currentScreen=='onboarding-w4'">
          <img class="w-full max-w-100" src="/img/screens/onboarding/onboarding-w4.png?v=10122022" />
          <div class="links">
            <screen-link left="85%" top="2%" width="5%" height="4.5%" dot-class="dot-right-center" data-tgt="hiring-applicants-jobboard-manager-sign" data-tgt-mode="admin" @click.native="close"></screen-link>
            <screen-link left="10%" top="12.25%" width="20%" height="4.5%" data-tgt-scrolltop="true" data-tgt="onboarding-id" @click.native="onLinkClick"></screen-link>
            <screen-link left="10%" top="19.5%" width="20%" height="4.5%" data-tgt-scrolltop="true" data-tgt="onboarding-directdeposit" @click.native="onLinkClick"></screen-link>
            <screen-link left="10%" top="26.25%" width="20%" height="4.5%" data-tgt-scrolltop="true" data-tgt="onboarding-w4" @click.native="onLinkClick"></screen-link>
            <screen-link left="10%" top="33.5%" width="20%" height="4.5%" data-tgt-scrolltop="true" data-tgt="onboarding-state-w4" @click.native="onLinkClick"></screen-link>
            <screen-link left="10%" top="40.5%" width="20%" height="4.5%" data-tgt-scrolltop="true" data-tgt="onboarding-download" @click.native="onLinkClick"></screen-link>
            <screen-link left="10%" top="47.5%" width="20%" height="4.5%" data-tgt-scrolltop="true" data-tgt="onboarding-upload" @click.native="onLinkClick"></screen-link>
            <screen-link left="10%" top="54.5%" width="20%" height="4.5%" data-tgt-scrolltop="true" data-tgt="onboarding-policy" @click.native="onLinkClick"></screen-link>
            <screen-link left="10%" top="61.75%" width="20%" height="4.5%" data-tgt-scrolltop="true" data-tgt="onboarding-handbook" @click.native="onLinkClick"></screen-link>
            <screen-link left="10%" top="68.75%" width="20%" height="4.5%" data-tgt-scrolltop="true" data-tgt="onboarding-contacts" @click.native="onLinkClick"></screen-link>
            <screen-link left="10%" top="75.75%" width="20%" height="4.5%" data-tgt-scrolltop="true" data-tgt="onboarding-i9" @click.native="onLinkClick"></screen-link>
            <screen-link left="10%" top="82.75%" width="20%" height="4.5%" data-tgt-scrolltop="true" data-tgt="onboarding-final" @click.native="onLinkClick"></screen-link>

            <screen-link left="82%" top="33%" width="7%" height="4.5%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="onboarding-w4-form" @click.native="onLinkClick"></screen-link>

          </div>
        </div>

        <div class="screen z-max" data-screen="onboarding-w4-form" :data-active="currentScreen=='onboarding-w4-form'">
          <img class="w-full max-w-100" src="/img/screens/onboarding/onboarding-w4-form.png?v=10122022" />
          <div class="links">
            <screen-link left="85%" top="2%" width="5%" height="4.5%" dot-class="dot-right-center" data-tgt="hiring-applicants-jobboard-manager-sign" data-tgt-mode="admin" @click.native="close"></screen-link>
            <screen-link left="10%" top="12.25%" width="20%" height="4.5%" data-tgt-scrolltop="true" data-tgt="onboarding-id" @click.native="onLinkClick"></screen-link>
            <screen-link left="10%" top="19.5%" width="20%" height="4.5%" data-tgt-scrolltop="true" data-tgt="onboarding-directdeposit" @click.native="onLinkClick"></screen-link>
            <screen-link left="10%" top="26.25%" width="20%" height="4.5%" data-tgt-scrolltop="true" data-tgt="onboarding-w4" @click.native="onLinkClick"></screen-link>
            <screen-link left="10%" top="33.5%" width="20%" height="4.5%" data-tgt-scrolltop="true" data-tgt="onboarding-state-w4" @click.native="onLinkClick"></screen-link>
            <screen-link left="10%" top="40.5%" width="20%" height="4.5%" data-tgt-scrolltop="true" data-tgt="onboarding-download" @click.native="onLinkClick"></screen-link>
            <screen-link left="10%" top="47.5%" width="20%" height="4.5%" data-tgt-scrolltop="true" data-tgt="onboarding-upload" @click.native="onLinkClick"></screen-link>
            <screen-link left="10%" top="54.5%" width="20%" height="4.5%" data-tgt-scrolltop="true" data-tgt="onboarding-policy" @click.native="onLinkClick"></screen-link>
            <screen-link left="10%" top="61.75%" width="20%" height="4.5%" data-tgt-scrolltop="true" data-tgt="onboarding-handbook" @click.native="onLinkClick"></screen-link>
            <screen-link left="10%" top="68.75%" width="20%" height="4.5%" data-tgt-scrolltop="true" data-tgt="onboarding-contacts" @click.native="onLinkClick"></screen-link>
            <screen-link left="10%" top="75.75%" width="20%" height="4.5%" data-tgt-scrolltop="true" data-tgt="onboarding-i9" @click.native="onLinkClick"></screen-link>
            <screen-link left="10%" top="82.75%" width="20%" height="4.5%" data-tgt-scrolltop="true" data-tgt="onboarding-final" @click.native="onLinkClick"></screen-link>

            <screen-link left="82%" top="154%" width="7%" height="4.5%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="onboarding-w4-form-preview" @click.native="onLinkClick"></screen-link>

          </div>
        </div>

        <div class="screen z-max" data-screen="onboarding-w4-form-preview" :data-active="currentScreen=='onboarding-w4-form-preview'">
          <img class="w-full max-w-100" src="/img/screens/onboarding/onboarding-w4-form-preview.png?v=10122022" />
          <div class="links">
            <screen-link left="85%" top="2%" width="5%" height="4.5%" dot-class="dot-right-center" data-tgt="hiring-applicants-jobboard-manager-sign" data-tgt-mode="admin" @click.native="close"></screen-link>
            <screen-link left="10%" top="12.25%" width="20%" height="4.5%" data-tgt-scrolltop="true" data-tgt="onboarding-id" @click.native="onLinkClick"></screen-link>
            <screen-link left="10%" top="19.5%" width="20%" height="4.5%" data-tgt-scrolltop="true" data-tgt="onboarding-directdeposit" @click.native="onLinkClick"></screen-link>
            <screen-link left="10%" top="26.25%" width="20%" height="4.5%" data-tgt-scrolltop="true" data-tgt="onboarding-w4" @click.native="onLinkClick"></screen-link>
            <screen-link left="10%" top="33.5%" width="20%" height="4.5%" data-tgt-scrolltop="true" data-tgt="onboarding-state-w4" @click.native="onLinkClick"></screen-link>
            <screen-link left="10%" top="40.5%" width="20%" height="4.5%" data-tgt-scrolltop="true" data-tgt="onboarding-download" @click.native="onLinkClick"></screen-link>
            <screen-link left="10%" top="47.5%" width="20%" height="4.5%" data-tgt-scrolltop="true" data-tgt="onboarding-upload" @click.native="onLinkClick"></screen-link>
            <screen-link left="10%" top="54.5%" width="20%" height="4.5%" data-tgt-scrolltop="true" data-tgt="onboarding-policy" @click.native="onLinkClick"></screen-link>
            <screen-link left="10%" top="61.75%" width="20%" height="4.5%" data-tgt-scrolltop="true" data-tgt="onboarding-handbook" @click.native="onLinkClick"></screen-link>
            <screen-link left="10%" top="68.75%" width="20%" height="4.5%" data-tgt-scrolltop="true" data-tgt="onboarding-contacts" @click.native="onLinkClick"></screen-link>
            <screen-link left="10%" top="75.75%" width="20%" height="4.5%" data-tgt-scrolltop="true" data-tgt="onboarding-i9" @click.native="onLinkClick"></screen-link>
            <screen-link left="10%" top="82.75%" width="20%" height="4.5%" data-tgt-scrolltop="true" data-tgt="onboarding-final" @click.native="onLinkClick"></screen-link>

            <screen-link left="79%" top="164%" width="10%" height="4.5%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="onboarding-policy" @click.native="showOverlay('sign-w4')"></screen-link>
            <document-sign v-if="$store.state.overlayPanel === 'sign-w4'" confirm-tgt="onboarding-state-w4" confirm-mode="onboarding"></document-sign>


          </div>
        </div>

        <div class="screen z-max" data-screen="onboarding-state-w4" :data-active="currentScreen=='onboarding-state-w4'">
          <img class="w-full max-w-100" src="/img/screens/onboarding/onboarding-state-w4.png?v=10122022" />
          <div class="links">
            <screen-link left="85%" top="2%" width="5%" height="4.5%" dot-class="dot-right-center" data-tgt="hiring-applicants-jobboard-manager-sign" data-tgt-mode="admin" @click.native="close"></screen-link>
            <screen-link left="10%" top="12.25%" width="20%" height="4.5%" data-tgt-scrolltop="true" data-tgt="onboarding-id" @click.native="onLinkClick"></screen-link>
            <screen-link left="10%" top="19.5%" width="20%" height="4.5%" data-tgt-scrolltop="true" data-tgt="onboarding-directdeposit" @click.native="onLinkClick"></screen-link>
            <screen-link left="10%" top="26.25%" width="20%" height="4.5%" data-tgt-scrolltop="true" data-tgt="onboarding-w4" @click.native="onLinkClick"></screen-link>
            <screen-link left="10%" top="33.5%" width="20%" height="4.5%" data-tgt-scrolltop="true" data-tgt="onboarding-state-w4" @click.native="onLinkClick"></screen-link>
            <screen-link left="10%" top="40.5%" width="20%" height="4.5%" data-tgt-scrolltop="true" data-tgt="onboarding-download" @click.native="onLinkClick"></screen-link>
            <screen-link left="10%" top="47.5%" width="20%" height="4.5%" data-tgt-scrolltop="true" data-tgt="onboarding-upload" @click.native="onLinkClick"></screen-link>
            <screen-link left="10%" top="54.5%" width="20%" height="4.5%" data-tgt-scrolltop="true" data-tgt="onboarding-policy" @click.native="onLinkClick"></screen-link>
            <screen-link left="10%" top="61.75%" width="20%" height="4.5%" data-tgt-scrolltop="true" data-tgt="onboarding-handbook" @click.native="onLinkClick"></screen-link>
            <screen-link left="10%" top="68.75%" width="20%" height="4.5%" data-tgt-scrolltop="true" data-tgt="onboarding-contacts" @click.native="onLinkClick"></screen-link>
            <screen-link left="10%" top="75.75%" width="20%" height="4.5%" data-tgt-scrolltop="true" data-tgt="onboarding-i9" @click.native="onLinkClick"></screen-link>
            <screen-link left="10%" top="82.75%" width="20%" height="4.5%" data-tgt-scrolltop="true" data-tgt="onboarding-final" @click.native="onLinkClick"></screen-link>

            <screen-link left="82%" top="32.5%" width="7%" height="4.5%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="onboarding-download" @click.native="onLinkClick"></screen-link>

          </div>
        </div>

        <div class="screen z-max" data-screen="onboarding-download" :data-active="currentScreen=='onboarding-download'">
          <img class="w-full max-w-100" src="/img/screens/onboarding/onboarding-download.png?v=10122022" />
          <div class="links">
            <screen-link left="85%" top="2%" width="5%" height="4.5%" dot-class="dot-right-center" data-tgt="hiring-applicants-jobboard-manager-sign" data-tgt-mode="admin" @click.native="close"></screen-link>
            <screen-link left="10%" top="12.25%" width="20%" height="4.5%" data-tgt-scrolltop="true" data-tgt="onboarding-id" @click.native="onLinkClick"></screen-link>
            <screen-link left="10%" top="19.5%" width="20%" height="4.5%" data-tgt-scrolltop="true" data-tgt="onboarding-directdeposit" @click.native="onLinkClick"></screen-link>
            <screen-link left="10%" top="26.25%" width="20%" height="4.5%" data-tgt-scrolltop="true" data-tgt="onboarding-w4" @click.native="onLinkClick"></screen-link>
            <screen-link left="10%" top="33.5%" width="20%" height="4.5%" data-tgt-scrolltop="true" data-tgt="onboarding-state-w4" @click.native="onLinkClick"></screen-link>
            <screen-link left="10%" top="40.5%" width="20%" height="4.5%" data-tgt-scrolltop="true" data-tgt="onboarding-download" @click.native="onLinkClick"></screen-link>
            <screen-link left="10%" top="47.5%" width="20%" height="4.5%" data-tgt-scrolltop="true" data-tgt="onboarding-upload" @click.native="onLinkClick"></screen-link>
            <screen-link left="10%" top="54.5%" width="20%" height="4.5%" data-tgt-scrolltop="true" data-tgt="onboarding-policy" @click.native="onLinkClick"></screen-link>
            <screen-link left="10%" top="61.75%" width="20%" height="4.5%" data-tgt-scrolltop="true" data-tgt="onboarding-handbook" @click.native="onLinkClick"></screen-link>
            <screen-link left="10%" top="68.75%" width="20%" height="4.5%" data-tgt-scrolltop="true" data-tgt="onboarding-contacts" @click.native="onLinkClick"></screen-link>
            <screen-link left="10%" top="75.75%" width="20%" height="4.5%" data-tgt-scrolltop="true" data-tgt="onboarding-i9" @click.native="onLinkClick"></screen-link>
            <screen-link left="10%" top="82.75%" width="20%" height="4.5%" data-tgt-scrolltop="true" data-tgt="onboarding-final" @click.native="onLinkClick"></screen-link>

            <screen-link left="82%" top="85%" width="7%" height="4.5%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="onboarding-download-health" @click.native="onLinkClick"></screen-link>

          </div>
        </div>

        <div class="screen z-max" data-screen="onboarding-download-health" :data-active="currentScreen=='onboarding-download-health'">
          <img class="w-full max-w-100" src="/img/screens/onboarding/onboarding-download-health.png?v=10122022" />
          <div class="links">
            <screen-link left="85%" top="2%" width="5%" height="4.5%" dot-class="dot-right-center" data-tgt="hiring-applicants-jobboard-manager-sign" data-tgt-mode="admin" @click.native="close"></screen-link>
            <screen-link left="10%" top="12.25%" width="20%" height="4.5%" data-tgt-scrolltop="true" data-tgt="onboarding-id" @click.native="onLinkClick"></screen-link>
            <screen-link left="10%" top="19.5%" width="20%" height="4.5%" data-tgt-scrolltop="true" data-tgt="onboarding-directdeposit" @click.native="onLinkClick"></screen-link>
            <screen-link left="10%" top="26.25%" width="20%" height="4.5%" data-tgt-scrolltop="true" data-tgt="onboarding-w4" @click.native="onLinkClick"></screen-link>
            <screen-link left="10%" top="33.5%" width="20%" height="4.5%" data-tgt-scrolltop="true" data-tgt="onboarding-state-w4" @click.native="onLinkClick"></screen-link>
            <screen-link left="10%" top="40.5%" width="20%" height="4.5%" data-tgt-scrolltop="true" data-tgt="onboarding-download" @click.native="onLinkClick"></screen-link>
            <screen-link left="10%" top="47.5%" width="20%" height="4.5%" data-tgt-scrolltop="true" data-tgt="onboarding-upload" @click.native="onLinkClick"></screen-link>
            <screen-link left="10%" top="54.5%" width="20%" height="4.5%" data-tgt-scrolltop="true" data-tgt="onboarding-policy" @click.native="onLinkClick"></screen-link>
            <screen-link left="10%" top="61.75%" width="20%" height="4.5%" data-tgt-scrolltop="true" data-tgt="onboarding-handbook" @click.native="onLinkClick"></screen-link>
            <screen-link left="10%" top="68.75%" width="20%" height="4.5%" data-tgt-scrolltop="true" data-tgt="onboarding-contacts" @click.native="onLinkClick"></screen-link>
            <screen-link left="10%" top="75.75%" width="20%" height="4.5%" data-tgt-scrolltop="true" data-tgt="onboarding-i9" @click.native="onLinkClick"></screen-link>
            <screen-link left="10%" top="82.75%" width="20%" height="4.5%" data-tgt-scrolltop="true" data-tgt="onboarding-final" @click.native="onLinkClick"></screen-link>

            <screen-link left="82%" top="56%" width="7%" height="4.5%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="onboarding-upload" @click.native="onLinkClick"></screen-link>

          </div>
        </div>

        <div class="screen z-max" data-screen="onboarding-upload" :data-active="currentScreen=='onboarding-upload'">
          <img class="w-full max-w-100" src="/img/screens/onboarding/onboarding-upload.png?v=10122022" />
          <div class="links">
            <screen-link left="85%" top="2%" width="5%" height="4.5%" dot-class="dot-right-center" data-tgt="hiring-applicants-jobboard-manager-sign" data-tgt-mode="admin" @click.native="close"></screen-link>
            <screen-link left="10%" top="12.25%" width="20%" height="4.5%" data-tgt-scrolltop="true" data-tgt="onboarding-id" @click.native="onLinkClick"></screen-link>
            <screen-link left="10%" top="19.5%" width="20%" height="4.5%" data-tgt-scrolltop="true" data-tgt="onboarding-directdeposit" @click.native="onLinkClick"></screen-link>
            <screen-link left="10%" top="26.25%" width="20%" height="4.5%" data-tgt-scrolltop="true" data-tgt="onboarding-w4" @click.native="onLinkClick"></screen-link>
            <screen-link left="10%" top="33.5%" width="20%" height="4.5%" data-tgt-scrolltop="true" data-tgt="onboarding-state-w4" @click.native="onLinkClick"></screen-link>
            <screen-link left="10%" top="40.5%" width="20%" height="4.5%" data-tgt-scrolltop="true" data-tgt="onboarding-download" @click.native="onLinkClick"></screen-link>
            <screen-link left="10%" top="47.5%" width="20%" height="4.5%" data-tgt-scrolltop="true" data-tgt="onboarding-upload" @click.native="onLinkClick"></screen-link>
            <screen-link left="10%" top="54.5%" width="20%" height="4.5%" data-tgt-scrolltop="true" data-tgt="onboarding-policy" @click.native="onLinkClick"></screen-link>
            <screen-link left="10%" top="61.75%" width="20%" height="4.5%" data-tgt-scrolltop="true" data-tgt="onboarding-handbook" @click.native="onLinkClick"></screen-link>
            <screen-link left="10%" top="68.75%" width="20%" height="4.5%" data-tgt-scrolltop="true" data-tgt="onboarding-contacts" @click.native="onLinkClick"></screen-link>
            <screen-link left="10%" top="75.75%" width="20%" height="4.5%" data-tgt-scrolltop="true" data-tgt="onboarding-i9" @click.native="onLinkClick"></screen-link>
            <screen-link left="10%" top="82.75%" width="20%" height="4.5%" data-tgt-scrolltop="true" data-tgt="onboarding-final" @click.native="onLinkClick"></screen-link>

            <screen-link left="82%" top="57%" width="7%" height="4.5%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="onboarding-policy" @click.native="onLinkClick"></screen-link>

          </div>
        </div>

        <div class="screen z-max" data-screen="onboarding-policy" :data-active="currentScreen=='onboarding-policy'">
          <img class="w-full max-w-100" src="/img/screens/onboarding/onboarding-policy.png?v=10122022" />
          <div class="links">
            <screen-link left="85%" top="2%" width="5%" height="4.5%" dot-class="dot-right-center" data-tgt="hiring-applicants-jobboard-manager-sign" data-tgt-mode="admin" @click.native="close"></screen-link>
            <screen-link left="10%" top="12.25%" width="20%" height="4.5%" data-tgt-scrolltop="true" data-tgt="onboarding-id" @click.native="onLinkClick"></screen-link>
            <screen-link left="10%" top="19.5%" width="20%" height="4.5%" data-tgt-scrolltop="true" data-tgt="onboarding-directdeposit" @click.native="onLinkClick"></screen-link>
            <screen-link left="10%" top="26.25%" width="20%" height="4.5%" data-tgt-scrolltop="true" data-tgt="onboarding-w4" @click.native="onLinkClick"></screen-link>
            <screen-link left="10%" top="33.5%" width="20%" height="4.5%" data-tgt-scrolltop="true" data-tgt="onboarding-state-w4" @click.native="onLinkClick"></screen-link>
            <screen-link left="10%" top="40.5%" width="20%" height="4.5%" data-tgt-scrolltop="true" data-tgt="onboarding-download" @click.native="onLinkClick"></screen-link>
            <screen-link left="10%" top="47.5%" width="20%" height="4.5%" data-tgt-scrolltop="true" data-tgt="onboarding-upload" @click.native="onLinkClick"></screen-link>
            <screen-link left="10%" top="54.5%" width="20%" height="4.5%" data-tgt-scrolltop="true" data-tgt="onboarding-policy" @click.native="onLinkClick"></screen-link>
            <screen-link left="10%" top="61.75%" width="20%" height="4.5%" data-tgt-scrolltop="true" data-tgt="onboarding-handbook" @click.native="onLinkClick"></screen-link>
            <screen-link left="10%" top="68.75%" width="20%" height="4.5%" data-tgt-scrolltop="true" data-tgt="onboarding-contacts" @click.native="onLinkClick"></screen-link>
            <screen-link left="10%" top="75.75%" width="20%" height="4.5%" data-tgt-scrolltop="true" data-tgt="onboarding-i9" @click.native="onLinkClick"></screen-link>
            <screen-link left="10%" top="82.75%" width="20%" height="4.5%" data-tgt-scrolltop="true" data-tgt="onboarding-final" @click.native="onLinkClick"></screen-link>

            <screen-link left="82%" top="33%" width="7%" height="4.5%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="onboarding-handbook" @click.native="onLinkClick"></screen-link>

          </div>
        </div>



        <div class="screen z-max" data-screen="onboarding-handbook" :data-active="currentScreen=='onboarding-handbook'">
          <img class="w-full max-w-100" src="/img/screens/onboarding/onboarding-handbook.png?v=10122022" />
          <div class="links">
            <screen-link left="85%" top="2%" width="5%" height="4.5%" dot-class="dot-right-center" data-tgt="hiring-applicants-jobboard-manager-sign" data-tgt-mode="admin" @click.native="close"></screen-link>
            <screen-link left="10%" top="12.25%" width="20%" height="4.5%" data-tgt-scrolltop="true" data-tgt="onboarding-id" @click.native="onLinkClick"></screen-link>
            <screen-link left="10%" top="19.5%" width="20%" height="4.5%" data-tgt-scrolltop="true" data-tgt="onboarding-directdeposit" @click.native="onLinkClick"></screen-link>
            <screen-link left="10%" top="26.25%" width="20%" height="4.5%" data-tgt-scrolltop="true" data-tgt="onboarding-w4" @click.native="onLinkClick"></screen-link>
            <screen-link left="10%" top="33.5%" width="20%" height="4.5%" data-tgt-scrolltop="true" data-tgt="onboarding-state-w4" @click.native="onLinkClick"></screen-link>
            <screen-link left="10%" top="40.5%" width="20%" height="4.5%" data-tgt-scrolltop="true" data-tgt="onboarding-download" @click.native="onLinkClick"></screen-link>
            <screen-link left="10%" top="47.5%" width="20%" height="4.5%" data-tgt-scrolltop="true" data-tgt="onboarding-upload" @click.native="onLinkClick"></screen-link>
            <screen-link left="10%" top="54.5%" width="20%" height="4.5%" data-tgt-scrolltop="true" data-tgt="onboarding-policy" @click.native="onLinkClick"></screen-link>
            <screen-link left="10%" top="61.75%" width="20%" height="4.5%" data-tgt-scrolltop="true" data-tgt="onboarding-handbook" @click.native="onLinkClick"></screen-link>
            <screen-link left="10%" top="68.75%" width="20%" height="4.5%" data-tgt-scrolltop="true" data-tgt="onboarding-contacts" @click.native="onLinkClick"></screen-link>
            <screen-link left="10%" top="75.75%" width="20%" height="4.5%" data-tgt-scrolltop="true" data-tgt="onboarding-i9" @click.native="onLinkClick"></screen-link>
            <screen-link left="10%" top="82.75%" width="20%" height="4.5%" data-tgt-scrolltop="true" data-tgt="onboarding-final" @click.native="onLinkClick"></screen-link>

            <screen-link left="81%" top="82%" width="8%" height="4.5%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="onboarding-handbook-acknowledgement" @click.native="onLinkClick"></screen-link>

          </div>
        </div>

        <div class="screen z-max" data-screen="onboarding-handbook-acknowledgement" :data-active="currentScreen=='onboarding-handbook-acknowledgement'">
          <img class="w-full max-w-100" src="/img/screens/onboarding/onboarding-handbook-doc.png?v=10122022" />
          <div class="links">
            <screen-link left="85%" top="2%" width="5%" height="4.5%" dot-class="dot-right-center" data-tgt="hiring-applicants-jobboard-manager-sign" data-tgt-mode="admin" @click.native="close"></screen-link>
            <screen-link left="10%" top="12.25%" width="20%" height="4.5%" data-tgt-scrolltop="true" data-tgt="onboarding-id" @click.native="onLinkClick"></screen-link>
            <screen-link left="10%" top="19.5%" width="20%" height="4.5%" data-tgt-scrolltop="true" data-tgt="onboarding-directdeposit" @click.native="onLinkClick"></screen-link>
            <screen-link left="10%" top="26.25%" width="20%" height="4.5%" data-tgt-scrolltop="true" data-tgt="onboarding-w4" @click.native="onLinkClick"></screen-link>
            <screen-link left="10%" top="33.5%" width="20%" height="4.5%" data-tgt-scrolltop="true" data-tgt="onboarding-state-w4" @click.native="onLinkClick"></screen-link>
            <screen-link left="10%" top="40.5%" width="20%" height="4.5%" data-tgt-scrolltop="true" data-tgt="onboarding-download" @click.native="onLinkClick"></screen-link>
            <screen-link left="10%" top="47.5%" width="20%" height="4.5%" data-tgt-scrolltop="true" data-tgt="onboarding-upload" @click.native="onLinkClick"></screen-link>
            <screen-link left="10%" top="54.5%" width="20%" height="4.5%" data-tgt-scrolltop="true" data-tgt="onboarding-policy" @click.native="onLinkClick"></screen-link>
            <screen-link left="10%" top="61.75%" width="20%" height="4.5%" data-tgt-scrolltop="true" data-tgt="onboarding-handbook" @click.native="onLinkClick"></screen-link>
            <screen-link left="10%" top="68.75%" width="20%" height="4.5%" data-tgt-scrolltop="true" data-tgt="onboarding-contacts" @click.native="onLinkClick"></screen-link>
            <screen-link left="10%" top="75.75%" width="20%" height="4.5%" data-tgt-scrolltop="true" data-tgt="onboarding-i9" @click.native="onLinkClick"></screen-link>
            <screen-link left="10%" top="82.75%" width="20%" height="4.5%" data-tgt-scrolltop="true" data-tgt="onboarding-final" @click.native="onLinkClick"></screen-link>

            <screen-link left="79%" top="164%" width="10%" height="4.5%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="onboarding-handbook-acknowledgment" @click.native="showOverlay('sign-handbook')"></screen-link>
            <document-sign v-if="$store.state.overlayPanel === 'sign-handbook'" confirm-tgt="onboarding-contacts" confirm-mode="onboarding"></document-sign>


          </div>
        </div>

        <div class="screen z-max" data-screen="onboarding-contacts" :data-active="currentScreen=='onboarding-contacts'">
          <img class="w-full max-w-100" src="/img/screens/onboarding/onboarding-contacts.png?v=10122022" />
          <div class="links">
            <screen-link left="85%" top="2%" width="5%" height="4.5%" dot-class="dot-right-center" data-tgt="hiring-applicants-jobboard-manager-sign" data-tgt-mode="admin" @click.native="close"></screen-link>
            <screen-link left="10%" top="12.25%" width="20%" height="4.5%" data-tgt-scrolltop="true" data-tgt="onboarding-id" @click.native="onLinkClick"></screen-link>
            <screen-link left="10%" top="19.5%" width="20%" height="4.5%" data-tgt-scrolltop="true" data-tgt="onboarding-directdeposit" @click.native="onLinkClick"></screen-link>
            <screen-link left="10%" top="26.25%" width="20%" height="4.5%" data-tgt-scrolltop="true" data-tgt="onboarding-w4" @click.native="onLinkClick"></screen-link>
            <screen-link left="10%" top="33.5%" width="20%" height="4.5%" data-tgt-scrolltop="true" data-tgt="onboarding-state-w4" @click.native="onLinkClick"></screen-link>
            <screen-link left="10%" top="40.5%" width="20%" height="4.5%" data-tgt-scrolltop="true" data-tgt="onboarding-download" @click.native="onLinkClick"></screen-link>
            <screen-link left="10%" top="47.5%" width="20%" height="4.5%" data-tgt-scrolltop="true" data-tgt="onboarding-upload" @click.native="onLinkClick"></screen-link>
            <screen-link left="10%" top="54.5%" width="20%" height="4.5%" data-tgt-scrolltop="true" data-tgt="onboarding-policy" @click.native="onLinkClick"></screen-link>
            <screen-link left="10%" top="61.75%" width="20%" height="4.5%" data-tgt-scrolltop="true" data-tgt="onboarding-handbook" @click.native="onLinkClick"></screen-link>
            <screen-link left="10%" top="68.75%" width="20%" height="4.5%" data-tgt-scrolltop="true" data-tgt="onboarding-contacts" @click.native="onLinkClick"></screen-link>
            <screen-link left="10%" top="75.75%" width="20%" height="4.5%" data-tgt-scrolltop="true" data-tgt="onboarding-i9" @click.native="onLinkClick"></screen-link>
            <screen-link left="10%" top="82.75%" width="20%" height="4.5%" data-tgt-scrolltop="true" data-tgt="onboarding-final" @click.native="onLinkClick"></screen-link>

            <screen-link left="36%" top="35%" width="40%" height="4.5%" data-tgt-scrolltop="true" dot-class="dot-left-center" data-tgt="onboarding-contacts-details" @click.native="onLinkClick"></screen-link>

          </div>
        </div>

        <div class="screen z-max" data-screen="onboarding-contacts-details" :data-active="currentScreen=='onboarding-contacts-details'">
          <img class="w-full max-w-100" src="/img/screens/onboarding/onboarding-contacts-details.png?v=10122022" />
          <div class="links">
            <screen-link left="85%" top="2%" width="5%" height="4.5%" dot-class="dot-right-center" data-tgt="hiring-applicants-jobboard-manager-sign" data-tgt-mode="admin" @click.native="close"></screen-link>
            <screen-link left="10%" top="12.25%" width="20%" height="4.5%" data-tgt-scrolltop="true" data-tgt="onboarding-id" @click.native="onLinkClick"></screen-link>
            <screen-link left="10%" top="19.5%" width="20%" height="4.5%" data-tgt-scrolltop="true" data-tgt="onboarding-directdeposit" @click.native="onLinkClick"></screen-link>
            <screen-link left="10%" top="26.25%" width="20%" height="4.5%" data-tgt-scrolltop="true" data-tgt="onboarding-w4" @click.native="onLinkClick"></screen-link>
            <screen-link left="10%" top="33.5%" width="20%" height="4.5%" data-tgt-scrolltop="true" data-tgt="onboarding-state-w4" @click.native="onLinkClick"></screen-link>
            <screen-link left="10%" top="40.5%" width="20%" height="4.5%" data-tgt-scrolltop="true" data-tgt="onboarding-download" @click.native="onLinkClick"></screen-link>
            <screen-link left="10%" top="47.5%" width="20%" height="4.5%" data-tgt-scrolltop="true" data-tgt="onboarding-upload" @click.native="onLinkClick"></screen-link>
            <screen-link left="10%" top="54.5%" width="20%" height="4.5%" data-tgt-scrolltop="true" data-tgt="onboarding-policy" @click.native="onLinkClick"></screen-link>
            <screen-link left="10%" top="61.75%" width="20%" height="4.5%" data-tgt-scrolltop="true" data-tgt="onboarding-handbook" @click.native="onLinkClick"></screen-link>
            <screen-link left="10%" top="68.75%" width="20%" height="4.5%" data-tgt-scrolltop="true" data-tgt="onboarding-contacts" @click.native="onLinkClick"></screen-link>
            <screen-link left="10%" top="75.75%" width="20%" height="4.5%" data-tgt-scrolltop="true" data-tgt="onboarding-i9" @click.native="onLinkClick"></screen-link>
            <screen-link left="10%" top="82.75%" width="20%" height="4.5%" data-tgt-scrolltop="true" data-tgt="onboarding-final" @click.native="onLinkClick"></screen-link>

            <screen-link left="81%" top="100.5%" width="8%" height="4.5%" data-tgt-scrolltop="true" dot-class="dot-left-center" data-tgt="onboarding-i9" @click.native="onLinkClick"></screen-link>

          </div>
        </div>


        <div class="screen z-max" data-screen="onboarding-i9" :data-active="currentScreen=='onboarding-i9'">
          <img class="w-full max-w-100" src="/img/screens/onboarding/onboarding-i9.png?v=10122022" />
          <div class="links">
            <screen-link left="85%" top="2%" width="5%" height="4.5%" dot-class="dot-right-center" data-tgt="hiring-applicants-jobboard-manager-sign" data-tgt-mode="admin" @click.native="close"></screen-link>
            <screen-link left="10%" top="12.25%" width="20%" height="4.5%" data-tgt-scrolltop="true" data-tgt="onboarding-id" @click.native="onLinkClick"></screen-link>
            <screen-link left="10%" top="19.5%" width="20%" height="4.5%" data-tgt-scrolltop="true" data-tgt="onboarding-directdeposit" @click.native="onLinkClick"></screen-link>
            <screen-link left="10%" top="26.25%" width="20%" height="4.5%" data-tgt-scrolltop="true" data-tgt="onboarding-w4" @click.native="onLinkClick"></screen-link>
            <screen-link left="10%" top="33.5%" width="20%" height="4.5%" data-tgt-scrolltop="true" data-tgt="onboarding-state-w4" @click.native="onLinkClick"></screen-link>
            <screen-link left="10%" top="40.5%" width="20%" height="4.5%" data-tgt-scrolltop="true" data-tgt="onboarding-download" @click.native="onLinkClick"></screen-link>
            <screen-link left="10%" top="47.5%" width="20%" height="4.5%" data-tgt-scrolltop="true" data-tgt="onboarding-upload" @click.native="onLinkClick"></screen-link>
            <screen-link left="10%" top="54.5%" width="20%" height="4.5%" data-tgt-scrolltop="true" data-tgt="onboarding-policy" @click.native="onLinkClick"></screen-link>
            <screen-link left="10%" top="61.75%" width="20%" height="4.5%" data-tgt-scrolltop="true" data-tgt="onboarding-handbook" @click.native="onLinkClick"></screen-link>
            <screen-link left="10%" top="68.75%" width="20%" height="4.5%" data-tgt-scrolltop="true" data-tgt="onboarding-contacts" @click.native="onLinkClick"></screen-link>
            <screen-link left="10%" top="75.75%" width="20%" height="4.5%" data-tgt-scrolltop="true" data-tgt="onboarding-i9" @click.native="onLinkClick"></screen-link>
            <screen-link left="10%" top="82.75%" width="20%" height="4.5%" data-tgt-scrolltop="true" data-tgt="onboarding-final" @click.native="onLinkClick"></screen-link>

            <screen-link left="77%" top="38%" width="12%" height="5%" data-tgt-scrolltop="true" data-tgt="onboarding-i9-employee-info" @click.native="onLinkClick"></screen-link>

          </div>
        </div>

        <div class="screen z-max" data-screen="onboarding-final" :data-active="currentScreen=='onboarding-final'">
          <img class="w-full max-w-100" src="/img/screens/onboarding/onboarding-final.png?v=10122022" />
          <div class="links">
            <screen-link left="85%" top="2%" width="5%" height="4.5%" dot-class="dot-right-center" data-tgt="hiring-applicants-jobboard-manager-sign" data-tgt-mode="admin" @click.native="close"></screen-link>
            <screen-link left="10%" top="12.25%" width="20%" height="4.5%" data-tgt-scrolltop="true" data-tgt="onboarding-id" @click.native="onLinkClick"></screen-link>
            <screen-link left="10%" top="19.5%" width="20%" height="4.5%" data-tgt-scrolltop="true" data-tgt="onboarding-directdeposit" @click.native="onLinkClick"></screen-link>
            <screen-link left="10%" top="26.25%" width="20%" height="4.5%" data-tgt-scrolltop="true" data-tgt="onboarding-w4" @click.native="onLinkClick"></screen-link>
            <screen-link left="10%" top="33.5%" width="20%" height="4.5%" data-tgt-scrolltop="true" data-tgt="onboarding-state-w4" @click.native="onLinkClick"></screen-link>
            <screen-link left="10%" top="40.5%" width="20%" height="4.5%" data-tgt-scrolltop="true" data-tgt="onboarding-download" @click.native="onLinkClick"></screen-link>
            <screen-link left="10%" top="47.5%" width="20%" height="4.5%" data-tgt-scrolltop="true" data-tgt="onboarding-upload" @click.native="onLinkClick"></screen-link>
            <screen-link left="10%" top="54.5%" width="20%" height="4.5%" data-tgt-scrolltop="true" data-tgt="onboarding-policy" @click.native="onLinkClick"></screen-link>
            <screen-link left="10%" top="61.75%" width="20%" height="4.5%" data-tgt-scrolltop="true" data-tgt="onboarding-handbook" @click.native="onLinkClick"></screen-link>
            <screen-link left="10%" top="68.75%" width="20%" height="4.5%" data-tgt-scrolltop="true" data-tgt="onboarding-contacts" @click.native="onLinkClick"></screen-link>
            <screen-link left="10%" top="75.75%" width="20%" height="4.5%" data-tgt-scrolltop="true" data-tgt="onboarding-i9" @click.native="onLinkClick"></screen-link>
            <screen-link left="10%" top="82.75%" width="20%" height="4.5%" data-tgt-scrolltop="true" data-tgt="onboarding-final" @click.native="onLinkClick"></screen-link>

            <screen-link left="82%" top="90%" width="7%" height="4.5%" dot-class="dot-right-center" data-tgt="hiring-applicants-jobboard-manager-sign" data-tgt-mode="admin" @click.native="onLinkClick"></screen-link>

          </div>
        </div>

        <div class="screen z-max" data-screen="onboarding-i9-employee-info" :data-active="currentScreen=='onboarding-i9-employee-info'">
          <img class="w-full max-w-100" src="/img/screens/i9-employee-info.png?v=10122022" />
          <div class="links">
            <screen-link left="38%" top="1%" width="25%" height="11%" dot-class="dot-top-center" :data-tgt="i9BackTgt" @click.native="onLinkClick"></screen-link>
            <screen-link left="63.5%" top="97.5%" width="8%" height="5%" data-tgt-scrolltop="true" data-tgt="onboarding-i9-citizenship" @click.native="onLinkClick"></screen-link>

          </div>
        </div>

        <div class="screen z-max" data-screen="onboarding-i9-citizenship" :data-active="currentScreen=='onboarding-i9-citizenship'">
          <img class="w-full max-w-100" src="/img/screens/i9-citizenship.png?v=10122022" />
          <div class="links">
            <screen-link left="38%" top="1%" width="25%" height="11%" dot-class="dot-top-center" :data-tgt="i9BackTgt" @click.native="onLinkClick"></screen-link>
            <screen-link left="56.5%" top="61.5%" width="7%" height="5%" dot-class="dot-bottom-center" data-tgt-scrolltop="true" data-tgt="onboarding-i9-employee-info" @click.native="onLinkClick"></screen-link>

            <screen-link left="64%" top="61.5%" width="7%" height="5%" dot-class="dot-bottom-center" data-tgt-scrolltop="true" data-tgt="onboarding-i9-translator" @click.native="onLinkClick"></screen-link>

          </div>
        </div>

        <div class="screen z-max" data-screen="onboarding-i9-translator" :data-active="currentScreen=='onboarding-i9-translator'">
          <img class="w-full max-w-100" src="/img/screens/i9-translator.png?v=10122022" />
          <div class="links">
            <screen-link left="38%" top="1%" width="25%" height="11%" dot-class="dot-top-center"  :data-tgt="i9BackTgt" @click.native="onLinkClick"></screen-link>
            <screen-link left="56.5%" top="55.5%" width="7%" height="5%" dot-class="dot-bottom-center" data-tgt-scrolltop="true" data-tgt="onboarding-i9-citizenship" @click.native="onLinkClick"></screen-link>

            <screen-link left="64%" top="55.5%" width="7%" height="5%" dot-class="dot-bottom-center" data-tgt-scrolltop="true" data-tgt="onboarding-i9-review" @click.native="onLinkClick"></screen-link>

          </div>
        </div>

        <div class="screen z-max" data-screen="onboarding-i9-review" :data-active="currentScreen=='onboarding-i9-review'">
          <img class="w-full max-w-100" src="/img/screens/i9-review.png?v=10122022" />
          <div class="links">
            <screen-link left="38%" top="1%" width="25%" height="11%" dot-class="dot-top-center" :data-tgt="i9BackTgt" @click.native="onLinkClick"></screen-link>
            <screen-link left="56.75%" top="77.5%" width="7%" height="5%" dot-class="dot-bottom-center" data-tgt-scrolltop="true" data-tgt="onboarding-i9-translator" @click.native="onLinkClick"></screen-link>

            <screen-link left="64.25%" top="77.5%" width="7%" height="5%" dot-class="dot-bottom-center" data-tgt-scrolltop="true" data-tgt="onboarding-i9-signature" @click.native="onLinkClick"></screen-link>

          </div>
        </div>

        <div class="screen z-max" data-screen="onboarding-i9-signature" :data-active="currentScreen=='onboarding-i9-signature'">
          <img class="w-full max-w-100" src="/img/screens/i9-signature.png?v=10122022" />
          <div class="links">
            <screen-link left="38%" top="1%" width="25%" height="11%" dot-class="dot-top-center"  :data-tgt="i9BackTgt" @click.native="onLinkClick"></screen-link>
            <screen-link left="56.75%" top="54%" width="7%" height="6%" dot-class="dot-bottom-center" data-tgt-scrolltop="true" data-tgt="onboarding-i9-review" @click.native="onLinkClick"></screen-link>

            <screen-link left="38%" top="36.5%" width="33%" height="5%" dot-class="dot-left-center" data-tgt-scrolltop="true" data-tgt="onboarding-i9-signature-signed" @click.native="onLinkClick"></screen-link>

          </div>
        </div>

        <div class="screen z-max" data-screen="onboarding-i9-signature-signed" :data-active="currentScreen=='onboarding-i9-signature-signed'">
          <img class="w-full max-w-100" src="/img/screens/i9-signature-signed.png?v=10122022" />
          <div class="links">
            <screen-link left="38%" top="1%" width="25%" height="11%" dot-class="dot-top-center"  :data-tgt="i9BackTgt" @click.native="onLinkClick"></screen-link>
            <screen-link left="56.75%" top="54%" width="7%" height="6%" dot-class="dot-bottom-center" data-tgt-scrolltop="true" data-tgt="onboarding-i9-review" @click.native="onLinkClick"></screen-link>

            <screen-link left="64.25%" top="54%" width="7%" height="6%" dot-class="dot-bottom-center" data-tgt-scrolltop="true" data-tgt="onboarding-i9-signature-signed-finish" @click.native="onLinkClick"></screen-link>

          </div>
        </div>

        <div class="screen z-max" data-screen="onboarding-i9-signature-signed-finish" :data-active="currentScreen=='onboarding-i9-signature-signed-finish'">
          <img class="w-full max-w-100" src="/img/screens/i9-signature-signed-finish.png?v=10122022" />
          <div class="links">
            <screen-link left="49.75%" top="22.5%" width="7.5%" height="5%" dot-class="dot-bottom-center" data-tgt-scrolltop="true" data-tgt="onboarding-i9-confirmation" @click.native="onLinkClick"></screen-link>

            <screen-link left="58.25%" top="22.5%" width="8.5%" height="5%" dot-class="dot-bottom-center" data-tgt-scrolltop="true" data-tgt="onboarding-i9-signature-signed" @click.native="onLinkClick"></screen-link>

          </div>
        </div>

        <div class="screen z-max" data-screen="onboarding-i9-confirmation" :data-active="currentScreen=='onboarding-i9-confirmation'">
          <img class="w-full max-w-100" src="/img/screens/i9-confimation.png?v=10122022" />
          <div class="links">
            <screen-link left="38%" top="1%" width="25%" height="11%" dot-class="dot-top-center"  :data-tgt="i9BackTgt" @click.native="onLinkClick"></screen-link>

            <screen-link left="64.25%" top="68.5%" width="7%" height="5%" dot-class="dot-bottom-center" data-tgt-scrolltop="true" data-tgt="onboarding-i9-success" @click.native="onLinkClick"></screen-link>

          </div>
        </div>

        <div class="screen z-max" data-screen="onboarding-i9-success" :data-active="currentScreen=='onboarding-i9-success'">
          <img class="w-full max-w-100" src="/img/screens/i9-success.png?v=10122022" />
          <div class="links">
            <screen-link left="38%" top="1%" width="25%" height="11%" dot-class="dot-top-center" :data-tgt="i9BackTgt" @click.native="onLinkClick"></screen-link>

            <screen-link left="64.25%" top="45.5%" width="9%" height="5%" dot-class="dot-bottom-center" data-tgt-scrolltop="true" :data-tgt="i9FinishTgt" @click.native="onLinkClick"></screen-link>

          </div>
        </div>


        <!--- MANAGER SCREENS -->
        <div class="screen z-max" data-screen="onboarding-manager-begin" :data-active="currentScreen=='onboarding-manager-begin'">
          <img class="w-full max-w-100" src="/img/screens/onboarding/onboarding-manager-begin.png?v=10122022" />
          <div class="links">
            <screen-link left="85%" top="2%" width="5%" height="4.5%" dot-class="dot-right-center" data-tgt="hiring-applicants-jobboard-sign-complete" data-tgt-mode="admin" @click.native="close"></screen-link>
            <screen-link left="84%" top="36%" width="4.5%" height="5%" dot-class="dot-right-center" data-tgt="onboarding-manager-federal-begin" @click.native="onLinkClick"></screen-link>

          </div>
        </div>

        <div class="screen z-max" data-screen="onboarding-manager-federal-begin" :data-active="currentScreen=='onboarding-manager-federal-begin'">
          <img class="w-full max-w-100" src="/img/screens/onboarding/onboarding-manager-federal-begin.png?v=10122022" />
          <div class="links">
            <screen-link left="85%" top="2%" width="5%" height="4.5%" dot-class="dot-right-center" data-tgt="hiring-applicants-jobboard-sign-complete" data-tgt-mode="admin" @click.native="close"></screen-link>
            <screen-link left="77.5%" top="36%" width="11%" height="5.5%" dot-class="dot-right-center" data-tgt="onboarding-manager-federal-info" @click.native="onLinkClick"></screen-link>

          </div>
        </div>

        <div class="screen z-max" data-screen="onboarding-manager-federal-info" :data-active="currentScreen=='onboarding-manager-federal-info'">
          <img class="w-full max-w-100" src="/img/screens/onboarding/onboarding-manager-federal-info.png?v=10122022" />
          <div class="links">
            <screen-link left="96.5%" top="1%" width="3%" height="4.5%" dot-class="dot-left-center" data-tgt="onboarding-manager-thankyou" data-tgt-mode="onboarding"  @click.native="close" />
            <screen-link left="15%" top="74%" width="8%" height="5.5%" dot-class="dot-right-center" data-tgt="onboarding-manager-federal-info-date" @click.native="onLinkClick"/>

          </div>
        </div>

        <div class="screen z-max" data-screen="onboarding-manager-federal-info-date" :data-active="currentScreen=='onboarding-manager-federal-info-date'">
          <img class="w-full max-w-100" src="/img/screens/onboarding/onboarding-manager-federal-info-date.png?v=10122022" />
          <div class="links">
            <screen-link left="96.5%" top="1%" width="3%" height="4.5%" dot-class="dot-left-center" data-tgt="onboarding-manager-thankyou"  @click.native="close" />
            <screen-link left="88%" top="82.5%" width="8%" height="5.5%" dot-class="dot-right-center" data-tgt="onboarding-manager-federal-identity" @click.native="onLinkClick"></screen-link>

          </div>
        </div>

        <div class="screen z-max" data-screen="onboarding-manager-federal-identity" :data-active="currentScreen=='onboarding-manager-federal-identity'">
          <img class="w-full max-w-100" src="/img/screens/onboarding/onboarding-manager-federal-identity.png?v=10122022" />
          <div class="links">
            <screen-link left="96.5%" top="1%" width="3%" height="4.5%" dot-class="dot-left-center" data-tgt="onboarding-manager-thankyou"  @click.native="close" />
            <screen-link left="5%" top="82.5%" width="18%" height="5.5%" dot-class="dot-right-center" data-tgt="onboarding-manager-federal-identity-passport" @click.native="onLinkClick"></screen-link>

          </div>
        </div>

        <div class="screen z-max" data-screen="onboarding-manager-federal-identity-passport" :data-active="currentScreen=='onboarding-manager-federal-identity-passport'">
          <img class="w-full max-w-100" src="/img/screens/onboarding/onboarding-manager-federal-identity-passport.png?v=10122022" />
          <div class="links">
            <screen-link left="96.5%" top="1%" width="3%" height="4.5%" dot-class="dot-left-center" data-tgt="onboarding-manager-thankyou"  @click.native="close" />
            <screen-link left="6.5%" top="118%" width="23%" height="5.5%" dot-class="dot-right-center" data-tgt="onboarding-manager-federal-identity-passport-details" @click.native="onLinkClick"></screen-link>

          </div>
        </div>

        <div class="screen z-max" data-screen="onboarding-manager-federal-identity-passport-details" :data-active="currentScreen=='onboarding-manager-federal-identity-passport-details'">
          <img class="w-full max-w-100" src="/img/screens/onboarding/onboarding-manager-federal-identity-passport-details.png?v=10122022" />
          <div class="links">
            <screen-link left="96.5%" top="1%" width="3%" height="4.5%" dot-class="dot-left-center" data-tgt="onboarding-manager-thankyou"  @click.native="close" />
            <screen-link left="88%" top="165%" width="8%" height="5.5%" dot-class="dot-right-center" data-tgt="onboarding-manager-federal-identity-passport-upload" @click.native="onLinkClick"></screen-link>

          </div>
        </div>

        <div class="screen z-max" data-screen="onboarding-manager-federal-identity-passport-upload" :data-active="currentScreen=='onboarding-manager-federal-identity-passport-upload'">
          <img class="w-full max-w-100" src="/img/screens/onboarding/onboarding-manager-federal-identity-passport-upload.png?v=10122022" />
          <div class="links">
            <screen-link left="96.5%" top="1%" width="3%" height="4.5%" dot-class="dot-left-center" data-tgt="onboarding-manager-thankyou"  @click.native="close" />
            <screen-link left="6.5%" top="56%" width="34%" height="7.5%" dot-class="dot-right-center" data-tgt="onboarding-manager-federal-identity-passport-uploaded" @click.native="onLinkClick"></screen-link>

          </div>
        </div>

        <div class="screen z-max" data-screen="onboarding-manager-federal-identity-passport-uploaded" :data-active="currentScreen=='onboarding-manager-federal-identity-passport-uploaded'">
          <img class="w-full max-w-100" src="/img/screens/onboarding/onboarding-manager-federal-identity-passport-uploaded.png?v=10122022" />
          <div class="links">
            <screen-link left="96.5%" top="1%" width="3%" height="4.5%" dot-class="dot-left-center" data-tgt="onboarding-manager-thankyou"  @click.native="close" />
            <screen-link left="90%" top="74.75%" width="8%" height="5.5%" dot-class="dot-right-center" data-tgt="onboarding-manager-confirm" @click.native="onLinkClick"></screen-link>

          </div>
        </div>

        <div class="screen z-max" data-screen="onboarding-manager-confirm" :data-active="currentScreen=='onboarding-manager-confirm'">
          <img class="w-full max-w-100" src="/img/screens/onboarding/onboarding-manager-confirm.png?v=10122022" />
          <div class="links">
            <screen-link left="96.5%" top="1%" width="3%" height="4.5%" dot-class="dot-left-center" data-tgt="onboarding-manager-thankyou"  @click.native="close" />
            <screen-link left="4%" top="103%" width="47%" height="5.5%" dot-class="dot-left-center" data-tgt="onboarding-manager-confirm-sign" @click.native="onLinkClick"></screen-link>

          </div>
        </div>

        <div class="screen z-max" data-screen="onboarding-manager-confirm-sign" :data-active="currentScreen=='onboarding-manager-confirm-sign'">
          <img class="w-full max-w-100" src="/img/screens/onboarding/onboarding-manager-confirm-sign.png?v=10122022" />
          <div class="links">
            <screen-link left="96.5%" top="1%" width="3%" height="4.5%" dot-class="dot-left-center" data-tgt="onboarding-manager-thankyou"  @click.native="close" />
            <screen-link left="86.5%" top="119.5%" width="12%" height="5.5%" dot-class="dot-left-center" data-tgt="onboarding-manager-complete" @click.native="onLinkClick"></screen-link>

          </div>
        </div>

        <div class="screen z-max" data-screen="onboarding-manager-complete" :data-active="currentScreen=='onboarding-manager-complete'">
          <img class="w-full max-w-100" src="/img/screens/onboarding/onboarding-manager-complete.png?v=10122022" />
          <div class="links">

            <screen-link left="63.5%" top="42.5%" width="10%" height="5.5%" dot-class="dot-right-center" data-tgt="onboarding-manager-thankyou" @click.native="onLinkClick"></screen-link>

          </div>
        </div>

        <div class="screen z-max" data-screen="onboarding-manager-thankyou" :data-active="currentScreen=='onboarding-manager-thankyou'">
          <img class="w-full max-w-100" src="/img/screens/onboarding/onboarding-manager-thankyou.png?v=10122022" />
          <div class="links">
            <screen-link left="85%" top="2%" width="5.5%" height="4.5%" dot-class="dot-right-center" data-tgt="hiring-applicants-jobboard-sign-complete" data-tgt-mode="admin" @click.native="close"></screen-link>
            <screen-link left="81%" top="28.5%" width="8%" height="5.5%" dot-class="dot-left-center" data-tgt="hiring-applicants-jobboard-sign-complete" data-tgt-mode="admin"  @click.native="onLinkClick"></screen-link>

          </div>
        </div>

        </div>




      </div>

    </div>

  </div>

</template>

<script>
import ScreenLink from "../components/ScreenLink";
import DocumentSign from "@/components/onboarding/DocumentSign";
//import FlexNavTopBar from "../components/FlexNavTopBar";
//import FlexSideNav from "../components/FlexAdminSideNav";
//import InfoBox from "../components/InfoBox";

// import PeoplePullout from "@/components/PeoplePullout";
// import SelectBox from "@/components/SelectBox";
// import NewsPullout from "@/components/NewsPullout";
// import NotificationsPullout from "@/components/NotificationsPullout";

// import FiltersPanel from "../components/FiltersPanel";
// import SelectBox from "@/components/SelectBox";
// import FlexInfo from "@/components/FlexInfo";

export default {
  name: "onboarding-screens",
  components: {DocumentSign, ScreenLink, /*FiltersPanel, FlexInfo, SelectBox,*/ },
  mounted: function(){

    this.$store.state.screenScrollRef = window;

    // this.$store.commit("showMenu", false);
    // if(this.$route.query){
    //   this.$store.commit("guidedMode", this.$route.query.guided);
    //   if(this.$route.query.comments){
    //     this.$store.commit("showFeedback", true);
    //   }
    // }

  },
  props: {},
  data: function () {
    return {
      defaultScreen: 'onboarding-begin',
      visibility: {
        topnav: true
      },
      pulloutPanel: null,
      disableScreenScoll: false,
      noRecruiting: false,
      essentials: false,
      i9BackTgt: "onboarding-i9",
      i9FinishTgt: "onboarding-final",
      managerI9finishTgt: ""


    }
  },
  methods: {

    setNoRecruitMode: function(e){
        this.noRecruiting = true;
        this.onLinkClick(e)
    },

    setEssentialsMode: function(e){
      this.essentials = true;
      this.i9FinishTgt = "onboarding-essentials-final";
      this.i9BackTgt = "onboarding-essentials-i9";
      this.onLinkClick(e)
    },

    launchEssentialsI9: function(e){
      this.essentials = true;
      //this.i9FinishTgt = "onboarding-essentials-final";
      //this.i9BackTgt = "onboarding-essentials-i9";
      this.managerI9finishTgt = "hiring-essentials-worker-final-review"
      this.onLinkClick(e)
    },

    close: function(e){
      if(this.noRecruiting){
        this.onLinkClick({
          target: {
            dataset: {
              tgt: "hiring-norecruit-workers-add-details",
              tgtMode: "admin",
              tgtScrolltop: "true"
            }
          }
        })
      }else{
        this.$screens.onLinkClick(e)
      }

    },

    showPrevious: function(){
      this.$router.back();
    },

    onLinkClick: function(e){
      // this.currentScreen = e.target.dataset.tgt;
      if(!e.target.dataset.tgtMode){
        e.target.dataset.tgtMode = 'onboarding';
      }

      this.$screens.onLinkClick(e);

      // if(e.target.dataset.commit){
      //   this.$store.commit(e.target.dataset.commit, e.target.dataset.commitValue);
      // }
      //
      // if(!e.target.dataset.tgt && this.pulloutPanel) {
      //   this.pulloutPanel = null;
      // }else if(e.target.dataset.isOverlay){
      //   this.overlayPanel = e.target.dataset.tgt
      // }else{
      //
      //
      //   this.setScreen(e.target.dataset.tgt, e.target.dataset.tgtMode,  e.target.dataset.tgtScrolltop);
      // }


    },

    showExternalPage: function(e){
      // this.currentScreen = e.target.dataset.tgt;
      let tgtUrl = e.target.dataset.tgt;
      window.open(tgtUrl, "_blank");
    },

    showPullout: function(e){
      // this.currentScreen = e.target.dataset.tgt;
      this.pulloutPanel = e.target.dataset.tgt
    },

    onSelectClick: function(tgt){
      // this.currentScreen = e.target.dataset.tgt;

      this.setScreen(tgt);



    },



    onSideNavLink: function(link){
      if(link){
        let linkParams = link.split(":");
        if(linkParams[0] == "onboarding"){

          this.$router.push({name: "onboarding", params: { screen: linkParams[1]}});

        }
      }

    },

    onTopNavLink: function(link){


      if(link){
        let linkParams = link.split(":");
        if(linkParams[0] == "pullout"){
          this.showOverlay(linkParams[1]);

        }
      }

    },

    showOverlay: function(id){
      this.$screens.setOverlayPanel(id)
      //this.overlayPanel = id;
    }
  },
  computed: {

    showMenu: function(){
      return this.$store.state.showMenu;
    },

    showLinks: function(){
      return this.$store.state.showLinks;
    },

    guidedMode: function(){
      return this.$store.state.guidedMode;
    },

    currentScreen: function(){

      let target = this.defaultScreen;
      if(this.$route.params.screen){
        target = this.$route.params.screen;
      }

      return target
    },

    sideNavLink: function(){
      if(this.$route.params.screen){
        return this.$route.params.screen.split("-")[0];
      }else{
        return "dashboard";
      }
    },

    sideNavMode: function(){
      if(this.$route.query.mode){
        return this.$route.query.mode;
      }else{
        return "admin";
      }
    }
  }
}
</script>

<style scoped>

img {
  margin: 0;
}

.screen-container {

  margin: 0px auto;
}

#app[data-feedback-mode="true"] .screen-container {

  margin: 0;

}


.demo {
  width: 100%;
  position: relative;



}

.demo[data-noscroll="true"]{


}



.screens {

  position: relative;
  width: 100%;
  overflow-x: hidden;
}

.overlay {
  position: absolute;
  width: calc((320/1580) * 100%);
  top: calc((60/920) * 100%);
  right: 0;
  display: none;
}

.overlay[data-active="true"] {
  display: block;
}

.pullout {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  right: 0;
  display: none;
  background-color: rgba(0,0,0,0.49);
}

.pullout[data-active="true"] {
  display: block;
}

.popover {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  right: 0;
  display: none;
}

.popover[data-active="true"] {
  display: block;
}

.overlay[data-active="true"] {
  display: block;
}

.screens .screen {
  position: relative;
  width: 100%;
  display: none;
}

.screen[data-active="true"] {
  display: block;
}

.screen .links {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 0;
  padding-top: calc((920/1580) * 100%);
}

.pullout .links {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 0;
  padding-top: calc((920/1580) * 100%);
}


/* UTILITIES */

.w-full {
  width: 100%;
}



.pullout-1200 {
  position: absolute;
  width: calc(100% * (1200 / 3160));
  top: 0;
  right: 0;
}

.popover-email {
  position: absolute;
  width: calc(100% * (1000 / 1580));
  top: 20%;
  right: 5%;
  box-shadow: 0 10px 20px rgba(0,0,0,0.3);

}

.popover-with-bkg {
  background-color: rgba(0,0,0,0.4);


}

.popover-time-attendance {
  position: absolute;
  width: calc(100% * (1000 / 1580));
  top: 20%;
  right: 15%;
  box-shadow: 0 10px 20px rgba(0,0,0,0.3);

}

.popover-schedule-details {
  position: absolute;
  width: calc(100% * (808 / 1580));
  top: 20%;
  right: 20%;
  box-shadow: 0 10px 20px rgba(0,0,0,0.3);

}

.x-ray {
  opacity: 0.5;
}

*[data-hidden] {
  opacity: 0;
}



.demo[data-show-links="true"] .select-box {

}

.demo[data-show-links="true"] .flex-info {
  border: 2px solid var(--color-green);
}

.demo[data-show-links="true"] .info-box {
  display: block;
}

.demo .info-box {
  display: none;
}


.demo[data-guided="true"] .info-box {
  display: block;
}

.z-max {
  z-index: 50;
}

.z-45 {
  z-index: 45;
}

.z-40 {
  z-index: 40;
}

.info-box {
  z-index: 30;
}

.demo-menu {
  top: 0;
}

</style>

<style>
html {
  background: #F3F7FA;
}
</style>
