


<template>
<div class="check-stubs-pullout pullout-panel">
  <div class="bkg"></div>
  <div class="check-stubs-list">


    <div v-if="($store.state.company === 'pro') && !detailPanel" class="check-stubs-scroll">
      <img class="w-full" src="/img/screens/employee/time-attendance/pullouts/pullout-entry-details-admin-pro.png?v=10122022" />
      <div class="links">
        <screen-link left="82.5%" top="1%" width="14.5%" height="5%" data-tgt="profile" dot-class="dot-bottom-center" data-is-overlay="true" @click.native="clockIn"></screen-link>
        <screen-link left="68.5%" top="1%" width="13%" height="5%" data-tgt="profile" dot-class="dot-bottom-center" data-is-overlay="true" @click.native="$emit('close')"></screen-link>

      </div>
    </div>

    <div v-if="($store.state.company !== 'pro') && !detailPanel" class="check-stubs-scroll">
      <img class="w-full" src="/img/screens/employee/time-attendance/pullouts/pullout-entry-details-admin.png?v=10122022" />
      <div class="links">
        <screen-link left="82.5%" top="1%" width="14.5%" height="5%" data-tgt="profile" dot-class="dot-bottom-center" data-is-overlay="true" @click.native="clockIn"></screen-link>
        <screen-link left="68.5%" top="1%" width="13%" height="5%" data-tgt="profile" dot-class="dot-bottom-center" data-is-overlay="true" @click.native="$emit('close')"></screen-link>

      </div>
    </div>

    <div class="check-stubs-scroll" v-if="(detailPanel === 'add-details') && ($store.state.company === 'pro')">
      <img class="w-full" src="/img/screens/employee/time-attendance/pullouts/pullout-add-entry-details-admin-pro.png?v=10122022" />
      <div class="links">
        <screen-link left="85%" top="1%" width="12.5%" height="5%" data-tgt="profile" dot-class="dot-bottom-center" data-is-overlay="true" @click.native="clockIn"></screen-link>
        <screen-link left="71%" top="1%" width="13.5%" height="5%" data-tgt="profile" dot-class="dot-bottom-center" data-is-overlay="true" @click.native="$emit('close')"></screen-link>
      </div>
    </div>

    <div class="check-stubs-scroll" v-if="(detailPanel === 'add-details') && ($store.state.company !== 'pro')">
      <img class="w-full" src="/img/screens/employee/time-attendance/pullouts/pullout-add-entry-details-admin.png?v=10122022" />
      <div class="links">
        <screen-link left="85%" top="1%" width="12.5%" height="5%" data-tgt="profile" dot-class="dot-bottom-center" data-is-overlay="true" @click.native="clockIn"></screen-link>
        <screen-link left="71%" top="1%" width="13.5%" height="5%" data-tgt="profile" dot-class="dot-bottom-center" data-is-overlay="true" @click.native="$emit('close')"></screen-link>
        <screen-link left="5%" top="24.5%" width="45%" height="4%" data-tgt="add-costing-options" dot-class="dot-left-center" data-is-overlay="true" @click.native="showDetails"></screen-link>
      </div>
    </div>

    <div class="check-stubs-scroll" v-if="detailPanel === 'add-costing-options'">
      <img class="w-full" src="/img/screens/employee/dashboard/pullouts/pullout-clock-in-transfer-options.png?v=10122022" />
      <coming-soon></coming-soon>
      <div class="links">

        <screen-link left="81%" top="51%" width="15%" height="5.5%" data-tgt="add-details" dot-class="dot-left-center" data-is-overlay="true" @click.native="showDetails"></screen-link>

      </div>
    </div>

    <div v-if="(detailPanel === 'transfer') && $store.state.company === 'pro'" class="check-stubs-scroll" >
      <img class="w-full" src="/img/screens/employee/dashboard/pullouts/pullout-clock-in-transfer-pro.png?v=10122022" />

      <div class="links">
        <screen-link left="68%" top="1%" width="14%" height="5%" data-tgt="profile" dot-class="dot-bottom-center" data-is-overlay="true" @click.native="$emit('close')"></screen-link>

      </div>
    </div>

    <div class="check-stubs-scroll" v-if="(detailPanel === 'transfer') && $store.state.company !== 'pro'">
      <img class="w-full" src="/img/screens/employee/dashboard/pullouts/pullout-clock-in-transfer.png?v=10122022" />

      <div class="links">
        <screen-link left="68%" top="1%" width="14%" height="5%" data-tgt="profile" dot-class="dot-bottom-center" data-is-overlay="true" @click.native="$emit('close')"></screen-link>
        <screen-link left="5%" top="32%" width="45%" height="4%" data-tgt="transfer-options" dot-class="dot-left-center" data-is-overlay="true" @click.native="showDetails"></screen-link>

      </div>
    </div>

    <div class="check-stubs-scroll" v-if="detailPanel === 'transfer-options'">
      <img class="w-full" src="/img/screens/employee/dashboard/pullouts/pullout-clock-in-transfer-options.png?v=10122022" />

      <div class="links">

        <screen-link left="81%" top="51%" width="15%" height="5.5%" data-tgt="transfer" dot-class="dot-left-center" data-is-overlay="true" @click.native="showDetails"></screen-link>

      </div>
    </div>



  </div>



</div>
</template>

<script>
import ScreenLink from "@/components/ScreenLink";
import ComingSoon from "@/components/ComingSoon";
export default {
    name: "clock-in-pullout",
  components: {ComingSoon, ScreenLink},
  props: {},
    data: function(){
        return {
          detailPanel: null
        }
        },
    mounted() {
      if(this.$route.query.level !== undefined){
        this.detailPanel = this.$route.query.level;
      }
    },

    watch: {
      $route: function(next){
        if(next.query.level !== undefined){
          this.detailPanel = next.query.level;
        }
      }
    },

    methods: {

      clockIn: function(){
        this.$store.state.clockin = 'working';
        this.$emit('close');
      },

      showDetails: function(e){
          this.detailPanel = e.target.dataset.tgt;
let query = {...this.$route.query};
          query.level=e.target.dataset.tgt;
          this.$router.replace({ name: this.$route.name, params: this.$route.params, query: query });
      },

      onLinkClick: function(e){
        console.log(e);
        this.$emit('link', e);
      }
    },
    computed: {}
}
</script>

<style scoped>


.check-stubs-pullout {
  width: 100%;
  position: absolute;
  top: calc((60/920) * 100%);
  height: calc(((920 - 60)/920) * 100%);

}

.check-stubs-pullout .bkg {
  position: absolute;
  width: 100%;
  height: 100%;
  right: 0;
  background-color: black;
  opacity: 0.4;
}

.check-stubs-pullout img {
  display: block;
}


.check-stubs-list {

  position: absolute;
  width: calc((600/1580) * 100% + 15px);
  height: 100%;

  right: 0;
  display: flex;
  flex-direction: column;

}



.check-stubs-scroll {
  position: relative;

  overflow-y: scroll;
}

.w-full {
  width: 100%;
}

.links {

}

</style>
