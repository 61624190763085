<template>
  <div>
    <div class="screen" data-screen="mypay" :data-active="currentScreen=='mypay'">
      <img class="w-full max-w-100" src="/img/screens/employee/mypay/my-pay.png?v=10122022" />
      <div class="links">
        <screen-link dot-class="dot-top-center" left="15.4%" top="20.5%" width="7.5%" height="3%" data-tgt="mypay" @click.native="onLinkClick"></screen-link>
        <screen-link dot-class="dot-top-center" left="23%" top="20.5%" width="6.5%" height="3%" data-tgt="mypay-history" @click.native="onLinkClick"></screen-link>
        <screen-link dot-class="dot-top-center" left="29.5%" top="20.5%" width="10%" height="3%" data-tgt="mypay-earnings" @click.native="onLinkClick"></screen-link>
        <screen-link dot-class="dot-top-center" left="39.75%" top="20.5%" width="7%" height="3%" data-tgt="mypay-directdeposit" @click.native="onLinkClick"></screen-link>
        <screen-link dot-class="dot-top-center" left="47%" top="20.5%" width="4%" height="3%" data-tgt="mypay-taxes" @click.native="onLinkClick"></screen-link>
        <company-tab :company="$store.state.company" select-tgt="mypay"/>
        <info-box left="97.5%" top="10.5%" width="40em" height="4.5%" >
          <p>Using My Pay, employees can make changes to direct deposit, address, contact information and tax setup. Admins can setup to approve all changes made by employees.</p>
        </info-box>
      </div>
    </div>

    <div class="screen" data-screen="mypay-history" :data-active="currentScreen=='mypay-history'">
      <img class="w-full max-w-100" src="/img/screens/employee/mypay/my-pay-history.png?v=10122022" />
      <div class="links">
        <screen-link dot-class="dot-top-center" left="15.4%" top="20.5%" width="7.5%" height="3%" data-tgt="mypay" @click.native="onLinkClick"></screen-link>
        <screen-link dot-class="dot-top-center" left="23%" top="20.5%" width="6.5%" height="3%" data-tgt="mypay-history" @click.native="onLinkClick"></screen-link>
        <screen-link dot-class="dot-top-center" left="29.5%" top="20.5%" width="10%" height="3%" data-tgt="mypay-earnings" @click.native="onLinkClick"></screen-link>
        <screen-link dot-class="dot-top-center" left="39.75%" top="20.5%" width="7%" height="3%" data-tgt="mypay-directdeposit" @click.native="onLinkClick"></screen-link>
        <screen-link dot-class="dot-top-center" left="47%" top="20.5%" width="4%" height="3%" data-tgt="mypay-taxes" @click.native="onLinkClick"></screen-link>
        <company-tab :company="$store.state.company" select-tgt="mypay"/>
      </div>
    </div>

    <div class="screen" data-screen="mypay-earnings" :data-active="currentScreen=='mypay-earnings'">
      <img class="w-full max-w-100" src="/img/screens/employee/mypay/my-pay-earnings.png?v=10122022" />
      <div class="links">
        <screen-link dot-class="dot-top-center" left="15.4%" top="20.5%" width="7.5%" height="3%" data-tgt="mypay" @click.native="onLinkClick"></screen-link>
        <screen-link dot-class="dot-top-center" left="23%" top="20.5%" width="6.5%" height="3%" data-tgt="mypay-history" @click.native="onLinkClick"></screen-link>
        <screen-link dot-class="dot-top-center" left="29.5%" top="20.5%" width="10%" height="3%" data-tgt="mypay-earnings" @click.native="onLinkClick"></screen-link>
        <screen-link dot-class="dot-top-center" left="39.75%" top="20.5%" width="7%" height="3%" data-tgt="mypay-directdeposit" @click.native="onLinkClick"></screen-link>
        <screen-link dot-class="dot-top-center" left="47%" top="20.5%" width="4%" height="3%" data-tgt="mypay-taxes" @click.native="onLinkClick"></screen-link>
        <company-tab :company="$store.state.company" select-tgt="mypay"/>
      </div>
    </div>

    <div class="screen" data-screen="mypay-directdeposit" :data-active="currentScreen=='mypay-directdeposit'">
      <img class="w-full max-w-100" src="/img/screens/employee/mypay/my-pay-direct-deposit.png?v=10122022" />
      <div class="links">
        <screen-link dot-class="dot-top-center" left="15.4%" top="20.5%" width="7.5%" height="3%" data-tgt="mypay" @click.native="onLinkClick"></screen-link>
        <screen-link dot-class="dot-top-center" left="23%" top="20.5%" width="6.5%" height="3%" data-tgt="mypay-history" @click.native="onLinkClick"></screen-link>
        <screen-link dot-class="dot-top-center" left="29.5%" top="20.5%" width="10%" height="3%" data-tgt="mypay-earnings" @click.native="onLinkClick"></screen-link>
        <screen-link dot-class="dot-top-center" left="39.75%" top="20.5%" width="7%" height="3%" data-tgt="mypay-directdeposit" @click.native="onLinkClick"></screen-link>
        <screen-link dot-class="dot-top-center" left="47%" top="20.5%" width="4%" height="3%" data-tgt="mypay-taxes" @click.native="onLinkClick"></screen-link>

        <screen-link dot-class="dot-left-center" left="80%" top="28%" width="6.5%" height="6%" data-tgt="mypay-directdeposit-edit" @click.native="onLinkClick"></screen-link>
        <company-tab :company="$store.state.company" select-tgt="mypay"/>
      </div>
    </div>

    <div class="screen" data-screen="mypay-directdeposit-edit" :data-active="currentScreen=='mypay-directdeposit-edit'">
      <img class="w-full max-w-100" src="/img/screens/employee/mypay/my-pay-direct-deposit-edit.png?v=10122022" />
      <div class="links">
        <screen-link dot-class="dot-top-center" left="15.4%" top="20.5%" width="7.5%" height="3%" data-tgt="mypay" @click.native="onLinkClick"></screen-link>
        <screen-link dot-class="dot-top-center" left="23%" top="20.5%" width="6.5%" height="3%" data-tgt="mypay-history" @click.native="onLinkClick"></screen-link>
        <screen-link dot-class="dot-top-center" left="29.5%" top="20.5%" width="10%" height="3%" data-tgt="mypay-earnings" @click.native="onLinkClick"></screen-link>
        <screen-link dot-class="dot-top-center" left="39.75%" top="20.5%" width="7%" height="3%" data-tgt="mypay-directdeposit" @click.native="onLinkClick"></screen-link>
        <screen-link dot-class="dot-top-center" left="47%" top="20.5%" width="4%" height="3%" data-tgt="mypay-taxes" @click.native="onLinkClick"></screen-link>

        <screen-link dot-class="dot-left-center" left="74%" top="28%" width="6.5%" height="6%" data-tgt="mypay-directdeposit" @click.native="onLinkClick"></screen-link>
        <company-tab :company="$store.state.company" select-tgt="mypay"/>


      </div>
    </div>

    <div class="screen" data-screen="mypay-directdeposit-edit-add" :data-active="currentScreen=='mypay-directdeposit-edit-add'">
      <img class="w-full max-w-100" src="/img/screens/employee/mypay/my-pay-direct-deposit-edit-add.png?v=10122022" />
      <div class="links">
        <screen-link dot-class="dot-top-center" left="15.4%" top="20.5%" width="7.5%" height="3%" data-tgt="mypay" @click.native="onLinkClick"></screen-link>
        <screen-link dot-class="dot-top-center" left="23%" top="20.5%" width="10%" height="3%" data-tgt="mypay-earnings" @click.native="onLinkClick"></screen-link>
        <screen-link dot-class="dot-top-center" left="33.5%" top="20.5%" width="7%" height="3%" data-tgt="mypay-directdeposit" @click.native="onLinkClick"></screen-link>
        <screen-link dot-class="dot-top-center" left="40.75%" top="20.5%" width="4%" height="3%" data-tgt="mypay-taxes" @click.native="onLinkClick"></screen-link>

        <screen-link dot-class="dot-left-center" left="74%" top="35.5%" width="6.5%" height="4%" data-tgt="mypay-directdeposit" @click.native="onLinkClick"></screen-link>
        <screen-link dot-class="dot-left-center" left="29%" top="91%" width="25%" height="4%" data-tgt="mypay-directdeposit-edit-add-details" @click.native="onLinkClick"></screen-link>

      </div>
    </div>

    <div class="screen" data-screen="mypay-directdeposit-edit-add-details" :data-active="currentScreen=='mypay-directdeposit-edit-add-details'">
      <img class="w-full max-w-100" src="/img/screens/employee-mypay-directdeposit-edit-add-details.png?v=10122022" />
      <div class="links">
        <screen-link dot-class="dot-top-center" left="15.4%" top="20.5%" width="7.5%" height="3%" data-tgt="mypay" @click.native="onLinkClick"></screen-link>
        <screen-link dot-class="dot-top-center" left="23%" top="20.5%" width="10%" height="3%" data-tgt="mypay-earnings" @click.native="onLinkClick"></screen-link>
        <screen-link dot-class="dot-top-center" left="33.5%" top="20.5%" width="7%" height="3%" data-tgt="mypay-directdeposit" @click.native="onLinkClick"></screen-link>
        <screen-link dot-class="dot-top-center" left="40.75%" top="20.5%" width="4%" height="3%" data-tgt="mypay-taxes" @click.native="onLinkClick"></screen-link>

        <screen-link dot-class="dot-left-center" left="74%" top="28%" width="6.5%" height="4%" data-tgt="mypay-directdeposit" @click.native="onLinkClick"></screen-link>


      </div>
    </div>

    <div class="screen" data-screen="mypay-taxes" :data-active="currentScreen=='mypay-taxes'">
      <img class="w-full max-w-100" src="/img/screens/employee/mypay/my-pay-taxes.png?v=10122022" />
      <div class="links">
        <screen-link dot-class="dot-top-center" left="15.4%" top="20.5%" width="7.5%" height="3%" data-tgt="mypay" @click.native="onLinkClick"></screen-link>
        <screen-link dot-class="dot-top-center" left="23%" top="20.5%" width="6.5%" height="3%" data-tgt="mypay-history" @click.native="onLinkClick"></screen-link>
        <screen-link dot-class="dot-top-center" left="29.5%" top="20.5%" width="10%" height="3%" data-tgt="mypay-earnings" @click.native="onLinkClick"></screen-link>
        <screen-link dot-class="dot-top-center" left="39.75%" top="20.5%" width="7%" height="3%" data-tgt="mypay-directdeposit" @click.native="onLinkClick"></screen-link>
        <screen-link dot-class="dot-top-center" left="47%" top="20.5%" width="4%" height="3%" data-tgt="mypay-taxes" @click.native="onLinkClick"></screen-link>

        <screen-link dot-class="dot-right-center" left="28%" top="34%" width="58%" height="7%" data-tgt="pullout-check-stubs" data-init-info="fed-tax-details" @click.native="$screens.showPullout"></screen-link>
        <company-tab :company="$store.state.company" select-tgt="mypay"/>
      </div>
    </div>


  </div>
</template>

<script>

export default {
  name: "EmployeeMyPayScreens",
  props: {
    onLinkClick: {},
    showPullout: {},
    currentScreen: {
      default: ""
    }
  }
}
</script>

<style scoped>

</style>