<template>
  <div>
    <div class="screen" data-screen="hb-summary" :data-active="currentScreen=='hb-summary'">
      <img class="w-full max-w-100" src="/img/screens/employee-hb-summary.png?v=10122022" />
      <div class="links">
        <screen-link dot-class="dot-top-center" left="15.4%" top="18.5%" width="4.5%" height="3%" data-tgt="hb-summary" @click.native="onLinkClick"></screen-link>
        <screen-link dot-class="dot-top-center" left="20.3%" top="18.5%" width="3.5%" height="3%" data-tgt="hb-family" @click.native="onLinkClick"></screen-link>
        <screen-link dot-class="dot-top-cent" left="24%" top="18.5%" width="4.5%" height="3%" data-tgt="hb-history" @click.native="onLinkClick"></screen-link>
        <company-tab :company="$store.state.coermpany" select-tgt="hb-summary"/>
        <info-box left="97.5%" top="10.5%" width="40em" height="4.5%" >
          <p>When Health & Benefits are integrated with payroll, employees can view their current benefits overview, see future benefits, make changes during open enrollment and change coverage after a qualifying event.</p>
        </info-box>
      </div>
    </div>

    <div class="screen" data-screen="hb-family" :data-active="currentScreen=='hb-family'">
      <img class="w-full max-w-100" src="/img/screens/employee/hb/employee-hb-family.png?v=10122022" />
      <div class="links">
        <screen-link dot-class="dot-top-center" left="15.4%" top="18.5%" width="4.5%" height="3%" data-tgt="hb-summary" @click.native="onLinkClick"></screen-link>
        <screen-link dot-class="dot-top-center" left="20.3%" top="18.5%" width="3.5%" height="3%" data-tgt="hb-family" @click.native="onLinkClick"></screen-link>
        <screen-link dot-class="dot-top-center" left="24%" top="18.5%" width="4.5%" height="3%" data-tgt="hb-history" @click.native="onLinkClick"></screen-link>

        <screen-link dot-class="dot-right-center" left="81%" top="37%" width="6%" height="5%" data-tgt="pullout-benefits-family" @click.native="$screens.showPullout"></screen-link>
        <company-tab :company="$store.state.company" select-tgt="hb-summary"/>
      </div>
    </div>

    <div class="screen" data-screen="hb-family-added" :data-active="currentScreen=='hb-family-added'">
      <img class="w-full max-w-100" src="/img/screens/employee/hb/employee-hb-family-added.png?v=10122022" />
      <div class="links">
        <screen-link dot-class="dot-top-center" left="15.4%" top="18.5%" width="4.5%" height="3%" data-tgt="hb-summary" @click.native="onLinkClick"></screen-link>
        <screen-link dot-class="dot-top-center" left="20.3%" top="18.5%" width="3.5%" height="3%" data-tgt="hb-family" @click.native="onLinkClick"></screen-link>
        <screen-link dot-class="dot-top-center" left="24%" top="18.5%" width="4.5%" height="3%" data-tgt="hb-history" @click.native="onLinkClick"></screen-link>

        <screen-link dot-class="dot-right-center" left="81%" top="69%" width="6%" height="5%" data-tgt="pullout-benefits-family" data-init-info="edit-beneficiary" @click.native="$screens.showPullout"></screen-link>

        <company-tab :company="$store.state.company" select-tgt="hb-summary"/>
      </div>
    </div>


    <div class="screen" data-screen="hb-family-beneficiary-added" :data-active="currentScreen=='hb-family-beneficiary-added'">
      <img class="w-full max-w-100" src="/img/screens/employee/hb/employee-hb-beneficiary-added.png?v=10122022" />
      <div class="links">

        <screen-link dot-class="dot-top-center" left="15.4%" top="18.5%" width="4.5%" height="3%" data-tgt="hb-summary" @click.native="onLinkClick"></screen-link>
        <screen-link dot-class="dot-top-center" left="20.3%" top="18.5%" width="3.5%" height="3%" data-tgt="hb-family" @click.native="onLinkClick"></screen-link>
        <screen-link dot-class="dot-top-center" left="24%" top="18.5%" width="4.5%" height="3%" data-tgt="hb-history" @click.native="onLinkClick"></screen-link>

        <company-tab :company="$store.state.company" select-tgt="hb-summary"/>

      </div>
    </div>


    <!-- HISTORY -->

    <div class="screen" data-screen="hb-history" :data-active="currentScreen=='hb-history'">
      <img class="w-full max-w-100" src="/img/screens/employee-hb-history.png?v=10122022" />
      <div class="links">
        <screen-link dot-class="dot-top-center" left="15.4%" top="18.5%" width="4.5%" height="3%" data-tgt="hb-summary" @click.native="onLinkClick"></screen-link>
        <screen-link dot-class="dot-top-center" left="20.3%" top="18.5%" width="3.5%" height="3%" data-tgt="hb-family" @click.native="onLinkClick"></screen-link>
        <screen-link dot-class="dot-top-center" left="24%" top="18.5%" width="4.5%" height="3%" data-tgt="hb-history" @click.native="onLinkClick"></screen-link>
        <company-tab :company="$store.state.company" select-tgt="hb-summary"/>
      </div>
    </div>

    <div class="screen" data-screen="hb-enrollment-begin" :data-active="currentScreen=='hb-enrollment-begin'">
      <img class="w-full max-w-100" src="/img/screens/employee-hb-enrollment-begin.png?v=10122022" />
      <div class="links">

      </div>
    </div>

  </div>
</template>

<script>

export default {
  name: "EmployeeHealthBenefitsScreens",
  props: {
    onLinkClick: {},
    showPullout: {},
    currentScreen: {
      default: ""
    }
  }
}
</script>

<style scoped>

</style>