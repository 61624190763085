<template>
  <div class="demo-frame">
    <div class="flex-screens">
      <NonFlexLayout default-screen="flock-home">
        <template #screens>
          <AdminFlock :current-screen="currentScreen" />
        </template>
      </NonFlexLayout>
    </div>
  </div>

</template>

<script>
import AdminFlock from "@/components/admin/AdminFlock";
import NonFlexLayout from "@/views/NonFlexLayout";
export default {
  name: "FlockAdminExperience",
  components: {NonFlexLayout, AdminFlock},
  computed: {
    currentScreen: function(){

      if(this.$route.params.screen){
        return this.$route.params.screen;
      }

      return ""

    }
  }
}
</script>

<style scoped>

</style>