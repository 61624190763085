<template>
  <div>
    <div v-if="$store.state.company === 'pro'" class="screen" data-screen="time-overview" :data-active="currentScreen=='time-overview'">
      <img class="w-full max-w-100" src="/img/screens/employee/time-attendance/time-attendance-pro.png?v=10122022" />
      <div class="links">

        <div v-if="clockin === 'working'" class="clock-box" style="left:26.55%; top: 23.6%;">
          <img  src="/img/screens/employee/time-attendance/cutouts/clock-in-working-pro.png?v=10122022" />
        </div>
        <div v-if="clockin === 'meal'" class="clock-box" style="left:26.55%; top: 23.6%;">
          <img  src="/img/screens/employee/time-attendance/cutouts/clock-in-meal-pro.png?v=10122022" />
        </div>
        <div v-if="clockin === 'break'" class="clock-box" style="left:26.55%; top: 23.6%;">
          <img  src="/img/screens/employee/time-attendance/cutouts/clock-in-break-pro.png?v=10122022" />
        </div>

        <screen-link left="14.5%" top="17.5%" width="5.5%" height="5%" dot-class="dot-bottom-center" data-tgt="time-overview" @click.native="onLinkClick"></screen-link>
        <screen-link left="20%" top="17.5%" width="4.5%" height="5%" dot-class="dot-bottom-center" data-tgt="time-calendar" @click.native="onLinkClick"></screen-link>
        <screen-link left="24.5%" top="17.5%" width="7%" height="5%" dot-class="dot-bottom-center" data-tgt="time-status" @click.native="onLinkClick"></screen-link>


        <screen-link v-if="!clockin" left="30.1%" top="51%" width="4%" height="10%" dot-class="dot-top-center"  data-tgt="pullout-clock-in" @click.native="$screens.showPullout"></screen-link>
        <screen-link v-else left="30.1%" top="51%" width="4%" height="10%" dot-class="dot-top-center" data-tgt="time-clockin" @click.native="$emit('clock','')"></screen-link>
        <screen-link left="38.1%" top="51%" width="4%" height="10%" dot-class="dot-top-center" data-tgt="time-transfer" @click.native="$emit('clock','meal')"></screen-link>
        <screen-link left="46%" top="51%" width="4%" height="10%" dot-class="dot-top-center" data-tgt="time-callback" @click.native="$emit('clock','break')"></screen-link>


        <screen-link left="66%" top="63%" width="10%" height="4%" dot-class="dot-right-center" data-tgt="time-calendar" data-tgt-scrolltop="true" @click.native="onLinkClick"></screen-link>

        <screen-link left="90.5%" top="79.5%" width="6%" height="4%" dot-class="dot-right-center" data-tgt="time-timesheet" data-tgt-scrolltop="true" @click.native="onLinkClick"></screen-link>
        <company-tab :company="$store.state.company" select-tgt="time-overview"/>
        <info-box left="97.5%" top="10.5%" width="40em" height="4.5%" >
          <p>By integrating payroll with Time & Attendance, employees can use the platform to clock in and out, view their time cards and even request vacation.</p>
        </info-box>
      </div>
    </div>
    <div v-else class="screen" data-screen="time-overview" :data-active="currentScreen=='time-overview'">
      <img class="w-full max-w-100" src="/img/screens/employee/time-attendance/time-attendance.png?v=10122022" />
      <div class="links">

        <div v-if="clockin === 'working'" class="clock-box" style="left:26.55%; top: 23.6%;">
          <img  src="/img/screens/employee/time-attendance/cutouts/clock-in-working.png?v=10122022" />
        </div>
        <div v-if="clockin === 'meal'" class="clock-box" style="left:26.55%; top: 23.6%;">
          <img  src="/img/screens/employee/time-attendance/cutouts/clock-in-meal.png?v=10122022" />
        </div>
        <div v-if="clockin === 'break'" class="clock-box" style="left:26.55%; top: 23.6%;">
          <img  src="/img/screens/employee/time-attendance/cutouts/clock-in-break.png?v=10122022" />
        </div>

        <screen-link left="14.5%" top="17.5%" width="5.5%" height="5%" dot-class="dot-bottom-center" data-tgt="time-overview" @click.native="onLinkClick"></screen-link>
        <screen-link left="20%" top="17.5%" width="4.5%" height="5%" dot-class="dot-bottom-center" data-tgt="time-calendar" @click.native="onLinkClick"></screen-link>
        <screen-link left="24.5%" top="17.5%" width="7%" height="5%" dot-class="dot-bottom-center" data-tgt="time-status" @click.native="onLinkClick"></screen-link>


        <screen-link v-if="!clockin" left="29.2%" top="51%" width="4%" height="10%" dot-class="dot-top-center"  data-tgt="pullout-clock-in" @click.native="$screens.showPullout"></screen-link>
        <screen-link v-else left="29.2%" top="51%" width="4%" height="10%" dot-class="dot-top-center" data-tgt="time-clockin" @click.native="$emit('clock','')"></screen-link>
        <screen-link left="35.2%" top="51%" width="4%" height="10%" dot-class="dot-top-center" data-tgt="time-transfer" @click.native="$emit('clock','meal')"></screen-link>
        <screen-link left="41.2%" top="51%" width="4%" height="10%" dot-class="dot-top-center" data-tgt="time-callback" @click.native="$emit('clock','break')"></screen-link>
        <screen-link left="47.2%" top="51%" width="4%" height="10%" dot-class="dot-top-center" data-tgt="pullout-clock-in" data-init-info="transfer" @click.native="$screens.showPullout"></screen-link>

        <screen-link left="66%" top="63%" width="10%" height="4%" dot-class="dot-right-center" data-tgt="time-calendar" data-tgt-scrolltop="true" @click.native="onLinkClick"></screen-link>

        <screen-link left="90.5%" top="79.5%" width="6%" height="4%" dot-class="dot-right-center" data-tgt="time-timesheet" data-tgt-scrolltop="true" @click.native="onLinkClick"></screen-link>
        <company-tab :company="$store.state.company" select-tgt="time-overview"/>
        <info-box left="97.5%" top="10.5%" width="40em" height="4.5%" >
          <p>By integrating payroll with Time & Attendance, employees can use the platform to clock in and out, view their time cards and even request vacation.</p>
        </info-box>
      </div>
    </div>



    <div v-if="$store.state.company === 'pro'" class="screen" data-screen="time-overview-edited" :data-active="currentScreen=='time-overview-edited'">
      <img class="w-full max-w-100" src="/img/screens/employee/time-attendance/time-attendance-edited-pro.png?v=10122022" />
      <div class="links">
        <div v-if="clockin === 'working'" class="clock-box" style="left:26.55%; top: 23.6%;">
          <img  src="/img/screens/employee/time-attendance/cutouts/clock-in-working-pro.png?v=10122022" />
        </div>
        <div v-if="clockin === 'meal'" class="clock-box" style="left:26.55%; top: 23.6%;">
          <img  src="/img/screens/employee/time-attendance/cutouts/clock-in-meal-pro.png?v=10122022" />
        </div>
        <div v-if="clockin === 'break'" class="clock-box" style="left:26.55%; top: 23.6%;">
          <img  src="/img/screens/employee/time-attendance/cutouts/clock-in-break-pro.png?v=10122022" />
        </div>

        <screen-link left="14.5%" top="17.5%" width="5.5%" height="5%" dot-class="dot-bottom-center" data-tgt="time-overview" @click.native="onLinkClick"></screen-link>
        <screen-link left="20%" top="17.5%" width="4.5%" height="5%" dot-class="dot-bottom-center" data-tgt="time-calendar" @click.native="onLinkClick"></screen-link>
        <screen-link left="24.5%" top="17.5%" width="7%" height="5%" dot-class="dot-bottom-center" data-tgt="time-status" @click.native="onLinkClick"></screen-link>


        <screen-link v-if="!clockin" left="30.1%" top="51%" width="4%" height="10%" dot-class="dot-top-center"  data-tgt="pullout-clock-in" @click.native="$screens.showPullout"></screen-link>
        <screen-link v-else left="30.1%" top="51%" width="4%" height="10%" dot-class="dot-top-center" data-tgt="time-clockin" @click.native="$emit('clock','')"></screen-link>
        <screen-link left="38.1%" top="51%" width="4%" height="10%" dot-class="dot-top-center" data-tgt="time-transfer" @click.native="$emit('clock','meal')"></screen-link>
        <screen-link left="46%" top="51%" width="4%" height="10%" dot-class="dot-top-center" data-tgt="time-callback" @click.native="$emit('clock','break')"></screen-link>

        <screen-link left="66%" top="63%" width="10%" height="4%" dot-class="dot-right-center" data-tgt="time-calendar" data-tgt-scrolltop="true" @click.native="onLinkClick"></screen-link>

        <screen-link left="90.5%" top="79.5%" width="6%" height="4%" dot-class="dot-right-center" data-tgt="time-timesheet" data-tgt-scrolltop="true" @click.native="onLinkClick"></screen-link>
        <company-tab :company="$store.state.company" select-tgt="time-overview"/>
      </div>
    </div>

    <div v-else class="screen" data-screen="time-overview-edited" :data-active="currentScreen=='time-overview-edited'">
      <img class="w-full max-w-100" src="/img/screens/employee/time-attendance/time-attendance-edited.png?v=10122022" />
      <div class="links">
        <div v-if="clockin === 'working'" class="clock-box" style="left:26.55%; top: 23.6%;">
          <img  src="/img/screens/employee/time-attendance/cutouts/clock-in-working.png?v=10122022" />
        </div>
        <div v-if="clockin === 'meal'" class="clock-box" style="left:26.55%; top: 23.6%;">
          <img  src="/img/screens/employee/time-attendance/cutouts/clock-in-meal.png?v=10122022" />
        </div>
        <div v-if="clockin === 'break'" class="clock-box" style="left:26.55%; top: 23.6%;">
          <img  src="/img/screens/employee/time-attendance/cutouts/clock-in-break.png?v=10122022" />
        </div>

        <screen-link left="14.5%" top="17.5%" width="5.5%" height="5%" dot-class="dot-bottom-center" data-tgt="time-overview" @click.native="onLinkClick"></screen-link>
        <screen-link left="20%" top="17.5%" width="4.5%" height="5%" dot-class="dot-bottom-center" data-tgt="time-calendar" @click.native="onLinkClick"></screen-link>
        <screen-link left="24.5%" top="17.5%" width="7%" height="5%" dot-class="dot-bottom-center" data-tgt="time-status" @click.native="onLinkClick"></screen-link>


        <screen-link v-if="!clockin" left="29.2%" top="51%" width="4%" height="10%" dot-class="dot-top-center"  data-tgt="pullout-clock-in" @click.native="$screens.showPullout"></screen-link>
        <screen-link v-else left="29.2%" top="51%" width="4%" height="10%" dot-class="dot-top-center" data-tgt="time-clockin" @click.native="$emit('clock','')"></screen-link>
        <screen-link left="35.2%" top="51%" width="4%" height="10%" dot-class="dot-top-center" data-tgt="time-transfer" @click.native="$emit('clock','meal')"></screen-link>
        <screen-link left="41.2%" top="51%" width="4%" height="10%" dot-class="dot-top-center" data-tgt="time-callback" @click.native="$emit('clock','break')"></screen-link>
        <screen-link left="47.2%" top="51%" width="4%" height="10%" dot-class="dot-top-center" data-tgt="pullout-clock-in" data-init-info="transfer" @click.native="$screens.showPullout"></screen-link>

        <screen-link left="66%" top="63%" width="10%" height="4%" dot-class="dot-right-center" data-tgt="time-calendar" data-tgt-scrolltop="true" @click.native="onLinkClick"></screen-link>

        <screen-link left="90.5%" top="79.5%" width="6%" height="4%" dot-class="dot-right-center" data-tgt="time-timesheet" data-tgt-scrolltop="true" @click.native="onLinkClick"></screen-link>
        <company-tab :company="$store.state.company" select-tgt="time-overview"/>
      </div>
    </div>



    <div class="screen" data-screen="time-timesheet" :data-active="currentScreen=='time-timesheet'">
      <img v-if="$store.state.company === 'pro'" class="w-full max-w-100" src="/img/screens/employee/time-attendance/time-attendance-timesheet-pro.png?v=10122022" />
      <img v-else class="w-full max-w-100" src="/img/screens/employee/time-attendance/time-attendance-timesheet.png?v=10122022" />
      <div class="links">
        <screen-link left="14.5%" top="17.5%" width="5.5%" height="5%" dot-class="dot-bottom-center" data-tgt="time-timesheet" @click.native="onLinkClick"></screen-link>
        <screen-link left="20%" top="17.5%" width="4.5%" height="5%" dot-class="dot-bottom-center" data-tgt="time-calendar" @click.native="onLinkClick"></screen-link>
        <screen-link left="24.5%" top="17.5%" width="7%" height="5%" dot-class="dot-bottom-center" data-tgt="time-status" @click.native="onLinkClick"></screen-link>
        <screen-link left="86.25%" top="41.5%" width="5.5%" height="4.5%" dot-class="dot-bottom-center" data-tgt="time-overview" @click.native="onLinkClick"></screen-link>

        <screen-link left="29%" top="57%" width="7%" height="5%" data-tgt="time-timesheet-entry" @click.native="onLinkClick"></screen-link>
        <company-tab :company="$store.state.company" select-tgt="time-overview"/>
      </div>
    </div>

    <div class="screen" data-screen="time-timesheet-entry" :data-active="currentScreen=='time-timesheet-entry'">
      <img v-if="$store.state.company === 'pro'" class="w-full max-w-100" src="/img/screens/employee/time-attendance/time-attendance-timesheet-entry-pro.png?v=10122022" />
      <img v-else class="w-full max-w-100" src="/img/screens/employee/time-attendance/time-attendance-timesheet-entry.png?v=10122022" />
      <div class="links">
        <screen-link left="14.5%" top="17.5%" width="5.5%" height="5%" dot-class="dot-bottom-center" data-tgt="time-timesheet" @click.native="onLinkClick"></screen-link>
        <screen-link left="20%" top="17.5%" width="4.5%" height="5%" dot-class="dot-bottom-center" data-tgt="time-calendar" @click.native="onLinkClick"></screen-link>
        <screen-link left="24.5%" top="17.5%" width="7%" height="5%" dot-class="dot-bottom-center" data-tgt="time-status" @click.native="onLinkClick"></screen-link>
        <screen-link left="86.25%" top="41.5%" width="5.5%" height="4.5%" dot-class="dot-bottom-center" data-tgt="time-overview" @click.native="onLinkClick"></screen-link>


        <screen-link left="41.5%" top="56.5%" width="5.5%" height="5%" data-tgt="time-timesheet-entry-in" @click.native="onLinkClick"></screen-link>
        <company-tab :company="$store.state.company" select-tgt="time-overview"/>
      </div>
    </div>

    <div class="screen" data-screen="time-timesheet-entry-hours" :data-active="currentScreen=='time-timesheet-entry-in'">
      <img v-if="$store.state.company === 'pro'" class="w-full max-w-100" src="/img/screens/employee/time-attendance/time-attendance-timesheet-entry-in-pro.png?v=10122022" />
      <img v-else class="w-full max-w-100" src="/img/screens/employee/time-attendance/time-attendance-timesheet-entry-in.png?v=10122022" />
      <div class="links">
        <screen-link left="14.5%" top="17.5%" width="5.5%" height="5%" dot-class="dot-bottom-center" data-tgt="time-timesheet" @click.native="onLinkClick"></screen-link>
        <screen-link left="20%" top="17.5%" width="4.5%" height="5%" dot-class="dot-bottom-center" data-tgt="time-calendar" @click.native="onLinkClick"></screen-link>
        <screen-link left="24.5%" top="17.5%" width="7%" height="5%" dot-class="dot-bottom-center" data-tgt="time-status" @click.native="onLinkClick"></screen-link>
        <screen-link left="86.25%" top="41.5%" width="5.5%" height="4.5%" dot-class="dot-bottom-center" data-tgt="time-overview" @click.native="onLinkClick"></screen-link>



        <screen-link left="48.5%" top="56.5%" width="6%" height="5%" dot-class="dot-right-center" data-tgt="time-timesheet-entry-out" @click.native="onLinkClick"></screen-link>
        <company-tab :company="$store.state.company" select-tgt="time-overview"/>
      </div>
    </div>

    <div class="screen" data-screen="time-timesheet-entry-hours" :data-active="currentScreen=='time-timesheet-entry-out'">
      <img v-if="$store.state.company === 'pro'" class="w-full max-w-100" src="/img/screens/employee/time-attendance/time-attendance-timesheet-entry-out-pro.png?v=10122022" />
      <img v-else class="w-full max-w-100" src="/img/screens/employee/time-attendance/time-attendance-timesheet-entry-out.png?v=10122022" />
      <div class="links">
        <screen-link left="14.5%" top="17.5%" width="5.5%" height="5%" dot-class="dot-bottom-center" data-tgt="time-timesheet" @click.native="onLinkClick"></screen-link>
        <screen-link left="20%" top="17.5%" width="4.5%" height="5%" dot-class="dot-bottom-center" data-tgt="time-calendar" @click.native="onLinkClick"></screen-link>
        <screen-link left="24.5%" top="17.5%" width="7%" height="5%" dot-class="dot-bottom-center" data-tgt="time-status" @click.native="onLinkClick"></screen-link>
        <screen-link left="86.25%" top="41.5%" width="5.5%" height="4.5%" dot-class="dot-bottom-center" data-tgt="time-overview" @click.native="onLinkClick"></screen-link>
        <screen-link left="92%" top="41.5%" width="5.5%" height="4.5%" dot-class="dot-bottom-center" data-tgt="time-overview-edited" @click.native="onLinkClick"></screen-link>


        <screen-link v-if="$store.state.company === 'pro'" left="55%" top="56.5%" width="13%" height="5%" dot-class="dot-right-center" data-tgt-scrolltop="true" data-tgt="pullout-clock-in" data-init-info="add-details" @click.native="$screens.showPullout"></screen-link>
        <screen-link v-else left="55%" top="56.5%" width="4%" height="5%" dot-class="dot-right-center" data-tgt-scrolltop="true" data-tgt="pullout-clock-in" data-init-info="add-details" @click.native="$screens.showPullout"></screen-link>

        <company-tab :company="$store.state.company" select-tgt="time-overview"/>
      </div>
    </div>

    <div class="screen" data-screen="time-timesheet-entry-details" :data-active="currentScreen=='time-timesheet-entry-details'">
      <img v-if="$store.state.company === 'pro'" class="w-full max-w-100" src="/img/screens/employee-time-attendance-timesheet-entry-details-pro.png?v=10122022" />
      <img v-else class="w-full max-w-100" src="/img/screens/employee-time-attendance-timesheet-entry-details.png?v=10122022" />
      <div class="links">


        <screen-link left="89%" top="7%" width="5%" height="5%" dot-class="dot-bottom-center" data-tgt="time-timesheet-entry-hours" @click.native="onLinkClick"></screen-link>
        <screen-link left="94.5%" top="7%" width="4.5%" height="5%" dot-class="dot-bottom-center" data-tgt="time-timesheet-entry-hours" @click.native="onLinkClick"></screen-link>
        <company-tab :company="$store.state.company" select-tgt="time-overview"/>
      </div>
    </div>


    <div class="screen" data-screen="time-calendar" :data-active="currentScreen=='time-calendar'">
      <img class="w-full max-w-100" src="/img/screens/employee/time-attendance/time-attendance-calendar.png?v=10122022" />
      <div class="links">
        <screen-link left="14.5%" top="17.5%" width="5.5%" height="5%" dot-class="dot-bottom-center" data-tgt="time-overview" @click.native="onLinkClick"></screen-link>
        <screen-link left="20%" top="17.5%" width="4.5%" height="5%" dot-class="dot-bottom-center" data-tgt="time-calendar" @click.native="onLinkClick"></screen-link>
        <screen-link left="24.5%" top="17.5%" width="7%" height="5%" dot-class="dot-bottom-center" data-tgt="time-status" @click.native="onLinkClick"></screen-link>


        <screen-link left="86%" top="44.5%" width="7.5%" height="4%" dot-class="dot-bottom-center" data-tgt="pullout-time-off" @click.native="$screens.showPullout"></screen-link>
        <screen-link left="69.5%" top="133%" width="10%" height="5%" dot-class="dot-top-center" data-tgt="pullout-time-off" data-init-info="calendar-settings" @click.native="$screens.showPullout"></screen-link>
        <company-tab :company="$store.state.company" select-tgt="time-overview"/>
      </div>
    </div>


    <div class="screen" data-screen="time-status" :data-active="currentScreen=='time-status'">
      <img class="w-full max-w-100" src="/img/screens/employee/time-attendance/time-attendance-status-board.png?v=10122022" />
      <div class="links">

        <screen-link left="14.5%" top="17.5%" width="5.5%" height="5%" dot-class="dot-bottom-center" data-tgt="time-overview" @click.native="onLinkClick"></screen-link>
        <screen-link left="20%" top="17.5%" width="4.5%" height="5%" dot-class="dot-bottom-center" data-tgt="time-calendar" @click.native="onLinkClick"></screen-link>
        <screen-link left="24.5%" top="17.5%" width="7%" height="5%" dot-class="dot-bottom-center" data-tgt="time-status" @click.native="onLinkClick"></screen-link>

        <company-tab :company="$store.state.company" select-tgt="time-overview"/>

      </div>
    </div>

  </div>
</template>

<script>

export default {
  name: "EmployeeTimeScreens",
  props: {
    onLinkClick: {},
    showPullout: {},
    currentScreen: {
      default: ""
    }
  },
  computed: {
    clockin: function(){
      return this.$store.state.clockin;
    }
  }
}
</script>

<style scoped>

.clock-box {
  position: absolute;
  width: calc(430/1580 * 100%);
}

.clock-box img {
  width: 100%;
  display: block;
}

</style>