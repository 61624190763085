<template>

  <div class="demo-frame">
    <FlexLayout ref="layout" default-screen="dashboard" environment="employee" :lang="currentLang">
      <template #screens>

        <div v-if="$store.state.company === 'pro'" class="screen" data-screen="dashboard" :data-active="currentScreen == 'dashboard'">
          <img class="w-full max-w-100" src="/img/screens/employee/dashboard/dashboard-pro.png?v=05062024" />
          <div class="links">
            <div v-if="clockin === 'working'" class="clock-box" style="left:27.1%; top: 20.8%;">
              <img  src="/img/screens/employee/dashboard/cutouts/clock-in-working-pro.png?v=10122022" />
            </div>
            <div v-if="clockin === 'meal'" class="clock-box" style="left:27.1%; top: 20.8%;">
              <img  src="/img/screens/employee/dashboard/cutouts/clock-in-meal-pro.png?v=10122022" />
            </div>
            <div v-if="clockin === 'break'" class="clock-box" style="left:27.1%; top: 20.8%;">
              <img  src="/img/screens/employee/dashboard/cutouts/clock-in-break-pro.png?v=10122022" />
            </div>

            <screen-link v-if="!clockin" left="29.2%" top="48%" width="4%" height="10%" dot-class="dot-top-center" data-tgt="pullout-clock-in" @click.native="$screens.showPullout"></screen-link>
            <screen-link v-else left="29.2%" top="48%" width="4%" height="10%" dot-class="dot-top-center" data-tgt="dashboard-clock-in" @click.native="toggleClock('')"></screen-link>
            <screen-link left="34.75%" top="48%" width="4%" height="10%" dot-class="dot-top-center" data-tgt="dashboard-transfer" @click.native="toggleClock('meal')"></screen-link>
            <screen-link left="40.5%" top="48%" width="4%" height="10%" dot-class="dot-top-center" data-tgt="dashboard-callback" @click.native="toggleClock('break')"></screen-link>




            <screen-link left="50%" top="32%" width="4.5%" height="4%" dot-class="dot-right-center" data-tgt="pullout-check-stubs" data-init-info="check-details" data-tgt-scrolltop="true" @click.native="$screens.showPullout"></screen-link>
            <screen-link left="59%" top="42.75%" width="6.5%" height="4%" dot-class="dot-right-center" data-tgt="/#/payactiv/home" data-tgt-query="true" @click.native="$screens.showExternalPage"></screen-link>
            <screen-link left="52.5%" top="49.5%" width="10%" height="4%" dot-class="dot-right-center" data-tgt="pullout-check-stubs" data-tgt-scrolltop="true" @click.native="$screens.showPullout"></screen-link>








            <screen-link left="33%" top="92%" width="8%" height="4%"  dot-class="dot-right-center" data-tgt="https://demo.paychex.com/benefits/enrollment" @click.native="$screens.showExternalPage"></screen-link>
            <screen-link left="72.5%" top="40.5%" width="10%" height="4%"  dot-class="dot-right-center" data-tgt="/#/engage/dashboard" data-tgt-query="true" @click.native="$screens.showExternalPage"></screen-link>
            <screen-link left="73%" top="108.5%" width="9%" height="4%"  dot-class="dot-right-center" data-tgt="retirement-enrollment-begin" @click.native="onLinkClick"></screen-link>

            <screen-link left="68%" top="115%" width="19.25%" height="10%"  dot-class="dot-right-center" data-tgt="/#/training/hr-training" data-tgt-query="true" @click.native="$screens.showExternalPage"></screen-link>

            <screen-link left="53%" top="73.5%" width="9%" height="4%"  dot-class="dot-right-center" data-tgt="/#/finfit/finfit-home" data-tgt-query="true" @click.native="$screens.showExternalPage"></screen-link>

            <screen-link left="54.25%" top="133.5%" width="6%" height="5.5%" dot-class="dot-top-center" data-tgt="mobile-dashboard-trip" data-tgt-mode="mobile" data-tgt-scrolltop="true" data-tgt-scroll-y="bottom" @click.native="onLinkClick"></screen-link>

            <company-tab :company="$store.state.company" select-tgt="dashboard"/>

            <info-box v-if="!$store.state.pulloutPanel && !$store.state.overlayPanel" left="97.5%" top="10.5%" width="40em" height="4.5%" >
              <p>The employee view has a dashboard that gives access to basic information – including employee time off, past check stub and the option to clock in and out if their employers uses Paychex Flex® Time. With quick access to training, HR conversations and contacts, giving employees access reduces time that admins spend answering questions and providing information to employees.</p>
            </info-box>



          </div>
        </div>

        <div v-else class="screen" data-screen="dashboard" :data-active="currentScreen == 'dashboard'">
          <img class="w-full max-w-100" src="/img/screens/employee/dashboard/dashboard-enterprise.png?v=05062024" />
          <div class="links">
            <div v-if="clockin === 'working'" class="clock-box" style="left:27.1%; top: 20.8%;">
              <img  src="/img/screens/employee/dashboard/cutouts/clock-in-working.png?v=10122022" />
            </div>
            <div v-if="clockin === 'meal'" class="clock-box" style="left:27.1%; top: 20.8%;">
              <img  src="/img/screens/employee/dashboard/cutouts/clock-in-meal.png?v=10122022" />
            </div>
            <div v-if="clockin === 'break'" class="clock-box" style="left:27.1%; top: 20.8%;">
              <img  src="/img/screens/employee/dashboard/cutouts/clock-in-break.png?v=10122022" />
            </div>

            <screen-link v-if="!clockin" left="28.5%" top="48%" width="4%" height="10%" dot-class="dot-top-center" data-tgt="pullout-clock-in" @click.native="$screens.showPullout"></screen-link>
            <screen-link v-else left="28.5%" top="48%" width="4%" height="10%" dot-class="dot-top-center" data-tgt="dashboard-clock-in" @click.native="toggleClock('')"></screen-link>
            <screen-link left="32.75%" top="48%" width="4%" height="10%" dot-class="dot-top-center" data-tgt="dashboard-transfer" @click.native="toggleClock('meal')"></screen-link>
            <screen-link left="37.1%" top="48%" width="4%" height="10%" dot-class="dot-top-center" data-tgt="dashboard-callback" @click.native="toggleClock('break')"></screen-link>
            <screen-link left="41.3%" top="48%" width="4%" height="10%" dot-class="dot-top-center" data-tgt="pullout-clock-in" data-init-info="transfer" @click.native="$screens.showPullout"></screen-link>

            <screen-link left="54%" top="172.75%" width="6%" height="4%" dot-class="dot-right-center" data-tgt="pullout-time-off" data-tgt-scrolltop="true" @click.native="$screens.showPullout"></screen-link>

            <screen-link left="70.5%" top="58.5%" width="4.5%" height="3%" dot-class="dot-right-center" data-tgt="pullout-check-stubs" data-init-info="check-details" data-tgt-scrolltop="true" @click.native="$screens.showPullout"></screen-link>
            <screen-link left="79.5%" top="69%" width="6.5%" height="4%" dot-class="dot-right-center" data-tgt="/#/payactiv/home" data-tgt-query="true" @click.native="$screens.showExternalPage"></screen-link>
            <screen-link left="72.5%" top="75.5%" width="10%" height="4%" dot-class="dot-right-center" data-tgt="pullout-check-stubs" data-tgt-scrolltop="true" @click.native="$screens.showPullout"></screen-link>


            <div style="font-size:1.5em;  background-color: white; position: absolute; left:54.5%; top:106.3%; width: 5%; text-align: center;">{{ $store.state.uploadedVaccination?'18':'19' }}</div>
            <screen-link left="54.5%" top="115%" width="5%" height="4%"  :data-tgt="$store.state.uploadedVaccination?'dashboard-tasks-updated':'dashboard-tasks'" @click.native="onLinkClick"></screen-link>

            <screen-link left="81.5%" top="83.5%" width="5%" height="4%"  data-tgt="pullout-conversations" data-init-info="add-note" @click.native="$screens.showPullout"></screen-link>
            <screen-link left="74.5%" top="115.5%" width="6%" height="4%"  data-tgt="pullout-conversations" @click.native="$screens.showPullout"></screen-link>

            <screen-link left="49.5%" top="133%" width="6%" height="4%"  data-tgt="dashboard-my-issues" @click.native="onLinkClick"></screen-link>
            <screen-link left="58%" top="133%" width="8%" height="4%"  data-tgt="pullout-submit-issue" @click.native="$screens.showPullout"></screen-link>

            <screen-link left="33%" top="116.5%" width="8%" height="4%"  dot-class="dot-right-center" data-tgt="https://demo.paychex.com/benefits/enrollment" @click.native="$screens.showExternalPage"></screen-link>
            <screen-link left="72.5%" top="40.5%" width="10%" height="4%"  dot-class="dot-right-center" data-tgt="/#/engage/dashboard" data-tgt-query="true" @click.native="$screens.showExternalPage"></screen-link>

            <screen-link left="53%" top="82%" width="9%" height="4%"  dot-class="dot-right-center" data-tgt="retirement-enrollment-begin" @click.native="onLinkClick"></screen-link>

            <screen-link left="47.5%" top="139.5%" width="19.25%" height="10%"  dot-class="dot-right-center" data-tgt="/#/training/hr-training" data-tgt-query="true" @click.native="$screens.showExternalPage"></screen-link>

            <screen-link left="33%" top="82%" width="9%" height="4%"  dot-class="dot-right-center" data-tgt="/#/finfit/finfit-home" data-tgt-query="true" @click.native="$screens.showExternalPage"></screen-link>

            <screen-link left="74.5%" top="164%" width="6%" height="5.5%" dot-class="dot-top-center" data-tgt="mobile-dashboard-trip" data-tgt-mode="mobile" data-tgt-scrolltop="true" data-tgt-scroll-y="bottom" @click.native="onLinkClick"></screen-link>

            <company-tab :company="$store.state.company" select-tgt="dashboard"/>

            <info-box v-if="!$store.state.pulloutPanel && !$store.state.overlayPanel" left="97.5%" top="10.5%" width="40em" height="4.5%" >
              <p>The employee view has a dashboard that gives access to basic information – including employee time off, past check stub and the option to clock in and out if their employers uses Paychex Flex® Time. With quick access to training, HR conversations and contacts, giving employees access reduces time that admins spend answering questions and providing information to employees.</p>
            </info-box>



          </div>
        </div>



        <div class="screen" data-screen="dashboard-spanish" :data-active="currentScreen == 'dashboard-spanish'">
          <img class="w-full max-w-100" src="/img/screens/spanish/employee-dashboard-spanish.png?v=10122022" />
          <div class="links">
          </div>
        </div>

        <div class="link-capture" :data-active="currentScreen == 'dashboard-spanish'" >
          <div class="link-capture-notice" @click="onLanguageChange('en')"><span>Back to English</span></div>
        </div>

        <!--- HR ISSUES -->



        <div class="screen" data-screen="dashboard-my-issues" :data-active="currentScreen=='dashboard-my-issues'">
          <img class="w-full max-w-100" src="/img/screens/employee/dashboard/my-issues.png?v=10122022" />
          <div class="links">

            <screen-link left="15%" top="13.5%" width="8%" height="5%" dot-class="dot-right-center" data-tgt="dashboard" @click.native="onLinkClick"></screen-link>
            <screen-link left="15%" top="46%" width="84%" height="7%" dot-class="dot-right-center" data-tgt="pullout-review-issues" @click.native="$screens.showPullout"></screen-link>
            <screen-link left="15%" top="53%" width="84%" height="7%" dot-class="dot-right-center" data-tgt="pullout-review-issues" data-init-info="michael" @click.native="$screens.showPullout"></screen-link>
            <company-tab :company="$store.state.company" select-tgt="dashboard"/>

          </div>
        </div>


        <!-- END HR -->


        <!--- TIMEOFF -->

        <!-- END TIMEOFF -->

        <!--- BEGIN CHECK DETAILS -->
        <!-- END CHECK DETAILS -->

        <!--- BEGIN CONVERSATIONS -->

        <!-- END CONVERSATIONS -->

        <!-- BEGIN TIMECLOCK -->


        <div class="screen" data-screen="dashboard-clock-in-attestation-questions" :data-active="currentScreen=='dashboard-clock-in-attestation-questions'">
          <img class="w-full max-w-100" src="/img/screens/employee-dashboard-clock-in-attestation-questions.png?v=10122022" />
          <div class="links">
            <screen-link left="87.5%" top="7%" width="5.75%" height="5%" dot-class="dot-bottom-center" data-tgt="dashboard" @click.native="onLinkClick"></screen-link>
            <screen-link left="64%" top="39%" width="35%" height="5%" data-tgt="dashboard-clock-in-attestation-questions-answer" @click.native="onLinkClick"></screen-link>
          </div>
        </div>

        <div class="screen" data-screen="dashboard-clock-in-attestation-questions-answer" :data-active="currentScreen=='dashboard-clock-in-attestation-questions-answer'">
          <img class="w-full max-w-100" src="/img/screens/employee-dashboard-clock-in-attestation-questions-answer.png?v=10122022" />
          <div class="links">
            <screen-link left="87.5%" top="7%" width="5.75%" height="5%" dot-class="dot-bottom-center" data-tgt="dashboard" @click.native="onLinkClick"></screen-link>
            <screen-link left="64%" top="41%" width="35%" height="5%" data-tgt="dashboard-clock-in-attestation-questions-answered" @click.native="onLinkClick"></screen-link>
          </div>
        </div>

        <div class="screen" data-screen="dashboard-clock-in-attestation-questions-answered" :data-active="currentScreen=='dashboard-clock-in-attestation-questions-answered'">
          <img class="w-full max-w-100" src="/img/screens/employee-dashboard-clock-in-attestation-questions-answered.png?v=10122022" />
          <div class="links">
            <screen-link left="87.5%" top="7%" width="5.75%" height="5%" dot-class="dot-bottom-center" data-tgt="dashboard" @click.native="onLinkClick"></screen-link>
            <screen-link left="93.25%" top="7%" width="5.75%" height="5%" dot-class="dot-bottom-center" data-tgt="dashboard-clocked-in" @click.native="onLinkClick"></screen-link>
          </div>
        </div>

        <div class="screen" data-screen="dashboard-tasks" :data-active="currentScreen=='dashboard-tasks'">
          <img class="w-full max-w-100" src="/img/screens/employee/dashboard/tasks.png?v=10122022" />
          <div class="links">
            <screen-link dot-class="dot-right-center" left="15%" top="14%" width="8%" height="4%" data-tgt="dashboard" @click.native="onLinkClick"></screen-link>
            <screen-link dot-class="dot-right-center" left="29%" top="32%" width="57%" height="6%" data-tgt="dashboard-tasks-performance" @click.native="onLinkClick"></screen-link>
            <screen-link dot-class="dot-right-center" left="29%" top="40%" width="57%" height="6%" data-tgt="dashboard-tasks-documents" @click.native="onLinkClick"></screen-link>
            <screen-link dot-class="dot-right-center" left="29%" top="47.75%" width="57%" height="6%" data-tgt="dashboard-tasks-pay" @click.native="onLinkClick"></screen-link>
            <company-tab :company="$store.state.company" select-tgt="dashboard"/>
          </div>
        </div>

        <div class="screen" data-screen="dashboard-tasks-pay" :data-active="currentScreen=='dashboard-tasks-pay'">
          <img class="w-full max-w-100" src="/img/screens/employee/dashboard/tasks-pay.png?v=10122022" />
          <div class="links">
            <screen-link dot-class="dot-right-center" left="15%" top="13%" width="5%" height="3.5%" data-tgt="dashboard-tasks" @click.native="onLinkClick"></screen-link>
            <screen-link dot-class="dot-right-center" left="28%" top="36%" width="45%" height="5%" data-tgt="pullout-employee-tasks" data-init-info="i9" @click.native="$screens.showPullout"></screen-link>
            <company-tab :company="$store.state.company" select-tgt="dashboard"/>
          </div>
        </div>

        <div class="screen" data-screen="dashboard-tasks-updated" :data-active="currentScreen=='dashboard-tasks-updated'">
          <img class="w-full max-w-100" src="/img/screens/employee-dashboard-tasks-updated.png?v=10122022" />
          <div class="links">
            <screen-link dot-class="dot-right-center" left="15%" top="14%" width="8%" height="4%" data-tgt="dashboard" @click.native="onLinkClick"></screen-link>
            <screen-link dot-class="dot-right-center" left="29%" top="32%" width="57%" height="6%" data-tgt="dashboard-tasks-documents-updated" @click.native="onLinkClick"></screen-link>
            <screen-link dot-class="dot-right-center" left="29%" top="40%" width="57%" height="6%" data-tgt="dashboard-tasks-performance" @click.native="onLinkClick"></screen-link>
            <company-tab :company="$store.state.company" select-tgt="dashboard"/>
          </div>
        </div>

        <div class="screen" data-screen="dashboard-tasks-documents" :data-active="currentScreen=='dashboard-tasks-documents'">
          <img class="w-full max-w-100" src="/img/screens/employee/dashboard/tasks-documents.png?v=10122022" />
          <div class="links">
            <screen-link dot-class="dot-right-center" left="15%" top="13%" width="5%" height="3.5%" data-tgt="dashboard-tasks" @click.native="onLinkClick"></screen-link>
            <screen-link dot-class="dot-right-center" left="79.8%" top="42.5%" width="6%" height="5%" data-tgt="pullout-doc-upload" @click.native="$screens.showPullout"></screen-link>
            <company-tab :company="$store.state.company" select-tgt="dashboard"/>
          </div>
        </div>

        <div class="screen" data-screen="dashboard-tasks-documents" :data-active="currentScreen=='dashboard-tasks-performance'">
          <img class="w-full max-w-100" src="/img/screens/employee/dashboard/tasks-performance.png?v=10122022" />
          <div class="links">
            <screen-link dot-class="dot-right-center" left="15%" top="14%" width="5%" height="3.5%" data-tgt="dashboard-tasks" @click.native="onLinkClick"></screen-link>
            <company-tab :company="$store.state.company" select-tgt="dashboard"/>
          </div>
        </div>



        <div class="screen" data-screen="performance" :data-active="currentScreen=='performance'">
          <img class="w-full max-w-100" src="/img/screens/employee/performance/performance.png?v=10122022" />
          <div class="links">
            <company-tab :company="$store.state.company" select-tgt="performance"/>
          </div>
        </div>

        <div class="screen" data-screen="locations" :data-active="currentScreen=='locations'">
          <img class="w-full max-w-100" src="/img/screens/employee/locations/locations.png?v=10122022" />
          <div class="links">
            <company-tab :company="$store.state.company" select-tgt="locations"/>
          </div>
        </div>

        <div class="screen" data-screen="dashboard-tasks-documents-updated" :data-active="currentScreen=='dashboard-tasks-documents-updated'">
          <img class="w-full max-w-100" src="/img/screens/employee/documents/my-documents-tasks-uploaded.png?v=10122022" />
          <div class="links">
            <screen-link dot-class="dot-right-center" left="15%" top="13%" width="5%" height="3.5%" data-tgt="dashboard-tasks-updated" @click.native="onLinkClick"></screen-link>

            <screen-link dot-class="dot-right-center" left="83.5%" top="93%" width="3%" height="5%" data-tgt="" @click.native="$screens.setOverlayPanel('documentListOptions')"></screen-link>
            <document-list-options-panel left="75%" v-if="overlayPanel === 'documentListOptions'" top="93%" width="13%" @close="$screens.setOverlayPanel('')" @link="onLinkClick" selection=""></document-list-options-panel>

            <company-tab :company="$store.state.company" select-tgt="mydocs"/>


          </div>
        </div>

        <div class="screen" data-screen="mydocs" :data-active="currentScreen=='mydocs'">
          <img class="w-full max-w-100" src="/img/screens/employee/documents/my-documents.png?v=10122022" />
          <div class="links">
            <info-box v-if="!pulloutPanel && !overlayPanel" left="97.5%" top="10.5%" width="40em" height="4.5%" >
              <p>All documents the company chooses can be housed in the My Documents module and shows employees what needs attention and action.</p>
            </info-box>
            <screen-link dot-class="dot-right-center" left="79.8%" top="42.5%" width="6%" height="5%" data-tgt="pullout-doc-upload" @click.native="$screens.showPullout"></screen-link>
            <company-tab :company="$store.state.company" select-tgt="mydocs"/>
          </div>
        </div>

        <div class="screen" data-screen="mydocs-updated" :data-active="currentScreen=='mydocs-updated'">
          <img class="w-full max-w-100" src="/img/screens/employee/documents/my-documents-uploaded.png?v=10122022" />
          <div class="links">
            <info-box v-if="!pulloutPanel && !overlayPanel" left="97.5%" top="10.5%" width="40em" height="4.5%" >
              <p>All documents the company chooses can be housed in the My Documents module and shows employees what needs attention and action.</p>
            </info-box>

            <company-tab :company="$store.state.company" select-tgt="mydocs"/>
          </div>
        </div>

        <div class="screen" data-screen="myprofile" :data-active="currentScreen=='myprofile'">
          <img class="w-full max-w-100" src="/img/screens/employee/profile/my-profile.png?v=10122022" />
          <div class="links">
            <info-box left="97.5%" top="10.5%" width="40em" height="4.5%" >
              <p>In the My Profile tab employees can view their pay and HR information, add emergency contacts and more.</p>
            </info-box>
            <company-tab :company="$store.state.company" select-tgt="myprofile"/>
          </div>
        </div>

        <div class="screen" data-screen="directory" :data-active="currentScreen=='directory'">
          <img class="w-full max-w-100" src="/img/screens/employee/directory/company-directory.png?v=10122022" />
          <div class="links">
            <screen-link left="32.5%" top="34.5%" width="8%" height="3%"  dot-class="dot-right-center" data-tgt="pullout-directory-profile" @click.native="$screens.showPullout"></screen-link>
            <info-box v-if="!$store.state.pulloutPanel && !$store.state.overlayPanel" left="97.5%" top="10.5%" width="40em" height="4.5%" >
              <p>In Company Directory view, clients can see full listing of active employees, with contact information and quick search functionality. Employees can also be accessed in the People List – giving a full profile view. Admins can edit information from either location, and when employees have access to Paychex Flex®, they can make their own changes, eliminating time spent for business owners and managers.</p>
            </info-box>
            <company-tab :company="$store.state.company" select-tgt="directory"/>
          </div>
        </div>



        <div class="screen" data-screen="help-center" :data-active="currentScreen=='help-center'">
          <img class="w-full max-w-100" src="/img/screens/employee-help-center.png?v=10122022" />
          <div class="links">

            <screen-link left="44.5%" top="49%" width="25%" height="11%"  dot-class="dot-right-center" data-tgt="help-center-employment-details" @click.native="onLinkClick"></screen-link>

            <info-box left="97.5%" top="10.5%" width="40em" height="4.5%" >
              <p>Employees have access to our Help Center, where they can search for how to complete tasks on their own and access information they need. </p>
            </info-box>
          </div>
        </div>

        <div class="screen" data-screen="help-center-employment-details" :data-active="currentScreen=='help-center-employment-details'">
          <img class="w-full max-w-100" src="/img/screens/employee-help-employment-details.png?v=10122022" />
          <div class="links">
            <screen-link left="15%" top="23%" width="8%" height="3%"  dot-class="dot-right-center" data-tgt="help-center" @click.native="onLinkClick"></screen-link>



          </div>
        </div>

        <div class="screen" data-screen="hr-overview" :data-active="currentScreen=='hr-overview'">
          <img class="w-full max-w-100" src="/img/screens/employee-hr.png?v=10122022" />
          <div class="links">
            <info-box left="97.5%" top="10.5%" width="40em" height="4.5%" >
              <p>Through the Human Resources tab, users can view their total compensation summary, handbook acknowledgments and important documents.</p>
            </info-box>
          </div>
        </div>

        <EmployeeMyPerksScreens v-if="currentModule == 'myperks'" :onLinkClick="onLinkClick" :showPullout="$screens.showPullout" :current-screen="currentScreen"></EmployeeMyPerksScreens>
        <EmployeeMyPayScreens v-if="currentModule == 'mypay'" :onLinkClick="onLinkClick" :showPullout="$screens.showPullout" :current-screen="currentScreen"></EmployeeMyPayScreens>

        <EmployeeTimeScreens v-if="currentModule == 'time'" @clock="toggleClock" :onLinkClick="onLinkClick" :showPullout="$screens.showPullout" :current-screen="currentScreen"></EmployeeTimeScreens>
        <EmployeeHealthBenefitsScreens v-if="currentModule == 'hb'" :onLinkClick="onLinkClick" :showPullout="$screens.showPullout" :current-screen="currentScreen"></EmployeeHealthBenefitsScreens>
        <EmployeeRetirementScreens v-if="currentModule == 'retirement'" :onLinkClick="onLinkClick" :showPullout="$screens.showPullout" :current-screen="currentScreen" :employeeState="employeeState"></EmployeeRetirementScreens>

      </template>
      <template #pullouts>

        <check-stubs-pullout class="" v-if="$store.state.pulloutPanel=='pullout-check-stubs'" @close="$screens.closePullout" @link="onLinkClick"></check-stubs-pullout>
        <vaccine-card-upload-pullout class="" v-if="$store.state.pulloutPanel=='pullout-doc-upload'" :current-screen="currentScreen" @close="$screens.closePullout" @link="onLinkClick"></vaccine-card-upload-pullout>
        <clock-in-pullout v-if="$store.state.pulloutPanel=='pullout-clock-in'" @close="$screens.closePullout" @link="onLinkClick"/>
        <conversations-pullout v-if="$store.state.pulloutPanel=='pullout-conversations'" @close="$screens.closePullout" @link="onLinkClick" />
        <submit-issue-pullout  v-if="$store.state.pulloutPanel=='pullout-submit-issue'" @close="$screens.closePullout" @link="onLinkClick" />
        <review-issues-pullout v-if="$store.state.pulloutPanel=='pullout-review-issues'" @close="$screens.closePullout" @link="onLinkClick" />
        <time-off-pullout v-if="$store.state.pulloutPanel=='pullout-time-off'" @close="$screens.closePullout" @link="onLinkClick" />
        <directory-profile-pullout v-if="$store.state.pulloutPanel=='pullout-directory-profile'" @close="$screens.closePullout" @link="onLinkClick" />
        <benefits-family-pullout v-if="$store.state.pulloutPanel=='pullout-benefits-family'" @close="$screens.closePullout" @link="onLinkClick" />

        <EstimatePaymentPullout v-if="$store.state.pulloutPanel=='pullout-estimate-payment'" @close="$screens.closePullout" @link="onLinkClick" :employeeState="employeeState" />
        <LegalInfoPullout v-if="$store.state.pulloutPanel=='pullout-legal-info'" @close="$screens.closePullout" @link="onLinkClick" :employeeState="employeeState" />
        <DirectDepositPullout v-if="$store.state.pulloutPanel=='pullout-direct-deposit'" @close="$screens.closePullout" @link="onLinkClick" :employeeState="employeeState"></DirectDepositPullout>

        <RetirementContributionsPullout v-if="$store.state.pulloutPanel=='pullout-retirement-contributions'" @close="$screens.closePullout" @link="onLinkClick" :employeeState="employeeState" />
        <AddBeneficiaryPullout v-if="$store.state.pulloutPanel=='pullout-retirement-beneficiaries'" @close="$screens.closePullout" @link="onLinkClick" :employeeState="employeeState"></AddBeneficiaryPullout>
        <ManageInvestmentsPullout v-if="$store.state.pulloutPanel=='pullout-manage-investments'" @close="$screens.closePullout" @link="onLinkClick" :employeeState="employeeState" />
        <EmployeeTasksPullout  v-if="$store.state.pulloutPanel=='pullout-employee-tasks'" @close="$screens.closePullout" @link="onLinkClick" />

        <MyPerksPullout v-if="$store.state.pulloutPanel=='pullout-my-perks'" @close="$screens.closePullout" @link="onLinkClick"/>

        <notifications-pullout v-if="$store.state.overlayPanel == 'notifications'" @close="$screens.setOverlayPanel('')"></notifications-pullout>
        <preferences-pullout v-if="$store.state.pulloutPanel == 'preferences'" @close="$screens.closePullout()" @langChange="onLanguageChange" :lang="currentLang"></preferences-pullout>
        <news-pullout v-if="$store.state.overlayPanel == 'news'" @close="$screens.setOverlayPanel('')"></news-pullout>
        <profile-pullout v-if="$store.state.pulloutPanel === 'pullout-profile'" @close="$screens.closePullout"></profile-pullout>


      </template>



    </FlexLayout>
  </div>


</template>

<script>

import FlexLayout from "@/views/FlexLayout";

import NotificationsPullout from "@/components/NotificationsPullout";
import NewsPullout from "@/components/NewsPullout";

import ProfilePullout from "@/components/ProfilePullout";
//import HelpPullout from "@/components/HelpPullout";
import PreferencesPullout from "@/components/PreferencesPullout";
import EmployeeRetirementScreens from "@/views/EmployeeRetirementScreens";
import EmployeeMyPerksScreens from "@/views/EmployeeMyPerksScreens";

import RetirementContributionsPullout from "@/components/RetirementContributionsPullout";
import VaccineCardUploadPullout from "@/components/VaccineCardUploadPullout";
import CheckStubsPullout from "@/components/CheckStubsPullout";
import EmployeeTimeScreens from "@/views/EmployeeTimeScreens";
import EmployeeHealthBenefitsScreens from "@/views/EmployeeHealthBenefitsScreens";
import EmployeeMyPayScreens from "@/views/EmployeeMyPayScreens";
import AddBeneficiaryPullout from "@/components/AddBeneficiaryPullout";
import ManageInvestmentsPullout from "@/components/ManageInvestmentsPullout";
import DirectDepositPullout from "@/components/DirectDepositPullout";
import LegalInfoPullout from "@/components/LegalInfoPullout";
import EstimatePaymentPullout from "@/components/EstimatePaymentPullout";
import ClockInPullout from "@/components/ClockInPullout";
import ConversationsPullout from "@/components/ConversationsPullout";
import SubmitIssuePullout from "@/components/SubmitIssuePullout";
import ReviewIssuesPullout from "@/components/ReviewIssuesPullout";
import TimeOffPullout from "@/components/TimeOffPullout";
import DocumentListOptionsPanel from "@/components/DocumentListOptionsPanel";
import DirectoryProfilePullout from "@/components/DirectoryProfilePullout";
import BenefitsFamilyPullout from "@/components/BenefitsFamilyPullout";
import EmployeeTasksPullout from "@/components/EmployeeTasksPullout";

import MyPerksPullout from "@/components/MyPerksPullout";





export default {
  name: "EmployeeExperience",
  props: {
  },
  mounted() {
    if(!this.$store.state.hasShownMenuOnce){
      this.$store.state.showMenu = true;
      this.$store.state.hasShownMenuOnce = true;
    }
  },
  data:function(){
    return {
      currentLang: "en", //or es for spanish
      employeeSelections: [
        { label: "All Employees", tgt: "hb-employees-all"},
        { label: "Newly Eligible", tgt: "hb-employees-newly"},
        { label: "Annual Enrollment", tgt: "hb-employees-annual"},
        { label: "Current Enrollment", tgt: "hb-employees-current"},
        { label: "Future Enrollment", tgt: "hb-employees-future"},
        { label: "Missing Information", tgt: "hb-employees-missing"},

      ],

      planClassificationsMedical: [
        { label: "MANAGEMENT", tgt: "hb-plans-medical"},
        { label: "HOURLY", tgt: "hb-plans-medical-hourly"},
      ],
      employeeState: {
        retirement: {
          contribution: 2,
          autoIncrease: false,
          hasBeneficiary: false
        }
      }
    }
  },
  components: {
    MyPerksPullout,
    EmployeeTasksPullout,
    BenefitsFamilyPullout,
    DirectoryProfilePullout,
    DocumentListOptionsPanel,
    TimeOffPullout,
    ReviewIssuesPullout,
    SubmitIssuePullout,
    ConversationsPullout,
    ClockInPullout,
    EstimatePaymentPullout,
    LegalInfoPullout,
    DirectDepositPullout,
    ManageInvestmentsPullout,
    AddBeneficiaryPullout,
    EmployeeMyPayScreens,
    EmployeeHealthBenefitsScreens,
    EmployeeTimeScreens,
    CheckStubsPullout,
    VaccineCardUploadPullout,
    RetirementContributionsPullout,
    EmployeeRetirementScreens,
    PreferencesPullout,
    FlexLayout,
    ProfilePullout,
    NewsPullout,
    NotificationsPullout,
    EmployeeMyPerksScreens
  },
  methods: {
    toggleClock: function(clockType){

      console.log('clock', clockType)

      if(clockType){
        if(this.$store.state.clockin == clockType){
          this.$store.state.clockin = ""
        }else{
          this.$store.state.clockin = clockType;
        }
      }else{
        this.$store.state.clockin = ""
      }
    },
    onLanguageChange: function(newLang){
      console.log("lang change", newLang, this.currentLang)
      this.currentLang = newLang;
      if(newLang === 'es'){
        this.$store.dispatch("setScreen", {id:"dashboard-spanish", mode:"employee", scrollToTop:"true"});
      }else{
        this.$store.dispatch("setScreen", {id:"dashboard", mode:"employee", scrollToTop:"true"});
      }
    },

    onLinkClick: function(e){
      if(!e.target.dataset.tgtMode){
        e.target.dataset.tgtMode = "employee"
      }

      if(e.target.dataset.tgtScrolltop){

        if(this.$refs.layout?.$refs.screens){
          console.log("scrollTop")
          this.$refs.layout.$refs.screens.scrollTo(0,0);
        }

      }
      this.$screens.onLinkClick(e);
    }
  },
  computed: {

    clockin: function(){
      return this.$store.state.clockin;
    },

    currentModule: function(){
      if(this.$route.params.screen){
        return this.$route.params.screen.split("-")[0];
      }else{
        return "dashboard";
      }
    },

    currentScreen: function(){
      return this.$route.params.screen?this.$route.params.screen:this.defaultScreen
    },

    pulloutPanel: function(){
      return this.$store.state.pulloutPanel;
    },

    overlayPanel: function() {
      return this.$store.state.overlayPanel;
    },

    panelInitInfo: function() {
      return this.$store.state.panelInitInfo;
    },

  }

}
</script>

<style scoped>

.link-capture {
  position: fixed;
  top: 0;
  left: 0;
  width: 62em;
  height: 100vh;


  z-index: 50 ;
  box-sizing: border-box;
  text-align: right;
  display: none;
}

.link-capture[data-active="true"]{
  display: block;
}

@keyframes blink {
  0% {
    opacity: 0.5;
  }

  40% {
    opacity: 1.0;
  }

  60% {
    opacity: 1.0;
  }

  100% {
    opacity: 0.5;
  }
}

.link-capture-notice {
  padding: 0.75em 2em;
  margin-top: 2.4em;
  background: var(--color-green);
  display: inline-block;
  border-radius: 0.5em;
  color: white;


  user-select: none;
  cursor: pointer;
  position: relative;
  box-sizing: border-box;



}

.link-capture-notice:hover {
  text-decoration: underline;

}

.clock-box {
  position: absolute;
  width: calc(310/1580 * 100%);
}

.clock-box img {
  width: 100%;
  display: block;
}



</style>