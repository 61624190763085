


<template>
<div class="check-stubs-pullout pullout-panel">
  <div class="bkg"></div>
  <div class="check-stubs-list">


    <div class="check-stubs-scroll" v-if="!detailPanel">
      <img class="w-full" src="/img/screens/employee/health/pullouts/pullout-employee-hb-family-add.png?v=10122022" />
      <div class="links">

        <screen-link left="60%" top="1%" width="39%" height="5%" data-tgt="profile" dot-class="dot-left-center" @click.native="$emit('close')"></screen-link>

        <screen-link dot-class="dot-left-center" left="5%" top="19%" width="20%" height="5%" data-tgt="add-son" @click.native="showDetails"></screen-link>

      </div>
    </div>
    <div class="check-stubs-scroll" v-if="detailPanel === 'add-son'">
      <img class="w-full" src="/img/screens/employee/hb/pullouts/pullout-employee-hb-family-add-son.png?v=10122022" />
      <div class="links">
        <screen-link left="61%" top="1%" width="15%" height="5%" data-tgt="profile" dot-class="dot-bottom-center" @click.native="$emit('close')"></screen-link>
        <screen-link left="76%" top="1%" width="16%" height="5%" data-tgt="hb-family-added" dot-class="dot-bottom-center"  @click.native="onLinkClick"></screen-link>
        <screen-link left="92%" top="1%" width="7%" height="5%" data-tgt="hb-family-added" dot-class="dot-bottom-center"  @click.native="$emit('close')"></screen-link>

      </div>
    </div>

    <div class="check-stubs-scroll" v-if="detailPanel === 'edit-beneficiary'">
      <img class="w-full" src="/img/screens/employee/hb/pullouts/pullout-employee-hb-beneficiary.png?v=10122022" />
      <div class="links">
        <screen-link left="60%" top="1%" width="39%" height="5%" data-tgt="profile" dot-class="dot-left-center" @click.native="$emit('close')"></screen-link>
        <screen-link left="78.5%" top="9%" width="16%" height="6%" data-tgt="edit-beneficiary-primary" dot-class="dot-left-center"  @click.native="showDetails"></screen-link>


      </div>
    </div>

    <div class="check-stubs-scroll" v-if="detailPanel === 'edit-beneficiary-primary'">
      <img class="w-full" src="/img/screens/employee/hb/pullouts/pullout-employee-hb-beneficiary-choose.png?v=10122022" />
      <div class="links">
        <screen-link left="65%" top="1%" width="34%" height="10%" data-tgt="profile" dot-class="dot-left-center" @click.native="$emit('close')"></screen-link>
        <screen-link left="78.5%" top="24%" width="16%" height="6%" data-tgt="edit-beneficiary-primary-selected" dot-class="dot-left-center"  @click.native="showDetails"></screen-link>


      </div>
    </div>

    <div class="check-stubs-scroll" v-if="detailPanel === 'edit-beneficiary-primary-selected'">
      <img class="w-full" src="/img/screens/employee/hb/pullouts/pullout-employee-hb-beneficiary-selected.png?v=10122022" />
      <div class="links">
        <screen-link left="66.5%" top="6%" width="15%" height="5%" data-tgt="profile" dot-class="dot-bottom-center" @click.native="$emit('close')"></screen-link>
        <screen-link left="81%" top="6%" width="16%" height="5%" data-tgt="edit-beneficiary-primary-selected-amount" dot-class="dot-bottom-center"  @click.native="showDetails"></screen-link>
        <screen-link left="92%" top="1%" width="7%" height="4%" data-tgt="hb-family-added" dot-class="dot-bottom-center"  @click.native="$emit('close')"></screen-link>

        <screen-link left="74.5%" top="24%" width="20%" height="6%" data-tgt="edit-beneficiary-primary" dot-class="dot-left-center"  @click.native="showDetails"></screen-link>


      </div>
    </div>

    <div class="check-stubs-scroll" v-if="detailPanel === 'edit-beneficiary-primary-selected-amount'">
      <img class="w-full" src="/img/screens/employee/hb/pullouts/pullout-employee-hb-beneficiary-selected-amount.png?v=10122022" />
      <div class="links">
        <screen-link left="61.5%" top="1%" width="15%" height="5%" data-tgt="profile" dot-class="dot-bottom-center" @click.native="$emit('close')"></screen-link>

        <screen-link left="92%" top="1%" width="7%" height="4%" data-tgt="hb-family-added" dot-class="dot-bottom-center"  @click.native="$emit('close')"></screen-link>

        <screen-link left="71%" top="30.5%" width="19%" height="6%" data-tgt="edit-beneficiary-primary-selected-amount-filled" dot-class="dot-left-center"  @click.native="showDetails"></screen-link>


      </div>
    </div>

    <div class="check-stubs-scroll" v-if="detailPanel === 'edit-beneficiary-primary-selected-amount-filled'">
      <img class="w-full" src="/img/screens/employee/hb/pullouts/pullout-employee-hb-beneficiary-selected-amount-filled.png?v=10122022" />
      <div class="links">
        <screen-link left="61.5%" top="1%" width="15%" height="5%" data-tgt="profile" dot-class="dot-bottom-center" @click.native="$emit('close')"></screen-link>
        <screen-link left="76%" top="1%" width="16%" height="5%" data-tgt="hb-family-beneficiary-added" dot-class="dot-bottom-center"  @click.native="onLinkClick"></screen-link>
        <screen-link left="92%" top="1%" width="7%" height="4%" data-tgt="hb-family-added" dot-class="dot-bottom-center"  @click.native="$emit('close')"></screen-link>


      </div>
    </div>


  </div>



</div>
</template>

<script>
import ScreenLink from "@/components/ScreenLink";

export default {
    name: "benefits-family-pullout",
  components: {ScreenLink},
  props: {},
    data: function(){
        return {
          detailPanel: null
        }
        },
    mounted() {
      if(this.$route.query.level !== undefined){
        this.detailPanel = this.$route.query.level;
      }
    },

    watch: {
      $route: function(next){
        if(next.query.level !== undefined){
          this.detailPanel = next.query.level;
        }
      }
    },

    methods: {

      clockIn: function(){
        this.$store.state.clockin = 'working';
        this.$emit('close');
      },

      showDetails: function(e){
          this.detailPanel = e.target.dataset.tgt;
let query = {...this.$route.query};
          query.level=e.target.dataset.tgt;
          this.$router.replace({ name: this.$route.name, params: this.$route.params, query: query });
      },

      onLinkClick: function(e){
        console.log(e);
        this.$emit('link', e);
      }
    },
    computed: {}
}
</script>

<style scoped>


.check-stubs-pullout {
  width: 100%;
  position: absolute;
  top: calc((60/920) * 100%);
  height: calc(((920 - 60)/920) * 100%);

}

.check-stubs-pullout .bkg {
  position: absolute;
  width: 100%;
  height: 100%;
  right: 0;
  background-color: black;
  opacity: 0.4;
}

.check-stubs-pullout img {
  display: block;
}


.check-stubs-list {

  position: absolute;
  width: calc((600/1580) * 100% + 15px);
  height: 100%;

  right: 0;
  display: flex;
  flex-direction: column;

}



.check-stubs-scroll {
  position: relative;

  overflow-y: scroll;
}

.w-full {
  width: 100%;
}

.links {

}

</style>
