


<template>
<div class="basic-pullout pullout-panel">
  <div class="bkg"></div>
  <div class="primary-panel">

    <div class="basic-scroll" ref="basicScroll">
      <div class="detail-panel" v-if="detailPanel">

        <div class="detail-content" :data-active="detailPanel=='manage-investments'" >
          <img class="w-full" src="/img/screens/retirement/pullout-manage-investments.png?v=10122022" />
          <div class="links">
            <screen-link left="93%" top="1%" width="5%" height="5%" data-tgt="" dot-class="dot-bottom-center" data-is-overlay="true" @click.native="$emit('close')"></screen-link>
            <screen-link left="3%" top="8%" width="94%" height="10%" data-tgt="future" dot-class="dot-right-center"  @click.native="showDetails"></screen-link>
            <screen-link left="3%" top="20%" width="94%" height="12%" data-tgt="redistribute" dot-class="dot-right-center"  @click.native="showDetails"></screen-link>
            <screen-link left="3%" top="33.5%" width="94%" height="10%" data-tgt="current" dot-class="dot-right-center"  @click.native="showDetails"></screen-link>
          </div>
        </div>

        <div class="detail-content" :data-active="detailPanel=='future'" >
          <img class="w-full" src="/img/screens/retirement/pullout-manage-investments-future.png?v=10122022" />
          <div class="links">
            <screen-link left="83%" top="1%" width="14%" height="5%" data-tgt="manage-investments" dot-class="dot-bottom-center" data-is-overlay="true" @click.native="showDetails"></screen-link>
            <screen-link left="81%" top="47%" width="15%" height="7%" data-tgt="future-edit-bonds" dot-class="dot-right-center" @click.native="showDetails"></screen-link>
          </div>
        </div>

        <div class="detail-content" :data-active="detailPanel=='future-edit-bonds'" >
          <img class="w-full" src="/img/screens/retirement/pullout-manage-investments-future-edit-bonds.png?v=10122022" />
          <div class="links">
            <screen-link left="83%" top="1%" width="14%" height="5%" data-tgt="manage-investments" dot-class="dot-bottom-center" data-is-overlay="true" @click.native="showDetails"></screen-link>
            <screen-link left="81%" top="103%" width="15%" height="7%" data-tgt="future-edit-equities" dot-class="dot-right-center" @click.native="showDetails"></screen-link>
          </div>
        </div>

        <div class="detail-content" :data-active="detailPanel=='future-edit-equities'" >
          <img class="w-full" src="/img/screens/retirement/pullout-manage-investments-future-edit-equities.png?v=10122022" />
          <div class="links">
            <screen-link left="83%" top="1%" width="14%" height="5%" data-tgt="manage-investments" dot-class="dot-bottom-center" data-is-overlay="true" @click.native="showDetails"></screen-link>
            <screen-link left="81%" top="315%" width="17%" height="6%" data-tgt="future-edit-confirm" dot-class="dot-top-center" data-tgt-scrolltop="true" @click.native="showDetails"></screen-link>
          </div>
        </div>

        <div class="detail-content" :data-active="detailPanel=='future-edit-equity'" >
          <img class="w-full" src="/img/screens/retirement/pullout-manage-investments-future-edit-equities.png?v=10122022" />
          <div class="links">
            <screen-link left="83%" top="1%" width="14%" height="5%" data-tgt="manage-investments" dot-class="dot-bottom-center" data-is-overlay="true" @click.native="showDetails"></screen-link>
            <screen-link left="81%" top="315%" width="17%" height="6%" data-tgt="future-edit-confirm" dot-class="dot-right-center" data-tgt-scrolltop="true" @click.native="showDetails"></screen-link>
          </div>
        </div>

        <div class="detail-content" :data-active="detailPanel=='future-edit-confirm'" >
          <img class="w-full" src="/img/screens/retirement/pullout-manage-investments-future-edit-confirm.png?v=10122022" />
          <div class="links">
            <screen-link left="83%" top="1%" width="14%" height="5%" data-tgt="manage-investments" dot-class="dot-bottom-center" data-is-overlay="true" @click.native="showDetails"></screen-link>
            <screen-link left="81%" top="88.5%" width="17%" height="6%" data-tgt="future-edit-confirmed" dot-class="dot-right-center" @click.native="showDetails"></screen-link>
          </div>
        </div>

        <div class="detail-content" :data-active="detailPanel=='future-edit-confirmed'" >
          <img class="w-full" src="/img/screens/retirement/pullout-manage-investments-future-edit-confirmed.png?v=10122022" />
          <div class="links">
            <screen-link left="93%" top="1%" width="6%" height="5%" data-tgt="retirement-investments-changed" dot-class="dot-bottom-center" data-tgt-scrolltop="true" data-tgt-mode="employee"  @click.native="$screens.onLinkClick"></screen-link>
            <screen-link left="40%" top="38.5%" width="20%" height="6%" data-tgt="retirement-investments-changed" dot-class="dot-right-center" data-tgt-scrolltop="true" data-tgt-mode="employee" @click.native="$screens.onLinkClick"></screen-link>
          </div>
        </div>

        <div class="detail-content" :data-active="detailPanel=='redistribute'" >
          <img class="w-full" src="/img/screens/retirement/pullout-manage-investments-redistribute.png?v=10122022" />
          <div class="links">
            <screen-link left="83%" top="1%" width="14%" height="5%" data-tgt="manage-investments" dot-class="dot-bottom-center" data-is-overlay="true" @click.native="showDetails"></screen-link>
          </div>
        </div>

        <div class="detail-content" :data-active="detailPanel=='current'" >
          <img class="w-full" src="/img/screens/retirement/pullout-manage-investments-current.png?v=10122022" />
          <div class="links">
            <screen-link left="83%" top="1%" width="14%" height="5%" data-tgt="manage-investments" dot-class="dot-bottom-center" data-is-overlay="true" @click.native="showDetails"></screen-link>
          </div>
        </div>

        <div class="detail-content" :data-active="detailPanel=='fund-info'" >
          <img class="w-full" src="/img/screens/employee/retirement/pullouts/pullout-fund-details.png?v=10122022" />
          <div class="links">
            <screen-link left="93%" top="1%" width="5%" height="5%" data-tgt="" dot-class="dot-bottom-center" data-is-overlay="true" @click.native="$emit('close')"></screen-link>

            <screen-link left="4%" top="53%" width="92%" height="5%" data-tgt="/documents/vanguard-500-index-fund-fact-sheet.pdf" dot-class="dot-left-center"  @click.native="$screens.showExternalPage"></screen-link>
          </div>
        </div>

        <div class="detail-content" :data-active="detailPanel=='auto-rebalance'" >
          <img class="w-full" src="/img/screens/retirement/pullout-auto-rebalance.png?v=10122022" />
          <div class="links">
            <screen-link left="68%" top="1%" width="14%" height="5%" data-tgt="" dot-class="dot-bottom-center" data-is-overlay="true" @click.native="$emit('close')"></screen-link>
            <screen-link left="22.5%" top="19%" width="32%" height="5%" data-tgt="rebalance" dot-class="dot-right-center"  @click.native="$screens.showHelp"></screen-link>
            <screen-link left="84%" top="25.8%" width="10%" height="5%" data-tgt="auto-rebalance-active" dot-class="dot-right-center"  @click.native="showDetails"></screen-link>

          </div>
        </div>

        <div class="detail-content" :data-active="detailPanel=='auto-rebalance-active'" >
          <img class="w-full" src="/img/screens/retirement/pullout-auto-rebalance-active.png?v=10122022" />
          <div class="links">
            <screen-link left="68%" top="1%" width="14%" height="5%" data-tgt="" dot-class="dot-bottom-center" data-is-overlay="true" @click.native="$emit('close')"></screen-link>
            <screen-link left="22.5%" top="19%" width="32%" height="5%" data-tgt="rebalance" dot-class="dot-right-center"  @click.native="$screens.showHelp"></screen-link>
            <screen-link left="84%" top="25.8%" width="10%" height="5%" data-tgt="auto-rebalance" dot-class="dot-right-center"  @click.native="showDetails"></screen-link>
            <screen-link left="6%" top="35%" width="50%" height="5%" data-tgt="auto-rebalance-active-options" dot-class="dot-right-center"  @click.native="showDetails"></screen-link>

          </div>
        </div>

        <div class="detail-content" :data-active="detailPanel=='auto-rebalance-active-options'" >
          <img class="w-full" src="/img/screens/retirement/pullout-auto-rebalance-active-options.png?v=10122022" />
          <div class="links">
            <screen-link left="68%" top="1%" width="14%" height="5%" data-tgt="" dot-class="dot-bottom-center" data-is-overlay="true" @click.native="$emit('close')"></screen-link>
            <screen-link left="22.5%" top="19%" width="32%" height="5%" data-tgt="rebalance" dot-class="dot-right-center"  @click.native="$screens.showHelp"></screen-link>
            <screen-link left="84%" top="25.8%" width="10%" height="5%" data-tgt="auto-rebalance" dot-class="dot-right-center"  @click.native="showDetails"></screen-link>
            <screen-link left="5%" top="35%" width="50%" height="5%" data-tgt="auto-rebalance-active-yearly" dot-class="dot-left-center"  @click.native="showDetails"></screen-link>

          </div>
        </div>

        <div class="detail-content" :data-active="detailPanel=='auto-rebalance-active-yearly'" >
          <img class="w-full" src="/img/screens/retirement/pullout-auto-rebalance-active-yearly.png?v=10122022" />
          <div class="links">
            <screen-link left="68%" top="1%" width="14%" height="5%" data-tgt="" dot-class="dot-bottom-center" data-is-overlay="true" @click.native="$emit('close')"></screen-link>
            <screen-link left="83%" top="1%" width="14%" height="5%" data-tgt="retirement-investments-changed-auto" dot-class="dot-bottom-center" @click.native="$screens.onLinkClick" data-tgt-mode="employee"></screen-link>
            <screen-link left="22.5%" top="19%" width="32%" height="5%" data-tgt="rebalance" dot-class="dot-right-center"  @click.native="$screens.showHelp"></screen-link>
            <screen-link left="84%" top="25.8%" width="10%" height="5%" data-tgt="auto-rebalance" dot-class="dot-right-center"  @click.native="showDetails"></screen-link>

          </div>
        </div>


    </div>

  </div>
  </div>


</div>
</template>

<script>
import ScreenLink from "@/components/ScreenLink";


export default {
    name: "manage-investments-pullout",
  components: {ScreenLink},
  props: {
      employeeState: {}
  },
    data: function(){
        return {
          detailPanel: "manage-investments",
          showOptions: false,
        }
        },
    mounted() {
      if(this.$route.query.level !== undefined){
        this.detailPanel = this.$route.query.level;
      }else{
        if(this.initInfo){
          this.detailPanel = this.initInfo; //start screen
        }
      }
    },

    watch: {
      $route: function(next){
        if(next.query.level !== undefined){
          this.detailPanel = next.query.level;
        }
      }
    },

    methods: {

      setBene: function(confirmed){
        this.employeeState.retirement.hasBeneficiary = confirmed;
        this.showDetails({
          target:{
            dataset: {
              tgt: "add-bene-confirmation"
            }
          }
        })
      },


      showDetails: function(e){

        this.showOptions = false;

        this.detailPanel = e.target.dataset.tgt;

        if(!this.detailPanel){
          this.$emit('close');
          return;
        }

        let query = {...this.$route.query};
        query.level = e.target.dataset.tgt;
        this.$router.replace({ name: this.$route.name, params: this.$route.params, query: query });
        console.log("Scroll", e.target.dataset)
        if(e.target.dataset.tgtScrolltop){
          this.$refs['basicScroll'].scrollTo(0,0);
        }



      },

    },
    computed: {

    }
}
</script>

<style scoped>


/* SEE GLOBAL CSS FOR BASIC PULLOUT CLASSES */

.close-icon {
  padding: 0.5em;
  position: absolute;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
}

.detail-content {
  display: none;
}

.detail-content[data-active="true"] {
  display: block;
}

</style>
