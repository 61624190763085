


<template>
<div class="basic-pullout pullout-panel">
  <div class="bkg"></div>
  <div class="primary-panel">

    <div class="basic-scroll" ref="basicScroll">
      <div class="detail-panel" v-if="detailPanel">

        <div class="detail-content" :data-active="detailPanel=='loan-terms'" >
          <img class="w-full" src="/img/screens/retirement/pullout-loan-terms.png?v=10122022" />
          <div class="links">
            <screen-link left="93%" top="1%" width="6%" height="5%" data-tgt="" dot-class="dot-bottom-center" data-is-overlay="true" @click.native="$emit('close')"></screen-link>
          </div>
        </div>

        <div class="detail-content" :data-active="detailPanel=='about-distributions'" >
          <img class="w-full" src="/img/screens/retirement/pullout-about-distributions.png?v=10122022" />
          <div class="links">
            <screen-link left="90%" top="1%" width="6%" height="5%" data-tgt="" dot-class="dot-bottom-center" data-is-overlay="true" @click.native="$emit('close')"></screen-link>
          </div>
        </div>

        <div class="detail-content" :data-active="detailPanel=='about-bene-request'" >
          <img class="w-full" src="/img/screens/retirement/pullout-about-bene-request.png?v=10122022" />
          <div class="links">
            <screen-link left="92%" top="1%" width="6%" height="5%" data-tgt="" dot-class="dot-bottom-center" data-is-overlay="true" @click.native="$emit('close')"></screen-link>
          </div>
        </div>

        <div class="detail-content" :data-active="detailPanel=='about-hardship'" >
          <img class="w-full" src="/img/screens/retirement/pullout-about-hardship.png?v=10122022" />
          <div class="links">
            <screen-link left="92%" top="1%" width="6%" height="5%" data-tgt="" dot-class="dot-bottom-center" data-is-overlay="true" @click.native="$emit('close')"></screen-link>
          </div>
        </div>

        <div class="detail-content" :data-active="detailPanel=='about-disability-req'" >
          <img class="w-full" src="/img/screens/retirement/pullout-about-disability-req.png?v=10122022" />
          <div class="links">
            <screen-link left="92%" top="1%" width="6%" height="5%" data-tgt="" dot-class="dot-bottom-center" data-is-overlay="true" @click.native="$emit('close')"></screen-link>
          </div>
        </div>

        <div class="detail-content" :data-active="detailPanel=='retirement-calc'" >
          <img class="w-full" src="/img/screens/retirement/pullout-retirement-calc.png?v=10122022" />
          <div class="links">
            <screen-link left="92%" top="1%" width="6%" height="5%" data-tgt="" dot-class="dot-bottom-center" data-is-overlay="true" @click.native="$emit('close')"></screen-link>
          </div>
        </div>


    </div>

  </div>
  </div>


</div>
</template>

<script>
import ScreenLink from "@/components/ScreenLink";


export default {
    name: "legal-info-pullout",
  components: {ScreenLink},
  props: {
      employeeState: {}
  },
    data: function(){
        return {
          detailPanel: "loan-terms",
          showOptions: false,
        }
        },
    mounted() {
      if(this.$route.query.level !== undefined){
        this.detailPanel = this.$route.query.level;
      }else{
        if(this.initInfo){
          this.detailPanel = this.initInfo; //start screen
        }
      }
    },

    watch: {
      $route: function(next){
        if(next.query.level !== undefined){
          this.detailPanel = next.query.level;
        }
      }
    },

    methods: {

      setBene: function(confirmed){
        this.employeeState.retirement.hasBeneficiary = confirmed;
        this.showDetails({
          target:{
            dataset: {
              tgt: "add-bene-confirmation"
            }
          }
        })
      },


      showDetails: function(e){

        this.showOptions = false;

        this.detailPanel = e.target.dataset.tgt;

        if(!this.detailPanel){
          this.$emit('close');
          return;
        }

        let query = {...this.$route.query};
        query.level=e.target.dataset.tgt;
        this.$router.replace({ name: this.$route.name, params: this.$route.params, query: query });
        this.$refs['basicScroll'].scrollTo(0,0);


      },

    },
    computed: {

    }
}
</script>

<style scoped>


/* SEE GLOBAL CSS FOR BASIC PULLOUT CLASSES */

.close-icon {
  padding: 0.5em;
  position: absolute;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
}

.detail-content {
  display: none;
}

.detail-content[data-active="true"] {
  display: block;
}

</style>
