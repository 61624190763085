


<template>
<div class="vaccine-card-upload-pullout pullout-panel">
  <div class="bkg"></div>
  <div class="vaccine-card-upload-list">


    <div class="vaccine-card-upload-scroll" v-if="!detailPanel">
      <img class="w-full" src="/img/screens/employee/documents/pullouts/pullout-cpr-upload.png?v=10122022" />
      <div class="links">
        <screen-link left="68%" top="1%" width="14%" height="5%" data-tgt="" dot-class="dot-bottom-center" data-is-overlay="true" @click.native="$emit('close')"></screen-link>
        <screen-link left="9%" top="25%" width="83%" height="11%" data-tgt="document" dot-class="dot-top-center" data-is-overlay="true" @click.native="showDetails"></screen-link>
      </div>
    </div>
    <div class="vaccine-card-upload-scroll" v-if="detailPanel === 'document'">
      <img class="w-full" src="/img/screens/employee/documents/pullouts/pullout-cpr-upload-card.png?v=10122022" />
      <div class="links">
        <screen-link left="68%" top="1%" width="14%" height="5%" data-tgt="" dot-class="dot-bottom-center" data-is-overlay="true" @click.native="$emit('close')"></screen-link>
        <screen-link left="83%" top="1%" width="14%" height="5%" :data-tgt="(currentScreen === 'mydocs')?'mydocs-updated':'dashboard-tasks-documents-updated'" dot-class="dot-bottom-center" @click.native="onLinkClick"></screen-link>

      </div>
    </div>



  </div>



</div>
</template>

<script>
import ScreenLink from "@/components/ScreenLink";
export default {
    name: "vaccine-card-upload-pullout",
  components: {ScreenLink},
  props: {
      currentScreen: {

      }
  },
    data: function(){
        return {
          detailPanel: null
        }
        },
    mounted() {
      if(this.$route.query.level !== undefined){
        this.detailPanel = this.$route.query.level;
      }
    },

    watch: {
      $route: function(next){
        if(next.query.level !== undefined){
          this.detailPanel = next.query.level;
        }
      }
    },

    methods: {
      showDetails: function(e){
          this.detailPanel = e.target.dataset.tgt;
let query = {...this.$route.query};
          query.level=e.target.dataset.tgt;
          this.$router.replace({ name: this.$route.name, params: this.$route.params, query: query });
      },

      onLinkClick: function(e){
        if(e.target.dataset.tgt === "dashboard-tasks-documents-updated"){
          this.$store.state.uploadedVaccination = true;
        }
        console.log(e);
        this.$emit('link', e);
      }
    },
    computed: {}
}
</script>

<style scoped>


.vaccine-card-upload-pullout {
  width: 100%;
  position: absolute;
  top: calc((60/920) * 100%);
  height: calc(((920 - 60)/920) * 100%);

}

.vaccine-card-upload-pullout .bkg {
  position: absolute;
  width: 100%;
  height: 100%;
  right: 0;
  background-color: black;
  opacity: 0.4;
}

.vaccine-card-upload-pullout img {
  display: block;
}


.vaccine-card-upload-list {

  position: absolute;
  width: calc((600/1580) * 100% + 15px);
  height: 100%;

  right: 0;
  display: flex;
  flex-direction: column;

}



.vaccine-card-upload-scroll {
  position: relative;

  overflow-y: scroll;
}

.w-full {
  width: 100%;
}

.links {

}

</style>
