<template>
  <div class="screen-container" >
    <div class="demo" :data-noscroll="$store.state.overlayPanel?true:false" :data-guided="guidedMode" :data-show-links="showLinks" >

      <div class="screens" ref="screens">



        <div class="screen z-max" data-screen="onboarding-employee-info" :data-active="currentScreen=='onboarding-employee-info'">
          <img class="w-full max-w-100" src="/img/screens/peo/onboarding-peo-employee-info.png?v=10122022" />
          <div class="links">
            <screen-link left="85%" top="2%" width="5%" height="4.5%" dot-class="dot-right-center" data-tgt="hiring-peo-workers-add-details" data-tgt-mode="admin" @click.native="close"></screen-link>


            <screen-link left="10%" top="19.5%" width="20%" height="4.5%" data-tgt-scrolltop="true" data-tgt="onboarding-employee-info" @click.native="onLinkClick"></screen-link>
            <screen-link left="10%" top="26.25%" width="20%" height="4.5%" data-tgt-scrolltop="true" data-tgt="onboarding-employment" @click.native="onLinkClick"></screen-link>
            <screen-link left="10%" top="33.5%" width="20%" height="4.5%" data-tgt-scrolltop="true" data-tgt="onboarding-employment-cont" @click.native="onLinkClick"></screen-link>
            <screen-link left="10%" top="40.5%" width="20%" height="4.5%" data-tgt-scrolltop="true" data-tgt="onboarding-compensation" @click.native="onLinkClick"></screen-link>
            <screen-link left="10%" top="47.5%" width="20%" height="4.5%" data-tgt-scrolltop="true" data-tgt="onboarding-signature" @click.native="onLinkClick"></screen-link>
            <screen-link left="10%" top="54.5%" width="20%" height="4.5%" data-tgt-scrolltop="true" data-tgt="onboarding-closing" @click.native="onLinkClick"></screen-link>


            <screen-link left="82%" top="105%" width="6.5%" height="4.5%" dot-class="dot-right-center" data-tgt="onboarding-employment" @click.native="onLinkClick"></screen-link>



          </div>
        </div>

        <div class="screen z-max" data-screen="onboarding-employment" :data-active="currentScreen=='onboarding-employment'">
          <img class="w-full max-w-100" src="/img/screens/peo/onboarding-peo-employment.png?v=10122022" />
          <div class="links">
            <screen-link left="85%" top="2%" width="5%" height="4.5%" dot-class="dot-right-center" data-tgt="hiring-peo-workers-add-details" data-tgt-mode="admin" @click.native="close"></screen-link>


            <screen-link left="10%" top="19.5%" width="20%" height="4.5%" data-tgt-scrolltop="true" data-tgt="onboarding-employee-info" @click.native="onLinkClick"></screen-link>
            <screen-link left="10%" top="26.25%" width="20%" height="4.5%" data-tgt-scrolltop="true" data-tgt="onboarding-employment" @click.native="onLinkClick"></screen-link>
            <screen-link left="10%" top="33.5%" width="20%" height="4.5%" data-tgt-scrolltop="true" data-tgt="onboarding-employment-cont" @click.native="onLinkClick"></screen-link>
            <screen-link left="10%" top="40.5%" width="20%" height="4.5%" data-tgt-scrolltop="true" data-tgt="onboarding-compensation" @click.native="onLinkClick"></screen-link>
            <screen-link left="10%" top="47.5%" width="20%" height="4.5%" data-tgt-scrolltop="true" data-tgt="onboarding-signature" @click.native="onLinkClick"></screen-link>
            <screen-link left="10%" top="54.5%" width="20%" height="4.5%" data-tgt-scrolltop="true" data-tgt="onboarding-closing" @click.native="onLinkClick"></screen-link>


            <screen-link left="82%" top="137%" width="6.5%" height="4.5%" dot-class="dot-right-center" data-tgt="onboarding-employment-cont" @click.native="onLinkClick"></screen-link>

          </div>
        </div>

        <div class="screen z-max" data-screen="onboarding-employment-cont" :data-active="currentScreen=='onboarding-employment-cont'">
          <img class="w-full max-w-100" src="/img/screens/peo/onboarding-peo-employment-cont.png?v=10122022" />
          <div class="links">
            <screen-link left="85%" top="2%" width="5%" height="4.5%" dot-class="dot-right-center" data-tgt="hiring-peo-workers-add-details" data-tgt-mode="admin" @click.native="close"></screen-link>


            <screen-link left="10%" top="19.5%" width="20%" height="4.5%" data-tgt-scrolltop="true" data-tgt="onboarding-employee-info" @click.native="onLinkClick"></screen-link>
            <screen-link left="10%" top="26.25%" width="20%" height="4.5%" data-tgt-scrolltop="true" data-tgt="onboarding-employment" @click.native="onLinkClick"></screen-link>
            <screen-link left="10%" top="33.5%" width="20%" height="4.5%" data-tgt-scrolltop="true" data-tgt="onboarding-employment-cont" @click.native="onLinkClick"></screen-link>
            <screen-link left="10%" top="40.5%" width="20%" height="4.5%" data-tgt-scrolltop="true" data-tgt="onboarding-compensation" @click.native="onLinkClick"></screen-link>
            <screen-link left="10%" top="47.5%" width="20%" height="4.5%" data-tgt-scrolltop="true" data-tgt="onboarding-signature" @click.native="onLinkClick"></screen-link>
            <screen-link left="10%" top="54.5%" width="20%" height="4.5%" data-tgt-scrolltop="true" data-tgt="onboarding-closing" @click.native="onLinkClick"></screen-link>


            <screen-link left="82%" top="116%" width="6.5%" height="4.5%" dot-class="dot-right-center" data-tgt="onboarding-compensation" @click.native="onLinkClick"></screen-link>

          </div>
        </div>

        <div class="screen z-max" data-screen="onboarding-compensation" :data-active="currentScreen=='onboarding-compensation'">
          <img class="w-full max-w-100" src="/img/screens/peo/onboarding-peo-compensation.png?v=10122022" />
          <div class="links">
            <screen-link left="85%" top="2%" width="5%" height="4.5%" dot-class="dot-right-center" data-tgt="hiring-peo-workers-add-details" data-tgt-mode="admin" @click.native="close"></screen-link>


            <screen-link left="10%" top="19.5%" width="20%" height="4.5%" data-tgt-scrolltop="true" data-tgt="onboarding-employee-info" @click.native="onLinkClick"></screen-link>
            <screen-link left="10%" top="26.25%" width="20%" height="4.5%" data-tgt-scrolltop="true" data-tgt="onboarding-employment" @click.native="onLinkClick"></screen-link>
            <screen-link left="10%" top="33.5%" width="20%" height="4.5%" data-tgt-scrolltop="true" data-tgt="onboarding-employment-cont" @click.native="onLinkClick"></screen-link>
            <screen-link left="10%" top="40.5%" width="20%" height="4.5%" data-tgt-scrolltop="true" data-tgt="onboarding-compensation" @click.native="onLinkClick"></screen-link>
            <screen-link left="10%" top="47.5%" width="20%" height="4.5%" data-tgt-scrolltop="true" data-tgt="onboarding-signature" @click.native="onLinkClick"></screen-link>
            <screen-link left="10%" top="54.5%" width="20%" height="4.5%" data-tgt-scrolltop="true" data-tgt="onboarding-closing" @click.native="onLinkClick"></screen-link>


            <screen-link left="82%" top="50.75%" width="6.5%" height="4.5%" dot-class="dot-right-center" data-tgt="onboarding-pay-rate" @click.native="onLinkClick"></screen-link>

          </div>
        </div>

        <div class="screen z-max" data-screen="onboarding-pay-rate" :data-active="currentScreen=='onboarding-pay-rate'">
          <img class="w-full max-w-100" src="/img/screens/peo/onboarding-peo-pay-rate.png?v=10122022" />
          <div class="links">
            <screen-link left="85%" top="2%" width="5%" height="4.5%" dot-class="dot-right-center" data-tgt="hiring-peo-workers-add-details" data-tgt-mode="admin" @click.native="close"></screen-link>


            <screen-link left="10%" top="19.5%" width="20%" height="4.5%" data-tgt-scrolltop="true" data-tgt="onboarding-employee-info" @click.native="onLinkClick"></screen-link>
            <screen-link left="10%" top="26.25%" width="20%" height="4.5%" data-tgt-scrolltop="true" data-tgt="onboarding-employment" @click.native="onLinkClick"></screen-link>
            <screen-link left="10%" top="33.5%" width="20%" height="4.5%" data-tgt-scrolltop="true" data-tgt="onboarding-employment-cont" @click.native="onLinkClick"></screen-link>
            <screen-link left="10%" top="40.5%" width="20%" height="4.5%" data-tgt-scrolltop="true" data-tgt="onboarding-compensation" @click.native="onLinkClick"></screen-link>
            <screen-link left="10%" top="47.5%" width="20%" height="4.5%" data-tgt-scrolltop="true" data-tgt="onboarding-signature" @click.native="onLinkClick"></screen-link>
            <screen-link left="10%" top="54.5%" width="20%" height="4.5%" data-tgt-scrolltop="true" data-tgt="onboarding-closing" @click.native="onLinkClick"></screen-link>


            <screen-link left="81%" top="63.5%" width="8%" height="4.5%" dot-class="dot-right-center" data-tgt="onboarding-pay-rate-2" @click.native="onLinkClick"></screen-link>

          </div>
        </div>

        <div class="screen z-max" data-screen="onboarding-pay-rate-2" :data-active="currentScreen=='onboarding-pay-rate-2'">
          <img class="w-full max-w-100" src="/img/screens/peo/onboarding-peo-pay-rate-2.png?v=10122022" />
          <div class="links">
            <screen-link left="85%" top="2%" width="5%" height="4.5%" dot-class="dot-right-center" data-tgt="hiring-peo-workers-add-details" data-tgt-mode="admin" @click.native="close"></screen-link>


            <screen-link left="10%" top="19.5%" width="20%" height="4.5%" data-tgt-scrolltop="true" data-tgt="onboarding-employee-info" @click.native="onLinkClick"></screen-link>
            <screen-link left="10%" top="26.25%" width="20%" height="4.5%" data-tgt-scrolltop="true" data-tgt="onboarding-employment" @click.native="onLinkClick"></screen-link>
            <screen-link left="10%" top="33.5%" width="20%" height="4.5%" data-tgt-scrolltop="true" data-tgt="onboarding-employment-cont" @click.native="onLinkClick"></screen-link>
            <screen-link left="10%" top="40.5%" width="20%" height="4.5%" data-tgt-scrolltop="true" data-tgt="onboarding-compensation" @click.native="onLinkClick"></screen-link>
            <screen-link left="10%" top="47.5%" width="20%" height="4.5%" data-tgt-scrolltop="true" data-tgt="onboarding-signature" @click.native="onLinkClick"></screen-link>
            <screen-link left="10%" top="54.5%" width="20%" height="4.5%" data-tgt-scrolltop="true" data-tgt="onboarding-closing" @click.native="onLinkClick"></screen-link>


            <screen-link left="37%" top="39%" width="11%" height="3.5%" dot-class="dot-left-center" data-tgt="onboarding-pay-rate-2-30" @click.native="onLinkClick"></screen-link>

          </div>
        </div>

        <div class="screen z-max" data-screen="onboarding-pay-rate-2-30" :data-active="currentScreen=='onboarding-pay-rate-2-30'">
          <img class="w-full max-w-100" src="/img/screens/peo/onboarding-peo-pay-rate-2-30.png?v=10122022" />
          <div class="links">
            <screen-link left="85%" top="2%" width="5%" height="4.5%" dot-class="dot-right-center" data-tgt="hiring-peo-workers-add-details" data-tgt-mode="admin" @click.native="close"></screen-link>


            <screen-link left="10%" top="19.5%" width="20%" height="4.5%" data-tgt-scrolltop="true" data-tgt="onboarding-employee-info" @click.native="onLinkClick"></screen-link>
            <screen-link left="10%" top="26.25%" width="20%" height="4.5%" data-tgt-scrolltop="true" data-tgt="onboarding-employment" @click.native="onLinkClick"></screen-link>
            <screen-link left="10%" top="33.5%" width="20%" height="4.5%" data-tgt-scrolltop="true" data-tgt="onboarding-employment-cont" @click.native="onLinkClick"></screen-link>
            <screen-link left="10%" top="40.5%" width="20%" height="4.5%" data-tgt-scrolltop="true" data-tgt="onboarding-compensation" @click.native="onLinkClick"></screen-link>
            <screen-link left="10%" top="47.5%" width="20%" height="4.5%" data-tgt-scrolltop="true" data-tgt="onboarding-signature" @click.native="onLinkClick"></screen-link>
            <screen-link left="10%" top="54.5%" width="20%" height="4.5%" data-tgt-scrolltop="true" data-tgt="onboarding-closing" @click.native="onLinkClick"></screen-link>


            <screen-link left="74.5%" top="62.5%" width="6.5%" height="4.5%" dot-class="dot-left-center" data-tgt="onboarding-signature" @click.native="onLinkClick"></screen-link>

          </div>
        </div>

        <div class="screen z-max" data-screen="onboarding-signature" :data-active="currentScreen=='onboarding-signature'">
          <img class="w-full max-w-100" src="/img/screens/peo/onboarding-peo-signature.png?v=10122022" />
          <div class="links">
            <screen-link left="85%" top="2%" width="5%" height="4.5%" dot-class="dot-right-center" data-tgt="hiring-peo-workers-add-details" data-tgt-mode="admin" @click.native="close"></screen-link>


            <screen-link left="10%" top="19.5%" width="20%" height="4.5%" data-tgt-scrolltop="true" data-tgt="onboarding-employee-info" @click.native="onLinkClick"></screen-link>
            <screen-link left="10%" top="26.25%" width="20%" height="4.5%" data-tgt-scrolltop="true" data-tgt="onboarding-employment" @click.native="onLinkClick"></screen-link>
            <screen-link left="10%" top="33.5%" width="20%" height="4.5%" data-tgt-scrolltop="true" data-tgt="onboarding-employment-cont" @click.native="onLinkClick"></screen-link>
            <screen-link left="10%" top="40.5%" width="20%" height="4.5%" data-tgt-scrolltop="true" data-tgt="onboarding-compensation" @click.native="onLinkClick"></screen-link>
            <screen-link left="10%" top="47.5%" width="20%" height="4.5%" data-tgt-scrolltop="true" data-tgt="onboarding-signature" @click.native="onLinkClick"></screen-link>
            <screen-link left="10%" top="54.5%" width="20%" height="4.5%" data-tgt-scrolltop="true" data-tgt="onboarding-closing" @click.native="onLinkClick"></screen-link>


            <screen-link left="79.5%" top="164%" width="9%" height="4.5%" data-tgt-scrolltop="true" dot-class="dot-right-center" data-tgt="" @click.native="showOverlay('sign-packet')"></screen-link>
            <document-sign v-if="$store.state.overlayPanel === 'sign-packet'" person="Suzy Rabick" confirm-tgt="onboarding-closing" confirm-mode="peo"></document-sign>

          </div>
        </div>

        <div class="screen z-max" data-screen="onboarding-closing" :data-active="currentScreen=='onboarding-closing'">
          <img class="w-full max-w-100" src="/img/screens/peo/onboarding-peo-closing.png?v=10122022" />
          <div class="links">
            <screen-link left="85%" top="2%" width="5%" height="4.5%" dot-class="dot-right-center" data-tgt="hiring-peo-workers-add-details" data-tgt-mode="admin" @click.native="close"></screen-link>


            <screen-link left="10%" top="19.5%" width="20%" height="4.5%" data-tgt-scrolltop="true" data-tgt="onboarding-employee-info" @click.native="onLinkClick"></screen-link>
            <screen-link left="10%" top="26.25%" width="20%" height="4.5%" data-tgt-scrolltop="true" data-tgt="onboarding-employment" @click.native="onLinkClick"></screen-link>
            <screen-link left="10%" top="33.5%" width="20%" height="4.5%" data-tgt-scrolltop="true" data-tgt="onboarding-employment-cont" @click.native="onLinkClick"></screen-link>
            <screen-link left="10%" top="40.5%" width="20%" height="4.5%" data-tgt-scrolltop="true" data-tgt="onboarding-compensation" @click.native="onLinkClick"></screen-link>
            <screen-link left="10%" top="47.5%" width="20%" height="4.5%" data-tgt-scrolltop="true" data-tgt="onboarding-signature" @click.native="onLinkClick"></screen-link>
            <screen-link left="10%" top="54.5%" width="20%" height="4.5%" data-tgt-scrolltop="true" data-tgt="onboarding-closing" @click.native="onLinkClick"></screen-link>


            <screen-link left="81.5%" top="30.5%" width="6.5%" height="4.5%" dot-class="dot-left-center" data-tgt="onboarding-email-2" @click.native="onLinkClick"></screen-link>

          </div>
        </div>

        <div class="screen z-max" data-screen="onboarding-email-2" :data-active="currentScreen=='onboarding-email-2'">
          <img class="w-full max-w-100" src="/img/screens/peo/onboarding-email.png?v=10122022" />
          <div class="links">
            <screen-link left="97%" top="0%" width="3%" height="4.5%" dot-class="dot-bottom-center" data-tgt="hiring-peo-workers-add-details" data-tgt-mode="admin" @click.native="onLinkClick"></screen-link>
            <screen-link left="17.75%" top="74%" width="5%" height="4%" dot-class="dot-bottom-center" data-tgt="onboarding-new-hire-confirm" @click.native="onLinkClick"></screen-link>

          </div>
        </div>

        <div class="screen z-max" data-screen="onboarding-new-hire-confirm" :data-active="currentScreen=='onboarding-new-hire-confirm'">
          <img class="w-full max-w-100" src="/img/screens/peo/onboarding-peo-new-hire-confirm.png?v=10122022" />
          <div class="links">
            <screen-link left="97%" top="0%" width="3%" height="4.5%" dot-class="dot-bottom-center" data-tgt="hiring-peo-workers-add-details" data-tgt-mode="admin" @click.native="onLinkClick"></screen-link>
            <screen-link left="63%" top="55%" width="7%" height="4%" dot-class="dot-left-center" data-tgt="onboarding-verification" @click.native="onLinkClick"></screen-link>

          </div>
        </div>

        <div class="screen z-max" data-screen="onboarding-verification" :data-active="currentScreen=='onboarding-verification'">
          <img class="w-full max-w-100" src="/img/screens/peo/onboarding-peo-verification.png?v=10122022" />
          <div class="links">
            <screen-link left="97%" top="0%" width="3%" height="4.5%" dot-class="dot-bottom-center" data-tgt="hiring-peo-workers-add-details" data-tgt-mode="admin" @click.native="onLinkClick"></screen-link>
            <screen-link left="39%" top="46.5%" width="22%" height="5.5%" dot-class="dot-left-center" data-tgt="onboarding-verification-code" @click.native="onLinkClick"></screen-link>

          </div>
        </div>

        <div class="screen z-max" data-screen="onboarding-verification-code" :data-active="currentScreen=='onboarding-verification-code'">
          <img class="w-full max-w-100" src="/img/screens/peo/onboarding-peo-verification-code.png?v=10122022" />
          <div class="links">
            <screen-link left="97%" top="0%" width="3%" height="4.5%" dot-class="dot-bottom-center" data-tgt="hiring-peo-workers-add-details" data-tgt-mode="admin" @click.native="onLinkClick"></screen-link>
            <screen-link left="39%" top="31.8%" width="22%" height="5.5%" dot-class="dot-left-center" data-tgt="onboarding-verification-code-entered" @click.native="onLinkClick"></screen-link>

          </div>
        </div>

        <div class="screen z-max" data-screen="onboarding-verification-code-entered" :data-active="currentScreen=='onboarding-verification-code-entered'">
          <img class="w-full max-w-100" src="/img/screens/peo/onboarding-peo-verification-code-entered.png?v=10122022" />
          <div class="links">
            <screen-link left="97%" top="0%" width="3%" height="4.5%" dot-class="dot-bottom-center" data-tgt="hiring-peo-workers-add-details" data-tgt-mode="admin" @click.native="onLinkClick"></screen-link>
            <screen-link left="39%" top="52.8%" width="22%" height="5.5%" dot-class="dot-left-center" data-tgt="onboarding-new-user" @click.native="onLinkClick"></screen-link>

          </div>
        </div>

        <div class="screen z-max" data-screen="onboarding-new-user" :data-active="currentScreen=='onboarding-new-user'">
          <img class="w-full max-w-100" src="/img/screens/peo/onboarding-peo-new-user.png?v=10122022" />
          <div class="links">
            <screen-link left="97%" top="0%" width="3%" height="4.5%" dot-class="dot-bottom-center" data-tgt="hiring-peo-workers-add-details" data-tgt-mode="admin" @click.native="onLinkClick"></screen-link>

            <screen-link left="10%" top="12.25%" width="20%" height="4.5%" data-tgt-scrolltop="true" data-tgt="onboarding-welcome" @click.native="onLinkClick"></screen-link>
            <screen-link left="10%" top="19.5%" width="20%" height="4.5%" data-tgt-scrolltop="true" data-tgt="onboarding-w4" @click.native="onLinkClick"></screen-link>
            <screen-link left="10%" top="26.25%" width="20%" height="4.5%" data-tgt-scrolltop="true" data-tgt="onboarding-state" @click.native="onLinkClick"></screen-link>
            <screen-link left="10%" top="33.5%" width="20%" height="4.5%" data-tgt-scrolltop="true" data-tgt="onboarding-19-verification" @click.native="onLinkClick"></screen-link>
            <screen-link left="10%" top="40.5%" width="20%" height="4.5%" data-tgt-scrolltop="true" data-tgt="onboarding-eeo" @click.native="onLinkClick"></screen-link>
            <screen-link left="10%" top="47.5%" width="20%" height="4.5%" data-tgt-scrolltop="true" data-tgt="onboarding-acknowledgement" @click.native="onLinkClick"></screen-link>
            <screen-link left="10%" top="54.5%" width="20%" height="4.5%" data-tgt-scrolltop="true" data-tgt="onboarding-packet" @click.native="onLinkClick"></screen-link>
            <screen-link left="10%" top="61.75%" width="20%" height="4.5%" data-tgt-scrolltop="true" data-tgt="onboarding-direct-deposit" @click.native="onLinkClick"></screen-link>
            <screen-link left="10%" top="68.75%" width="20%" height="4.5%" data-tgt-scrolltop="true" data-tgt="onboarding-marketplace" @click.native="onLinkClick"></screen-link>
            <screen-link left="10%" top="75.75%" width="20%" height="4.5%" data-tgt-scrolltop="true" data-tgt="onboarding-final-step" @click.native="onLinkClick"></screen-link>
            <screen-link left="10%" top="82.75%" width="20%" height="4.5%" data-tgt-scrolltop="true" data-tgt="onboarding-completed" @click.native="onLinkClick"></screen-link>

            <screen-link left="81.5%" top="95.5%" width="7%" height="5.5%" dot-class="dot-left-center" data-tgt="onboarding-security" @click.native="onLinkClick"></screen-link>

          </div>
        </div>

        <div class="screen z-max" data-screen="onboarding-security" :data-active="currentScreen=='onboarding-security'">
          <img class="w-full max-w-100" src="/img/screens/peo/onboarding-peo-security.png?v=10122022" />
          <div class="links">
            <screen-link left="97%" top="0%" width="3%" height="4.5%" dot-class="dot-bottom-center" data-tgt="hiring-peo-workers-add-details" data-tgt-mode="admin" @click.native="onLinkClick"></screen-link>

            <screen-link left="10%" top="12.25%" width="20%" height="4.5%" data-tgt-scrolltop="true" data-tgt="onboarding-welcome" @click.native="onLinkClick"></screen-link>
            <screen-link left="10%" top="19.5%" width="20%" height="4.5%" data-tgt-scrolltop="true" data-tgt="onboarding-w4" @click.native="onLinkClick"></screen-link>
            <screen-link left="10%" top="26.25%" width="20%" height="4.5%" data-tgt-scrolltop="true" data-tgt="onboarding-state" @click.native="onLinkClick"></screen-link>
            <screen-link left="10%" top="33.5%" width="20%" height="4.5%" data-tgt-scrolltop="true" data-tgt="onboarding-19-verification" @click.native="onLinkClick"></screen-link>
            <screen-link left="10%" top="40.5%" width="20%" height="4.5%" data-tgt-scrolltop="true" data-tgt="onboarding-eeo" @click.native="onLinkClick"></screen-link>
            <screen-link left="10%" top="47.5%" width="20%" height="4.5%" data-tgt-scrolltop="true" data-tgt="onboarding-acknowledgement" @click.native="onLinkClick"></screen-link>
            <screen-link left="10%" top="54.5%" width="20%" height="4.5%" data-tgt-scrolltop="true" data-tgt="onboarding-packet" @click.native="onLinkClick"></screen-link>
            <screen-link left="10%" top="61.75%" width="20%" height="4.5%" data-tgt-scrolltop="true" data-tgt="onboarding-direct-deposit" @click.native="onLinkClick"></screen-link>
            <screen-link left="10%" top="68.75%" width="20%" height="4.5%" data-tgt-scrolltop="true" data-tgt="onboarding-marketplace" @click.native="onLinkClick"></screen-link>
            <screen-link left="10%" top="75.75%" width="20%" height="4.5%" data-tgt-scrolltop="true" data-tgt="onboarding-final-step" @click.native="onLinkClick"></screen-link>
            <screen-link left="10%" top="82.75%" width="20%" height="4.5%" data-tgt-scrolltop="true" data-tgt="onboarding-completed" @click.native="onLinkClick"></screen-link>

            <screen-link left="81.5%" top="58.5%" width="7%" height="5.5%" dot-class="dot-left-center" data-tgt="onboarding-welcome" @click.native="onLinkClick"></screen-link>

          </div>
        </div>

        <div class="screen z-max" data-screen="onboarding-welcome" :data-active="currentScreen=='onboarding-welcome'">
          <img class="w-full max-w-100" src="/img/screens/peo/onboarding-peo-welcome.png?v=10122022" />
          <div class="links">
            <screen-link left="97%" top="0%" width="3%" height="4.5%" dot-class="dot-bottom-center" data-tgt="hiring-peo-workers-add-details" data-tgt-mode="admin" @click.native="onLinkClick"></screen-link>

            <screen-link left="10%" top="12.25%" width="20%" height="4.5%" data-tgt-scrolltop="true" data-tgt="onboarding-welcome" @click.native="onLinkClick"></screen-link>
            <screen-link left="10%" top="19.5%" width="20%" height="4.5%" data-tgt-scrolltop="true" data-tgt="onboarding-w4" @click.native="onLinkClick"></screen-link>
            <screen-link left="10%" top="26.25%" width="20%" height="4.5%" data-tgt-scrolltop="true" data-tgt="onboarding-state" @click.native="onLinkClick"></screen-link>
            <screen-link left="10%" top="33.5%" width="20%" height="4.5%" data-tgt-scrolltop="true" data-tgt="onboarding-19-verification" @click.native="onLinkClick"></screen-link>
            <screen-link left="10%" top="40.5%" width="20%" height="4.5%" data-tgt-scrolltop="true" data-tgt="onboarding-eeo" @click.native="onLinkClick"></screen-link>
            <screen-link left="10%" top="47.5%" width="20%" height="4.5%" data-tgt-scrolltop="true" data-tgt="onboarding-acknowledgement" @click.native="onLinkClick"></screen-link>
            <screen-link left="10%" top="54.5%" width="20%" height="4.5%" data-tgt-scrolltop="true" data-tgt="onboarding-packet" @click.native="onLinkClick"></screen-link>
            <screen-link left="10%" top="61.75%" width="20%" height="4.5%" data-tgt-scrolltop="true" data-tgt="onboarding-direct-deposit" @click.native="onLinkClick"></screen-link>
            <screen-link left="10%" top="68.75%" width="20%" height="4.5%" data-tgt-scrolltop="true" data-tgt="onboarding-marketplace" @click.native="onLinkClick"></screen-link>
            <screen-link left="10%" top="75.75%" width="20%" height="4.5%" data-tgt-scrolltop="true" data-tgt="onboarding-final-step" @click.native="onLinkClick"></screen-link>
            <screen-link left="10%" top="82.75%" width="20%" height="4.5%" data-tgt-scrolltop="true" data-tgt="onboarding-completed" @click.native="onLinkClick"></screen-link>

            <screen-link left="81.5%" top="37.5%" width="7%" height="5.5%" dot-class="dot-left-center" data-tgt="onboarding-legal-name" @click.native="onLinkClick"></screen-link>

          </div>
        </div>

        <div class="screen z-max" data-screen="onboarding-legal-name" :data-active="currentScreen=='onboarding-legal-name'">
          <img class="w-full max-w-100" src="/img/screens/peo/onboarding-peo-legal-name.png?v=10122022" />
          <div class="links">
            <screen-link left="97%" top="0%" width="3%" height="4.5%" dot-class="dot-bottom-center" data-tgt="hiring-peo-workers-add-details" data-tgt-mode="admin" @click.native="onLinkClick"></screen-link>

            <screen-link left="10%" top="12.25%" width="20%" height="4.5%" data-tgt-scrolltop="true" data-tgt="onboarding-welcome" @click.native="onLinkClick"></screen-link>
            <screen-link left="10%" top="19.5%" width="20%" height="4.5%" data-tgt-scrolltop="true" data-tgt="onboarding-w4" @click.native="onLinkClick"></screen-link>
            <screen-link left="10%" top="26.25%" width="20%" height="4.5%" data-tgt-scrolltop="true" data-tgt="onboarding-state" @click.native="onLinkClick"></screen-link>
            <screen-link left="10%" top="33.5%" width="20%" height="4.5%" data-tgt-scrolltop="true" data-tgt="onboarding-19-verification" @click.native="onLinkClick"></screen-link>
            <screen-link left="10%" top="40.5%" width="20%" height="4.5%" data-tgt-scrolltop="true" data-tgt="onboarding-eeo" @click.native="onLinkClick"></screen-link>
            <screen-link left="10%" top="47.5%" width="20%" height="4.5%" data-tgt-scrolltop="true" data-tgt="onboarding-acknowledgement" @click.native="onLinkClick"></screen-link>
            <screen-link left="10%" top="54.5%" width="20%" height="4.5%" data-tgt-scrolltop="true" data-tgt="onboarding-packet" @click.native="onLinkClick"></screen-link>
            <screen-link left="10%" top="61.75%" width="20%" height="4.5%" data-tgt-scrolltop="true" data-tgt="onboarding-direct-deposit" @click.native="onLinkClick"></screen-link>
            <screen-link left="10%" top="68.75%" width="20%" height="4.5%" data-tgt-scrolltop="true" data-tgt="onboarding-marketplace" @click.native="onLinkClick"></screen-link>
            <screen-link left="10%" top="75.75%" width="20%" height="4.5%" data-tgt-scrolltop="true" data-tgt="onboarding-final-step" @click.native="onLinkClick"></screen-link>
            <screen-link left="10%" top="82.75%" width="20%" height="4.5%" data-tgt-scrolltop="true" data-tgt="onboarding-completed" @click.native="onLinkClick"></screen-link>

            <screen-link left="81.5%" top="79%" width="7%" height="5.5%" dot-class="dot-left-center" data-tgt="onboarding-employee-address" @click.native="onLinkClick"></screen-link>

          </div>
        </div>

        <div class="screen z-max" data-screen="onboarding-employee-address" :data-active="currentScreen=='onboarding-employee-address'">
          <img class="w-full max-w-100" src="/img/screens/peo/onboarding-peo-employee-address.png?v=10122022" />
          <div class="links">
            <screen-link left="97%" top="0%" width="3%" height="4.5%" dot-class="dot-bottom-center" data-tgt="hiring-peo-workers-add-details" data-tgt-mode="admin" @click.native="onLinkClick"></screen-link>

            <screen-link left="10%" top="12.25%" width="20%" height="4.5%" data-tgt-scrolltop="true" data-tgt="onboarding-welcome" @click.native="onLinkClick"></screen-link>
            <screen-link left="10%" top="19.5%" width="20%" height="4.5%" data-tgt-scrolltop="true" data-tgt="onboarding-w4" @click.native="onLinkClick"></screen-link>
            <screen-link left="10%" top="26.25%" width="20%" height="4.5%" data-tgt-scrolltop="true" data-tgt="onboarding-state" @click.native="onLinkClick"></screen-link>
            <screen-link left="10%" top="33.5%" width="20%" height="4.5%" data-tgt-scrolltop="true" data-tgt="onboarding-19-verification" @click.native="onLinkClick"></screen-link>
            <screen-link left="10%" top="40.5%" width="20%" height="4.5%" data-tgt-scrolltop="true" data-tgt="onboarding-eeo" @click.native="onLinkClick"></screen-link>
            <screen-link left="10%" top="47.5%" width="20%" height="4.5%" data-tgt-scrolltop="true" data-tgt="onboarding-acknowledgement" @click.native="onLinkClick"></screen-link>
            <screen-link left="10%" top="54.5%" width="20%" height="4.5%" data-tgt-scrolltop="true" data-tgt="onboarding-packet" @click.native="onLinkClick"></screen-link>
            <screen-link left="10%" top="61.75%" width="20%" height="4.5%" data-tgt-scrolltop="true" data-tgt="onboarding-direct-deposit" @click.native="onLinkClick"></screen-link>
            <screen-link left="10%" top="68.75%" width="20%" height="4.5%" data-tgt-scrolltop="true" data-tgt="onboarding-marketplace" @click.native="onLinkClick"></screen-link>
            <screen-link left="10%" top="75.75%" width="20%" height="4.5%" data-tgt-scrolltop="true" data-tgt="onboarding-final-step" @click.native="onLinkClick"></screen-link>
            <screen-link left="10%" top="82.75%" width="20%" height="4.5%" data-tgt-scrolltop="true" data-tgt="onboarding-completed" @click.native="onLinkClick"></screen-link>

            <screen-link left="81.5%" top="134%" width="7%" height="5.5%" dot-class="dot-left-center" data-tgt="onboarding-w4" @click.native="onLinkClick"></screen-link>

          </div>
        </div>

        <div class="screen z-max" data-screen="onboarding-w4" :data-active="currentScreen=='onboarding-w4'">
          <img class="w-full max-w-100" src="/img/screens/peo/onboarding-peo-w4.png?v=10122022" />
          <div class="links">
            <screen-link left="97%" top="0%" width="3%" height="4.5%" dot-class="dot-bottom-center" data-tgt="hiring-peo-workers-add-details" data-tgt-mode="admin" @click.native="onLinkClick"></screen-link>

            <screen-link left="10%" top="12.25%" width="20%" height="4.5%" data-tgt-scrolltop="true" data-tgt="onboarding-welcome" @click.native="onLinkClick"></screen-link>
            <screen-link left="10%" top="19.5%" width="20%" height="4.5%" data-tgt-scrolltop="true" data-tgt="onboarding-w4" @click.native="onLinkClick"></screen-link>
            <screen-link left="10%" top="26.25%" width="20%" height="4.5%" data-tgt-scrolltop="true" data-tgt="onboarding-state" @click.native="onLinkClick"></screen-link>
            <screen-link left="10%" top="33.5%" width="20%" height="4.5%" data-tgt-scrolltop="true" data-tgt="onboarding-19-verification" @click.native="onLinkClick"></screen-link>
            <screen-link left="10%" top="40.5%" width="20%" height="4.5%" data-tgt-scrolltop="true" data-tgt="onboarding-eeo" @click.native="onLinkClick"></screen-link>
            <screen-link left="10%" top="47.5%" width="20%" height="4.5%" data-tgt-scrolltop="true" data-tgt="onboarding-acknowledgement" @click.native="onLinkClick"></screen-link>
            <screen-link left="10%" top="54.5%" width="20%" height="4.5%" data-tgt-scrolltop="true" data-tgt="onboarding-packet" @click.native="onLinkClick"></screen-link>
            <screen-link left="10%" top="61.75%" width="20%" height="4.5%" data-tgt-scrolltop="true" data-tgt="onboarding-direct-deposit" @click.native="onLinkClick"></screen-link>
            <screen-link left="10%" top="68.75%" width="20%" height="4.5%" data-tgt-scrolltop="true" data-tgt="onboarding-marketplace" @click.native="onLinkClick"></screen-link>
            <screen-link left="10%" top="75.75%" width="20%" height="4.5%" data-tgt-scrolltop="true" data-tgt="onboarding-final-step" @click.native="onLinkClick"></screen-link>
            <screen-link left="10%" top="82.75%" width="20%" height="4.5%" data-tgt-scrolltop="true" data-tgt="onboarding-completed" @click.native="onLinkClick"></screen-link>

            <screen-link left="81.5%" top="39%" width="7%" height="5.5%" dot-class="dot-left-center" data-tgt="onboarding-w4-form" @click.native="onLinkClick"></screen-link>

          </div>
        </div>

        <div class="screen z-max" data-screen="onboarding-w4-form" :data-active="currentScreen=='onboarding-w4-form'">
          <img class="w-full max-w-100" src="/img/screens/peo/onboarding-peo-w4-form.png?v=10122022" />
          <div class="links">
            <screen-link left="97%" top="0%" width="3%" height="4.5%" dot-class="dot-bottom-center" data-tgt="hiring-peo-workers-add-details" data-tgt-mode="admin" @click.native="onLinkClick"></screen-link>

            <screen-link left="10%" top="12.25%" width="20%" height="4.5%" data-tgt-scrolltop="true" data-tgt="onboarding-welcome" @click.native="onLinkClick"></screen-link>
            <screen-link left="10%" top="19.5%" width="20%" height="4.5%" data-tgt-scrolltop="true" data-tgt="onboarding-w4" @click.native="onLinkClick"></screen-link>
            <screen-link left="10%" top="26.25%" width="20%" height="4.5%" data-tgt-scrolltop="true" data-tgt="onboarding-state" @click.native="onLinkClick"></screen-link>
            <screen-link left="10%" top="33.5%" width="20%" height="4.5%" data-tgt-scrolltop="true" data-tgt="onboarding-19-verification" @click.native="onLinkClick"></screen-link>
            <screen-link left="10%" top="40.5%" width="20%" height="4.5%" data-tgt-scrolltop="true" data-tgt="onboarding-eeo" @click.native="onLinkClick"></screen-link>
            <screen-link left="10%" top="47.5%" width="20%" height="4.5%" data-tgt-scrolltop="true" data-tgt="onboarding-acknowledgement" @click.native="onLinkClick"></screen-link>
            <screen-link left="10%" top="54.5%" width="20%" height="4.5%" data-tgt-scrolltop="true" data-tgt="onboarding-packet" @click.native="onLinkClick"></screen-link>
            <screen-link left="10%" top="61.75%" width="20%" height="4.5%" data-tgt-scrolltop="true" data-tgt="onboarding-direct-deposit" @click.native="onLinkClick"></screen-link>
            <screen-link left="10%" top="68.75%" width="20%" height="4.5%" data-tgt-scrolltop="true" data-tgt="onboarding-marketplace" @click.native="onLinkClick"></screen-link>
            <screen-link left="10%" top="75.75%" width="20%" height="4.5%" data-tgt-scrolltop="true" data-tgt="onboarding-final-step" @click.native="onLinkClick"></screen-link>
            <screen-link left="10%" top="82.75%" width="20%" height="4.5%" data-tgt-scrolltop="true" data-tgt="onboarding-completed" @click.native="onLinkClick"></screen-link>

            <screen-link left="81.5%" top="153.5%" width="7%" height="5.5%" dot-class="dot-left-center" data-tgt-scrolltop="true" data-tgt="onboarding-w4-doc" @click.native="onLinkClick"></screen-link>

          </div>
        </div>

        <div class="screen z-max" data-screen="onboarding-w4-doc" :data-active="currentScreen=='onboarding-w4-doc'">
          <img class="w-full max-w-100" src="/img/screens/peo/onboarding-peo-w4-doc.png?v=10122022" />
          <div class="links">
            <screen-link left="97%" top="0%" width="3%" height="4.5%" dot-class="dot-bottom-center" data-tgt="hiring-peo-workers-add-details" data-tgt-mode="admin" @click.native="onLinkClick"></screen-link>

            <screen-link left="10%" top="12.25%" width="20%" height="4.5%" data-tgt-scrolltop="true" data-tgt="onboarding-welcome" @click.native="onLinkClick"></screen-link>
            <screen-link left="10%" top="19.5%" width="20%" height="4.5%" data-tgt-scrolltop="true" data-tgt="onboarding-w4" @click.native="onLinkClick"></screen-link>
            <screen-link left="10%" top="26.25%" width="20%" height="4.5%" data-tgt-scrolltop="true" data-tgt="onboarding-state" @click.native="onLinkClick"></screen-link>
            <screen-link left="10%" top="33.5%" width="20%" height="4.5%" data-tgt-scrolltop="true" data-tgt="onboarding-19-verification" @click.native="onLinkClick"></screen-link>
            <screen-link left="10%" top="40.5%" width="20%" height="4.5%" data-tgt-scrolltop="true" data-tgt="onboarding-eeo" @click.native="onLinkClick"></screen-link>
            <screen-link left="10%" top="47.5%" width="20%" height="4.5%" data-tgt-scrolltop="true" data-tgt="onboarding-acknowledgement" @click.native="onLinkClick"></screen-link>
            <screen-link left="10%" top="54.5%" width="20%" height="4.5%" data-tgt-scrolltop="true" data-tgt="onboarding-packet" @click.native="onLinkClick"></screen-link>
            <screen-link left="10%" top="61.75%" width="20%" height="4.5%" data-tgt-scrolltop="true" data-tgt="onboarding-direct-deposit" @click.native="onLinkClick"></screen-link>
            <screen-link left="10%" top="68.75%" width="20%" height="4.5%" data-tgt-scrolltop="true" data-tgt="onboarding-marketplace" @click.native="onLinkClick"></screen-link>
            <screen-link left="10%" top="75.75%" width="20%" height="4.5%" data-tgt-scrolltop="true" data-tgt="onboarding-final-step" @click.native="onLinkClick"></screen-link>
            <screen-link left="10%" top="82.75%" width="20%" height="4.5%" data-tgt-scrolltop="true" data-tgt="onboarding-completed" @click.native="onLinkClick"></screen-link>

            <screen-link left="78.5%" top="164%" width="10%" height="5.5%" dot-class="dot-left-center" data-tgt="onboarding-state" @click.native="showOverlay('sign-w4')"></screen-link>
            <document-sign v-if="$store.state.overlayPanel === 'sign-w4'" person="Jennifer Trayler" confirm-tgt="onboarding-state" confirm-mode="peo"></document-sign>

          </div>
        </div>

        <div class="screen z-max" data-screen="onboarding-state" :data-active="currentScreen=='onboarding-state'">
          <img class="w-full max-w-100" src="/img/screens/peo/onboarding-peo-state.png?v=10122022" />
          <div class="links">
            <screen-link left="97%" top="0%" width="3%" height="4.5%" dot-class="dot-bottom-center" data-tgt="hiring-peo-workers-add-details" data-tgt-mode="admin" @click.native="onLinkClick"></screen-link>

            <screen-link left="10%" top="12.25%" width="20%" height="4.5%" data-tgt-scrolltop="true" data-tgt="onboarding-welcome" @click.native="onLinkClick"></screen-link>
            <screen-link left="10%" top="19.5%" width="20%" height="4.5%" data-tgt-scrolltop="true" data-tgt="onboarding-w4" @click.native="onLinkClick"></screen-link>
            <screen-link left="10%" top="26.25%" width="20%" height="4.5%" data-tgt-scrolltop="true" data-tgt="onboarding-state" @click.native="onLinkClick"></screen-link>
            <screen-link left="10%" top="33.5%" width="20%" height="4.5%" data-tgt-scrolltop="true" data-tgt="onboarding-19-verification" @click.native="onLinkClick"></screen-link>
            <screen-link left="10%" top="40.5%" width="20%" height="4.5%" data-tgt-scrolltop="true" data-tgt="onboarding-eeo" @click.native="onLinkClick"></screen-link>
            <screen-link left="10%" top="47.5%" width="20%" height="4.5%" data-tgt-scrolltop="true" data-tgt="onboarding-acknowledgement" @click.native="onLinkClick"></screen-link>
            <screen-link left="10%" top="54.5%" width="20%" height="4.5%" data-tgt-scrolltop="true" data-tgt="onboarding-packet" @click.native="onLinkClick"></screen-link>
            <screen-link left="10%" top="61.75%" width="20%" height="4.5%" data-tgt-scrolltop="true" data-tgt="onboarding-direct-deposit" @click.native="onLinkClick"></screen-link>
            <screen-link left="10%" top="68.75%" width="20%" height="4.5%" data-tgt-scrolltop="true" data-tgt="onboarding-marketplace" @click.native="onLinkClick"></screen-link>
            <screen-link left="10%" top="75.75%" width="20%" height="4.5%" data-tgt-scrolltop="true" data-tgt="onboarding-final-step" @click.native="onLinkClick"></screen-link>
            <screen-link left="10%" top="82.75%" width="20%" height="4.5%" data-tgt-scrolltop="true" data-tgt="onboarding-completed" @click.native="onLinkClick"></screen-link>

            <screen-link left="36%" top="38%" width="12%" height="5.5%" dot-class="dot-left-center" data-tgt="onboarding-ohio-tax-form" @click.native="onLinkClick"></screen-link>


          </div>
        </div>

        <div class="screen z-max" data-screen="onboarding-ohio-tax-form" :data-active="currentScreen=='onboarding-ohio-tax-form'">
          <img class="w-full max-w-100" src="/img/screens/peo/onboarding-peo-ohio-tax-form.png?v=10122022" />
          <div class="links">
            <screen-link left="97%" top="0%" width="3%" height="4.5%" dot-class="dot-bottom-center" data-tgt="hiring-peo-workers-add-details" data-tgt-mode="admin" @click.native="onLinkClick"></screen-link>

            <screen-link left="10%" top="12.25%" width="20%" height="4.5%" data-tgt-scrolltop="true" data-tgt="onboarding-welcome" @click.native="onLinkClick"></screen-link>
            <screen-link left="10%" top="19.5%" width="20%" height="4.5%" data-tgt-scrolltop="true" data-tgt="onboarding-w4" @click.native="onLinkClick"></screen-link>
            <screen-link left="10%" top="26.25%" width="20%" height="4.5%" data-tgt-scrolltop="true" data-tgt="onboarding-state" @click.native="onLinkClick"></screen-link>
            <screen-link left="10%" top="33.5%" width="20%" height="4.5%" data-tgt-scrolltop="true" data-tgt="onboarding-19-verification" @click.native="onLinkClick"></screen-link>
            <screen-link left="10%" top="40.5%" width="20%" height="4.5%" data-tgt-scrolltop="true" data-tgt="onboarding-eeo" @click.native="onLinkClick"></screen-link>
            <screen-link left="10%" top="47.5%" width="20%" height="4.5%" data-tgt-scrolltop="true" data-tgt="onboarding-acknowledgement" @click.native="onLinkClick"></screen-link>
            <screen-link left="10%" top="54.5%" width="20%" height="4.5%" data-tgt-scrolltop="true" data-tgt="onboarding-packet" @click.native="onLinkClick"></screen-link>
            <screen-link left="10%" top="61.75%" width="20%" height="4.5%" data-tgt-scrolltop="true" data-tgt="onboarding-direct-deposit" @click.native="onLinkClick"></screen-link>
            <screen-link left="10%" top="68.75%" width="20%" height="4.5%" data-tgt-scrolltop="true" data-tgt="onboarding-marketplace" @click.native="onLinkClick"></screen-link>
            <screen-link left="10%" top="75.75%" width="20%" height="4.5%" data-tgt-scrolltop="true" data-tgt="onboarding-final-step" @click.native="onLinkClick"></screen-link>
            <screen-link left="10%" top="82.75%" width="20%" height="4.5%" data-tgt-scrolltop="true" data-tgt="onboarding-completed" @click.native="onLinkClick"></screen-link>

            <screen-link left="81.5%" top="150.5%" width="8%" height="5.5%" dot-class="dot-left-center" data-tgt="onboarding-ohio-tax-doc" data-tgt-scrolltop="true" @click.native="onLinkClick"></screen-link>


          </div>
        </div>

        <div class="screen z-max" data-screen="onboarding-ohio-tax-doc" :data-active="currentScreen=='onboarding-ohio-tax-doc'">
          <img class="w-full max-w-100" src="/img/screens/peo/onboarding-peo-ohio-tax-doc.png?v=10122022" />
          <div class="links">
            <screen-link left="97%" top="0%" width="3%" height="4.5%" dot-class="dot-bottom-center" data-tgt="hiring-peo-workers-add-details" data-tgt-mode="admin" @click.native="onLinkClick"></screen-link>

            <screen-link left="10%" top="12.25%" width="20%" height="4.5%" data-tgt-scrolltop="true" data-tgt="onboarding-welcome" @click.native="onLinkClick"></screen-link>
            <screen-link left="10%" top="19.5%" width="20%" height="4.5%" data-tgt-scrolltop="true" data-tgt="onboarding-w4" @click.native="onLinkClick"></screen-link>
            <screen-link left="10%" top="26.25%" width="20%" height="4.5%" data-tgt-scrolltop="true" data-tgt="onboarding-state" @click.native="onLinkClick"></screen-link>
            <screen-link left="10%" top="33.5%" width="20%" height="4.5%" data-tgt-scrolltop="true" data-tgt="onboarding-19-verification" @click.native="onLinkClick"></screen-link>
            <screen-link left="10%" top="40.5%" width="20%" height="4.5%" data-tgt-scrolltop="true" data-tgt="onboarding-eeo" @click.native="onLinkClick"></screen-link>
            <screen-link left="10%" top="47.5%" width="20%" height="4.5%" data-tgt-scrolltop="true" data-tgt="onboarding-acknowledgement" @click.native="onLinkClick"></screen-link>
            <screen-link left="10%" top="54.5%" width="20%" height="4.5%" data-tgt-scrolltop="true" data-tgt="onboarding-packet" @click.native="onLinkClick"></screen-link>
            <screen-link left="10%" top="61.75%" width="20%" height="4.5%" data-tgt-scrolltop="true" data-tgt="onboarding-direct-deposit" @click.native="onLinkClick"></screen-link>
            <screen-link left="10%" top="68.75%" width="20%" height="4.5%" data-tgt-scrolltop="true" data-tgt="onboarding-marketplace" @click.native="onLinkClick"></screen-link>
            <screen-link left="10%" top="75.75%" width="20%" height="4.5%" data-tgt-scrolltop="true" data-tgt="onboarding-final-step" @click.native="onLinkClick"></screen-link>
            <screen-link left="10%" top="82.75%" width="20%" height="4.5%" data-tgt-scrolltop="true" data-tgt="onboarding-completed" @click.native="onLinkClick"></screen-link>

            <screen-link left="78.5%" top="164%" width="10%" height="5.5%" dot-class="dot-left-center" data-tgt="onboarding-state" @click.native="showOverlay('sign-state')"></screen-link>
            <document-sign v-if="$store.state.overlayPanel === 'sign-state'" person="Jennifer Trayler" confirm-tgt="onboarding-state-forms" confirm-mode="peo"></document-sign>


          </div>
        </div>

        <div class="screen z-max" data-screen="onboarding-state-forms" :data-active="currentScreen=='onboarding-state-forms'">
          <img class="w-full max-w-100" src="/img/screens/peo/onboarding-peo-state-forms.png?v=10122022" />
          <div class="links">
            <screen-link left="97%" top="0%" width="3%" height="4.5%" dot-class="dot-bottom-center" data-tgt="hiring-peo-workers-add-details" data-tgt-mode="admin" @click.native="onLinkClick"></screen-link>

            <screen-link left="10%" top="12.25%" width="20%" height="4.5%" data-tgt-scrolltop="true" data-tgt="onboarding-welcome" @click.native="onLinkClick"></screen-link>
            <screen-link left="10%" top="19.5%" width="20%" height="4.5%" data-tgt-scrolltop="true" data-tgt="onboarding-w4" @click.native="onLinkClick"></screen-link>
            <screen-link left="10%" top="26.25%" width="20%" height="4.5%" data-tgt-scrolltop="true" data-tgt="onboarding-state" @click.native="onLinkClick"></screen-link>
            <screen-link left="10%" top="33.5%" width="20%" height="4.5%" data-tgt-scrolltop="true" data-tgt="onboarding-19-verification" @click.native="onLinkClick"></screen-link>
            <screen-link left="10%" top="40.5%" width="20%" height="4.5%" data-tgt-scrolltop="true" data-tgt="onboarding-eeo" @click.native="onLinkClick"></screen-link>
            <screen-link left="10%" top="47.5%" width="20%" height="4.5%" data-tgt-scrolltop="true" data-tgt="onboarding-acknowledgement" @click.native="onLinkClick"></screen-link>
            <screen-link left="10%" top="54.5%" width="20%" height="4.5%" data-tgt-scrolltop="true" data-tgt="onboarding-packet" @click.native="onLinkClick"></screen-link>
            <screen-link left="10%" top="61.75%" width="20%" height="4.5%" data-tgt-scrolltop="true" data-tgt="onboarding-direct-deposit" @click.native="onLinkClick"></screen-link>
            <screen-link left="10%" top="68.75%" width="20%" height="4.5%" data-tgt-scrolltop="true" data-tgt="onboarding-marketplace" @click.native="onLinkClick"></screen-link>
            <screen-link left="10%" top="75.75%" width="20%" height="4.5%" data-tgt-scrolltop="true" data-tgt="onboarding-final-step" @click.native="onLinkClick"></screen-link>
            <screen-link left="10%" top="82.75%" width="20%" height="4.5%" data-tgt-scrolltop="true" data-tgt="onboarding-completed" @click.native="onLinkClick"></screen-link>

            <screen-link left="65%" top="35.5%" width="23.5%" height="5.5%" dot-class="dot-right-center" data-tgt="onboarding-19-verification" @click.native="onLinkClick"></screen-link>


          </div>
        </div>

        <div class="screen z-max" data-screen="onboarding-19-verification" :data-active="currentScreen=='onboarding-19-verification'">
          <img class="w-full max-w-100" src="/img/screens/peo/onboarding-peo-i9-verification.png?v=10122022" />
          <div class="links">
            <screen-link left="97%" top="0%" width="3%" height="4.5%" dot-class="dot-bottom-center" data-tgt="hiring-peo-workers-add-details" data-tgt-mode="admin" @click.native="onLinkClick"></screen-link>

            <screen-link left="10%" top="12.25%" width="20%" height="4.5%" data-tgt-scrolltop="true" data-tgt="onboarding-welcome" @click.native="onLinkClick"></screen-link>
            <screen-link left="10%" top="19.5%" width="20%" height="4.5%" data-tgt-scrolltop="true" data-tgt="onboarding-w4" @click.native="onLinkClick"></screen-link>
            <screen-link left="10%" top="26.25%" width="20%" height="4.5%" data-tgt-scrolltop="true" data-tgt="onboarding-state" @click.native="onLinkClick"></screen-link>
            <screen-link left="10%" top="33.5%" width="20%" height="4.5%" data-tgt-scrolltop="true" data-tgt="onboarding-19-verification" @click.native="onLinkClick"></screen-link>
            <screen-link left="10%" top="40.5%" width="20%" height="4.5%" data-tgt-scrolltop="true" data-tgt="onboarding-eeo" @click.native="onLinkClick"></screen-link>
            <screen-link left="10%" top="47.5%" width="20%" height="4.5%" data-tgt-scrolltop="true" data-tgt="onboarding-acknowledgement" @click.native="onLinkClick"></screen-link>
            <screen-link left="10%" top="54.5%" width="20%" height="4.5%" data-tgt-scrolltop="true" data-tgt="onboarding-packet" @click.native="onLinkClick"></screen-link>
            <screen-link left="10%" top="61.75%" width="20%" height="4.5%" data-tgt-scrolltop="true" data-tgt="onboarding-direct-deposit" @click.native="onLinkClick"></screen-link>
            <screen-link left="10%" top="68.75%" width="20%" height="4.5%" data-tgt-scrolltop="true" data-tgt="onboarding-marketplace" @click.native="onLinkClick"></screen-link>
            <screen-link left="10%" top="75.75%" width="20%" height="4.5%" data-tgt-scrolltop="true" data-tgt="onboarding-final-step" @click.native="onLinkClick"></screen-link>
            <screen-link left="10%" top="82.75%" width="20%" height="4.5%" data-tgt-scrolltop="true" data-tgt="onboarding-completed" @click.native="onLinkClick"></screen-link>

            <screen-link left="81%" top="32.5%" width="8%" height="5.5%" dot-class="dot-left-center" data-tgt="onboarding-eeo" @click.native="onLinkClick"></screen-link>


          </div>
        </div>

        <div class="screen z-max" data-screen="onboarding-eeo" :data-active="currentScreen=='onboarding-eeo'">
          <img class="w-full max-w-100" src="/img/screens/peo/onboarding-peo-eeo.png?v=10122022" />
          <div class="links">
            <screen-link left="97%" top="0%" width="3%" height="4.5%" dot-class="dot-bottom-center" data-tgt="hiring-peo-workers-add-details" data-tgt-mode="admin" @click.native="onLinkClick"></screen-link>

            <screen-link left="10%" top="12.25%" width="20%" height="4.5%" data-tgt-scrolltop="true" data-tgt="onboarding-welcome" @click.native="onLinkClick"></screen-link>
            <screen-link left="10%" top="19.5%" width="20%" height="4.5%" data-tgt-scrolltop="true" data-tgt="onboarding-w4" @click.native="onLinkClick"></screen-link>
            <screen-link left="10%" top="26.25%" width="20%" height="4.5%" data-tgt-scrolltop="true" data-tgt="onboarding-state" @click.native="onLinkClick"></screen-link>
            <screen-link left="10%" top="33.5%" width="20%" height="4.5%" data-tgt-scrolltop="true" data-tgt="onboarding-19-verification" @click.native="onLinkClick"></screen-link>
            <screen-link left="10%" top="40.5%" width="20%" height="4.5%" data-tgt-scrolltop="true" data-tgt="onboarding-eeo" @click.native="onLinkClick"></screen-link>
            <screen-link left="10%" top="47.5%" width="20%" height="4.5%" data-tgt-scrolltop="true" data-tgt="onboarding-acknowledgement" @click.native="onLinkClick"></screen-link>
            <screen-link left="10%" top="54.5%" width="20%" height="4.5%" data-tgt-scrolltop="true" data-tgt="onboarding-packet" @click.native="onLinkClick"></screen-link>
            <screen-link left="10%" top="61.75%" width="20%" height="4.5%" data-tgt-scrolltop="true" data-tgt="onboarding-direct-deposit" @click.native="onLinkClick"></screen-link>
            <screen-link left="10%" top="68.75%" width="20%" height="4.5%" data-tgt-scrolltop="true" data-tgt="onboarding-marketplace" @click.native="onLinkClick"></screen-link>
            <screen-link left="10%" top="75.75%" width="20%" height="4.5%" data-tgt-scrolltop="true" data-tgt="onboarding-final-step" @click.native="onLinkClick"></screen-link>
            <screen-link left="10%" top="82.75%" width="20%" height="4.5%" data-tgt-scrolltop="true" data-tgt="onboarding-completed" @click.native="onLinkClick"></screen-link>

            <screen-link left="81%" top="104%" width="8%" height="5.5%" dot-class="dot-left-center" data-tgt="onboarding-acknowledgement" data-tgt-scrolltop="true" @click.native="onLinkClick"></screen-link>

          </div>
        </div>

        <div class="screen z-max" data-screen="onboarding-eeo-acknowledge" :data-active="currentScreen=='onboarding-acknowledgement'">
          <img class="w-full max-w-100" src="/img/screens/peo/onboarding-peo-eeo-acknowledge.png?v=10122022" />
          <div class="links">
            <screen-link left="97%" top="0%" width="3%" height="4.5%" dot-class="dot-bottom-center" data-tgt="hiring-peo-workers-add-details" data-tgt-mode="admin" @click.native="onLinkClick"></screen-link>

            <screen-link left="10%" top="12.25%" width="20%" height="4.5%" data-tgt-scrolltop="true" data-tgt="onboarding-welcome" @click.native="onLinkClick"></screen-link>
            <screen-link left="10%" top="19.5%" width="20%" height="4.5%" data-tgt-scrolltop="true" data-tgt="onboarding-w4" @click.native="onLinkClick"></screen-link>
            <screen-link left="10%" top="26.25%" width="20%" height="4.5%" data-tgt-scrolltop="true" data-tgt="onboarding-state" @click.native="onLinkClick"></screen-link>
            <screen-link left="10%" top="33.5%" width="20%" height="4.5%" data-tgt-scrolltop="true" data-tgt="onboarding-19-verification" @click.native="onLinkClick"></screen-link>
            <screen-link left="10%" top="40.5%" width="20%" height="4.5%" data-tgt-scrolltop="true" data-tgt="onboarding-eeo" @click.native="onLinkClick"></screen-link>
            <screen-link left="10%" top="47.5%" width="20%" height="4.5%" data-tgt-scrolltop="true" data-tgt="onboarding-acknowledgement" @click.native="onLinkClick"></screen-link>
            <screen-link left="10%" top="54.5%" width="20%" height="4.5%" data-tgt-scrolltop="true" data-tgt="onboarding-packet" @click.native="onLinkClick"></screen-link>
            <screen-link left="10%" top="61.75%" width="20%" height="4.5%" data-tgt-scrolltop="true" data-tgt="onboarding-direct-deposit" @click.native="onLinkClick"></screen-link>
            <screen-link left="10%" top="68.75%" width="20%" height="4.5%" data-tgt-scrolltop="true" data-tgt="onboarding-marketplace" @click.native="onLinkClick"></screen-link>
            <screen-link left="10%" top="75.75%" width="20%" height="4.5%" data-tgt-scrolltop="true" data-tgt="onboarding-final-step" @click.native="onLinkClick"></screen-link>
            <screen-link left="10%" top="82.75%" width="20%" height="4.5%" data-tgt-scrolltop="true" data-tgt="onboarding-completed" @click.native="onLinkClick"></screen-link>

            <screen-link left="36%" top="42%" width="10%" height="5.5%" dot-class="dot-left-center" data-tgt="onboarding-acknowledged" @click.native="onLinkClick"></screen-link>

          </div>
        </div>

        <div class="screen z-max" data-screen="onboarding-eeo-acknowledged" :data-active="currentScreen=='onboarding-acknowledged'">
          <img class="w-full max-w-100" src="/img/screens/peo/onboarding-peo-eeo-acknowledged.png?v=10122022" />
          <div class="links">
            <screen-link left="97%" top="0%" width="3%" height="4.5%" dot-class="dot-bottom-center" data-tgt="hiring-peo-workers-add-details" data-tgt-mode="admin" @click.native="onLinkClick"></screen-link>

            <screen-link left="10%" top="12.25%" width="20%" height="4.5%" data-tgt-scrolltop="true" data-tgt="onboarding-welcome" @click.native="onLinkClick"></screen-link>
            <screen-link left="10%" top="19.5%" width="20%" height="4.5%" data-tgt-scrolltop="true" data-tgt="onboarding-w4" @click.native="onLinkClick"></screen-link>
            <screen-link left="10%" top="26.25%" width="20%" height="4.5%" data-tgt-scrolltop="true" data-tgt="onboarding-state" @click.native="onLinkClick"></screen-link>
            <screen-link left="10%" top="33.5%" width="20%" height="4.5%" data-tgt-scrolltop="true" data-tgt="onboarding-19-verification" @click.native="onLinkClick"></screen-link>
            <screen-link left="10%" top="40.5%" width="20%" height="4.5%" data-tgt-scrolltop="true" data-tgt="onboarding-eeo" @click.native="onLinkClick"></screen-link>
            <screen-link left="10%" top="47.5%" width="20%" height="4.5%" data-tgt-scrolltop="true" data-tgt="onboarding-acknowledgement" @click.native="onLinkClick"></screen-link>
            <screen-link left="10%" top="54.5%" width="20%" height="4.5%" data-tgt-scrolltop="true" data-tgt="onboarding-packet" @click.native="onLinkClick"></screen-link>
            <screen-link left="10%" top="61.75%" width="20%" height="4.5%" data-tgt-scrolltop="true" data-tgt="onboarding-direct-deposit" @click.native="onLinkClick"></screen-link>
            <screen-link left="10%" top="68.75%" width="20%" height="4.5%" data-tgt-scrolltop="true" data-tgt="onboarding-marketplace" @click.native="onLinkClick"></screen-link>
            <screen-link left="10%" top="75.75%" width="20%" height="4.5%" data-tgt-scrolltop="true" data-tgt="onboarding-final-step" @click.native="onLinkClick"></screen-link>
            <screen-link left="10%" top="82.75%" width="20%" height="4.5%" data-tgt-scrolltop="true" data-tgt="onboarding-completed" @click.native="onLinkClick"></screen-link>

            <screen-link left="81%" top="57.5%" width="8%" height="5.5%" dot-class="dot-left-center" data-tgt="onboarding-packet" @click.native="onLinkClick"></screen-link>

          </div>
        </div>

        <div class="screen z-max" data-screen="onboarding-packet" :data-active="currentScreen=='onboarding-packet'">
          <img class="w-full max-w-100" src="/img/screens/peo/onboarding-peo-packet.png?v=10122022" />
          <div class="links">
            <screen-link left="97%" top="0%" width="3%" height="4.5%" dot-class="dot-bottom-center" data-tgt="hiring-peo-workers-add-details" data-tgt-mode="admin" @click.native="onLinkClick"></screen-link>

            <screen-link left="10%" top="12.25%" width="20%" height="4.5%" data-tgt-scrolltop="true" data-tgt="onboarding-welcome" @click.native="onLinkClick"></screen-link>
            <screen-link left="10%" top="19.5%" width="20%" height="4.5%" data-tgt-scrolltop="true" data-tgt="onboarding-w4" @click.native="onLinkClick"></screen-link>
            <screen-link left="10%" top="26.25%" width="20%" height="4.5%" data-tgt-scrolltop="true" data-tgt="onboarding-state" @click.native="onLinkClick"></screen-link>
            <screen-link left="10%" top="33.5%" width="20%" height="4.5%" data-tgt-scrolltop="true" data-tgt="onboarding-19-verification" @click.native="onLinkClick"></screen-link>
            <screen-link left="10%" top="40.5%" width="20%" height="4.5%" data-tgt-scrolltop="true" data-tgt="onboarding-eeo" @click.native="onLinkClick"></screen-link>
            <screen-link left="10%" top="47.5%" width="20%" height="4.5%" data-tgt-scrolltop="true" data-tgt="onboarding-acknowledgement" @click.native="onLinkClick"></screen-link>
            <screen-link left="10%" top="54.5%" width="20%" height="4.5%" data-tgt-scrolltop="true" data-tgt="onboarding-packet" @click.native="onLinkClick"></screen-link>
            <screen-link left="10%" top="61.75%" width="20%" height="4.5%" data-tgt-scrolltop="true" data-tgt="onboarding-direct-deposit" @click.native="onLinkClick"></screen-link>
            <screen-link left="10%" top="68.75%" width="20%" height="4.5%" data-tgt-scrolltop="true" data-tgt="onboarding-marketplace" @click.native="onLinkClick"></screen-link>
            <screen-link left="10%" top="75.75%" width="20%" height="4.5%" data-tgt-scrolltop="true" data-tgt="onboarding-final-step" @click.native="onLinkClick"></screen-link>
            <screen-link left="10%" top="82.75%" width="20%" height="4.5%" data-tgt-scrolltop="true" data-tgt="onboarding-completed" @click.native="onLinkClick"></screen-link>

            <screen-link left="78.5%" top="164%" width="10%" height="5.5%" dot-class="dot-left-center" data-tgt="onboarding-state" @click.native="showOverlay('sign-packet')"></screen-link>
            <document-sign v-if="$store.state.overlayPanel === 'sign-packet'" person="Jennifer Trayler" confirm-tgt="onboarding-direct-deposit" confirm-mode="peo"></document-sign>


          </div>
        </div>

        <div class="screen z-max" data-screen="onboarding-direct-deposit" :data-active="currentScreen=='onboarding-direct-deposit'">
          <img class="w-full max-w-100" src="/img/screens/peo/onboarding-peo-direct-deposit.png?v=10122022" />
          <div class="links">
            <screen-link left="97%" top="0%" width="3%" height="4.5%" dot-class="dot-bottom-center" data-tgt="hiring-peo-workers-add-details" data-tgt-mode="admin" @click.native="onLinkClick"></screen-link>

            <screen-link left="10%" top="12.25%" width="20%" height="4.5%" data-tgt-scrolltop="true" data-tgt="onboarding-welcome" @click.native="onLinkClick"></screen-link>
            <screen-link left="10%" top="19.5%" width="20%" height="4.5%" data-tgt-scrolltop="true" data-tgt="onboarding-w4" @click.native="onLinkClick"></screen-link>
            <screen-link left="10%" top="26.25%" width="20%" height="4.5%" data-tgt-scrolltop="true" data-tgt="onboarding-state" @click.native="onLinkClick"></screen-link>
            <screen-link left="10%" top="33.5%" width="20%" height="4.5%" data-tgt-scrolltop="true" data-tgt="onboarding-19-verification" @click.native="onLinkClick"></screen-link>
            <screen-link left="10%" top="40.5%" width="20%" height="4.5%" data-tgt-scrolltop="true" data-tgt="onboarding-eeo" @click.native="onLinkClick"></screen-link>
            <screen-link left="10%" top="47.5%" width="20%" height="4.5%" data-tgt-scrolltop="true" data-tgt="onboarding-acknowledgement" @click.native="onLinkClick"></screen-link>
            <screen-link left="10%" top="54.5%" width="20%" height="4.5%" data-tgt-scrolltop="true" data-tgt="onboarding-packet" @click.native="onLinkClick"></screen-link>
            <screen-link left="10%" top="61.75%" width="20%" height="4.5%" data-tgt-scrolltop="true" data-tgt="onboarding-direct-deposit" @click.native="onLinkClick"></screen-link>
            <screen-link left="10%" top="68.75%" width="20%" height="4.5%" data-tgt-scrolltop="true" data-tgt="onboarding-marketplace" @click.native="onLinkClick"></screen-link>
            <screen-link left="10%" top="75.75%" width="20%" height="4.5%" data-tgt-scrolltop="true" data-tgt="onboarding-final-step" @click.native="onLinkClick"></screen-link>
            <screen-link left="10%" top="82.75%" width="20%" height="4.5%" data-tgt-scrolltop="true" data-tgt="onboarding-completed" @click.native="onLinkClick"></screen-link>

            <screen-link left="74%" top="30.5%" width="9%" height="5.5%" dot-class="dot-left-center" data-tgt="onboarding-direct-deposit-added" @click.native="onLinkClick"></screen-link>

          </div>
        </div>

        <div class="screen z-max" data-screen="onboarding-direct-deposit-added" :data-active="currentScreen=='onboarding-direct-deposit-added'">
          <img class="w-full max-w-100" src="/img/screens/peo/onboarding-peo-direct-deposit-added.png?v=10122022" />
          <div class="links">
            <screen-link left="97%" top="0%" width="3%" height="4.5%" dot-class="dot-bottom-center" data-tgt="hiring-peo-workers-add-details" data-tgt-mode="admin" @click.native="onLinkClick"></screen-link>

            <screen-link left="10%" top="12.25%" width="20%" height="4.5%" data-tgt-scrolltop="true" data-tgt="onboarding-welcome" @click.native="onLinkClick"></screen-link>
            <screen-link left="10%" top="19.5%" width="20%" height="4.5%" data-tgt-scrolltop="true" data-tgt="onboarding-w4" @click.native="onLinkClick"></screen-link>
            <screen-link left="10%" top="26.25%" width="20%" height="4.5%" data-tgt-scrolltop="true" data-tgt="onboarding-state" @click.native="onLinkClick"></screen-link>
            <screen-link left="10%" top="33.5%" width="20%" height="4.5%" data-tgt-scrolltop="true" data-tgt="onboarding-19-verification" @click.native="onLinkClick"></screen-link>
            <screen-link left="10%" top="40.5%" width="20%" height="4.5%" data-tgt-scrolltop="true" data-tgt="onboarding-eeo" @click.native="onLinkClick"></screen-link>
            <screen-link left="10%" top="47.5%" width="20%" height="4.5%" data-tgt-scrolltop="true" data-tgt="onboarding-acknowledgement" @click.native="onLinkClick"></screen-link>
            <screen-link left="10%" top="54.5%" width="20%" height="4.5%" data-tgt-scrolltop="true" data-tgt="onboarding-packet" @click.native="onLinkClick"></screen-link>
            <screen-link left="10%" top="61.75%" width="20%" height="4.5%" data-tgt-scrolltop="true" data-tgt="onboarding-direct-deposit" @click.native="onLinkClick"></screen-link>
            <screen-link left="10%" top="68.75%" width="20%" height="4.5%" data-tgt-scrolltop="true" data-tgt="onboarding-marketplace" @click.native="onLinkClick"></screen-link>
            <screen-link left="10%" top="75.75%" width="20%" height="4.5%" data-tgt-scrolltop="true" data-tgt="onboarding-final-step" @click.native="onLinkClick"></screen-link>
            <screen-link left="10%" top="82.75%" width="20%" height="4.5%" data-tgt-scrolltop="true" data-tgt="onboarding-completed" @click.native="onLinkClick"></screen-link>

            <screen-link left="79%" top="166%" width="10%" height="5.5%" dot-class="dot-left-center" data-tgt="onboarding-direct-deposit-doc" data-tgt-scrolltop="true" @click.native="onLinkClick"></screen-link>

          </div>
        </div>

        <div class="screen z-max" data-screen="onboarding-direct-deposit-doc" :data-active="currentScreen=='onboarding-direct-deposit-doc'">
          <img class="w-full max-w-100" src="/img/screens/peo/onboarding-peo-direct-deposit-doc.png?v=10122022" />
          <div class="links">
            <screen-link left="97%" top="0%" width="3%" height="4.5%" dot-class="dot-bottom-center" data-tgt="hiring-peo-workers-add-details" data-tgt-mode="admin" @click.native="onLinkClick"></screen-link>

            <screen-link left="10%" top="12.25%" width="20%" height="4.5%" data-tgt-scrolltop="true" data-tgt="onboarding-welcome" @click.native="onLinkClick"></screen-link>
            <screen-link left="10%" top="19.5%" width="20%" height="4.5%" data-tgt-scrolltop="true" data-tgt="onboarding-w4" @click.native="onLinkClick"></screen-link>
            <screen-link left="10%" top="26.25%" width="20%" height="4.5%" data-tgt-scrolltop="true" data-tgt="onboarding-state" @click.native="onLinkClick"></screen-link>
            <screen-link left="10%" top="33.5%" width="20%" height="4.5%" data-tgt-scrolltop="true" data-tgt="onboarding-19-verification" @click.native="onLinkClick"></screen-link>
            <screen-link left="10%" top="40.5%" width="20%" height="4.5%" data-tgt-scrolltop="true" data-tgt="onboarding-eeo" @click.native="onLinkClick"></screen-link>
            <screen-link left="10%" top="47.5%" width="20%" height="4.5%" data-tgt-scrolltop="true" data-tgt="onboarding-acknowledgement" @click.native="onLinkClick"></screen-link>
            <screen-link left="10%" top="54.5%" width="20%" height="4.5%" data-tgt-scrolltop="true" data-tgt="onboarding-packet" @click.native="onLinkClick"></screen-link>
            <screen-link left="10%" top="61.75%" width="20%" height="4.5%" data-tgt-scrolltop="true" data-tgt="onboarding-direct-deposit" @click.native="onLinkClick"></screen-link>
            <screen-link left="10%" top="68.75%" width="20%" height="4.5%" data-tgt-scrolltop="true" data-tgt="onboarding-marketplace" @click.native="onLinkClick"></screen-link>
            <screen-link left="10%" top="75.75%" width="20%" height="4.5%" data-tgt-scrolltop="true" data-tgt="onboarding-final-step" @click.native="onLinkClick"></screen-link>
            <screen-link left="10%" top="82.75%" width="20%" height="4.5%" data-tgt-scrolltop="true" data-tgt="onboarding-completed" @click.native="onLinkClick"></screen-link>

            <screen-link left="78.5%" top="164%" width="10%" height="5.5%" dot-class="dot-left-center" data-tgt="onboarding-state" @click.native="showOverlay('sign-banking')"></screen-link>
            <document-sign v-if="$store.state.overlayPanel === 'sign-banking'" person="Jennifer Trayler" confirm-tgt="onboarding-marketplace" confirm-mode="peo"></document-sign>


          </div>
        </div>

        <div class="screen z-max" data-screen="onboarding-marketplace" :data-active="currentScreen=='onboarding-marketplace'">
          <img class="w-full max-w-100" src="/img/screens/peo/onboarding-peo-marketplace.png?v=10122022" />
          <div class="links">
            <screen-link left="97%" top="0%" width="3%" height="4.5%" dot-class="dot-bottom-center" data-tgt="hiring-peo-workers-add-details" data-tgt-mode="admin" @click.native="onLinkClick"></screen-link>

            <screen-link left="10%" top="12.25%" width="20%" height="4.5%" data-tgt-scrolltop="true" data-tgt="onboarding-welcome" @click.native="onLinkClick"></screen-link>
            <screen-link left="10%" top="19.5%" width="20%" height="4.5%" data-tgt-scrolltop="true" data-tgt="onboarding-w4" @click.native="onLinkClick"></screen-link>
            <screen-link left="10%" top="26.25%" width="20%" height="4.5%" data-tgt-scrolltop="true" data-tgt="onboarding-state" @click.native="onLinkClick"></screen-link>
            <screen-link left="10%" top="33.5%" width="20%" height="4.5%" data-tgt-scrolltop="true" data-tgt="onboarding-19-verification" @click.native="onLinkClick"></screen-link>
            <screen-link left="10%" top="40.5%" width="20%" height="4.5%" data-tgt-scrolltop="true" data-tgt="onboarding-eeo" @click.native="onLinkClick"></screen-link>
            <screen-link left="10%" top="47.5%" width="20%" height="4.5%" data-tgt-scrolltop="true" data-tgt="onboarding-acknowledgement" @click.native="onLinkClick"></screen-link>
            <screen-link left="10%" top="54.5%" width="20%" height="4.5%" data-tgt-scrolltop="true" data-tgt="onboarding-packet" @click.native="onLinkClick"></screen-link>
            <screen-link left="10%" top="61.75%" width="20%" height="4.5%" data-tgt-scrolltop="true" data-tgt="onboarding-direct-deposit" @click.native="onLinkClick"></screen-link>
            <screen-link left="10%" top="68.75%" width="20%" height="4.5%" data-tgt-scrolltop="true" data-tgt="onboarding-marketplace" @click.native="onLinkClick"></screen-link>
            <screen-link left="10%" top="75.75%" width="20%" height="4.5%" data-tgt-scrolltop="true" data-tgt="onboarding-final-step" @click.native="onLinkClick"></screen-link>
            <screen-link left="10%" top="82.75%" width="20%" height="4.5%" data-tgt-scrolltop="true" data-tgt="onboarding-completed" @click.native="onLinkClick"></screen-link>

            <screen-link left="81%" top="34%" width="8%" height="5.5%" dot-class="dot-left-center" data-tgt="onboarding-final-step" data-tgt-scrolltop="true" @click.native="onLinkClick"></screen-link>

          </div>
        </div>

        <div class="screen z-max" data-screen="onboarding-final-step" :data-active="currentScreen=='onboarding-final-step'">
          <img class="w-full max-w-100" src="/img/screens/peo/onboarding-peo-final-step.png?v=10122022" />
          <div class="links">
            <screen-link left="97%" top="0%" width="3%" height="4.5%" dot-class="dot-bottom-center" data-tgt="hiring-peo-workers-add-details" data-tgt-mode="admin" @click.native="onLinkClick"></screen-link>

            <screen-link left="10%" top="12.25%" width="20%" height="4.5%" data-tgt-scrolltop="true" data-tgt="onboarding-welcome" @click.native="onLinkClick"></screen-link>
            <screen-link left="10%" top="19.5%" width="20%" height="4.5%" data-tgt-scrolltop="true" data-tgt="onboarding-w4" @click.native="onLinkClick"></screen-link>
            <screen-link left="10%" top="26.25%" width="20%" height="4.5%" data-tgt-scrolltop="true" data-tgt="onboarding-state" @click.native="onLinkClick"></screen-link>
            <screen-link left="10%" top="33.5%" width="20%" height="4.5%" data-tgt-scrolltop="true" data-tgt="onboarding-19-verification" @click.native="onLinkClick"></screen-link>
            <screen-link left="10%" top="40.5%" width="20%" height="4.5%" data-tgt-scrolltop="true" data-tgt="onboarding-eeo" @click.native="onLinkClick"></screen-link>
            <screen-link left="10%" top="47.5%" width="20%" height="4.5%" data-tgt-scrolltop="true" data-tgt="onboarding-acknowledgement" @click.native="onLinkClick"></screen-link>
            <screen-link left="10%" top="54.5%" width="20%" height="4.5%" data-tgt-scrolltop="true" data-tgt="onboarding-packet" @click.native="onLinkClick"></screen-link>
            <screen-link left="10%" top="61.75%" width="20%" height="4.5%" data-tgt-scrolltop="true" data-tgt="onboarding-direct-deposit" @click.native="onLinkClick"></screen-link>
            <screen-link left="10%" top="68.75%" width="20%" height="4.5%" data-tgt-scrolltop="true" data-tgt="onboarding-marketplace" @click.native="onLinkClick"></screen-link>
            <screen-link left="10%" top="75.75%" width="20%" height="4.5%" data-tgt-scrolltop="true" data-tgt="onboarding-final-step" @click.native="onLinkClick"></screen-link>
            <screen-link left="10%" top="82.75%" width="20%" height="4.5%" data-tgt-scrolltop="true" data-tgt="onboarding-completed" @click.native="onLinkClick"></screen-link>

            <screen-link left="81%" top="51.5%" width="8%" height="5.5%" dot-class="dot-left-center" data-tgt="onboarding-completed" data-tgt-scrolltop="true" @click.native="onLinkClick"></screen-link>

          </div>
        </div>

        <div class="screen z-max" data-screen="onboarding-completed" :data-active="currentScreen=='onboarding-completed'">
          <img class="w-full max-w-100" src="/img/screens/peo/onboarding-peo-completed.png?v=10122022" />
          <div class="links">
            <screen-link left="97%" top="0%" width="3%" height="4.5%" dot-class="dot-bottom-center" data-tgt="hiring-peo-workers-add-details" data-tgt-mode="admin" @click.native="onLinkClick"></screen-link>

            <screen-link left="10%" top="12.25%" width="20%" height="4.5%" data-tgt-scrolltop="true" data-tgt="onboarding-welcome" @click.native="onLinkClick"></screen-link>
            <screen-link left="10%" top="19.5%" width="20%" height="4.5%" data-tgt-scrolltop="true" data-tgt="onboarding-w4" @click.native="onLinkClick"></screen-link>
            <screen-link left="10%" top="26.25%" width="20%" height="4.5%" data-tgt-scrolltop="true" data-tgt="onboarding-state" @click.native="onLinkClick"></screen-link>
            <screen-link left="10%" top="33.5%" width="20%" height="4.5%" data-tgt-scrolltop="true" data-tgt="onboarding-19-verification" @click.native="onLinkClick"></screen-link>
            <screen-link left="10%" top="40.5%" width="20%" height="4.5%" data-tgt-scrolltop="true" data-tgt="onboarding-eeo" @click.native="onLinkClick"></screen-link>
            <screen-link left="10%" top="47.5%" width="20%" height="4.5%" data-tgt-scrolltop="true" data-tgt="onboarding-acknowledgement" @click.native="onLinkClick"></screen-link>
            <screen-link left="10%" top="54.5%" width="20%" height="4.5%" data-tgt-scrolltop="true" data-tgt="onboarding-packet" @click.native="onLinkClick"></screen-link>
            <screen-link left="10%" top="61.75%" width="20%" height="4.5%" data-tgt-scrolltop="true" data-tgt="onboarding-direct-deposit" @click.native="onLinkClick"></screen-link>
            <screen-link left="10%" top="68.75%" width="20%" height="4.5%" data-tgt-scrolltop="true" data-tgt="onboarding-marketplace" @click.native="onLinkClick"></screen-link>
            <screen-link left="10%" top="75.75%" width="20%" height="4.5%" data-tgt-scrolltop="true" data-tgt="onboarding-final-step" @click.native="onLinkClick"></screen-link>
            <screen-link left="10%" top="82.75%" width="20%" height="4.5%" data-tgt-scrolltop="true" data-tgt="onboarding-completed" @click.native="onLinkClick"></screen-link>

            <screen-link left="81%" top="75%" width="8%" height="5.5%" dot-class="dot-left-center" data-tgt="hiring-peo-workers-add-details" data-tgt-mode="admin" @click.native="onLinkClick"></screen-link>

          </div>
        </div>




      </div>

    </div>

  </div>

</template>

<script>
import ScreenLink from "../components/ScreenLink";
import DocumentSign from "@/components/onboarding/DocumentSign";
//import FlexNavTopBar from "../components/FlexNavTopBar";
//import FlexSideNav from "../components/FlexAdminSideNav";
//import InfoBox from "../components/InfoBox";

// import PeoplePullout from "@/components/PeoplePullout";
// import SelectBox from "@/components/SelectBox";
// import NewsPullout from "@/components/NewsPullout";
// import NotificationsPullout from "@/components/NotificationsPullout";

// import FiltersPanel from "../components/FiltersPanel";
// import SelectBox from "@/components/SelectBox";
// import FlexInfo from "@/components/FlexInfo";

export default {
  name: "peo-onboarding-screens",
  components: {DocumentSign, ScreenLink, /*FiltersPanel, FlexInfo, SelectBox,*/ },
  mounted: function(){

    this.$store.state.screenScrollRef = window;

    // this.$store.commit("showMenu", false);
    // if(this.$route.query){
    //   this.$store.commit("guidedMode", this.$route.query.guided);
    //   if(this.$route.query.comments){
    //     this.$store.commit("showFeedback", true);
    //   }
    // }

  },
  props: {},
  data: function () {
    return {
      defaultScreen: 'onboarding-begin',
      visibility: {
        topnav: true
      },
      pulloutPanel: null,
      disableScreenScoll: false,


    }
  },
  methods: {

    close: function(e){
      this.$screens.onLinkClick(e)
    },

    onSetGuided: function(guided){

      //this.guidedMode = guided;
      //this.showMenu = false;

      this.$store.commit("guidedMode", guided);
      this.$store.commit("showMenu", false);

    },


    showPrevious: function(){
      this.$router.back();
    },

    onLinkClick: function(e){
      // this.currentScreen = e.target.dataset.tgt;
      if(!e.target.dataset.tgtMode){
        e.target.dataset.tgtMode = 'peo';
      }

      this.$screens.onLinkClick(e);

      // if(e.target.dataset.commit){
      //   this.$store.commit(e.target.dataset.commit, e.target.dataset.commitValue);
      // }
      //
      // if(!e.target.dataset.tgt && this.pulloutPanel) {
      //   this.pulloutPanel = null;
      // }else if(e.target.dataset.isOverlay){
      //   this.overlayPanel = e.target.dataset.tgt
      // }else{
      //
      //
      //   this.setScreen(e.target.dataset.tgt, e.target.dataset.tgtMode,  e.target.dataset.tgtScrolltop);
      // }


    },

    showExternalPage: function(e){
      // this.currentScreen = e.target.dataset.tgt;
      let tgtUrl = e.target.dataset.tgt;
      window.open(tgtUrl, "_blank");
    },

    showPullout: function(e){
      // this.currentScreen = e.target.dataset.tgt;
      this.pulloutPanel = e.target.dataset.tgt
    },

    onSelectClick: function(tgt){
      // this.currentScreen = e.target.dataset.tgt;

      this.setScreen(tgt);



    },



    onSideNavLink: function(link){
      if(link){
        let linkParams = link.split(":");
        if(linkParams[0] == "onboarding"){

          this.$router.push({name: "onboarding", params: { screen: linkParams[1]}});

        }
      }

    },

    onTopNavLink: function(link){


      if(link){
        let linkParams = link.split(":");
        if(linkParams[0] == "pullout"){
          this.showOverlay(linkParams[1]);

        }
      }

    },

    showOverlay: function(id){
      this.$screens.setOverlayPanel(id)
      //this.overlayPanel = id;
    }
  },
  computed: {

    showMenu: function(){
      return this.$store.state.showMenu;
    },

    showLinks: function(){
      return this.$store.state.showLinks;
    },

    guidedMode: function(){
      return this.$store.state.guidedMode;
    },

    currentScreen: function(){

      let target = this.defaultScreen;
      if(this.$route.params.screen){
        target = this.$route.params.screen;
      }

      return target
    },

    sideNavLink: function(){
      if(this.$route.params.screen){
        return this.$route.params.screen.split("-")[0];
      }else{
        return "dashboard";
      }
    },

    sideNavMode: function(){
      if(this.$route.query.mode){
        return this.$route.query.mode;
      }else{
        return "admin";
      }
    }
  }
}
</script>

<style scoped>

img {
  margin: 0;
}

.screen-container {

  margin: 0px auto;
}

#app[data-feedback-mode="true"] .screen-container {

  margin: 0;

}


.demo {
  width: 100%;
  position: relative;



}

.demo[data-noscroll="true"]{


}



.screens {

  position: relative;
  width: 100%;
  overflow-x: hidden;
}

.overlay {
  position: absolute;
  width: calc((320/1580) * 100%);
  top: calc((60/920) * 100%);
  right: 0;
  display: none;
}

.overlay[data-active="true"] {
  display: block;
}

.pullout {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  right: 0;
  display: none;
  background-color: rgba(0,0,0,0.49);
}

.pullout[data-active="true"] {
  display: block;
}

.popover {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  right: 0;
  display: none;
}

.popover[data-active="true"] {
  display: block;
}

.overlay[data-active="true"] {
  display: block;
}

.screens .screen {
  position: relative;
  width: 100%;
  display: none;
}

.screen[data-active="true"] {
  display: block;
}

.screen .links {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 0;
  padding-top: calc((920/1580) * 100%);
}

.pullout .links {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 0;
  padding-top: calc((920/1580) * 100%);
}


/* UTILITIES */

.w-full {
  width: 100%;
}



.pullout-1200 {
  position: absolute;
  width: calc(100% * (1200 / 3160));
  top: 0;
  right: 0;
}

.popover-email {
  position: absolute;
  width: calc(100% * (1000 / 1580));
  top: 20%;
  right: 5%;
  box-shadow: 0 10px 20px rgba(0,0,0,0.3);

}

.popover-with-bkg {
  background-color: rgba(0,0,0,0.4);


}

.popover-time-attendance {
  position: absolute;
  width: calc(100% * (1000 / 1580));
  top: 20%;
  right: 15%;
  box-shadow: 0 10px 20px rgba(0,0,0,0.3);

}

.popover-schedule-details {
  position: absolute;
  width: calc(100% * (808 / 1580));
  top: 20%;
  right: 20%;
  box-shadow: 0 10px 20px rgba(0,0,0,0.3);

}

.x-ray {
  opacity: 0.5;
}

*[data-hidden] {
  opacity: 0;
}



.demo[data-show-links="true"] .select-box {

}

.demo[data-show-links="true"] .flex-info {
  border: 2px solid var(--color-green);
}

.demo[data-show-links="true"] .info-box {
  display: block;
}

.demo .info-box {
  display: none;
}


.demo[data-guided="true"] .info-box {
  display: block;
}

.z-max {
  z-index: 50;
}

.z-45 {
  z-index: 45;
}

.z-40 {
  z-index: 40;
}

.info-box {
  z-index: 30;
}

.demo-menu {
  top: 0;
}

</style>

<style>
html {
  background: #F3F7FA;
}
</style>
