


<template>
<div class="basic-pullout pullout-panel">
  <div class="bkg"></div>
  <div class="primary-panel">

    <div class="basic-scroll" ref="basicScroll">
      <div class="detail-panel" v-if="detailPanel">

        <div class="detail-content" :data-active="detailPanel=='contributions'" >
          <img class="w-full" src="/img/screens/retirement/pullout-contributions.png?v=10182023" />
          <div class="links">
            <screen-link left="68%" top="1%" width="14%" height="5%" data-tgt="" dot-class="dot-bottom-center" data-is-overlay="true" @click.native="$emit('close')"></screen-link>
            <screen-link left="75%" top="12%" width="20%" height="4%" data-tgt="contributions-10" dot-class="dot-right-center" @click.native="showDetails"></screen-link>
            <screen-link left="83%" top="1%" width="14%" height="5%" data-tgt="contributions-10-confirm" dot-class="dot-bottom-center"  @click.native="setContribution(2)"></screen-link>
          </div>
        </div>

        <div class="detail-content" :data-active="detailPanel=='contributions-10'" >
          <img class="w-full" src="/img/screens/retirement/pullout-contributions-10.png?v=10182023" />
          <div class="links">
            <screen-link left="68%" top="1%" width="14%" height="5%" data-tgt="" dot-class="dot-bottom-center" data-is-overlay="true" @click.native="$emit('close')"></screen-link>
            <screen-link left="75%" top="12%" width="20%" height="4%" data-tgt="contributions" dot-class="dot-right-center" @click.native="showDetails"></screen-link>
            <screen-link left="83%" top="1%" width="14%" height="5%" data-tgt="contributions-10-confirm" dot-class="dot-bottom-center"  @click.native="showDetails"></screen-link>
          </div>
        </div>

        <div class="detail-content" :data-active="detailPanel=='contributions-10-confirm'" >
          <img class="w-full" src="/img/screens/retirement/pullout-contributions-10-confirm.png?v=10182023" />
          <div class="links">
            <screen-link left="88%" top="1%" width="10%" height="5%" data-tgt="" dot-class="dot-bottom-center" data-is-overlay="true" @click.native="$emit('close')"></screen-link>
            <screen-link left="1%" top="1%" width="12%" height="5%" data-tgt="contributions-10" dot-class="dot-bottom-center"  @click.native="showDetails"></screen-link>
            <screen-link left="83%" top="45%" width="14%" height="5%" data-tgt="contributions-10-confirm" dot-class="dot-bottom-center"  @click.native="setContribution(5)"></screen-link>
          </div>
        </div>

        <div class="detail-content" :data-active="detailPanel=='contributions-auto-increase'" >
          <img class="w-full" src="/img/screens/retirement/pullout-contributions-auto-increase.png?v=10182023" />
          <div class="links">

            <screen-link left="68%" top="1%" width="14%" height="5%" data-tgt="" dot-class="dot-bottom-center"  data-is-overlay="true" @click.native="$emit('close')"></screen-link>
            <screen-link left="83%" top="1%" width="14%" height="5%" data-tgt="" dot-class="dot-bottom-center"  data-is-overlay="true" @click.native="setAutoIncrease(false)"></screen-link>
            <screen-link left="84%" top="26.5%" width="13%" height="5%" data-tgt="contributions-auto-increase-on" dot-class="dot-left-center"  @click.native="showDetails"></screen-link>

          </div>
        </div>

        <div class="detail-content" :data-active="detailPanel=='contributions-auto-increase-on'" >
          <img class="w-full" src="/img/screens/retirement/pullout-contributions-auto-increase-on.png?v=10182023" />
          <div class="links">

            <screen-link left="68%" top="1%" width="14%" height="5%" data-tgt="" dot-class="dot-bottom-center"  data-is-overlay="true" @click.native="$emit('close')"></screen-link>
            <screen-link left="83%" top="1%" width="14%" height="5%" data-tgt="" dot-class="dot-bottom-center"  data-is-overlay="true" @click.native="setAutoIncrease(true)"></screen-link>
            <screen-link left="84%" top="26.5%" width="13%" height="5%" data-tgt="contributions-auto-increase" dot-class="dot-left-center"  @click.native="showDetails"></screen-link>

          </div>
        </div>

        <div class="detail-content" :data-active="detailPanel=='projections'" >
          <img class="w-full" src="/img/screens/retirement/pullout-projections.png?v=10182023" />
          <div class="links">

            <screen-link left="92%" top="1%" width="8%" height="5%" data-tgt="" dot-class="dot-bottom-center"  data-is-overlay="true" @click.native="$emit('close')"></screen-link>
            <screen-link left="2%" top="61%" width="94%" height="5%" data-tgt="projections-edited" dot-class="dot-right-center"  @click.native="showDetails"></screen-link>

          </div>
        </div>

        <div class="detail-content" :data-active="detailPanel=='projections-edited'" >
          <img class="w-full" src="/img/screens/retirement/pullout-projections-edited.png?v=10182023" />
          <div class="links">

            <screen-link left="92%" top="1%" width="8%" height="5%" data-tgt="" dot-class="dot-bottom-center"  data-is-overlay="true" @click.native="$emit('close')"></screen-link>
            <screen-link left="76%" top="24%" width="19%" height="5%" data-tgt="projections-calculate" dot-class="dot-right-center"  @click.native="showDetails"></screen-link>

          </div>
        </div>

        <div class="detail-content" :data-active="detailPanel=='projections-calculate'" >
          <img class="w-full" src="/img/screens/retirement/pullout-projections-calculate.png?v=10182023" />
          <div class="links">

            <screen-link left="92%" top="1%" width="8%" height="5%" data-tgt="" dot-class="dot-bottom-center"  data-is-overlay="true" @click.native="$emit('close')"></screen-link>
            <screen-link left="2%" top="40%" width="94%" height="5%" data-tgt="contributions-auto-increase" dot-class="dot-right-center"  @click.native="showDetails"></screen-link>
            <screen-link left="2%" top="90%" width="96%" height="18%" data-tgt="contributions" dot-class="dot-right-center"  @click.native="showDetails"></screen-link>

          </div>
        </div>

    </div>

  </div>
  </div>


</div>
</template>

<script>
import ScreenLink from "@/components/ScreenLink";


export default {
    name: "retirement-contributions-pullout",
  components: {ScreenLink},
  props: {
      employeeState: {}
  },
    data: function(){
        return {
          detailPanel: "contributions",
          showOptions: false,
        }
        },
    mounted() {
      if(this.$route.query.level !== undefined){
        this.detailPanel = this.$route.query.level;
      }else{
        if(this.initInfo){
          this.detailPanel = this.initInfo; //start screen
        }
      }
    },

    watch: {
      $route: function(next){
        if(next.query.level !== undefined){
          this.detailPanel = next.query.level;
        }
      }
    },

    methods: {

      setContribution: function(value){
        console.log(value);
        this.employeeState.retirement.contribution = value;
        this.$emit('close');
      },

      setAutoIncrease: function(value){
        console.log(value);
        this.employeeState.retirement.autoIncrease = value;
        this.$emit('close');
      },

      showDetails: function(e){

        this.showOptions = false;

        this.detailPanel = e.target.dataset.tgt;

        if(!this.detailPanel){
          this.$emit('close');
          return;
        }

        let query = {...this.$route.query};
        query.level=e.target.dataset.tgt;
        this.$router.replace({ name: this.$route.name, params: this.$route.params, query: query });
        this.$refs['basicScroll'].scrollTo(0,0);


      },

    },
    computed: {

    }
}
</script>

<style scoped>


/* SEE GLOBAL CSS FOR BASIC PULLOUT CLASSES */

.close-icon {
  padding: 0.5em;
  position: absolute;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
}

.detail-content {
  display: none;
}

.detail-content[data-active="true"] {
  display: block;
}

</style>
