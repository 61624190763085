


<template>
<div class="tools-pullout pullout-panel">
  <div class="bkg"></div>

  <div class="help-list">


    <div class="help-scroll" v-if="detailPanel" ref="scrollPanel">
      <div v-if="$store.state.company === 'pro'" class="detail-content" data-screen="profile" :data-active="detailPanel=='profile'" >
        <img class="w-full" src="/img/screens/people/pullout-people-pro-andrea.png?v=10122022" />
        <div class="links">
          <screen-link left="92%" top="1%" width="7%" height="4%" data-tgt="" @click.native="$emit('close')"></screen-link>
          <screen-link left="5%" top="50%" width="90%" height="6%" data-tgt="profile-details" dot-class="dot-right-center" @click.native="showDetails"></screen-link>
          <screen-link left="5%" top="73.5%" width="90%" height="6%" data-tgt="pay-compensation" dot-class="dot-right-center" @click.native="showDetails"></screen-link>




        </div>
      </div>

      <div v-else class="detail-content" data-screen="profile" :data-active="detailPanel=='profile'" >
        <img class="w-full" src="/img/screens/people/pullout-people-andrea.png?v=10122022" />
        <div class="links">
          <screen-link left="92%" top="1%" width="7%" height="4%" data-tgt="" @click.native="$emit('close')"></screen-link>
          <screen-link left="5%" top="50%" width="90%" height="6%" data-tgt="profile-details" dot-class="dot-right-center" @click.native="showDetails"></screen-link>
          <screen-link left="5%" top="73.5%" width="90%" height="6%" data-tgt="pay-compensation" dot-class="dot-right-center" @click.native="showDetails"></screen-link>

          <screen-link left="5%" top="107.5%" width="90%" height="7%" data-tgt="time-off" dot-class="dot-right-center" @click.native="showDetails"></screen-link>
          <screen-link left="5%" top="119%" width="90%" height="7%" data-tgt="conversations" dot-class="dot-right-center" @click.native="showDetails"></screen-link>
          <screen-link left="5%" top="165.5%" width="90%" height="7%" data-tgt="documents" dot-class="dot-right-center" @click.native="showDetails"></screen-link>


        </div>
      </div>

      <div class="detail-content" data-screen="profile-details" :data-active="detailPanel=='profile-details'" >
        <img class="w-full" src="/img/screens/people/pullout-people-andrea-details.png?v=10122022" />
        <div class="links">

          <screen-link left="92%" top="1%" width="7%" height="4%" data-tgt="" @click.native="$emit('close')"></screen-link>

          <screen-link left="2%" top="1%" width="25%" height="4%" data-tgt="profile" dot-class="dot-right-center" @click.native="showDetails"></screen-link>

        </div>
      </div>

      <div class="detail-content" data-screen="conversations" :data-active="detailPanel=='conversations'" >
        <img class="w-full" src="/img/screens/people/pullout-people-conversations.png?v=10122022" />
        <div class="links">
          <screen-link left="2%" top="1%" width="30%" height="4%" data-tgt="profile" @click.native="showDetails"></screen-link>
          <screen-link left="92%" top="1%" width="7%" height="4%" data-tgt="" @click.native="$emit('close')"></screen-link>
          <screen-link left="77%" top="5.75%" width="21%" height="5%" data-tgt="conversations-add-note" dot-class="dot-left-center" @click.native="showDetails"></screen-link>

        </div>
      </div>

      <div class="detail-content" data-screen="conversations-add-note" :data-active="detailPanel=='conversations-add-note'" >
        <img class="w-full" src="/img/screens/people/pullout-people-conversations-add-note.png?v=10122022" />
        <div class="links">

          <screen-link left="68%" top="1%" width="14%" height="4%" data-tgt="conversations" @click.native="showDetails"></screen-link>


        </div>
      </div>

      <div class="detail-content" data-screen="timeoff" :data-active="detailPanel=='time-off'" >
        <img class="w-full" src="/img/screens/people/pullout-people-timeoff.png?v=10122022" />
        <div class="links">
          <screen-link left="2%" top="1%" width="30%" height="4%" data-tgt="profile" @click.native="showDetails"></screen-link>
          <screen-link left="92%" top="1%" width="7%" height="4%" data-tgt="" @click.native="$emit('close')"></screen-link>


        </div>
      </div>

      <div class="detail-content" data-screen="documents" :data-active="detailPanel=='documents'" >
        <img class="w-full" src="/img/screens/people/pullout-people-documents.png?v=10122022" />
        <div class="links">
          <screen-link left="2%" top="1%" width="30%" height="4%" data-tgt="profile" @click.native="showDetails"></screen-link>
          <screen-link left="92%" top="1%" width="7%" height="4%" data-tgt="" @click.native="$emit('close')"></screen-link>
          <screen-link left="77%" top="5.75%" width="21%" height="5%" data-tgt="documents-actions" dot-class="dot-left-center" @click.native="showDetails"></screen-link>

        </div>
      </div>

      <div class="detail-content" data-screen="documents-actions" :data-active="detailPanel=='documents-actions'" >
        <img class="w-full" src="/img/screens/people/pullout-people-documents-actions.png?v=10122022" />
        <div class="links">
          <screen-link left="2%" top="1%" width="30%" height="4%" data-tgt="profile" @click.native="showDetails"></screen-link>
          <screen-link left="92%" top="1%" width="7%" height="4%" data-tgt="" @click.native="$emit('close')"></screen-link>
          <screen-link left="77%" top="5.75%" width="21%" height="5%" data-tgt="documents" dot-class="dot-left-center" @click.native="showDetails"></screen-link>

          <screen-link left="70%" top="12%" width="21%" height="4%" data-tgt="documents-add" dot-class="dot-left-center" @click.native="showDetails"></screen-link>
          <screen-link left="70%" top="17.5%" width="27%" height="4%" data-tgt="documents-request" dot-class="dot-left-center" @click.native="showDetails"></screen-link>


        </div>
      </div>

      <div class="detail-content" data-screen="documents-add" :data-active="detailPanel=='documents-add'" >
        <img class="w-full" src="/img/screens/people/pullout-people-documents-add.png?v=10122022" />
        <div class="links">

          <screen-link left="68%" top="1%" width="14%" height="4%" data-tgt="documents" @click.native="showDetails"></screen-link>
        </div>
      </div>

      <div class="detail-content" data-screen="documents-request" :data-active="detailPanel=='documents-request'" >
        <img class="w-full" src="/img/screens/people/pullout-people-documents-request.png?v=10122022" />
        <div class="links">

          <screen-link left="68%" top="1%" width="14%" height="4%" data-tgt="documents" @click.native="showDetails"></screen-link>
          <screen-link left="5%" top="28%" width="90%" height="4%" data-tgt="documents-request-title" @click.native="showDetails"></screen-link>
        </div>
      </div>


      <div class="detail-content" data-screen="documents-request-title" :data-active="detailPanel=='documents-request-title'" >
        <img class="w-full" src="/img/screens/people/pullout-people-documents-request-title.png?v=10122022" />
        <div class="links">

          <screen-link left="68%" top="1%" width="14%" height="4%" data-tgt="documents" @click.native="showDetails"></screen-link>
          <screen-link left="5%" top="51%" width="90%" height="5%" data-tgt="documents-request-categories" @click.native="showDetails"></screen-link>
        </div>
      </div>

      <div class="detail-content" data-screen="documents-request-categories" :data-active="detailPanel=='documents-request-categories'" >
        <img class="w-full" src="/img/screens/people/pullout-people-documents-request-categories.png?v=10122022" />
        <div class="links">

          <screen-link left="80%" top="71%" width="16%" height="5.5%" data-tgt="documents-request-title" @click.native="showDetails"></screen-link>
          <screen-link left="3%" top="59%" width="80%" height="5%" data-tgt="documents-request-details" @click.native="showDetails"></screen-link>
        </div>
      </div>

      <div class="detail-content" data-screen="documents-request-details" :data-active="detailPanel=='documents-request-details'" >
        <img class="w-full" src="/img/screens/people/pullout-people-documents-request-details.png?v=10122022" />
        <div class="links">

          <screen-link left="68%" top="1%" width="14%" height="4%" data-tgt="documents" @click.native="showDetails"></screen-link>
          <screen-link left="82.5%" top="0.5%" width="15%" height="5.5%" data-tgt="documents-request-added" dot-class="dot-right-center"   @click.native="showDetails"></screen-link>

        </div>
      </div>

      <div class="detail-content" data-screen="documents-request-added" :data-active="detailPanel=='documents-request-added'" >
        <img class="w-full" src="/img/screens/people/pullout-people-documents-request-added.png?v=10122022" />
        <div class="links">

          <screen-link left="2%" top="1%" width="30%" height="4%" data-tgt="profile" @click.native="showDetails"></screen-link>
          <screen-link left="92%" top="1%" width="7%" height="4%" data-tgt="" @click.native="showDetails"></screen-link>
        </div>
      </div>



      <div class="detail-content" data-screen="pay-compensation" :data-active="detailPanel=='pay-compensation'" >
        <img class="w-full" src="/img/screens/people/pullout-people-pay-compensation.png?v=10122022" />
        <div class="links">
          <screen-link left="2%" top="1%" width="30%" height="4%" data-tgt="profile" @click.native="showDetails"></screen-link>
          <screen-link left="92%" top="1%" width="7%" height="4%" data-tgt="" @click.native="$emit('close')"></screen-link>
          <screen-link left="2%" top="10%" width="19%" height="6%" data-tgt="pay-compensation" dot-class="dot-bottom-center" @click.native="showDetails"></screen-link>
          <screen-link left="21%" top="10%" width="14%" height="6%" data-tgt="pay-history" dot-class="dot-bottom-center" @click.native="showDetails"></screen-link>
          <screen-link left="63%" top="10%" width="17.5%" height="6%" data-tgt="pay-deposit" dot-class="dot-bottom-center" @click.native="showDetails"></screen-link>
        </div>
      </div>

      <div class="detail-content" data-screen="pay-history" :data-active="detailPanel=='pay-history'" >
        <img class="w-full" src="/img/screens/people/pullout-people-pay-history.png?v=10122022" />
        <div class="links">
          <screen-link left="2%" top="1%" width="30%" height="4%" data-tgt="profile" @click.native="showDetails"></screen-link>
          <screen-link left="92%" top="1%" width="7%" height="4%" data-tgt="" @click.native="$emit('close')"></screen-link>
          <screen-link left="2%" top="10%" width="19%" height="6%" data-tgt="pay-compensation" dot-class="dot-bottom-center" @click.native="showDetails"></screen-link>
          <screen-link left="21%" top="10%" width="14%" height="6%" data-tgt="pay-history" dot-class="dot-bottom-center" @click.native="showDetails"></screen-link>
          <screen-link left="4%" top="24.5%" width="92%" height="11%" data-tgt="pay-history-details" dot-class="dot-right-center" @click.native="showDetails"></screen-link>
          <screen-link left="63%" top="10%" width="17.5%" height="6%" data-tgt="pay-deposit" dot-class="dot-bottom-center" @click.native="showDetails"></screen-link>
        </div>
      </div>

      <div class="detail-content" data-screen="pay-history-details" :data-active="detailPanel=='pay-history-details'" >
        <img class="w-full" src="/img/screens/people/pullout-people-pay-history-details.png?v=10122022" />
        <div class="links">
          <screen-link left="2%" top="1%" width="30%" height="4%" data-tgt="pay-history" @click.native="showDetails"></screen-link>
          <screen-link left="92%" top="1%" width="7%" height="4%" data-tgt="" @click.native="$emit('close')"></screen-link>
          <screen-link left="78%" top="7%" width="19%" height="5%" data-tgt="pay-history-details-edit" dot-class="dot-bottom-center" @click.native="showDetails"></screen-link>

        </div>
      </div>

      <div class="detail-content" data-screen="pay-history-details-edit" :data-active="detailPanel=='pay-history-details-edit'" >
        <img class="w-full" src="/img/screens/people/pullout-people-pay-history-details-edit.png?v=10122022" />
        <div class="links">
          <screen-link left="67%" top="3%" width="16%" height="5%" dot-class="dot-bottom-center" data-tgt="pay-history-details" @click.native="showDetails"></screen-link>

        </div>
      </div>


      <div class="detail-content" data-screen="pay-deposit" :data-active="detailPanel=='pay-deposit'" >
        <img class="w-full" src="/img/screens/pullout-people-pay-deposit.png?v=10122022" />
        <div class="links">
          <screen-link left="2%" top="1%" width="30%" height="4%" data-tgt="profile" @click.native="showDetails"></screen-link>
          <screen-link left="92%" top="1%" width="7%" height="4%" data-tgt="" @click.native="showDetails"></screen-link>
          <screen-link left="2%" top="10%" width="19%" height="6%" data-tgt="pay-compensation" dot-class="dot-bottom-center" @click.native="showDetails"></screen-link>
          <screen-link left="21%" top="10%" width="14%" height="6%" data-tgt="pay-history" dot-class="dot-bottom-center" @click.native="showDetails"></screen-link>
          <screen-link left="4%" top="55%" width="92%" height="6%" data-tgt="pay-deposit-add" dot-class="dot-bottom-center" @click.native="showDetails"></screen-link>
          <screen-link left="63%" top="10%" width="17.5%" height="6%" data-tgt="pay-deposit" dot-class="dot-bottom-center" @click.native="showDetails"></screen-link>

        </div>
      </div>
      <div class="detail-content" data-screen="pay-deposit-add" :data-active="detailPanel=='pay-deposit-add'" >
        <img class="w-full" src="/img/screens/pullout-people-pay-deposit-add.png?v=10122022" />
        <div class="links">
          <screen-link left="2%" top="1%" width="30%" height="4%" data-tgt="profile" @click.native="showDetails"></screen-link>
          <screen-link left="92%" top="1%" width="7%" height="4%" data-tgt="" @click.native="showDetails"></screen-link>
          <screen-link left="2%" top="10%" width="19%" height="6%" data-tgt="pay-compensation" dot-class="dot-bottom-center" @click.native="showDetails"></screen-link>
          <screen-link left="21%" top="10%" width="14%" height="6%" data-tgt="pay-history" dot-class="dot-bottom-center" @click.native="showDetails"></screen-link>
          <screen-link left="4%" top="31%" width="48%" height="7%" data-tgt="pay-deposit-add-hsbc" dot-class="dot-left-center" @click.native="showDetails"></screen-link>
          <screen-link left="68%" top="17%" width="14%" height="5%" data-tgt="pay-deposit" dot-class="dot-bottom-center" @click.native="showDetails"></screen-link>
          <screen-link left="63%" top="10%" width="17.5%" height="6%" data-tgt="pay-deposit" dot-class="dot-bottom-center" @click.native="showDetails"></screen-link>

        </div>
      </div>

      <div class="detail-content" data-screen="pay-deposit-add-hsbc" :data-active="detailPanel=='pay-deposit-add-hsbc'" >
        <img class="w-full" src="/img/screens/pullout-people-pay-deposit-add-hsbc.png?v=10122022" />
        <div class="links">
          <screen-link left="2%" top="1%" width="30%" height="4%" data-tgt="profile" @click.native="showDetails"></screen-link>
          <screen-link left="92%" top="1%" width="7%" height="4%" data-tgt="" @click.native="showDetails"></screen-link>
          <screen-link left="2%" top="10%" width="19%" height="6%" data-tgt="pay-compensation" dot-class="dot-bottom-center" @click.native="showDetails"></screen-link>
          <screen-link left="21%" top="10%" width="14%" height="6%" data-tgt="pay-history" dot-class="dot-bottom-center" @click.native="showDetails"></screen-link>
          <screen-link left="68%" top="17%" width="14%" height="5%" data-tgt="pay-deposit" dot-class="dot-bottom-center" @click.native="showDetails"></screen-link>
          <screen-link left="83%" top="17%" width="14%" height="5%" data-tgt="pay-deposit-hsbc-added" dot-class="dot-bottom-center" @click.native="showDetails"></screen-link>
          <screen-link left="63%" top="10%" width="17.5%" height="6%" data-tgt="pay-deposit" dot-class="dot-bottom-center" @click.native="showDetails"></screen-link>

        </div>
      </div>

      <div class="detail-content" data-screen="pay-deposit-hsbc-added" :data-active="detailPanel=='pay-deposit-hsbc-added'" >
        <img class="w-full" src="/img/screens/pullout-people-pay-deposit-hsbc-added.png?v=10122022" />
        <div class="links">
          <screen-link left="2%" top="1%" width="30%" height="4%" data-tgt="profile" @click.native="showDetails"></screen-link>
          <screen-link left="92%" top="1%" width="7%" height="4%" data-tgt="" @click.native="showDetails"></screen-link>
          <screen-link left="2%" top="10%" width="19%" height="6%" data-tgt="pay-compensation" dot-class="dot-bottom-center" @click.native="showDetails"></screen-link>
          <screen-link left="21%" top="10%" width="14%" height="6%" data-tgt="pay-history" dot-class="dot-bottom-center" @click.native="showDetails"></screen-link>
          <screen-link left="63%" top="10%" width="17.5%" height="6%" data-tgt="pay-deposit" dot-class="dot-bottom-center" @click.native="showDetails"></screen-link>

        </div>
      </div>


      <div class="detail-content" data-screen="invite" :data-active="detailPanel=='invite'" >
        <img class="w-full" src="/img/screens/pullout-people-invites.png?v=10122022" />
        <div class="links">

          <screen-link left="92%" top="1%" width="7%" height="4%" data-tgt="" @click.native="$emit('close')"></screen-link>


        </div>
      </div>
    </div>

  </div>



</div>
</template>

<script>
import ScreenLink from "@/components/ScreenLink";
export default {
    name: "profile-pullout",
  components: {ScreenLink},
  props: {},
    data: function(){
        return {
          detailPanel: "profile"
        }
        },
    mounted() {
      if(this.$route.query.level !== undefined){
        this.detailPanel = this.$route.query.level;
      }
    },

    watch: {
      $route: function(next){
        if(next.query.level !== undefined){
          this.detailPanel = next.query.level;
        }
      }
    },

    methods: {
      showDetails: function(e){
          this.detailPanel = e.target.dataset.tgt;
let query = {...this.$route.query};
          query.level=e.target.dataset.tgt;
          this.$router.replace({ name: this.$route.name, params: this.$route.params, query: query });
          this.$refs.scrollPanel.scrollTo(0,0);
      },

      onLinkClick: function(e){
        console.log(e);
        this.$emit('link', e);
      },
      showExternalPage: function(e){
        this.$emit("external", e)
      }

    },
    computed: {}
}
</script>

<style scoped>


.tools-pullout {
  width: 100%;
  position: absolute;
  top: 0;
  height: 100%;


}

.tools-pullout .bkg {
  position: absolute;
  width: 100%;
  height: 100%;
  right: 0;
  background-color: black;
  opacity: 0.4;
}

.tools-pullout img {
  display: block;
}

.tools-pullout bkg {
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: black;
  opacity: 0.4;

}

.help-list {

  position: absolute;
  width: calc((600/1580) * 100% + 15px);
  top: calc((60/920) * 100%);
  bottom: 0;

  right: 0;
  display: flex;
  flex-direction: column;

}



.help-scroll {
  position: relative;

  overflow-y: scroll;
}

.w-full {
  width: 100%;
}

.links {

}

.detail-content {
  display: none;
}


.detail-content[data-active="true"] {
  display: block;
}

</style>
