<template>
  <div class="non-flex-layout" :data-noscroll="$store.state.overlayPanel?true:false"  :data-guided="$store.state.guidedMode" :data-show-links="$store.state.showLinks">
    <div class="non-flex-screens" ref="screens">
      <slot name="screens"></slot>
    </div>

  </div>
</template>

<script>

export default {
  name: "NonFlexLayout",
  components: {
   },
  props: {
    environment: {
      type: String,
      default: "admin"
    },
    lang: {
      type: String,
      default: "en"
    }
  },
  mounted() {
    this.$store.state.screenScrollRef = this.$refs["screens"];
    if(this.$route.query){
      // this.$store.state.pulloutPanel = this.$route.query.pullout?this.$route.query.pullout:"";
      // this.$store.state.overlayPanel = this.$route.query.tab?this.$route.query.tab:"";
    }
  },
  data: function(){
    return {

    }
  },
  methods: {

  },

  computed: {
  }

}
</script>

<style scoped>

  .non-flex-layout {
    width: 100%;
    position: absolute;
    height: 0;
    overflow: hidden;
    padding-bottom: calc((920/1580) * 100%);
    background-repeat: repeat-y;
    background-image: url(../assets/screen-bkg.png);
    background-size: 100% 10%;
    font-size: 1em;
    box-sizing: border-box;
    max-width: 1580px;
  }


  .non-flex-screens {
    position: absolute;
    top: 0;
    left:0;
    width: 100%;
    height: 100%;
    overflow-y:scroll;
    overflow-x: hidden;
  }


  [data-offset = "true"] .non-flex-screens {
    transform: translateX(calc((-180 / 1580) * 100%));
  }


  [data-noscroll="true"]{
    overflow-y: hidden;
  }

  @media(min-width:1580px){

  }

  @media(min-width: 1920px){


  }


</style>